var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex body" }, [
    _c("div", { staticClass: "form stack-y-xl" }, [
      _vm._m(0),
      _c("div", { staticClass: "stack-y-l" }, [
        _c("div", { staticClass: "stack-y-s" }, [
          _vm.isLoading
            ? _c("div", { staticClass: "loading" }, [_vm._v("Loading")])
            : _vm._e(),
          _vm.isSuccess
            ? _c("div", { staticClass: "success" }, [
                _vm.type === "email"
                  ? _c("p", [
                      _vm._v(
                        "Thank You! The link was successfully sent. Please check your email account."
                      )
                    ])
                  : _vm._e(),
                _vm.type === "google"
                  ? _c("p", [_vm._v("Successfully")])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.error
            ? _c("div", { staticClass: "errorMessage" }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "stack-y-s" }, [
          _c("div", { staticClass: "f-p" }, [_vm._v("Login with")]),
          _c(
            "button",
            {
              staticClass:
                "f-title pointer color-button-black size-button-l radius-m ani",
              attrs: { disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleGoogleSubmit($event)
                }
              }
            },
            [_vm._v("Google")]
          )
        ]),
        _c("div", { staticClass: "stack-y-s" }, [
          _c("div", { staticClass: "f-p" }, [_vm._v("or email")]),
          _c(
            "form",
            {
              staticClass: "stack-y-m",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                staticClass: "size-input-l f-p color-input-gray radius-m ani",
                attrs: {
                  type: "email",
                  name: "email",
                  placeholder: "Your email address",
                  autofocus: ""
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass:
                    "f-title pointer color-button-black size-button-l radius-m ani",
                  attrs: { type: "submit", disabled: _vm.isLoading }
                },
                [_vm._v("Submit")]
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "banner pointer", attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/logo/logo-dev.png") }
      }),
      _c("div", { staticClass: "f-h3 title" }, [
        _vm._v("Welcome to Bento"),
        _c("span", { staticClass: "beta pill f-p bold" }, [_vm._v("Dev")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }