<template lang="pug">
.fontscale-editor
  KeyValue(label="Font Size")
    bInputSelector(
      :value="computedFontSize"
      :defaultOptions="['inherit']"
      @change="onFontSizeChange"
    )
  KeyValue(label="Line Height")
    bInputSelector(
      :value="computedLineHeight"
      :defaultOptions="['inherit']"
      @change="onLineHeightChange"
    )
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'
.fontscale-editor
  display inline-grid
  grid-template-columns 1fr 1fr
  grid-column-gap .25rem
</style>

<script>
import LengthEditor from "@/components/form_element/length.vue";
import KeyValue from "@/components/prop_panel/component/label.vue";

export default {
  name: "FontscaleEditor",
  model: {
    prop: "value",
    event: "change"
  },
  components: {
    LengthEditor,
    KeyValue
  },
  props: {
    value: {
      type: String,
      default: "inherit@inherit" // 以 @ 符号作为分隔符 fontSize@lineHeight
    },
    param: String
  },
  watch: {
    value(newValue) {
      // 多人协作，同步更新 & 切换数据源更新 UI
      this.processValue(newValue);
    },
    result(newResult) {
      this.$emit("change", newResult, this.param);
    }
  },
  data() {
    return {
      type: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit"
    };
  },
  computed: {
    computedFontSize() {
      return this.isPixel(this.fontSize) ? Number.parseFloat(this.fontSize) : this.fontSize;
    },
    computedLineHeight() {
      return this.isPixel(this.lineHeight) ? Number.parseFloat(this.lineHeight) : this.lineHeight;
    },
    result() {
      return [this.fontSize, this.lineHeight].join("@");
    }
  },
  methods: {
    processValue(value) {
      if (!value || !value.includes("@")) {
        this.type = "inherit";
        this.fontSize = "inherit";
        this.lineHeight = "inherit";
      } else {
        const valueArr = value.split("@");
        this.fontSize = valueArr[0];
        this.lineHeight = valueArr[1];
        if (valueArr.some(i => i !== "inherit")) {
          this.type = "custom";
        } else {
          this.type = "inherit";
        }
      }
    },
    isPixel(value) {
      const pixelRegx = /^\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    onFontSizeChange(v) {
      this.fontSize = v;
    },
    onLineHeightChange(v) {
      this.lineHeight = v;
    },
    onTypeChange(v) {
      this.type = v;
      if (this.type === "inherit") {
        this.fontSize = "inherit";
        this.lineHeight = "inherit";
      }
    }
  },
  created() {
    this.processValue(this.value);
  }
};
</script>
