<template lang="pug">
  .bg.radius-l.shd-xl
    .f-h3.color-text Select a image
    .stack-x
      input.flex.size-input-m.radius-m.ani-fast.f-title.color-input-gray(
        v-model="keyword"
        placeholder="Search or paste link"
        @keyup.enter="onSubmit"
      )
      input.size-button-m.radius-m.ani-fast.f-title.color-button-black.pointer(
        type="button"
        value="Confirm"
        @click="onSubmit"
      )
    .between(v-if="unsplash.results.length > 0")
      .stack-y
        .stack-x
          .total.f-p.color-text-2 Total: {{ unsplash.total }}
          .curr.f-p.color-text-2 Current Page: {{ unsplash.page }}
        .copyright.color-text-3.f-p Powered by Unsplash
      .prev.color-button-normal.size-button-m.pointer.ani-fast.f-p.radius-m(v-if="hasPrev" @click="handlePrevClick") Prev
      .next.color-button-normal.size-button-m.pointer.ani-fast.f-p.radius-m(v-if="hasNext" @click="handleNextClick") Next
    scroll.flex(dir="y" hideBar)
      .imglist
        .img.radius-s.clip.pointer(
          v-for="item in unsplash.results"
          :key="item.id"
          @click="handleImgClick(item.urls.regular)")
          img.preview(
            :src="item.urls.thumb"
            :alt="item.id"
            )
          .hover.abs.ani-fast.f-title Select

        //- .img(
        //-   v-for="item in unsplash.results"
        //-   :key="item.id"
        //-   @click="handleImgClick(item.urls.regular)"
        //-   )
        //-   img(
        //-     :src="item.urls.thumb"
        //-     :alt="item.id"
        //-     )
</template>
<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.bg
  padding 1.5rem
  background var(--clr-bg)
  stack(y, gap: 1.5rem)
.stack-x
  stack(x, gap: .5rem, ali:center)
.between
    stack(x, gap: .5rem, arr: between)

.stack-y
  stack(y, ali: stretch)
.imglist
  gridflow(gap: .5rem)
  max-height 200px
.img
  width auto
  height 12rem
  background var(--clr-bg-2)
  position relative
  img
    width 100%
    height 100%
  .hover
    background var(--clr-shadow-2)
    opacity 0
    width 100%
    height 100%
    top 0
    left 0
    color var(--clr-content-reverse)
    display inline-grid
    place-items center
.img:hover
  .hover
    opacity 1



</style>

<script>
import { mapState, mapGetters } from "vuex";
import EditorBus from "@/eventBus/EditorBus.js";

export default {
  name: "imageSelector",
  props: {},
  data() {
    return {
      keyword: ""
    };
  },
  computed: {
    ...mapState(["unsplash"]),
    ...mapGetters(["hasPrev", "hasNext"])
  },
  methods: {
    isUrl(value) {
      // eslint-disable-next-line
      const urlRegx = /^(https|http):\/\//;
      return urlRegx.test(value);
    },
    closeImageSelector() {
      this.$store.commit("setUI", {
        key: "imageSelector",
        value: false
      });
    },
    updateSelectedImg(url) {
      EditorBus.$emit("imageChange", url);
    },
    handleImgClick(url) {
      this.updateSelectedImg(url);
      this.closeImageSelector();
    },
    handlePrevClick() {
      return this.handleSearchImg(this.unsplash.keyword, this.unsplash.page - 1);
    },
    handleNextClick() {
      return this.handleSearchImg(this.unsplash.keyword, this.unsplash.page + 1);
    },
    handleSearchImg(keyword, page = 1) {
      this.$store.dispatch("searchImage", {
        keyword,
        page
      });
    },
    onSubmit() {
      if (this.isUrl(this.keyword)) {
        this.updateSelectedImg(this.keyword);
        this.closeImageSelector();
      } else {
        this.handleSearchImg(this.keyword);
      }
    }
  }
};
</script>
