<template lang="pug"> 
transition(name="popup")
  .comp-thumbnail.ani.radius-l.color-text-2.clip.shadow-card.pointer(
    @click.right="showMenus"
    )
    //- :class="{isBeingDragging: isBeingDragging}"
    //- draggable
    //- @dragstart="dragstart"
    //- @drag="drag"
    //- @dragover="preventEvent"
    //- @dragend="dragend"
    .comp-thumbnail--preview.b-layer
      //- 上传 ing
      .comp-thumbnail--preview--loading.f-p(v-if="showProgress") {{progress}}%
      //- 已经上传完成
      //- 有缩略图时显示图片 todo
      .comp-thumbnail--preview--img.b-layer(v-else-if="false")
        img.img(src="")
      //- 无缩略图时用 icon
      .comp-thumbnail--preview--icon(v-else)
        icon.comp-icon(:name="icon")
    .comp-thumbnail--comp-name.f-p.ellipsis {{label}}

//- transition(name="popup")
  .item.ani.radius-m.color-text-2.clip.shadow-card.pointer(
    :class="{isBeingDragging: isBeingDragging}"
    draggable
    @dragstart="dragstart"
    @drag="drag"
    @dragover="preventEvent"
    @dragend="dragend"
    @click.right="showMenus"
    )
    //- .img
    .icon-container
      icon.icon(:name="icon")
    .name.f-p.ellipsis.flex {{label}}
    .loading.f-p(v-show="showProgress") {{progress}}%
    //- .actions.an-i.abs
      .action-button.insert.shd-m.size-button-s.radius-m.ani-fast.pointer(@click="insert" v-if="selectedComponent") insert
      template(v-if="editable")
        .action-button.edit.shd-m.size-button-s.radius-m.ani-fast.pointer(v-if="design" @click="edit") edit
        .action-button.edit.shd-m.size-button-s.radius-m.ani-fast.pointer(v-else) replace
</template>
<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.comp-thumbnail
  stack(y)
  background var(--clr-bg)

.comp-thumbnail--preview
  height 6rem
  // background var(--clr-bg-2)
  box-shadow inset 0 -1px 0 var(--clr-shadow)

.comp-thumbnail--preview--loading
  display inline-grid
  place-content center
.comp-thumbnail--preview--img
  .img
    display inline-block
    width 100%
    height 100%
.comp-thumbnail--preview--icon
  display inline-grid
  place-content center

.comp-thumbnail--comp-name
  padding .5rem 1rem
  text-align center
.comp-icon
  width 1rem
  height 1rem

// .item
//   stack(x, gap: .5rem, ali:center)
//   // .left
//   //   stack(x, ali:stretch, gap: .5rem)
//   //   background yellow
//   padding .75rem
//   // padding-right .5rem
//   // padding-left 0
//   background var(--clr-bg)
//   position relative
//   &.isBeingDragging
//     // padding-left .5rem
//     // box-shadow none
//     .actions
//       display none
//   // 正在编辑中的组件，高亮样式
//   &.focus
//     color var(--clr-primary)
//     .actions
//       display none
//   // 不在编辑时的样式
//   &:not(.focus)
//     .actions
//       opacity 0
    
//     //   cursor grab
//     //   color var(--clr-content-3)
//     //   padding-left .25rem
//     //   .actions
//     //     opacity 1
//     &:active
//       padding-left .75rem
//       background var(--clr-bg-3)

// .icon-container
//   line-height 0
//   text-align center
// .icon
//   width 1rem
//   height 1rem
// .img
//   height 3rem
//   width 100%
//   background gray

// .actions
//   // position
//   pos-abs(x:end, y: center)
//   // layout
//   stack(x, gap: .5rem)
// .action-button
//   background var(--clr-primary)
//   color var(--clr-content-reverse)
//   &:hover
//     background var(--clr-primary-dark-1)

</style>

<script>
import { mapGetters } from "vuex";
import builtInComp from "@/components/preview_area/built-in component/builtIn.js";

import throttle from "lodash.throttle";

export default {
  name: "ToggleButton",
  data() {
    return {
      // 自己是否正被拖动
      // isBeingDragging: false
    };
  },
  props: {
    // editable: {
    //   type: Boolean,
    //   default: false
    // },
    id: {
      type: String,
      default: ""
    },
    masterType: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    // isFocus: {
    //   type: Boolean,
    //   default: false
    // },
    icon: {
      type: String,
      default: ""
    },
    progress: {
      type: Number,
      default: 0
    },
    design: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showProgress() {
      if (this.design) {
        return false;
      }
      if (this.progress > 0 && this.progress < 100) {
        return true;
      }
      return false;
    },
    ...mapGetters(["selectedComponent"])
  },
  methods: {
    dragstart(e) {
      if (this.isFocus) {
        e.preventDefault();
        return;
      }
      // 清空默认的样式
      this.$utils.resetDropImage(e);
      this.isBeingDragging = true;
      e.dataTransfer.setData("from", "master");
      e.dataTransfer.setData("label", this.label);
      e.dataTransfer.setData("design", this.design);
      if (!this.design && !builtInComp.map(c => c.id).includes(this.id)) {
        // 不是设计组件是代码
        e.dataTransfer.setData("componentId", "code" + this.id);
      } else {
        e.dataTransfer.setData("componentId", this.id);
      }

      window.mousePozToggle(true);
    },
    dragend() {
      this.isBeingDragging = false;
      window.mousePozToggle(false);
    },
    // fix drop not working
    preventEvent(e) {
      // e.dataTransfer.dropEffect = "move";
      e.preventDefault();
      return false;
    },
    drag: throttle(function(e) {
      if (window.mousePozSet) {
        let x = e.clientX;
        let y = e.clientY;
        window.mousePozSet(x, y);
      }
    }, 0), // 节流会有卡顿
    edit() {
      this.$store.dispatch("setSelectedComponentId", {
        id: this.id
      });
    },
    showMenus(e) {
      // if (!this.editable) {
      //   return;
      // }
      this.$utils.setMenuPoz(e);
      this.$store.commit("setUI", {
        key: "menus",
        value: [
          {
            text: "Delete",
            operation: () => {
              this.$store.dispatch("deleteComponent", { id: this.id });
            }
          }
        ]
      });
    },
    insert() {
      this.$store.dispatch("insertLayer", {
        label: this.label,
        componentId: this.id
      });
    }
  }
};
</script>
