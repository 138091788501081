
<template lang="pug">
.project-editor.stack(v-shortkey="shortKey" @shortkey="action")
  ProjNav
  //- workspace tab view
  .flex.stack(v-if="currentTab === 'workspace'")
    //- 编辑器
    ComponentEditor.flex(v-if="currentTab === 'workspace'" previewContainer="workspace")
  //- components tab view
  template(v-else-if="currentTab === 'components'")
    //- todo: 
    router-view.flex
  //- theme tab view
  DesignTokenBrowser.flex(v-else-if="currentTab === 'themes'")
</template>

<style lang="stylus" scoped>

@require '../../styles/layout/main'
@require '../../styles/design/main'


.stack
  stack(y)
  background var(--clr-bg-2)

</style>

<script>
import ProjNav from "@/components/project_editor/component/nav";
import DesignTokenBrowser from "@/components/design_token/browser";
import ComponentEditor from "@/components/component_editor/componentEditor.vue";

import { mapActions } from "vuex";

export default {
  name: "ProjEditor",
  components: {
    ProjNav,
    // CompNav,
    DesignTokenBrowser,
    ComponentEditor
  },
  data() {
    return {
      id: 0,
      shortKey: {
        undo: ["meta", "z"],
        redo: ["meta", "shift", "z"],
        convertLayerToComponent: ["meta", "k"],
        componentToLayer: ["meta", "shift", "k"],
        duplicate: ["meta", "d"]
      }
    };
  },
  watch: {
    $route: function(route) {
      // 假如参数有选中的 componentId
      if (route.params.componentId) {
        this.currentTab = "components";
      }
    }
  },
  created() {
    if (typeof this.$store.getters.selectedProject == "undefined") {
      this.$store.dispatch("setSelectedProjectId", {
        id: this.$route.params.id
      });
    }
    this.$store.dispatch("fetchProject", { id: this.$route.params.id });
    this.$store.dispatch("fetchTeams");
  },
  mounted() {
    if (this.$route.params.componentId) {
      const id = this.$route.params.componentId;
      this.$store.state.ui.currentTab = "components";
      this.$store.commit("setSelectedComponentId", { id });
      this.$store.dispatch("fetchComponentById", { id }).then(() => {
        const component = this.$store.getters.component(id);
        if (component && component.root) {
          this.$store.dispatch("fetchLayer", component.root);
        }
      });
    }
    // 遇见重复监听的情况，先移除
    if (typeof this.$root._keyup == "function" && typeof this.$root._keydown == "function") {
      return;
    }
    this.$root._keyup = this.measureF.bind(this);
    this.$root._keydown = this.measureT.bind(this);
    document.addEventListener("keyup", this.$root._keyup);
    document.addEventListener("keydown", this.$root._keydown);
  },
  beforeRouteLeave(to, from, next) {
    // 离开清空选择的项目
    this.$store.commit("setSelectedComponentId", { id: 0 });
    this.$store.commit("setLayerId", 0);
    this.$store.commit("setSelectedProjectId", { id: 0 });
    this.$ui.currentTab = "workspace";
    next();
  },
  computed: {
    currentTab: {
      set(val) {
        this.$store.state.ui.currentTab = val;
      },
      get() {
        return this.$store.state.ui.currentTab;
      }
    }
  },
  methods: {
    ...mapActions(["redo", "undo"]),
    convertLayerToComponent() {
      if (this.$store.state.layer.layerId) {
        this.$store.dispatch("convertLayerToComponent", {
          id: this.$store.state.layer.layerId
        });
      }
    },
    componentToLayer() {
      if (this.$store.state.layer.layerId) {
        this.$store.dispatch("componentToLayer", {
          id: this.$store.state.layer.layerId
        });
      }
    },
    duplicate() {
      if (this.$store.state.layer.layerId) {
        this.$store.dispatch("userCopyLayer", {
          id: this.$store.state.layer.layerId
        });
      }
    },
    action(type) {
      return this[type.srcKey] && this[type.srcKey]();
    },
    measureT(e) {
      if (e.key == "Alt") {
        this.$store.state.ui.showMeasure = true;
      }
      if (e.key == "Meta") {
        this.$store.state.ui.showFloatingEditor = false;
        this.$store.state.ui.selectmode = "meta";
      }
    },
    measureF(e) {
      if (e.key == "Meta") {
        this.$store.state.ui.showFloatingEditor = true;
        this.$store.state.ui.selectmode = "";
      }
      if (e.key == "delete") {
        if (document.activeElement.nodeName == "INPUT" || document.activeElement.nodeName == "TEXTAREA") {
          return;
        }
        this.$store.dispatch("deleteSelectedLayer");
        return;
      }

      if (window.navigator.platform == "MacIntel") {
        if (e.key == "Backspace") {
          if (document.activeElement.nodeName == "INPUT" || document.activeElement.nodeName == "TEXTAREA") {
            return;
          }
          this.$store.dispatch("deleteSelectedLayer");
          return;
        }
      }
      this.$store.state.ui.showMeasure = false;
    }
  }
};
</script>
