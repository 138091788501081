var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper radius-m shd-l" },
    [
      _c("b-input", {
        staticClass: "search",
        attrs: { size: "s", color: "light" }
      }),
      _vm.options.length > 0
        ? _c(
            "scroll",
            {
              staticClass: "option-list flex",
              attrs: { padding: "0.375rem 0", dir: "y" }
            },
            _vm._l(_vm.options, function(item) {
              return _c("ItemDefault", {
                key: item,
                attrs: {
                  "is-hover": item === _vm.value,
                  "is-active": item === _vm.active,
                  text: item
                },
                nativeOn: {
                  click: function($event) {
                    _vm.onClick(item)
                  }
                }
              })
            }),
            1
          )
        : _c("div", { staticClass: "empty f-p color-text-3" }, [
            _vm._v("Not found")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }