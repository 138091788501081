var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _c("div", { staticClass: "enter" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "method" }, [
              _c("div", { staticClass: "f-p" }, [_vm._v("Confirming")])
            ])
          : _vm._e(),
        _vm.error
          ? _c("div", { staticClass: "method" }, [
              _c("div", { staticClass: "f-p" }, [_vm._v(_vm._s(_vm.error))]),
              _c(
                "button",
                {
                  staticClass:
                    "pointer color-button-black size-button-m radius-m ani",
                  on: { click: _vm.back2Login }
                },
                [_vm._v("Back")]
              )
            ])
          : _vm._e(),
        _vm.isSuccess
          ? _c("div", { staticClass: "method" }, [
              _c("div", { staticClass: "f-p" }, [_vm._v("Successfully")])
            ])
          : _vm._e(),
        _vm.needProvideEmail
          ? _c("div", { staticClass: "method" }, [
              _c("div", { staticClass: "f-p" }, [
                _vm._v("Please provide your email for confirmation")
              ]),
              _c(
                "form",
                {
                  staticClass: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit($event)
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      }
                    ],
                    staticClass:
                      "size-input-m f-p color-input-gray radius-m ani",
                    attrs: {
                      type: "email",
                      name: "email",
                      placeholder: "Your email address",
                      autofocus: ""
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      staticClass:
                        "pointer color-button-black size-button-m radius-m ani",
                      attrs: { type: "submit", disabled: _vm.isLoading }
                    },
                    [_vm._v("Confirm")]
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/logo/logo-dev.png") }
      }),
      _c("div", { staticClass: "f-h3 title" }, [
        _vm._v("Welcome to Bento"),
        _c("span", { staticClass: "beta pill f-p bold" }, [_vm._v("Dev")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }