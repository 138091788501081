var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "export-options" },
    [
      _c("avatar", {
        attrs: { email: _vm.email },
        nativeOn: {
          click: function($event) {
            return _vm.toggleDrawer($event)
          }
        }
      }),
      _c("transition", { attrs: { name: "fade-shd" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen"
            }
          ],
          staticClass: "shadow ani",
          on: { click: _vm.toggleDrawer }
        })
      ]),
      _c("transition", { attrs: { name: "fade-drawer" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen"
              }
            ],
            staticClass: "drawer shd-l radius-m ani"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "option f-p pointer color-button-sub size-button-m ani-fast",
                on: { click: _vm.logout }
              },
              [_vm._v("Log out")]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }