<template lang="pug">
.master
  //- bDropSelector(:value="type" @change="onTypeChange" :options="['Inherit', 'Custom', 'Transparent']")
  input.fix-width.color-input-sub.radius-m.ani-fast.size-input-s(@change="onValueChange" placeholder="Custom " :style="style" :value="value")
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.master
  stack(y, gap: .25rem, arr: stretch)
  .preview
    width 100%
    height 1.5rem
    display inline-grid
    place-items center
    box-shadow inset 0 0 0 1px var(--clr-shadow-2)
.fix-width
  width 100%
</style>

<script>
export default {
  name: "ColorEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    param: String
  },
  computed: {
    style() {
      return {
        background: this.value
      };
    },
    type() {
      if (this.value !== "transparent" && this.value !== "inherit") {
        return "custom";
      }
      return this.value;
    },
    showInput() {
      if (this.value !== "transparent" && this.value !== "inherit") {
        return true;
      }
      return false;
    }
  },
  methods: {
    // onTypeChange(v) {
    //   this.$emit("change", v, this.param);
    //   if (v == "custom") {
    //     this.$emit("change", "#fff", this.param);
    //     return;
    //   }
    // },
    onValueChange(e) {
      this.$emit("change", e.target.value, this.param);
    }
  }
};
</script>
