export default function render(value) {
  if (value == "off") {
    return {};
  } else if (value == "pill") {
    return {
      borderRadius: "99999rem"
    };
  } else if (value == "oval") {
    return {
      borderRadius: "50%"
    };
  }

  if (value.indexOf && value.indexOf("|") != -1) {
    const p = value.split("|");
    const top = p[0].indexOf("%") != -1 ? p[0] : p[0] + "px";
    const right = p[1].indexOf("%") != -1 ? p[1] : p[1] + "px";
    const bottom = p[3].indexOf("%") != -1 ? p[3] : p[3] + "px";
    const left = p[2].indexOf("%") != -1 ? p[2] : p[2] + "px";
    return {
      borderRadius: `${top} ${right} ${bottom} ${left}`
    };
  }

  return {
    borderRadius: value.includes && value.includes("%") ? value : `${value}px`
  };
}
