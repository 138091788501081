<template lang="pug">
  //- 只显示对应 layout 下 layout 用到的的表单
  .position-editor
    //- in layout stack
    //- PositionStack(v-if="layout === 'stack'" @change="onChange" :align="alignStack")
    //- in layout grid
    PositionGrid(v-if="layout === 'grid'" @change="onChange" :align="alignGrid")
    //- in layout workspace
    PositionWorkspace(v-if="layout === 'workspace'" @change="onChange" :align="alignWorkspace")
    //- in layout off
    PositionAbsolute(v-if="layout === 'off'" @change="onChange" :align="alignAbsolute")
    //- position.size
    PositionSize(dir="Width" @change="onChange" name="width" :size="sizeX")
    PositionSize(dir="Height" @change="onChange" name="height" :size="sizeY")
</template>
<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.position-editor
  stack(y, gap: .5rem)
</style>

<script>
import PositionWorkspace from "./align/workspace.vue";
import PositionAbsolute from "./align/absolute.vue";
import PositionGrid from "./align/grid.vue";
import PositionStack from "./align/stack.vue";
import PositionSize from "./size/xy.vue";

export default {
  name: "PositionEditor",
  components: {
    PositionWorkspace,
    PositionAbsolute,
    PositionGrid,
    PositionStack,
    PositionSize
  },
  props: {
    position: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    layout() {
      return this.position && this.position.activeLayout;
    },
    alignStack() {
      return this.position ? this.position.align && this.position.align.stack : undefined;
    },
    alignGrid() {
      return this.position ? this.position.align && this.position.align.grid : undefined;
    },
    alignWorkspace() {
      return this.position ? this.position.align && this.position.align.previewArea : undefined;
    },
    alignAbsolute() {
      return this.position ? this.position.align && this.position.align.absolute : undefined;
    },
    sizeX() {
      return this.position ? this.position.size && this.position.size.x : undefined;
    },
    sizeY() {
      return this.position ? this.position.size && this.position.size.y : undefined;
    },
    showHeight() {
      if (this.layout === "grid") {
        return false;
      }

      if (this.layout == "off") {
        if (this.position.absolute.top == "off" && this.position.absolute.bottom == "off") {
          return false;
        }
      }

      return true;
    },
    showWidth() {
      if (this.layout === "grid") {
        return false;
      }

      if (this.layout == "off") {
        if (this.position.absolute.left == "off" && this.position.absolute.right == "off") {
          return false;
        }
      }

      return true;
    }
  },
  methods: {
    onChange(value, param) {
      return this.$emit("change", value, param);
    }
  }
};
</script>
