// import clone from "clone";
// import { eqProp, notEq } from "../helpers";
import getWebFonts from "@/services/googleapis";

//
// ─── STATE ──────────────────────────────────────────────────────────────────────
//

const state = {
  fonts: [],
  generic: ["inherit", "system-ui", "serif", "sans-serif", "monospace", "cursive", "fantasy"]
};

//
// ─── GETTERS ────────────────────────────────────────────────────────────────────
//

const getters = {};

//
// ─── MUTATIONS ──────────────────────────────────────────────────────────────────
//

const mutations = {
  updateFontsState(state, payload) {
    for (let key of Object.keys(payload)) {
      state[key] = payload[key];
    }
  }
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//

const actions = {
  async fetchWebFonts({ commit }) {
    try {
      const data = await getWebFonts();
      commit("updateFontsState", {
        fonts: data.items
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }
};

export default { getters, actions, mutations, state };
