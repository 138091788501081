<template lang="pug">
  .scroll#scroll
    .padding(
      @drop="uploadReceoverDrop"
      @dragleave='noop'
      @dragenter='noop'
      @dragover='noop'
      @dragend='noop'
      @click.self="selectWorkspace"
    )
      //- 动态调整 .content-wrapper 的 padding，让 .content-wrapper 的大小始终大于子元素总和
      .content-wrapper.pointer-event-off#rootRender(
        :style="container"
      )
        //- 无限画布坐标原点
        .zero.pointer-event-on(v-if="currentTab == 'workspace'")
          layer(
            v-for="item in childrenLayers"
            :key="item.id"
            :id="item.id"
          )
        //- component 显示布局
        template(v-else)
          transition(name='fade')
            rootContainer-cutline.rootContainerCutline.ani(v-show="showEditorUI")
          layer(
            v-if="rootLayer"
            :id="rootLayer.id"
          )
        floatingEditor.abs.pointer-event-on(v-show="showEditorUI")
</template>

<style lang="stylus" scoped>
.scroll
  overflow auto
.padding
  position relative
  padding 32rem
  width max-content
  min-width 100%
  display inline-grid
  place-items center
.content-wrapper
  position relative
.zero
  off()
  position relative
  width 0px
  height 0px
  // 显示原点，debug 时候用
  // box-shadow 0 0 0 1px #000

</style>

<script>
import { mapGetters } from "vuex";

import layer from "@/components/preview_area/layer/Node.vue";
import layout from "@/render_func/layout_system/layoutRender/layout.js";
import floatingEditor from "@/components/floating_editor/floating_editor.vue";
import rootContainerCutline from "./rootContainerCutline.vue";

// 把 .padding 放到里面去，workspace 布局会出现问题，暂时用 mixins 先代替
import uploadReceiver from "@/components/mixins/uploadReceiver";

export default {
  name: "previewArea",
  components: {
    layer,
    rootContainerCutline,
    floatingEditor
  },
  props: {
    showEditorUI: {
      type: Boolean,
      default: true
    }
  },
  mixins: [uploadReceiver],
  async created() {
    // 设置当前选中组件
    this.selectWorkspace();
  },
  methods: {
    // 没有就去拉取，修复刷新没有得到值
    async fetch() {
      if (!this.previewLayer && this.selectedComponent && this.selectedComponent.root) {
        await this.$store.dispatch("fetchLayer", this.selectedComponent.root);
      }
    },
    selectWorkspace() {
      if (this.currentTab == "workspace") {
        this.$store.commit("setSelectedComponentId", { id: "workspace" });
        this.$store.dispatch("selectLayer", { id: this.selectedComponent.root });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    // 不是去组件详情编辑页，就清空
    if (to.name != "ProjEditorDetail") {
      // 重置选中的组件
      this.$store.commit("setSelectedComponentId", { id: 0 });
      // 重置选中的 layer
      this.$store.commit("setLayerId", 0);
    }
    next();
  },
  // 注入子布局计算方法，和子代要修复的布局
  provide() {
    const layoutType = () => {
      if (this.currentTab == "workspace") {
        return "previewArea";
      }
      if (this.currentTab == "components") {
        return "componentpreview";
      }
    };
    return {
      layoutItem(layer) {
        if (layer) {
          if (layoutType() == "previewArea") {
            return layout.previewArea.item(layer);
          }
          if (layoutType() == "componentpreview") {
            return layout.component.item(layer);
          }
        }
      },
      layoutType
    };
  },
  watch: {
    selectedProject() {
      this.fetch();
    },
    selectedComponent() {
      this.fetch();
    }
  },
  computed: {
    ...mapGetters(["rootLayer", "layer", "selectedProject", "selectedComponent"]),
    currentTab() {
      return this.$ui.currentTab;
    },
    previewLayer() {
      if (this.selectedComponent) {
        return this.layer(this.selectedComponent.root);
      }
    },
    childrenLayers() {
      if (this.previewLayer) {
        return this.previewLayer.children.default.map(this.layer);
      }
    },
    // 根据标签读取父布局样式
    container() {
      if (this.currentTab == "components" && this.previewLayer) {
        return layout.component.container(this.previewLayer);
      }
      const style = layout.previewArea.container(this.childrenLayers);
      return style;
    }
  }
};
</script>
