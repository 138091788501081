<template lang="pug">
    scroll.dt-finder(dir='y', padding='2rem')
      //- empty
      //- .empty.abs.ani
        .message
          img.img(src="@/assets/image/empty-project.png")
          .f-h3.color-text-1 No theme element in this project.
        //- b-btn(color="dark" size="l" @click.native="") + New Project
      //- not empty
      .content
        .temp.f-h3 😬 Coming Soon
        .session
          .title
            .f-h3 Colors
            b-btn(size="m" @click.native="") + New Color
          .dt-list
            ItemColor
            ItemColor
            ItemColor
            ItemColor
            ItemColor
            ItemColor
        .session
          .title
            .f-h3 Fonts
            b-btn(size="m" @click.native="") + New Font
          .dt-list
            ItemFont
            ItemFont
            ItemFont
            ItemFont
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.dt-finder
  stack(x, gap: 1rem, arr: start)
  background var(--clr-bg-2)
.empty
  position absolute
  pos-abs(x:stretch, y: stretch)
  stack(y, gap: 2rem, ali: center, arr: center)
  .img
    width 12rem
  .message
    stack(y, gap: 1rem, ali: center, arr: center)
.content
  stack(y, gap: 4rem)

// session
.session
  stack(y, gap: 2rem)
.title
  stack(x, arr: between)
.dt-list
  gridflow(gap:2rem, colmin:10rem)


</style>

<script>
import ItemColor from "@/components/design_token/item_color.vue";
import ItemFont from "@/components/design_token/item_font.vue";
// import ThumbnailTypeface from "@/components/Typeface/tokenThumbnail.vue";
// import ThumbnailDefault from "@/components/design_token/thumbnail.vue";
export default {
  name: "DesignTokenFinder",
  components: {
    ItemColor,
    ItemFont
    // ThumbnailDefault,
    // ThumbnailColor,
    // ThumbnailTypeface
  },
  data() {
    return {};
  }
};
</script>
