var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "b-switcher ani-fast pointer",
      class: { checked: _vm.value },
      on: {
        click: function($event) {
          _vm.switchChecked()
        }
      }
    },
    [_c("div", { staticClass: "pill ani-fast", class: _vm.size }, [_vm._m(0)])]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ball-wrapper ani-fast" }, [
      _c("div", { staticClass: "ball oval shd-s ani-fast" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }