import { eqProp } from "../helpers";
import { component as componentService } from "../services";
import { generate } from "shortid";
import clone from "clone";
import history from "./history";

//
// ─── STATE ──────────────────────────────────────────────────────────────────────
//

const state = {
  components: [],
  selectedComponentId: 0
};

//
// ─── GETTERS ────────────────────────────────────────────────────────────────────
//

const getters = {
  selectedComponent({ components, selectedComponentId }, getters) {
    if (selectedComponentId === "workspace")
      return {
        id: "workspace",
        label: "Workspace",
        props: {},
        slots: [],
        root: getters.selectedProject && getters.selectedProject.workspace
      };
    return components.find(eqProp("id", selectedComponentId));
  },
  component: state => id => state.components.find(eqProp("id", id)),
  existComponent: (state, getters) => id => Boolean(getters.component(id))
};

//
// ─── MUTATIONS ──────────────────────────────────────────────────────────────────
//

const mutations = {
  setSelectedComponentId(state, { id }) {
    state.selectedComponentId = id;
  },
  pushComponents(state, { components }) {
    let filterd = [];
    if (components.length) {
      filterd = components
        .filter(Boolean)
        .filter(c => !state.components.some(alearyExistComponent => c.id == alearyExistComponent.id));
    }
    state.components = state.components.concat(filterd);
  },
  replaceComponent(state, { components }) {
    state.components = components;
  },
  updateComponentInfomation({ components, selectedComponentId }, params) {
    let component = components.find(eqProp("id", params.id || selectedComponentId));
    let index = components.findIndex(eqProp("id", params.id || selectedComponentId));
    if (!component) {
      /* eslint-disable-next-line */
      console.log("没有找到component", params);
      return;
    }
    component = Object.assign({}, component, params);
    components.splice(index, 1, component);
  },

  deleteComponent(state, { id }) {
    state.components.find(c => {
      if (c.id == id) {
        c.status = 3;
      }
    });
    // state.components = state.components.filter(c => c.id != id).filter(Boolean);
  },
  restoreComponent(state, { id }) {
    state.components.find(c => {
      if (c.id == id) {
        c.status = 1;
      }
    });
    // state.components = state.components.filter(c => c.id != id).filter(Boolean);
  }
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
/* eslint-disable */
const actions = {
  async fetchComponentById({ state, commit, getters, dispatch }, { id }) {
    const component = await componentService.findById(id)
    commit('pushComponents', { components: [component] })
  },
  async createComponent({ state, dispatch, commit, rootState }, params) {
    if (!Reflect.has(params, 'noCache')) {
      history.push(rootState)
    }
    const defaultComponent = {
      id: generate(),
      slots: [],
      design: true,
      status: 1,
      props: {}
    }

    let component = Object.assign({}, defaultComponent, params)
    component.progress = 0
    commit('pushComponents', { components: [component] })
    if (!Reflect.has(component, 'code')) {
      // 不是代码组件才选中
      if (params.selected) {
        commit('setSelectedComponentId', { id: component.id })
        delete component.selected
      }
    }
    component.progress = 100
    await dispatch('addNewComponentToSeletedProject', { id: component.id })
    component = await componentService.create(component)
    commit('setLayerId', 0)
    return component
  },
  updateComponentInfomation({ commit, getters }, params) {
    commit('updateComponentInfomation', params)
    // 等待 200 ms
    componentService.update(Object.assign({}, getters.selectedComponent, params))
  },
  // 做截断处理，在预览区，可以点击到 design component 里面的组件，应该做判断只能选中直接子代
  async layerInSeletedComponent({ dispatch, getters }, { id, layerId }) {
    if (typeof layerId == 'undefined') {
      layerId = getters.selectedComponent.root
    }
    const rootLayer = getters.layer(layerId)
    // 选中 Root
    if (id == rootLayer.id) {
      return true
    }
    for (let slot of Object.keys(rootLayer.children)) {
      for (let index in rootLayer.children[slot]) {
        if (rootLayer.children[slot][index] == id) {
          return true
        }
        // 不能直接返回，直接返回就 return 出去了
        const ret = await dispatch('layerInSeletedComponent', { id, layerId: rootLayer.children[slot][index] })
        if (ret) {
          return ret
        }
      }
    }

    return false
  },
  async deleteComponent({ state, commit, getters, dispatch, rootState }, params) {
    history.push(rootState)

    const component = state.components.find(c => c.id == params.id)
    if (!component) {
      return
    }
    // await dispatch('deleteLayer', { id: component.root })
    await componentService.remove(component)
    commit('deleteComponent', { id: component.id })
    // dispatch('removeComponentFromSeletedProject', { id: component.id })
  },

  async restoreComponent(context, {id}){
    const component = state.components.find(c => c.id == id)
    if (!component) {
      return
    }
    context.commit('restoreComponent', { id: component.id })
    await componentService.update(component)

  },
  async setSelectedComponentId({ state, commit, getters, dispatch }, { id }) {
    const component = getters.component(id)
    commit('setSelectedComponentId', { id })
    if (component.root && !getters.layer(component.root)) {
      await dispatch('fetchLayer', component.root)
    }
    // 解决没有 root 没有被重置，导致自己插入自己，卡死
    commit("setLayerId", 0);
    if (component.root) {
      await dispatch("selectLayer", { id: component.root });
    }
  },
  async selectedComponentRegisterSlot({ state, commit, dispatch, getters }, { id }) {
    let component = getters.selectedComponent;
    if (component && !Reflect.has(component, 'slots')) {
      component.slots = []
    }
    if (!component.slots.includes(id)) {
      component.slots.push(id)
    }
    commit('updateComponentInfomation', component)
    if (component.id != 'workspace') {
      componentService.update(component)
    }
    dispatch('noticeLayerUpdate', {
      slots: component.slots,
      componentId: component.id
    })
  },
  async componentDeleteSlot({ state, commit, dispatch }, { id, componentId = state.selectedComponentId }) {
    let component = state.components.find(eqProp('id', componentId))
    if (!component) {
      component = state.components.find(eqProp('id', state.selectedComponentId))
    }
    if (typeof component === 'undefined') {
      return
    }
    if (component && !component.slots) {
      component.slots = []
    } else {
      component.slots = component.slots.filter(_id => _id != id)
    }
    commit('updateComponentInfomation', component)
    componentService.update(component)
    dispatch('noticeLayerUpdate', {
      slots: component.slots,
      componentId: component.id
    })
  },
  // 删除组件的某一个 props
  async componentDeleteProp({ state, commit, dispatch }, { id, componentId = state.selectedComponentId }) {
    let component = state.components.find(eqProp('id', componentId))
    if (!component) {
      component = state.components.find(eqProp('id', state.selectedComponentId))
    }
    if (typeof component == 'undefined') {
      return;
    }
    if (component && !Reflect.has(component, 'props')) {
      component.props = {}
    }
    if (component.props[id]) {
      delete component.props[id]
    }
    commit('updateComponentInfomation', component)
    componentService.update(component)
    dispatch('noticeLayerUpdate', {
      props: component.props,
      componentId: component.id
    })
  },
  // 更新组件默认值
  async updatePropDefaultValue(context, {id, key, layerId, value}) {
    const comp = context.getters.selectedComponent
    const props = comp.props
    console.log(value);
    
    if (props[id]) {
      
      if (props[id] && props[id][key] && Reflect.has(props[id][key], "value")) {
        props[id][key].value = value
      }
      if (layerId && props[id][layerId] && props[id][layerId][key] && Reflect.has(props[id][layerId][key], "value")) {
        console.log(props[id][layerId][key]);
        props[id][layerId][key].value = value
      }
    }
    comp.props = props
    await componentService.update(comp)
    console.log(comp);
    
  },
  // 用户点击 props editor 属性 ，暴露出该属性，触发的方法，mapTo 表示组件内要传递给内部组件的 id
  async toggleLinkPropToComponent({ state, commit, dispatch, getters, rootState }, { id, key, mapTo, type, value }) {
    history.push(rootState)
    const props = clone(getters.selectedComponent.props)
    // debugger
    if (!props[id]) {
      props[id] = {}
    }
    if (mapTo) {
      // 需要映射的东西
      if (props[id][key + '|map2:' + mapTo]) {
        // 已有属性则删除
        delete props[id][key + '|map2:' + mapTo]
        // 空的对象，连对象一起删除
        if (props[id].length == 0) {
          delete props[id]
        }
        dispatch('noticeLayerUpdate', {
          props,
          componentId: getters.selectedComponent.id
        })
        await dispatch('updateComponentInfomation', { props })
        return
      } else {
        props[id][key + '|map2:' + mapTo] = {
          type,
          value
        }
        dispatch('noticeLayerUpdate', {
          props,
          componentId: getters.selectedComponent.id
        })
        await dispatch('updateComponentInfomation', {
          props
        })
      }
    } else {
      if (props[id][key]) {
        // 已有属性则删除
        delete props[id][key]
        // 空的对象，连对象一起删除
        if (props[id].length == 0) {
          delete props[id]
        }
        dispatch('noticeLayerUpdate', {
          props,
          componentId: getters.selectedComponent.id
        })
        await dispatch('updateComponentInfomation', { props })
        return
      } else {
        props[id][key] = {
          type,
          value
        }
        dispatch('noticeLayerUpdate', {
          props,
          componentId: getters.selectedComponent.id
        })
        await dispatch('updateComponentInfomation', {
          props
        })
      }
    }
  }
}

export default { getters, actions, mutations, state }
