<script>
const options = [{ label: "Both", value: "both" }, { label: "X", value: "x" }, { label: "Y", value: "y" }];

export default {
  name: "Dir",
  props: {
    value: {
      type: String,
      default: "both"
    },
    param: {
      type: String,
      default: ""
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  render() {
    return <bRadioBtn options={options} value={this.value} onChange={v => this.$emit("change", v)} />;
  }
};
</script>
