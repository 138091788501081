<template lang="pug">
  .drawer-container
    //- ComponentDrawer.drawer(v-if="drawerType == 'components'")
    External.drawer(v-if="drawerType == 'External'")
    Internal.drawer(v-else-if="drawerType == 'Internal'")
    //- show this when error
    .bug.radius-m(v-else) Congrats! You've found a bug! Please tell us.
</template>

<style lang="stylus" scoped>
.drawer
  max-height 100%
  width 12rem
.bug
  padding 1rem
  background orange
  margin 1rem
</style>

<script>
// import ComponentDrawer from "@/components/Component/browser/finder.vue";
import External from "@/components/component_library/external.vue";
import Internal from "@/components/component_library/internal.vue";

export default {
  components: {
    // imageBrowser,
    // ComponentDrawer,
    External,
    Internal
  },
  props: {
    // which type of master list you want to show
    drawerType: {
      type: String,
      default: ""
    }
  }
};
</script>
