import firebase from "firebase/app";
import "firebase/storage";
import { generate } from "shortid";
const storageRef = firebase.storage().ref("layers");

export default {
  methods: {
    noop(e) {
      e.preventDefault();
      return false;
    },
    async uploadReceoverDrop(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i);
        // const url = window.URL.createObjectURL(file);
        const nameArr = file.name.split(".");
        const ext = nameArr[nameArr.length - 1];
        const ref = storageRef.child(generate() + "." + ext);
        await ref.put(file);
        const url = await ref.getDownloadURL();
        if (this.$store.getters.foucsLayer && this.$store.getters.foucsLayer.componentId == "bento-image") {
          this.$store.dispatch("updateLayerProp", {
            id: this.$store.getters.foucsLayer.id,
            key: "src",
            value: url
          });
          return;
        }
        this.$store.commit("needSetImageLayerSrc", url);
        // delete 最后的后缀
        nameArr.length = nameArr.length - 1;
        await this.$store.dispatch("insertLayer", {
          componentId: "bento-image",
          label: nameArr.join()
        });
      }
    }
  }
};
