export default function render(param, typograph) {
  if (typograph.lineBreak == "break") {
    if (param == "Hyphen") {
      return {
        hyphens: "auto"
      };
    }

    if (param == "No Hyphen") {
      return {
        overflowWrap: "break-word",
        hyphens: "none"
      };
    }

    if (param == "No Break") {
      return {
        overflowWrap: "normal"
      };
    }
  }

  return {};
}
