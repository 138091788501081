<template lang="pug">
  .input-selector
    .position-ctrl
      input.input.f-p.size-input-s.clr-input-p1.radius-m.ani-fast(
        :class="{ warn: hasError }"
        :placeholder="placeholder"
        :value="selected"
        ref="input"
        @input="onInput"
        @focus.stop="onFocus"
        @blur.stop="onBlur"
        @keyup.esc="onKeyEsc"
        @keyup.up="onKeyUp"
        @keyup.down="onKeyDown"
        @keyup.enter="onChange"
        @change="onChange"
        )
      transition(name="fade")
        .option-list.abs.shd-xl.radius-m.ani-fast(
          class="options"
          :class="{top}"
          v-show="isFocus && options.length > 0"
          )
          .option.f-small.pointer.ani-fast.ellipsis(
            v-for="item in options"
            :class="{ selected: active === item }"
            @click.stop="handleClick(item)"
          ) {{item}}
</template>

<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.input-selector
  display inline-grid
.position-ctrl
  position relative
  display inline-grid
.input
  background var(--clr-bg-2)
  &:hover
    background var(--clr-bg-3)
  &:focus
    background var(--clr-bg)
    box-shadow inset 0 0 0 2px $gray-3//var(--clr-primary-3)
  &::placeholder
    color var(--clr-content-3)

.input
  text-transform capitalize
  max-width 100%
  &.warn
    box-shadow inset 0 0 0 2px var(--clr-bg-danger)

.option-list
  $list-height = 280px
  $list-gap = .25rem
  // position
  width max-content
  min-width 100%
  max-width 200%
  height max-content
  max-height $list-height
  z-index 2
  // dynamic position
  &:not(.top)
    top calc(100% + .25rem)
  &.top
    bottom calc(100% + .25rem)

  // layout
  padding .25rem 0
  overflow-y scroll
  overflow-x hidden
  // style
  background $gray-9

.option
  padding .25rem 1rem
  text-transform capitalize
  color var(--clr-content-reverse)
  &.selected
    position relative
    &::before
      content ''
      position absolute
      left 6px
      top calc(50% - 2px)
      height 4px
      width 4px
      border-radius 1rem
      background var(--clr-content-reverse)
  &.to-select
    background $gray-5
  &:hover
    background var(--clr-primary)

// animation
// .fade-enter-active, .fade-leave-active {
//   transition: all .15s
// }
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform translateY(.25rem)
}
</style>

<script>
export default {
  name: "b-input-selector",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: "auto"
    },
    defaultOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    disableTipInput: {
      default: false
    }
  },
  watch: {
    value() {
      this.reSet();
    }
  },
  data() {
    return {
      selected: this.value,
      active: this.value,
      isFocus: false,
      hasError: false,
      top: false
    };
  },
  computed: {
    additional() {
      const value = this.defaultOptions
        .map(fn => {
          if (typeof fn == "function") {
            return fn(this.selected);
          }
          return fn;
        })
        .filter(v => v != null);
      // 去重
      return Array.from(new Set(value));
    },
    options() {
      if (!this.disableTipInput) {
        if (this.isNum(this.selected)) return [this.selected, ...this.additional];
        if (this.isPercentage(this.selected)) return [this.selected, ...this.additional];
      }
      return this.additional;
    }
  },
  methods: {
    reSet() {
      this.selected = this.value;
      this.active = this.value;
      this.isFocus = false;
      this.hasError = false;
    },
    isPercentage(value) {
      const percentageRegx = /^\d+(\.\d+)?(?=%$)/;
      return percentageRegx.test(value);
    },
    isNum(value) {
      const numRegx = /^\d+(\.\d+)?$/;
      return numRegx.test(value);
    },
    onFocus(e) {
      this.isFocus = true;
      this.computeTop(e);
    },
    computeTop(e) {
      const height = document.documentElement.clientHeight;
      const { bottom } = e.target.getBoundingClientRect();
      if (height - bottom > 280) {
        this.top = false;
        return;
      }
      this.top = true;
    },
    onBlur() {
      this.isFocus = false;
      if (this.selected !== this.active) {
        this.active = this.selected;
      }
    },
    onInput(e) {
      const targetValue = e.target.value.toLowerCase();
      this.selected = targetValue;
      this.active = targetValue;
      if (!this.isNum(targetValue) && !this.isPercentage(targetValue) && !this.options.includes(targetValue)) {
        this.hasError = true;
        return;
      }
      this.hasError = false;
    },
    onKeyUp() {
      const optionsLen = this.options.length;
      const currIndex = this.options.indexOf(this.active);
      if (currIndex === -1) {
        this.active = this.options[0];
      } else if (currIndex === 0) {
        this.active = this.options[optionsLen - 1];
      } else {
        this.active = this.options[currIndex - 1];
      }
    },
    onKeyDown() {
      const optionsLen = this.options.length;
      const currIndex = this.options.indexOf(this.active);
      if (currIndex === -1) {
        this.active = this.options[0];
      } else if (currIndex === optionsLen - 1) {
        this.active = this.options[0];
      } else {
        this.active = this.options[currIndex + 1];
      }
    },
    onKeyEsc() {
      this.$refs.input.blur();
      this.reSet();
    },
    onChange() {
      if (!this.isNum(this.active) && !this.isPercentage(this.active) && !this.options.includes(this.active)) {
        return;
      }
      this.hasError = false;
      this.selected = this.active;
      this.$refs.input.blur();
      this.$emit("change", this.active);
    },
    handleClick(item) {
      this.hasError = false;
      this.selected = item;
      this.active = item;
      this.$emit("change", item);
    }
  }
};
</script>
