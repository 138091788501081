var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-props--xy" },
    [
      _c(
        "KeyValue",
        { attrs: { label: _vm.dir.toUpperCase() + " Arrange" } },
        [
          _c("ArrangeEditor", {
            staticClass: "fix-width",
            attrs: { value: _vm.value.arrange, rotate: _vm.rotate_icon },
            on: { change: _vm.onArrangeChange }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "stack-x" },
        [
          _c(
            "KeyValue",
            { attrs: { label: "Count", level: "s" } },
            [
              _c("NumberEditor", {
                attrs: { min: 1, value: _vm.value.count },
                on: { change: _vm.onCountChange }
              })
            ],
            1
          ),
          _c(
            "KeyValue",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.value.arrange !== "stretch",
                  expression: "value.arrange!=='stretch'"
                }
              ],
              attrs: { label: "Size", level: "s" }
            },
            [
              _c("bInputSelector", {
                attrs: { value: _vm.size, defaultOptions: ["auto"] },
                on: { change: _vm.onSizeChange }
              })
            ],
            1
          ),
          _c(
            "KeyValue",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.value.arrange !== "space-between",
                  expression: "value.arrange!=='space-between'"
                }
              ],
              attrs: { label: "Gap", level: "s" }
            },
            [
              _c("SpaceEditor", {
                attrs: { value: _vm.gap },
                on: { change: _vm.onGapChange }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }