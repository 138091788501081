export default function render(param) {
  if (param == "stretch") {
    return {
      textAlign: "justify"
    };
  } else {
    return {
      textAlign: param
    };
  }
  // return objwrap("textAlign");
}
