<style lang="stylus" scoped>
  .layer
    // display inline-grid
    // background alpha(blue,5%)
    display inline-flex
    &>*
      position relative
      width 100%
      height 100%
      min-width 100%
      max-width 100%
      min-height 100%
      max-height 100%

    // debug 专用
    // background alpha(red, 5%)
  // .focus 
  .hover
    cursor pointer
    box-shadow 0 0 0 2px var(--clr-primary)
</style>

<script>
/* eslint-disable */
import Box from "@/components/preview_area/built-in component/Box.vue";
import BentoSlot from "@/components/preview_area/built-in component/Slot.vue";
import BentoTxt from "@/components/preview_area/built-in component/Txt.vue";
import BentoScroll from "@/components/preview_area/built-in component/Scroll.vue";
import BentoImage from "@/components/preview_area/built-in component/Image.vue";
// import DefaultInput from "./DefaultInput";
// import None from "../defaultComp.vue";
import basicBuiltInComponents from "@/components/preview_area/built-in component/builtIn.js";

// todo: code component 为什么在这里？
// import block from "@/tmp_lib/bento-demo--vinci/components/block";
// import featureList from "@/tmp_lib/bento-demo--vinci/components/featureList";
// import featureSingle from "@/tmp_lib/bento-demo--vinci/components/featureSingle";
// import footerComp from "@/tmp_lib/bento-demo--vinci/components/footer";
// import hero from "@/tmp_lib/bento-demo--vinci/components/hero";
// import subscribe from "@/tmp_lib/bento-demo--vinci/components/subscribe";
// import topnav from "@/tmp_lib/bento-demo--vinci/components/topnav";
// import Header from "../../../../tmp_lib/design-blocks--froala/components/header1";

import { component as componentService } from "@/services";

import throttle from "lodash.throttle";

import { mapGetters } from "vuex";
import map from "lodash.map";
import debounce from "lodash.debounce";
import dotProp from "dot-prop";
import clone from "clone";
import Vue from "vue";

export default {
  name: "Node",
  components: {
    // BentoLayoutGrid,
    // BentoLayoutStack,
    BentoSlot,
    Box,
    BentoTxt,
    BentoScroll,
    BentoImage,
    // block,
    // featureList,
    // featureSingle,
    // footerComp,
    // hero,
    // subscribe,
    // topnav
    // None
  },
  props: {
    id: String,
    noWrap: {
      default: false
    },
    rootChildren: null,
    rootProps: null,
    level: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      basicComponentNames: basicBuiltInComponents.map(c => c.id),
      localComponent: null,
      code: false,
      design: false
    };
  },
  inject: ["layoutItem", "layoutType"],
  watch: {
    parentLayout: function(newLayout, oldLayout) {
      // 父级布局组件变更时，更新 position.activeLayout 属性
      this.updateActiveLayout();
    },
    focus(newVal) {
      if (newVal) {
        // layer 被选中 计算 floating 相关的东西
        this.findRelativeDom(this.$el);
        this.changePoz();
      }
    }
  },
  async created() {
    this.$root.$relativeEl = document.querySelector("#rootRender");
    this.$root.$changePoz = () => this.changePoz();
  },
  async mounted() {
    if (!this.layer && this.id) {
      console.log(this.id);
      await this.$store.dispatch("fetchLayer", this.id);
    }
    if (this.layer && this.layer.componentId) {
      await this.fetchComponent(this.layer.componentId);
    }
    await this.layerMountedInit();
    this.updateActiveLayout();
    this.$el.addEventListener("mousedown", this.select.bind(this), true);
    this.$el.addEventListener("mousemove", this.mousemove.bind(this), true);
    this.$el.addEventListener("mouseout", this.mouseout.bind(this), true);
    this.$el.addEventListener("contextmenu", this.openMenu.bind(this), false);

    window.addEventListener(
      "resize",
      debounce(() => {
        if (this.changePoz) {
          this.changePoz();
        }
      }, 200),
      true
    );

    window.addEventListener(
      "scroll",
      () => {
        this.$nextTick(() => {
          if (this.changePoz) {
            this.changePoz();
          }
        });
      },
      true
    );
  },
  destroyed() {
    this.$el.removeEventListener("mousedown", this.select, true);
    this.$el.removeEventListener("mousemove", this.move, true);
    this.$el.removeEventListener("contextmenu", this.openMenu, false);
  },
  methods: {
    updateActiveLayout() {
      if (
        this.layer &&
        this.parentLayout &&
        this.layer.position.activeLayout != this.parentLayout
      ) {
        this.$store.dispatch("updateLayoutType", {
          id: this.layer.id,
          layoutType: this.parentLayout
        });
      }
    },
    findRelativeDom(el) {
      const isRelative = dom =>
        dom && window.getComputedStyle(dom).position == "relative";
      if (isRelative(el)) {
        this.$root.$relativeEl = el;
        return;
      }
      if (el.parentElement) {
        this.findRelativeDom(el.parentElement);
      }
    },
    findRelative(vm) {
      if (vm) {
        if (parent.$el.style.position == "relative") {
          return parent.$el.getBoundingClientRect();
        }
      }
      if (vm.$parent) {
        return this.findRelative(vm.$parent);
      }
    },
    changePoz() {
      if (this.focus) {
        this.$root.$floating && this.$root.$floating(this);
      }
    },
    compName(type) {
      return type == "component" ? "none" : type;
    },
    layerMountedInit() {
      // node 下面的 node，不应该注册
      if (this.noWrap || !this.layer || !this.layer.componentId) {
        return;
      }
      // 动态组件直接注册它的第一个渲染实例
      if (
        this.basicComponentNames.includes(this.layer.componentId) ||
        this.code
      ) {
        let renderLayer = this.$children[0];
        if (!renderLayer || !this.layer) {
          return;
        }

        let props = {}; // copy value
        // 类型要从构造器上面去取
        const componentProps = dotProp.get(
          renderLayer,
          "$vnode.componentOptions.Ctor.extendOptions.props",
          {}
        );

        // 使用 proptype 替换掉 type& key == "bentolayoutmeta"
        map(componentProps, (value, key) => {
          if (!Boolean(value.isRequired)) {
            return;
          }
          let current = (props[key] = {});
          current.type = value.proptype || "string";
          current.isRequired = value.isRequired;

          if (typeof value.default == "function") {
            current.value = value.default();
          } else if (typeof value.default == "boolean") {
            current.value = value.default;
          } else {
            current.value = value.default || "";
          }

          if (
            key == "background" &&
            (this.layer.componentId == "box" ||
              this.layer.componentId == "bento-txt")
          ) {
            current.value = "#fff";
          }

          // 设置 layer image 的 src 从 store 里面取得。
          if (
            this.focus &&
            this.layer.componentId == "bento-image" &&
            this.$store.state.ui.needSetImageLayerSrc.length > 0 &&
            key == "src"
          ) {
            current.value = this.$store.state.ui.needSetImageLayerSrc;
            this.$store.commit("needSetImageLayerSrc", "");
          }
        });
        this.$store.dispatch("layerMountedInit", {
          id: this.layer.id,
          props: props,
          slots: renderLayer.$options.slots
        });
      }
    },
    async openMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      const canShow = await this.selecte(e);
      if (!canShow) {
        return;
      }
      this.$utils.setMenuPoz(e);
      this.$store.commit("setUI", {
        key: "menus",
        value: [
          {
            text: "Delete",
            operation: () => {
              this.$store.dispatch("deleteLayer", { id: this.layer.id });
            }
          }
        ]
      });
    },
    mousemove(e) {
      if (this.$store.state.ui.selectmode == "meta") {
        const inThis = this.allLayers.find(v => v.id == this.layer.id);
        if (!inThis) {
          return false;
        }
        this.$store.state.ui.hoverLayerId = this.layer.id;
        return;
      }
      const inThis = this.canSelectedLayers.find(v => v.id == this.layer.id);
      if (!inThis) {
        return false;
      }
      this.$store.state.ui.hoverLayerId = this.layer.id;
    },
    mouseout(e) {
      const inThis = this.allLayers.find(v => v.id == this.layer.id);
      if (!inThis) {
        return false;
      }
      this.$store.state.ui.hoverLayerId = 0;
      return;
    },
    select(e) {
      const invoke = () => {
        this.$root.$floating(this.$el);
        this.$root.$emit('resizeMoveStart', {event: e, layer: this.layer})
      }

      if (
        typeof this.$store.getters.foucsLayer == "undefined" &&
        this.$store.getters.selectedComponent &&
        this.$store.getters.selectedComponent.root
      ) {
        this.$store.commit(
          "setLayerId",
          this.$store.getters.selectedComponent.root
        );
        this.$store.state.ui.selectedLevel = 0;
        invoke()
        return;
      }

      if (this.$store.state.ui.selectmode == "meta") {
        const inThis = this.allLayers.find(v => v.id == this.layer.id);
        if (!inThis) {
          return false;
        }
        this.$store.dispatch("selectLayer", { id: this.layer.id });
        invoke()
        return;
      }

      const inThis = this.canSelectedLayers.find(v => v.id == this.layer.id);
      if (!inThis) {
        return false;
      }
      this.$store.dispatch("selectLayer", { id: this.layer.id });
      invoke()
      return true;
    },
    // 获取组件的数据
    async fetchComponent(componentId) {
      // 是基础组件不需要拉取数据直接跳出
      if (
        this.basicComponentNames.includes(componentId) ||
        componentId === "workspace"
      ) {
        return;
      }

      if (componentId.startsWith("code")) {
        this.code = true;
        this.design = false;
        componentId = componentId.replace("code", "");
      }

      let component = this.component(componentId);
      if (!component) {
        await this.$store.dispatch("fetchComponentById", { id: componentId });
        component = this.component(componentId);
      }

      this.localComponent = component;
      if (component && Reflect.has(component, "root")) {
        this.design = true;
        this.code = false;
        await this.$store.dispatch("fetchLayer", component.root);
        if (!this.$store.getters.layer(component.root)) {
          // root 没有找到，404
          component.root = null;
          // 删除掉它的 root
          this.$store.dispatch("updateComponentInfomation", component);
        }
      }

      if (component && Reflect.has(component, "code")) {
        this.design = false;
        this.code = true;
        if (typeof Vue.component("code" + component.id) != "undefined") {
          // 已经注册过了，就不用再去编译了。
          return;
        }
        const raw_source = await componentService.vueCompile(component.code);
        const { default: obj } = eval(raw_source);
        Vue.component("code" + component.id, obj);
        /* eslint-disable-next-line */
        console.info("Component: code" + component.id + " loaded from server!");
        this.$forceUpdate();
      }
    },
    children(Comp) {
      // 渲染 child
      const { layer, rootChildren, rootProps } = this;
      // 对于 slot 组件进行特殊处理
      if (Comp == "bento-slot" && rootChildren && rootChildren[this.layer.id]) {
        // 以 rootChildren 里面的组件优先，没有就使用默认的
        const children = rootChildren[this.layer.id]; // 替换的子组件
        if (children.length > 0) {
          return children.map(id => {
            // 动态容器的 node 包裹
            // 将 rootChilrent 传递下去
            return (
              <node
                id={id}
                key={id}
                rootProps={rootProps}
                rootChildren={rootChildren}
                level={this.level + 1}
              />
            );
          });
        }
      }

      if (!this.hasChildren) {
        return null;
      }

      let children = Object.keys(layer.children).map(slot => (
        <template slot={slot}>
          {layer.children[slot].map(id => {
            // 动态容器的 node 包裹
            return (
              <node
                id={id}
                key={id}
                rootChildren={rootChildren}
                rootProps={rootProps}
                level={this.level + 1}
              />
            );
          })}
        </template>
      ));
      return children;
    },
    // 继续调用自身是树组件
    selfTree(Comp) {
      const _layer = this.layer;
      // 树组件
      if (this.design) {
        let layer = this.getLayer(this.localComponent.root);

        const rootChildren = {};
        Object.keys(_layer.children).map(key => {
          key = key.replace("lid:", "");
          rootChildren[key] = _layer.children["lid:" + key];
        });

        // 渲染根树，将根树的 children 传递下去，替换掉下面 bento-slot 的 child
        return (
          <div
            class={{
              focus: this.focus,
              layer: true,
              "no-select": true,
              component: true,
              hover: this.hover
            }}
            onContextmenu={this.openMenu}
            style={this.layerStyle}
          >
            <node
              id={this.localComponent.root}
              noWrap={true}
              key={this.localComponent.root}
              rootChildren={Object.assign(rootChildren, this.rootChildren)}
              rootProps={this.props}
            />
          </div>
        );
      }
    }
  },
  computed: {
    // 修复直接插入组件导致 layout 传递
    parentLayout() {
      if (this.layoutType) {
        return this.layoutType(); // 确保只有真正的 layout 组件才有 layout 属性
      }
    },
    containerProps() {
      return {
        direction: this.$parent.direction,
        arrange: this.$parent.arrange,
        align: this.$parent.align
      };
    },
    hover() {
      return this.$store.state.ui.hoverLayerId == this.layer.id;
    },
    layerStyle() {
      if (this.noWrap) {
        return {};
      }
      if (this.layoutItem && this.layer) {
        const style = this.layoutItem(this.layer);
        return style;
      }
    },
    layer() {
      return this.getLayer(this.id);
    },
    getLayer() {
      return this.$store.getters.layer;
    },
    hasChildren() {
      return (
        this.layer &&
        this.layer.children &&
        Object.keys(this.layer.children).length > 0
      );
    },
    focus() {
      return (
        this.foucsLayer && this.layer && this.foucsLayer.id == this.layer.id
      );
    },
    // 当前选中 component 的所有 layers
    allLayers() {
      return this.$store.getters.currentComponentAllLayers;
    },
    canSelectedLayers() {
      return this.$store.getters.currentComponentAllLayersHasLevel[
        this.$store.state.ui.selectedLevel
      ];
    },
    // 属性
    props() {
      const { layer, rootProps } = this;
      let props = clone(layer.props);

      if (rootProps && rootProps[layer.id]) {
        map(rootProps[layer.id], (value, key) => {
          // 有映射就替换过去
          if (key.includes("|map2:")) {
            let [skey, mapto] = key.split("|map2:");
            console.log("has mapping props");
            console.log(skey, mapto);
            if (props[mapto]) {
              props[mapto][skey] = value;
            }
            return;
          }
          props[key] = value;
        });
      }
      return props;
    },
    ...mapGetters(["foucsLayer", "component"])
  },
  updated() {
    // 重新渲染时候，重新计算位置
    this.$nextTick(() => {
      this.changePoz();
    });
  },
  render() {
    const { layer, rootProps } = this;
    if (typeof layer == "undefined") {
      // 数据拉取中，先 return, 或者数据有问题
      return null;
    }

    if (layer.componentId === "workspace") {
      return null;
    }

    if (this.code && typeof Vue.component(layer.componentId) == "undefined") {
      // 没有拉取到组件，或者根本就没有，先渲染个空节点
      return <none />;
    }

    const Comp = this.compName(layer.componentId);

    // debugger

    // 嵌套的代码组件去掉自身的 warp div
    if (this.noWrap && this.code) {
      return (
        <Comp id={layer.id} ref="current" {...{ props: this.props }}>
          {this.children(Comp)}
        </Comp>
      );
    }
    // 内置组件或者代码组件
    if (this.basicComponentNames.includes(Comp) || this.code) {
      return (
        <div
          class={{
            focus: this.focus,
            layer: true,
            hover: this.hover,
            "no-select": this.layer.root
          }}
          onContextmenu={this.openMenu}
          style={this.layerStyle}
        >
          <Comp id={layer.id} ref="current" {...{ props: this.props }}>
            {this.children(Comp)}
          </Comp>
        </div>
      );
    }
    // 层级组件
    return this.selfTree(Comp);
  }
};
</script>
