var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prop-editor", class: _vm.size },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "div",
            {
              staticClass: "prop-editor--label flex",
              on: {
                contextmenu: function($event) {
                  _vm.rightClickLabel()
                }
              }
            },
            [
              _vm._v(_vm._s(_vm.label)),
              _c(
                "span",
                {
                  staticClass: "prop-connector ani-fast",
                  class: { "is-on": _vm.isProp }
                },
                [_c("div", { staticClass: "oval" })]
              )
            ]
          ),
          !_vm.required
            ? _c("bSwitcher", {
                attrs: { size: _vm.size, value: _vm.show },
                on: {
                  change: function($event) {
                    _vm.$emit("toggle", !_vm.show)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.show ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }