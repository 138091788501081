// 无限画布布局

function findAllPadding(layers = []) {
  let top = 0;
  let right = 0;
  let bottom = 0;
  let left = 0;

  for (let layer of layers) {
    const _top = parseInt(layer.position.align.previewArea.top, 10);
    const _left = parseInt(layer.position.align.previewArea.left, 10);

    top = Math.min(_top, top);
    left = Math.min(_left, left);
    bottom = Math.max(_top, top);
    right = Math.max(_left, left);
  }

  return {
    left,
    right,
    bottom,
    top
  };
}

const container = layers => {
  const { left, top, right, bottom } = findAllPadding(layers);

  const paddingStyle = {
    "padding-left": `${Math.abs(left)}px`,
    "padding-top": `${Math.abs(top)}px`,
    "padding-right": `${Math.abs(right)}px`,
    "padding-bottom": `${Math.abs(bottom)}px`,
    position: "relative"
  };

  return paddingStyle;
};

export default container;
