var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "popup" } },
    [
      _c(
        "router-link",
        {
          staticClass: "proj-thumbnail radius-l ani-fast pointer shadow-card",
          attrs: {
            to: { name: "ProjEditorIndex", params: { id: _vm.projId } }
          },
          nativeOn: {
            contextmenu: function($event) {
              return _vm.openMenu($event)
            }
          }
        },
        [
          _c("div", { staticClass: "proj-name f-title color-text-2" }, [
            _vm._v(_vm._s(_vm.projName))
          ]),
          _c("div", { staticClass: "proj-info f-p color-text-3" }, [
            _vm._v("modified "),
            _c("span", { staticClass: "color-text-2" }, [
              _vm._v(_vm._s(_vm.lastModifiedTime) + " ")
            ])
          ]),
          _c("div", { staticClass: "proj-info f-p color-text-3" }, [
            _vm._v("by "),
            _c("span", { staticClass: "color-text-2" }, [
              _vm._v(_vm._s(_vm.lastModifiedMember))
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }