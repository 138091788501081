<script>
const options = [{ label: "False", value: "false" }, { label: "True", value: "true" }];

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    param: {
      type: String,
      default: ""
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  render() {
    return (
      <bRadioBtn
        options={options}
        value={this.value ? "true" : "false"}
        onChange={v => this.$emit("change", v == "true")}
      />
    );
  }
};
</script>
