// 获取单个子元素的 main size
const getGridSize = (item, dir) => {
  let gridSize;
  if (dir === "x") {
    if (item.position.align.stack.flex) {
      gridSize = "1fr";
    } else {
      gridSize = Number.isInteger(item.position.size.x.default)
        ? `${item.position.size.x.default}px`
        : item.position.size.x.default;
    }
  } else if (dir === "y") {
    if (item.position.align.stack.flex) {
      gridSize = "1fr";
    } else {
      gridSize = Number.isInteger(item.position.size.y.default)
        ? `${item.position.size.y.default}px`
        : item.position.size.y.default;
    }
  }
  return gridSize;
};

function container(layout, children) {
  const { direction: dir, arrange: arr, align: ali, gap } = layout;

  const sizeArray = children.map(layer => getGridSize(layer, dir)).join(" ");

  let style_dir, style_align, style_arrange, style_gap;
  // 设定排列方向
  if (dir === "x") {
    style_dir = {
      gridAutoFlow: "column"
    };
  } else if (dir === "y") {
    style_dir = {
      gridAutoFlow: "row"
    };
  }

  // align items
  if (dir === "x") {
    style_align = {
      alignItems: ali,
      alignContent: ali
    };
  } else if (dir === "y") {
    style_align = {
      justifyItems: ali,
      justifyContent: ali
    };
  }

  // arrange items
  if (dir === "x") {
    if (arr !== "stretch") {
      style_arrange = {
        justifyContent: arr,
        gridTemplateColumns: sizeArray
      };
    } else {
      style_arrange = {
        justifyContent: arr,
        gridAutoColumns: "1fr"
      };
    }
  } else if (dir === "y") {
    if (arr !== "stretch") {
      style_arrange = {
        alignContent: arr,
        gridTemplateRows: sizeArray
      };
    } else {
      style_arrange = {
        alignContent: arr,
        gridAutoRows: "1fr"
      };
    }
  }
  // gaps
  if (arr === "space-between") {
    style_gap = {
      gridGap: "unset"
    };
  } else {
    style_gap = {
      gridGap: gap
    };
  }

  return {
    display: "inline-grid",
    ...style_dir,
    ...style_align,
    ...style_arrange,
    ...style_gap
  };
}

export default container;
