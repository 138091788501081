<template lang="pug">
  .option.color-button-sub.ani-fast.pointer.f-p.color-text-2.radius-s {{ text }}
</template>

<style lang="stylus" scoped>
@require '../../../../styles/layout/main'
@require '../../../../styles/design/main'

.option
  padding 0.25rem .5rem
  &.hover
    color var(--clr-content)
    background var(--clr-bg-2)
  &.active
    color var(--clr-primary)

</style>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    isHover: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
};
</script>
