var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root radius-l shd-l" },
    [
      _c("input", {
        staticClass:
          "f-p bold input-name size-input-s color-input-gray radius-m ani-fast",
        attrs: { placeholder: "name" }
      }),
      _c("color-picker-chrome", {
        tag: "component",
        staticClass: "type-editor",
        attrs: { value: "blue" }
      }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buttons" }, [
      _c(
        "button",
        {
          staticClass:
            "f-p bold size-button-s color-button-normal pointer radius-m ani-fast"
        },
        [_vm._v("Cancel")]
      ),
      _c(
        "button",
        {
          staticClass:
            "f-p bold size-button-s color-button-black pointer radius-m ani-fast"
        },
        [_vm._v("Save")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }