import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import { router } from "./helpers";

Vue.config.productionTip = false;

// Execute Mock Backend
// Replace soon with real Backend
import { mockBackend } from "./helpers";
mockBackend();

/* components */

// layout component
import Flow from "./layout/Flow.vue";
Vue.component("flow", Flow);
import Scroll from "./layout/Scroll.vue";
Vue.component("scroll", Scroll);

// form component
import bBtn from "@/components/form_element/button.vue";
Vue.component("bBtn", bBtn);

import bBtnColor from "@/components/form_element/button_color.vue";
Vue.component("bBtnColor", bBtnColor);

import bRadioBtn from "@/components/form_element/enum/radio_button.vue";
Vue.component("bRadioBtn", bRadioBtn);

import bDropSelector from "@/components/form_element/enum/drop_selector.vue";
Vue.component("bDropSelector", bDropSelector);

import bInput from "@/components/form_element/input.vue";
Vue.component("bInput", bInput);

import bInputSelector from "@/components/form_element/input_selector.vue";
Vue.component("bInputSelector", bInputSelector);

import bTabs from "@/components/form_element/tabs.vue";
Vue.component("bTabs", bTabs);

import bSwitcher from "@/components/form_element/boolean/switcher.vue";
Vue.component("bSwitcher", bSwitcher);

// icon loader
import Icon from "vue-svg-icon/Icon.vue";
Vue.component("icon", Icon);

// gravatar
import Gravatar from "vue-gravatar";
Vue.component("v-gravatar", Gravatar);

// utils
import utils from "./utils/utils.js";
Vue.use(utils);

// sentry
if ("production" === process.env.NODE_ENV) {
  // import * as Sentry from "@sentry/browser";
  const Sentry = require("@sentry/browser"); // import only allow in top level
  Sentry.init({
    dsn: "https://a236d417514740dc948782bc7a04aff5@sentry.io/1294627",
    integrations: [new Sentry.Integrations.Vue({ Vue })]
  });
}

// hotkey <- buggy
// import VueHotkey from 'v-hotkey'
// Vue.use(VueHotkey)

import { Chrome, Swatches } from "vue-color";
Vue.component("color-picker-chrome", Chrome);
Vue.component("swatches-picker", Swatches);

/* directions */

//shortkey
Vue.use(require("vue-shortkey"), { prevent: ["input", "textarea"] });

import { format } from "timeago.js";

Vue.filter("ago", v => {
  if (v.toDate) {
    v = v.toDate();
  }
  return format(v);
});

Vue.prototype.$ui = store.state.ui;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
