var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "f-p color-light",
      attrs: { id: "app" },
      on: {
        contextmenu: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c("router-view", { staticClass: "full-xy" }),
      _vm.ui.teamCreator
        ? _c(
            "overlay",
            {
              staticClass: "full-xy abs",
              attrs: { center: "", shadowVisible: "", shadowClickable: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.closeMenus($event)
                }
              }
            },
            [_c("teamCreator", { staticClass: "center-xy abs" })],
            1
          )
        : _vm._e(),
      _vm.ui.menus && _vm.ui.menus.length > 0
        ? _c(
            "overlay",
            {
              staticClass: "full-xy abs",
              nativeOn: {
                click: function($event) {
                  return _vm.closeMenus($event)
                },
                contextmenu: function($event) {
                  return _vm.closeMenus($event)
                }
              }
            },
            [_c("contextMenu", { staticClass: "abs" })],
            1
          )
        : _vm._e(),
      _vm.ui.imageSelector
        ? _c(
            "overlay",
            {
              staticClass: "full-xy abs",
              attrs: {
                close: _vm.closeImageSelector,
                shadowVisible: "",
                shadowClickable: ""
              }
            },
            [_c("imageSelector", { staticClass: "full-xy-90 abs" })],
            1
          )
        : _vm._e(),
      _vm.ui.colorPicker.visible
        ? _c(
            "overlay",
            {
              staticClass: "full-xy abs",
              attrs: { close: _vm.closeColorPicker }
            },
            [
              _c(
                "div",
                { staticClass: "position", style: _vm.colorPositionStyle },
                [
                  _c("colorPicker", {
                    staticClass: "picker",
                    attrs: { value: _vm.ui.colorPicker.value }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.ui.optionSelector.visible
        ? _c(
            "overlay",
            { staticClass: "full-xy abs" },
            [
              _c("optionSelector", {
                staticClass: "abs",
                style: _vm.optionSelectorStyle,
                attrs: {
                  options: _vm.ui.optionSelector.options,
                  value: _vm.ui.optionSelector.value,
                  active: _vm.ui.optionSelector.active,
                  eventName: _vm.ui.optionSelector.eventName
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("dragThumbnail")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }