<template lang="pug">
  transition(name="fade-drawer")
    .context-menu.radius-l.shd-l(v-show="ui.menus.length > 0" :style="ui.menusConfig") 
      template(v-for="menu in ui.menus")
        menuLine(v-if="menu.sep")
        menuItem(v-else :menuText='menu.text' :operation='menu.operation')
</template>

<script>
import menuLine from "./menuLine";
import menuItem from "./menuItem";
import { mapState } from "vuex";

export default {
  name: "contextMenu",
  components: {
    menuLine,
    menuItem
  },
  computed: mapState(["ui"])
  // data() {
  //   return {
  //     items: [
  //       {
  //         name: "item 1",
  //         type: "menuItem",
  //         onclick: ""
  //       },
  //       {
  //         name: "item 2",
  //         type: "menuLine",
  //         onclick: ""
  //       }
  //     ]
  //   };
  // }
};
</script>

<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.context-menu
  padding 0.375rem 0
  background $gray-9
  min-width 8rem
  transform-origin top center
  transition:
    opacity .15s cubic-bezier(0.560, 0.480, 0.335, 0.980),
    transform .2s cubic-bezier(0.560, 0.480, 0.335, 0.980)

.fade-drawer-enter, .fade-drawer-leave-to
  opacity: 0;
  transform translateY(-0.125rem)

</style>
