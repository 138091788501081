const url = `${process.env.VUE_APP_API_URL}/projectFolders`;
export async function create(name) {
  return await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },

    body: JSON.stringify({
      name,
      projectIds: ["x9cbjqayid", "vrdqz2xnb6r", "ujfq4u12c0f"],
      lastModify: "12-11-2018"
    })
  }).then(d => d.json());
}

export async function findById(id) {
  return fetch(url + "/" + id).then(d => d.json());
}

export async function list() {
  return await fetch(url).then(d => d.json());
}

export async function remove(id) {
  return await fetch(url + "/" + id, {
    headers: {
      "Content-Type": "application/json"
    },
    method: "DELETE"
  }).then(d => d.json());
}
