var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "label-layer radius-s pointer ani-fast color-text-2",
      class: { "is-focused": _vm.isFocused }
    },
    [
      _c("icon", { staticClass: "icon", attrs: { name: _vm.icon } }),
      _c("div", { staticClass: "text flex ellipsis" }, [
        _vm._v(_vm._s(_vm.name))
      ]),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasChildren,
            expression: "hasChildren"
          }
        ],
        staticClass: "hasChildren oval"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }