<template lang="pug">
  .export-options
    avatar(:email="email" @click.native="toggleDrawer")
    transition(name="fade-shd")
      .shadow.ani(@click="toggleDrawer" v-show="isOpen")
    transition(name="fade-drawer")
      .drawer.shd-l.radius-m.ani(v-show="isOpen")
        .option.f-p.pointer.color-button-sub.size-button-m.ani-fast(@click="logout") Log out
</template>

<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.shadow
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  background alpha(black, .25)
  z-index 2

.drawer
  // position
  position absolute
  top calc(100% + .125rem)
  right 0
  z-index 3
  min-width 10rem
  // layout
  stack(y, gap: 1rem)
  padding .5rem 0
  // style
  color var(--clr-content-2)
  background white
// .option


// animation
.fade-shd-enter, .fade-shd-leave-to
  opacity: 0;

.fade-drawer-enter, .fade-drawer-leave-to
  opacity: 0;
  transform translateY(.25rem)

</style>

<script>
import avatar from "@/components/User/thumbnail/avatar";
import { getCurrentUser, signOut } from "@/services/init.js";
export default {
  components: {
    avatar
  },
  data() {
    return {
      isOpen: false,
      email: getCurrentUser() ? getCurrentUser().email : "somebody@somewhere.com"
    };
  },
  props: {
    // email: String
  },
  methods: {
    toggleDrawer() {
      this.isOpen = !this.isOpen;
    },
    async logout() {
      await signOut();
      this.$router.push("/login");
    }
  }
};
</script>
