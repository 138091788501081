var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    staticClass: "textarea f-p size-input-s clr-input-p1 radius-m ani-fast",
    attrs: { placeholder: _vm.placeholder },
    domProps: { value: _vm.value },
    on: {
      keydown: function($event) {
        if (
          !("button" in $event) &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        $event.preventDefault()
        return _vm.upload($event)
      },
      change: _vm.upload
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }