var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "master" }, [
    _c("input", {
      staticClass: "fix-width color-input-sub radius-m ani-fast size-input-s",
      style: _vm.style,
      attrs: { placeholder: "Custom " },
      domProps: { value: _vm.value },
      on: { change: _vm.onValueChange }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }