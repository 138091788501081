var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "shortkey",
          rawName: "v-shortkey",
          value: _vm.shortKey,
          expression: "shortKey"
        }
      ],
      staticClass: "project-editor stack",
      on: { shortkey: _vm.action }
    },
    [
      _c("ProjNav"),
      _vm.currentTab === "workspace"
        ? _c(
            "div",
            { staticClass: "flex stack" },
            [
              _vm.currentTab === "workspace"
                ? _c("ComponentEditor", {
                    staticClass: "flex",
                    attrs: { previewContainer: "workspace" }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm.currentTab === "components"
          ? [_c("router-view", { staticClass: "flex" })]
          : _vm.currentTab === "themes"
            ? _c("DesignTokenBrowser", { staticClass: "flex" })
            : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }