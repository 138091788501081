var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("scroll", { attrs: { dir: "y", padding: "2rem" } }, [
    _vm.selectedProjectComponent <= 0
      ? _c(
          "div",
          { staticClass: "empty abs ani" },
          [
            _c("div", { staticClass: "f-h3 color-text-1" }, [
              _vm._v("No Component in this team.")
            ]),
            _c(
              "b-btn",
              {
                attrs: { color: "dark", size: "l" },
                nativeOn: {
                  click: function($event) {
                    return _vm.addNewComponent($event)
                  }
                }
              },
              [_vm._v("+ New Component")]
            )
          ],
          1
        )
      : _c("div", { staticClass: "comp-finder" }, [
          _c(
            "div",
            { staticClass: "comp-finder--top" },
            [
              _c(
                "b-btn",
                {
                  attrs: { size: "m", color: "dark" },
                  nativeOn: {
                    click: [
                      function($event) {
                        return _vm.addNewComponent($event)
                      },
                      function($event) {
                        if (!$event.altKey) {
                          return null
                        }
                        return _vm.openFile($event)
                      }
                    ]
                  }
                },
                [_vm._v("+ New Component")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "comp-finder--body flex" },
            _vm._l(_vm.selectedProjectComponent, function(item, index) {
              return _c("ListItem", {
                key: item.id,
                attrs: {
                  progress: item.progress,
                  label: item.label,
                  design: _vm.isDesign(item),
                  icon: _vm.icon(item.type),
                  id: item.id
                },
                nativeOn: {
                  click: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft"
                      ])
                    ) {
                      return null
                    }
                    if ("button" in $event && $event.button !== 0) {
                      return null
                    }
                    _vm.select(item.id)
                  }
                }
              })
            }),
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }