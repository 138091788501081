<template lang="pug">
.menu-item.ani-fast.pointer.f-p(@click.stop="close" :class="{'no-hover': !hover}") {{menuText}}
</template>
<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.menu-item
  padding .25rem 1rem
  color var(--clr-content-reverse)
  &:hover
    background var(--clr-primary)
</style>

<script>
export default {
  props: {
    menuText: {
      type: String,
      required: true
    },
    hover: {
      type: Boolean,
      default: true
    },
    operation: {
      type: Function
    }
  },
  methods: {
    close() {
      if (!this.operation) {
        return;
      }
      this.operation();
      this.$store.commit("setUI", { key: "menus", value: [] });
    }
  }
};
</script>
