export default {
  data() {
    return {
      fontsFamilyType: "inherit",
      fontsFamily: "inherit",
      fontWeight: "inherit"
    };
  },
  watch: {
    "typography.typeface": function(newValue) {
      // 多人协作，同步更新 & 切换数据源更新 UI
      this.processTypeface(newValue);
    }
  },
  computed: {
    fontscaleArr() {
      if (!this.fontscale) return ["inherit", "inherit"];
      return this.typography.fontscale.split("@");
    },
    fontSize() {
      if (!this.fontscale) return "inherit";
      return this.typography.fontscaleArr[0];
    },
    lineHeight() {
      if (!this.fontscale) return "inherit";
      return this.typography.fontscaleArr[1];
    }
  },
  methods: {
    processTypeface(value) {
      if (!value || !value.includes("@")) {
        this.fontsFamilyType = "inherit";
        this.fontsFamily = "inherit";
        this.fontWeight = "inherit";
      } else {
        const valueArr = value.split("@");
        this.fontsFamilyType = valueArr[0];

        if (this.fontsFamilyType === "google") {
          // 兼容服务端渲染，服务端 `window === undefined` 会导致加载 `webfontloader` 异常
          if (typeof window !== "undefined") {
            const WebFont = require("webfontloader");
            WebFont.load({
              google: {
                families: [`${valueArr[1]}:${valueArr[2]}`]
              },
              classes: false,
              fontloading: () => {
                this.fontsFamily = valueArr[1];
                this.fontWeight = valueArr[2] === "regular" ? "400" : Number.parseInt(valueArr[2]) || 400;
                this.fontStyle = valueArr[2].includes("italic") ? "italic" : "unset";
              }
            });
          } else {
            // SSR 先进行样式标记
            this.fontsFamily = valueArr[1];
            this.fontWeight = valueArr[2] === "regular" ? "400" : Number.parseInt(valueArr[2]) || 400;
            this.fontStyle = valueArr[2].includes("italic") ? "italic" : "unset";
          }
        } else {
          this.fontsFamily = valueArr[1];
          this.fontWeight = valueArr[2];
        }
      }
    }
  },
  created() {
    this.processTypeface(this.typography.typeface);
  }
};
