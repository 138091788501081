import { generate } from "shortid";
import Firestore from "./init.js";

const firestoreProjectsInst = new Firestore({ collectionName: "projects" });

export async function create(name, layerId) {
  let now = new Date();
  let proj = await firestoreProjectsInst.create({
    id: generate(),
    name,
    editable: true,
    componentIds: [],
    workspace: layerId
  });
  proj.createdAt = {
    toDate() {
      return now;
    }
  };

  return proj;
}

export function list() {
  return firestoreProjectsInst.list();
}

export async function findById(id) {
  return firestoreProjectsInst.findById(id);
}

export async function update(project) {
  return firestoreProjectsInst.update({
    id: project.id,
    name: project.name,
    editable: project.editable,
    componentIds: project.componentIds
  });
}

export async function remove(id) {
  return firestoreProjectsInst.remove(id);
}
