export default function render(param) {
  if (param == false) {
    return {
      overflow: "visible"
    };
  } else {
    return {
      overflow: "hidden"
    };
  }
}
