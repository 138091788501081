import { GraphQLClient } from "graphql-request";

const GRAPHQL_API_URL = process.env.VUE_APP_GRAPHQL_API_URL;

const buildClient = (token = localStorage.getItem("token")) => {
  if (!token) {
    return new GraphQLClient(GRAPHQL_API_URL);
  }
  return new GraphQLClient(GRAPHQL_API_URL, { headers: { Authorization: `Bearer ${token}` } });
};

export default buildClient;
