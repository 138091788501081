import api from "./graphqlClient";

export async function requestJwtToken(email, auth0AccessToken) {
  const query = `
    mutation requestJwtToken($email: String!, $auth0AccessToken: String!){
      requestJwtToken(email: $email, auth0AccessToken: $auth0AccessToken) {
        id
        token
        expires_at
      }
    }
  `;
  const vars = { email, auth0AccessToken };
  return api()
    .request(query, vars)
    .then(({ requestJwtToken }) => requestJwtToken);
}
