<template lang="pug">
    .flex.body
      .form.stack-y-xl
        a.banner.pointer(href="/")
          img.logo(src="@/assets/logo/logo-dev.png")
          .f-h3.title Welcome to Bento
            span.beta.pill.f-p.bold Dev
        .stack-y-l
          //- 提示用户此时的状态
          .stack-y-s
            .loading(v-if="isLoading") Loading
            .success(v-if="isSuccess") 
              p(v-if="type === 'email'") Thank You! The link was successfully sent. Please check your email account.
              p(v-if="type === 'google'") Successfully
            .errorMessage(v-if="error") {{ error }}
          .stack-y-s
            .f-p Login with
            button.f-title.pointer.color-button-black.size-button-l.radius-m.ani(
              :disabled="isLoading"
              @click.prevent="handleGoogleSubmit"
              ) Google
          .stack-y-s
            .f-p or email
            form.stack-y-m(@submit.prevent="handleSubmit")
              input.size-input-l.f-p.color-input-gray.radius-m.ani(
                type="email"
                v-model="email"
                name="email"
                placeholder="Your email address"
                autofocus
              )
              button.f-title.pointer.color-button-black.size-button-l.radius-m.ani(
                v-show="email"
                type="submit"
                :disabled="isLoading"
              ) Submit
</template>

<style lang="stylus" scoped>
@require '../../styles/design/main'
@require '../../styles/layout/main'
.login-root
  width 100%
  height 100%
  stack(y, arr: between)

// body
.body
  display inline-grid
  place-content center
  position relative
.form
  height 20rem
  margin-top -10rem
  padding 1rem
  width 18rem
  max-width 100%

.banner
  stack(y, gap: 1rem, ali: center)
  .logo
    width 64px
  .beta
    // position
    position relative
    top -.75rem
    left .5rem
    // style
    background var(--clr-bg-3)
    // layout
    padding .25rem .5rem
  .title
    text-align center

.stack-y-xl
  stack(y, gap: 2rem, arr: space-between)
.stack-y-l
  stack(y, gap: 1rem, ali: stretch)
.stack-y-m
  stack(y, gap: .5rem, ali: stretch)
.stack-y-s
  stack(y, gap: .25rem, ali: stretch)
  color var(--clr-content-2)


input:invalid
  box-shadow 0 0 4px 0 red

.loading
  color #69c0ff

.success
 color #52c41a

.errorMessage
  color red

</style>

<script>
import { isAuthenticated, sendSignInLinkToEmail, signInWithGoogleProvider } from "@/services/init.js";

export default {
  name: "Login",
  data() {
    return {
      type: "",
      email: "",
      isSuccess: false,
      isLoading: false,
      returnUrl: "",
      error: ""
    };
  },
  beforeRouteEnter(from, to, next) {
    if (isAuthenticated()) {
      return next({ name: "ProjectBrowser", params: { id: 1 } });
    }
    return next();
  },
  created() {
    this.returnUrl = this.$route.query.returnUrl || "/login";
  },
  methods: {
    // 自定义校验逻辑
    // isEmail(value) {
    //   const emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   return emailRegx.test(value);
    // },
    async handleSubmit() {
      try {
        this.type = "email";
        this.error = "";
        this.isLoading = true;
        await sendSignInLinkToEmail(this.email);
        this.isLoading = false;
        this.isSuccess = true;
      } catch (e) {
        this.isLoading = false;
        this.isSuccess = false;
        // this.error = e.code;
        this.error = e.message;
      }
    },
    async handleGoogleSubmit() {
      try {
        this.type = "google";
        this.error = "";
        this.isLoading = true;
        const data = await signInWithGoogleProvider();
        this.isLoading = false;
        this.isSuccess = true;
        if (data.additionalUserInfo.isNewUser) {
          await this.$store.dispatch("createTeam", {
            teamName: "Primary"
          });
        }
        this.$router.push("/team/0");
      } catch (e) {
        this.isLoading = false;
        this.isSuccess = false;
        this.error = e.message;
      }
    }
  }
};
</script>
