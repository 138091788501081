var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "scroll",
    {
      staticClass: "flex",
      attrs: { dir: "y", padding: "0 0.5rem", hideBar: "" }
    },
    [
      _c(
        "div",
        { staticClass: "lib-list" },
        _vm._l(_vm.externalProjects, function(project) {
          return project.components.length > 0
            ? _c("div", { staticClass: "library" }, [
                _c(
                  "div",
                  { staticClass: "title color-text-2 f-title sticky ellipsis" },
                  [_vm._v(_vm._s(project.name))]
                ),
                _c(
                  "div",
                  { staticClass: "comp-list" },
                  _vm._l(project.components, function(item, index) {
                    return _c("ListItem", {
                      key: item.id,
                      attrs: {
                        progress: item.progress,
                        label: item.label,
                        design: _vm.isDesign(item),
                        icon: _vm.componentSlotIcon(item),
                        id: item.id,
                        isFocus: false
                      }
                    })
                  }),
                  1
                )
              ])
            : _vm._e()
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }