import inners, { isInnerComponent } from "@/components/preview_area/built-in component/builtIn.js";
import store from "../store/store";

let emptyImage;

function capitalizeFirstLetter(string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

const utils = {
  props: require("./props").default,
  master: {
    getComponentIcon(type) {
      const component = inners.find(c => c.icon == type);
      if (component) {
        return "component/builtin/" + component.icon;
      }
      return "component/component";
    }
  },
  inners,
  center() {
    let containerWidth = document.querySelector("#scroll").scrollWidth;
    document.querySelector("#scroll").scrollTo((containerWidth - document.documentElement.clientWidth) / 2, 0);
  },
  centerXY() {
    let containerWidth = document.querySelector("#scroll").scrollWidth;
    let containerHeight = document.querySelector("#scroll").scrollHeight;
    document
      .querySelector("#scroll")
      .scrollTo(
        (containerWidth - document.documentElement.clientWidth) / 2,
        (containerHeight - document.documentElement.clientHeight) / 2
      );
  },
  isInnerComponent,
  upperCase(str) {
    return capitalizeFirstLetter(str);
  },
  kebab2Pascal(str) {
    return str
      .split("-")
      .map(capitalizeFirstLetter)
      .join("");
  },
  // 默认值与左侧树节点大小一直
  emptyPng() {
    if (emptyImage) {
      return emptyImage;
    }
    // if (!emptyCanvas) {
    //   emptyCanvas = document.createElement("canvas");
    // }
    // emptyCanvas.width = width;
    // emptyCanvas.height = height;
    const image = new Image();
    /* eslint-disable-next-line */
    image.src = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
    emptyImage = image;
    return image;
  },
  setMenuPoz(e) {
    store.commit("setUI", {
      key: "menusConfig",
      value: {
        left: e.clientX + "px",
        top: e.clientY + "px"
      }
    });
  },
  resetDropImage(e) {
    e.dataTransfer.setDragImage(utils.emptyPng(), 90, 90);
  }
};

export { utils };

export default {
  /* eslint-disable no-unused-vars*/
  install(Vue, options = {}) {
    Vue.prototype.$utils = utils;
  }
};
