<template lang="pug">
.team-home.b-z--unset
  .team-home--head
    .logo
      img.logo-img(src='@/assets/logo/empty logo.png')
      .f-h3 Bento
      //- button.sel-team.color-button-sub.size-button-s.f-h3.radius-m.ani-fast.pointer(v-if="selectedTeamId === 0" @click="openTeamCreator") Create new team
      //- select.sel-team.color-button-sub.size-button-s.f-h3.radius-m.ani-fast.pointer(v-else v-bind:value="selectedTeamId" @change="teamChange")
        option(:value="0") Create new team
        option(v-for="team in teams", :value="team.id" :selected="team.id == selectedTeamId") {{ team.name }}
    navAvatar
  //- Team 首页 v2
  .flex.team-home--body
    //- 成员列表，暂时隐藏
    //- scroll.members(dir='y', padding='2rem')
      .session
        .f-h3 Members
        .member-list
          .item item member
          .item item member
          .item item member
          .item item member
    scroll.flex.center(dir='y', padding='2rem')
      //- empty
      .empty.abs.ani(v-if="projects.length <= 0")
        .message
          img.img(src="@/assets/image/empty-project.png")
          .f-h3.color-text-1 No project in this team.
        b-btn(color="dark" size="l" @click.native="addProject") + New Project
      //- not empty
      .center-content(v-else)
        .session
          .title
            .f-h3 Projects
            b-btn(size="m" @click.native="addProject") + New Project
          .project-list(v-model="projects")
            ProjectThumbnail(
              v-for="proj in sortedProj"
              :lastModifiedTime="(proj.updatedAt || proj.createdAt) | ago"
              :lastModifiedMember="proj.createdBy &&  proj.createdBy.displayName || proj.updatedBy && proj.updatedBy.displayName"
              :projId="proj.id"
              :projName="proj.name"
              :key="proj.id"
            )
        //- 已发布页面的列表，暂时隐藏
        //- .session(v-if="publishedSites.length > 0")
          .f-h3 Published
          .project-list(v-model="publishedSites")
            item-site(
              v-for="site in publishedSites"
              :lastModifiedTime="site.lastModify"
              :projId="site.id"
              :projName="site.name"
              :key="site.id"
              )
    //- 动态列表，暂时隐藏
    //- scroll.members(dir='y', padding='2rem')
      .session
        .f-h3 Activity
        .activity-list
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity
          Activity

    
  //- .flex.content
    //- file browser
    FileBrowser.flex
      template(slot='top')
        transition(name="fade")
          .top.padding.ani(v-if="projects.length > 0")
            .top-bg.padding.radius-m.shd-m
              .left
                .f-title Projects
              .right
                //- button.button.color-button-normal.size-button-m.f-p.radius-m.ani-fast.pointer
                  span.icon 📁 
                  span New Folder
                button.button.color-button-black.size-button-m.f-p.radius-m.ani-fast.pointer(@click="addProject") 
                  //- span.icon 🍣 
                  span + New Project
                //- delete dropzone only visible when draging items
                //- 临时隐藏回收站
                //- .button.color-button-danger.size-button-m.f-p.radius-m.ani-fast.pointer 
                  span.icon 🗑 
                  span Trash
      template(slot='content')
        //- empty
        transition(name="fade")
          .empty.abs.ani(v-if="projects.length <= 0")
            .message
              img.img(src="@/assets/image/empty-project.png")
              .f-h3.color-text-1 No project in this team.
            button.button.color-button-black.size-button-l.f-title.radius-m.ani-fast.pointer(@click="addProject") 
              span + New Project

        //- not empty
        .project-list(v-model="projects")
          item-proj(
            v-for="proj in projects"
            :lastModifiedTime="proj.lastModify"
            :projId="proj.id"
            :projName="proj.name"
            :key="proj.id"
            )
          //- insert sites todo
    //- 暂时隐藏 activity
    //- Activities

</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

// root
.team-home
  stack(y, ali:stretch)

// team-home--head
.team-home--head
  // layout
  stack(x, arr:between, ali:center)
  // style
  // color var(--clr-content-2)
  background var(--clr-bg)
  padding 1rem
.logo
  stack(x, gap: .5rem, ali: center)
.logo-img
  height 2rem
.sel-team
  min-width 8rem

// team-home--body
.team-home--body
  stack(x, gap: 1rem, arr: start)
  background var(--clr-bg-2)
.empty
  position absolute
  pos-abs(x:stretch, y: stretch)
  stack(y, gap: 2rem, ali: center, arr: center)
  .img
    width 12rem
  .message
    stack(y, gap: 1rem, ali: center, arr: center)
.center-content
  stack(y, gap: 2rem)

// session
.session
  stack(y, gap: 2rem)
.title
  stack(x, arr: between)
.activity-list
.member-list
  stack(y, gap: .5rem)
.project-list
  gridflow(gap:2rem, colmin:14rem)

// old
.padding
  padding 1rem
.top
  padding-bottom 0
  shade(var(--clr-bg-2))
.top-bg
  background var(--clr-bg)
  width 100%
  // layout
  stack(x, arr:between, ali:center)
  padding .5rem 1rem

  &>.right
    stack(x, gap:.5rem)
.fold-finder
  gridflow(gap:1rem, colmin:14rem)
.button
  &>.icon
    color var(--clr-content)

// old end

// aniamtion class
.fade-enter
.fade-leave-to
  opacity 0
.fade-enter-to
.card-leave
  opacity 1

</style>

<script>
import navAvatar from "@/components/team_home/Activity/nav.avatar.vue";
import FileBrowser from "@/components/others/fileBrowser.vue";

import ProjectThumbnail from "@/components/project_thumbnail/projectThumbnail.vue";
import avatar from "@/components/User/thumbnail/avatar";
// import Activities from "@/components/Activity/browser";
// import Activity from "@/components/Activity/thumbnail";
import ItemSite from "@/components/Site/thumbnail.vue";
import Folder from "@/components/Folder/thumbnail";
import { mapState } from "vuex";
import { ramdomName } from "@/helpers";
// import { getCurrentUser, signOut } from "@/services/init.js";

import clone from "clone";

export default {
  name: "ProjectBrowser",
  components: {
    FileBrowser,
    ProjectThumbnail,
    avatar,
    navAvatar,
    // Activities,
    // Activity,
    ItemSite,
    Folder
  },
  data() {
    return {
      // email: getCurrentUser() ? getCurrentUser().email : "somebody@somewhere.com"
    };
  },
  created() {
    if (this.$store.state.team.teams.every(t => t.id != this.$route.params.id)) {
      // 不存在再去获取，暂时先获取所有
      this.$store.dispatch("fetchTeams");
    }
  },
  computed: {
    projects() {
      return this.$store.getters.selectedTeamProject || [];
    },
    sortedProj() {
      const prjs = clone(this.projects);
      return prjs.sort((a, b) => {
        let a_date;
        let b_date;
        if (a.createdAt.toDate) {
          a_date = a.createdAt.toDate();
        } else {
          a_date = a.createdAt;
        }
        if (b.createdAt.toDate) {
          b_date = b.createdAt.toDate();
        } else {
          b_date = b.createdAt;
        }
        return b_date - a_date;
      });
    },
    ...mapState({
      teams(state) {
        return state.team.teams;
      },
      selectedTeamId(state) {
        return state.team.selectedTeamId;
      }
    })
  },
  watch: {
    selectedProjectFolderId(val) {
      this.$router.push({
        name: "ProjEditorIndex",
        params: {
          id: val
        }
      });
    },
    selectedTeamId(val) {
      this.$router.push({
        name: "ProjectBrowser",
        params: {
          id: val
        }
      });

      this.$store.dispatch("fetchTeamProjects");
    }
  },
  methods: {
    // async logout() {
    //   await signOut();
    //   this.$router.push("/login");
    // },
    addProject() {
      this.$store
        .dispatch("createProject", {
          name: ramdomName()
        })
        .then(id => {
          this.$store.commit("setSelectedProjectId", { id });
          this.$router.push({
            name: "ProjEditorIndex",
            params: {
              id
            }
          });
        });
    },
    teamChange(e) {
      const val = e.target.value;
      if (val == 0) {
        this.openTeamCreator();
        return (e.target.value = this.selectedTeamId); // 让 create team 无法选中
      }
      this.$store.commit("setSelectedTeamId", { id: val });
    },
    openTeamCreator() {
      this.$store.commit("setUI", {
        key: "teamCreator",
        value: true
      });
    }
  }
};
</script>
