var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btn-color" },
    [
      _c(
        "b-btn",
        {
          attrs: { color: "gray", size: "s" },
          nativeOn: {
            click: function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft"
                ])
              ) {
                return null
              }
              if ("button" in $event && $event.button !== 0) {
                return null
              }
              return _vm.togglePicker($event)
            }
          }
        },
        [
          _c(
            "div",
            { ref: "content", staticClass: "content" },
            [
              _c("div", { staticClass: "color clip radius-s" }, [
                _c("div", { staticClass: "triangle" }),
                _c("div", {
                  staticClass: "color--display abs",
                  style: { background: _vm.value }
                })
              ]),
              _c("span", { staticClass: "flex color-text-2 f-p" }, [
                _vm._v(_vm._s(_vm.value))
              ]),
              _c("icon", { staticClass: "icon", attrs: { name: "select" } })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }