<template lang="pug">
  bDropSelector(
    :options="['Inherit', 'Hyphen','No Hyphen','No Break']"
    :value="value"
    @change="$emit('change', $event)"
  )
</template>

<script>
export default {
  name: "wordBrakEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: "start"
    }
  }
};
</script>
