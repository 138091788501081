<template lang="pug">
  .value.fz-s.radius-s.mono {{valueText}}
</template>

<style lang="stylus" scoped>
.value
  padding 0 .125rem
  background black
  color white
  width max-content

</style>

<script>
export default {
  props: {
    valueText: {
      type: String,
      default: "u find a bug!"
    }
  }
};
</script>
