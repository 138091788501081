<template lang="pug">
.insert.ani-fast(
  @dragover="dragover()"
  @dragleave="dragleave()" 
  @drop="drop" 
  :class="{dropin: isDropIn}"
)
  .line.pill
</template>

<script>
// 需要补充被拖入东西后触发的行为
export default {
  name: "InsertZone",
  data: function() {
    return {
      isDropIn: false
    };
  },
  methods: {
    dragover() {
      this.isDropIn = true;
      return false;
    },
    dragleave() {
      this.isDropIn = false;
    },
    drop() {
      this.isDropIn = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.insert
  opacity 0
  &.dropin
    opacity 1
  display grid
  place-content center stretch
  padding 0 .5rem
.line
  // position
  height 2px
  // color
  background var(--clr-primary)
</style>
