// 将来会代替 prop editor

<template lang="pug">
  //- 没有 prop connector 按钮的 prop label
  .prop-editor(:class="[{x: x}, level, {'extend-label':extend}]")
    .label.flex {{label}}
    slot
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

// 上下结构
.prop-editor:not(.x):not(.s)
  stack(y, gap:.25rem)
.prop-editor:not(.x).s
  stack(y)

// 左右结构
.prop-editor.x:not(.extend-label)
  display inline-grid
  grid-template-columns 1fr 2fr
  grid-gap .25rem
.prop-editor.x.extend-label
  stack(x, gap: .25rem)

.prop-editor.s
  stack(y, gap:.25rem)
  .label
    @extend .f-small
    @extend .color-text-3
.prop-editor.m
  .label
    @extend .f-p
    @extend .color-text-2
.prop-editor.l
  .label
    @extend .f-title
    @extend .color-text
</style>

<script>
export default {
  name: "KeyValue",
  props: {
    extend: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    x: {
      type: Boolean,
      default: false
    },
    level: {
      type: String,
      default: "m" // s, m, l
    }
  }
};
</script>
