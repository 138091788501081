var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "master" },
    [
      _c(
        "KeyValue",
        { attrs: { label: "AlignSelf" } },
        [
          _c("AlignEditor", {
            attrs: { value: _vm.align.alignSelf },
            on: { change: _vm.onAlignSelfChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }