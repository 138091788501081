<template lang="pug">
.b-switcher.ani-fast.pointer(@click="switchChecked()", :class="{checked:value}")
  .pill.ani-fast(:class="size")
    .ball-wrapper.ani-fast
      .ball.oval.shd-s.ani-fast
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'
.b-switcher
  display inline-grid
  place-content center
.pill
  width var(--pill)
  height var(--ball)
  box-shadow inset 0 0 0 1px var(--clr-shadow)
  display inline-grid
  justify-content start
  position relative
.ball-wrapper
  display inline-grid
  place-content center
  width var(--ball)
  height var(--ball)
  position absolute
  left 0
.ball
  width var(--oval)
  height var(--oval)
  background var(--clr-bg)
.b-switcher:not(.checked)
  .pill
    background var(--bg-inactive)
  .ball-wrapper
    left 0
.b-switcher.checked
  .pill
    background var(--bg-active)
  .ball-wrapper
    left calc(var(--pill) - var(--ball))
.pill.l
  --ball: 1rem
  --pill: 1.5rem
  --oval: .75rem
  --bg-active: var(--clr-primary)
  --bg-inactive: var(--clr-bg-2)
.pill.m
  --ball: .75rem
  --pill: 1rem
  --oval: .5rem
  --bg-active: var(--clr-content-2)
  --bg-inactive: var(--clr-bg-2)
</style>


<script>
export default {
  model: {
    prop: "value",
    event: "change"
  },
  methods: {
    switchChecked() {
      this.$emit("change", !this.value);
    }
  },
  props: {
    size: {
      type: String,
      default: "m"
    },
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>
