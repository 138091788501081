var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-size-editor" },
    [
      _c(
        "KeyValue",
        { attrs: { label: _vm.dir } },
        [
          _c("bInputSelector", {
            attrs: {
              value: _vm.size.default,
              defaultOptions: ["auto", _vm.tipFunction]
            },
            on: { change: _vm.onDefaultChange }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Max" } },
        [
          _c("bInputSelector", {
            attrs: { value: _vm.size.max, defaultOptions: ["auto", "off"] },
            on: { change: _vm.onMaxChange }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Min" } },
        [
          _c("bInputSelector", {
            attrs: { value: _vm.size.min, defaultOptions: ["auto", "off"] },
            on: { change: _vm.onMinChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }