export default function render(param) {
  if (param == "inherit") {
    return {
      fontSize: "inherit"
    };
  }

  return {
    fontSize: param + "px"
  };
}
