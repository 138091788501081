<template lang="pug">
  .text-wrap
    .text(
      :style="style"
    ) {{ content }}
</template>

<style lang="stylus" scoped>
// .text-wrap
//   display inline-grid
.text
  display inline-block
  position relative
  width 100%
  height 100%
  min-width 100%
  max-width 100%
  min-height 100%
  max-height 100%
</style>

<script>
import typographyRender from "@/render_func/typography";
import commonStyle from "@/components/mixins/commonStyle";
import clipRender from "@/render_func/clip.js";
import paddingRender from "@/render_func/padding.js";
import textColorRender from "@/render_func/textcolor";

export default {
  name: "Txt",
  // todo:
  // 所有类型要使用已注册的类型
  mixins: [commonStyle],
  props: {
    content: {
      type: String,
      isRequired: true,
      proptype: "text",
      default:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    typography: {
      type: Object,
      isRequired: true,
      proptype: "typography",
      default: () => ({
        typeface: "inherit",
        fontSize: "inherit",
        lineHeight: "inherit",
        // fontScale: "inherit",
        textAlign: "inherit"
      })
    },
    textColor: {
      proptype: "color",
      isRequired: true,
      default: "inherit"
    },
    padding: {
      type: String,
      isRequired: true,
      proptype: "padding",
      default: "0"
    },
    clip: {
      type: Boolean,
      isRequired: true,
      proptype: "booleanradio",
      default: false
    },
    ...commonStyle.props
  },
  computed: {
    style() {
      return {
        ...paddingRender(this.padding),
        ...textColorRender(this.textColor),
        ...clipRender(this.clip),
        ...this.commonStyle,
        ...typographyRender(this.typography)
      };
    }
  }
};
</script>
