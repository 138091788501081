var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "scroll",
    { staticClass: "dt-finder", attrs: { dir: "y", padding: "2rem" } },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "temp f-h3" }, [_vm._v("😬 Coming Soon")]),
        _c("div", { staticClass: "session" }, [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("div", { staticClass: "f-h3" }, [_vm._v("Colors")]),
              _c(
                "b-btn",
                {
                  attrs: { size: "m" },
                  nativeOn: { click: function($event) {} }
                },
                [_vm._v("+ New Color")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dt-list" },
            [
              _c("ItemColor"),
              _c("ItemColor"),
              _c("ItemColor"),
              _c("ItemColor"),
              _c("ItemColor"),
              _c("ItemColor")
            ],
            1
          )
        ]),
        _c("div", { staticClass: "session" }, [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("div", { staticClass: "f-h3" }, [_vm._v("Fonts")]),
              _c(
                "b-btn",
                {
                  attrs: { size: "m" },
                  nativeOn: { click: function($event) {} }
                },
                [_vm._v("+ New Font")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dt-list" },
            [_c("ItemFont"), _c("ItemFont"), _c("ItemFont"), _c("ItemFont")],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }