<template lang="pug">
.master
  KeyValue(label="X")
    SpaceEditor(@change="onLeftChange" :value="left")
  KeyValue(label="Y")
    SpaceEditor(@change="onTopChange" :value="top")
</template>

<style lang="stylus" scoped>
@require '../../../../styles/layout/main'
@require '../../../../styles/design/main'

.master
  stack(x, gap: .5rem, arr: stretch)
</style>

<script>
import SpaceEditor from "@/components/form_element/space.vue";
import KeyValue from "@/components/prop_panel/component/label.vue";

export default {
  name: "absoluteEditor-workspace",
  components: {
    SpaceEditor,
    KeyValue
  },
  props: {
    align: {
      type: Object,
      default() {
        return {
          top: 0,
          left: 0
        };
      }
    }
  },
  computed: {
    top() {
      return this.isPixel(this.align && this.align.top) ? Number.parseFloat(this.align.top) : 0;
    },
    left() {
      return this.isPixel(this.align && this.align.left) ? Number.parseFloat(this.align.left) : 0;
    }
  },
  methods: {
    isPixel(value) {
      const pixelRegx = /^-?\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    onTopChange(v) {
      return this.onChange(v, "align.absolute.top");
    },
    onLeftChange(v) {
      return this.onChange(v, "align.absolute.left");
    },
    onChange(value, param) {
      return this.$emit("change", value, param);
    }
  }
};
</script>
