var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      staticClass:
        "f-p size-input-s color-button-normal radius-m ani-fast pointer",
      domProps: { value: _vm.value },
      on: { change: _vm.onChange }
    },
    _vm._l(_vm.options, function(option) {
      return _c("option", { domProps: { value: option.toLowerCase() } }, [
        _vm._v(_vm._s(option))
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }