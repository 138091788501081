<template lang="pug">
  .space-editor
    input.f-p.size-input-s.color-input-sub.radius-m.ani-fast(
      :class="{ warn: hasError }"
      :placeholder="placeholder"
      :value="currValue"
      ref="input"
      @input="onInput"
      @change="onChange"
    )
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.space-editor
  display inline-grid
  input.warn
    box-shadow 0px 0px 4px 0px #F44336
</style>


<script>
export default {
  name: "SpaceEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: 0
    },
    additional: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    value() {
      this.currValue = this.value;
    }
  },
  data() {
    return {
      currValue: this.value
    };
  },
  computed: {
    hasError() {
      if (this.currValue.replace) {
        return (
          !this.isNum(this.currValue.replace("px", "")) &&
          !this.isPercentage(this.currValue) &&
          !this.additional.includes(this.currValue)
        );
      } else {
        return (
          !this.isNum(this.currValue) && !this.isPercentage(this.currValue) && !this.additional.includes(this.currValue)
        );
      }
    }
  },
  methods: {
    isPercentage(value) {
      const percentageRegx = /^-?\d+(\.\d+)?(?=%$)/;
      return percentageRegx.test(value);
    },
    isNum(value) {
      const numRegx = /^-?\d+(\.\d+)?$/;
      return numRegx.test(value);
    },
    onInput(e) {
      const targetValue = e.target.value.toLowerCase();
      this.currValue = targetValue;
    },
    onChange(e) {
      if (this.hasError) return;
      let targetValue = e.target.value.toLowerCase();
      if (this.isNum(targetValue)) {
        targetValue = `${targetValue}px`;
      }
      this.$emit("change", targetValue);
    }
  }
};
</script>
