var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-flow-props" },
    [
      _c(
        "KeyValue",
        { attrs: { label: "Wrap Size" } },
        [
          _c("SpaceEditor", {
            attrs: { value: _vm.value.col_min_width },
            on: { change: _vm.onSizeChange }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Gap X" } },
        [
          _c("SpaceEditor", {
            attrs: { value: _vm.value.gap_x },
            on: { change: _vm.onGapXChange }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Gap Y" } },
        [
          _c("SpaceEditor", {
            attrs: { value: _vm.value.gap_y },
            on: { change: _vm.onGapYChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }