import { captureException } from "@sentry/browser";
/**
 * catch response error helper
 * @param {Vue} self Vue instance
 * @param {String} url
 * @param {fn} callback
 */
export function handleError(self, url = "/login", fn) {
  return e => {
    if (fn && fn(e)) {
      // fn return true can break
      return;
    }
    /* eslint-disable-next-line */
    console.error(e);
    captureException(e);
    self.$router.push(url);
  };
}
