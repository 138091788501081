<template lang="pug">
  //- 有 prop connector 按钮的 prop label
  .prop-editor
    .top.pointer(@click="toggleInterfaceConnector")
      .label.f-p.color-text-2 {{$utils.upperCase(showName)}}
      .interface-connector.radius-s.ani-fast(v-show="isComponents")
        .dot.oval.ani-fast(:class="{checked: interfaceConnectorChecked}")
    component(
      :is="editorName(propType)"
      :value="value"
      :param="propKey"
      @change="(value) => updatePropValueV2(value, propKey)"
    )
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.prop-editor
  stack(y, gap:.25rem)

// .prop-editor > .top > .label
  // color var(--clr-content-2)
  // padding-left .25rem

.top
  stack(x, arr: between, ali:center)
  &:hover
    .interface-connector
      background var(--clr-bg-3)

.interface-connector
  width 1rem
  height 1rem
  off()
  .dot
    width .5rem
    height .5rem
    pos-abs()
  .dot:not(.checked)
    box-shadow inset 0 0 0 2px var(--clr-content-3)
  .dot.checked
    box-shadow inset 0 0 0 8px var(--clr-primary)

</style>

<script>
// v2 版本属性编辑器
import StringEditor from "@/components/form_element/string.vue";
import PaddingEditor from "@/components/form_element/padding.vue";
import TypefaceEditor from "@/components/form_element/typography/typeface.vue";
import SpaceEditor from "@/components/form_element/space.vue"; // TODO: 待确认
import ShadowEditor from "@/components/form_element/shadow.vue";
import Switcher from "@/components/form_element/boolean/switcher.vue";
import LengthEditor from "@/components/form_element/length.vue";
import NumberEditor from "@/components/form_element/number.vue";
import RadiusEditor from "@/components/form_element/radius.vue";
import FontscaleEditor from "@/components/form_element/typography/fontscale.vue";
import ImageEditor from "@/components/form_element/image_src.vue";
import ColorEditor from "@/components/form_element/color.vue";
import BoxLayoutEditor from "@/components/form_element/box_layout/box_layout.vue";
import TypographyEditor from "@/components/form_element/typography/typography.vue";

export default {
  name: "LabelWidthPropConnector",
  components: {
    // v2 版本属性编辑器
    StringEditor,
    PaddingEditor,
    TypefaceEditor,
    SpaceEditor,
    ShadowEditor,
    Switcher,
    LengthEditor,
    NumberEditor,
    RadiusEditor,
    FontscaleEditor,
    ImageEditor,
    ColorEditor,
    BoxLayoutEditor,
    TypographyEditor
  },
  props: {
    propKey: {
      type: String,
      default: "{{PropKey}}"
    },
    layerId: null,
    value: null,
    propType: String
  },
  data() {
    return {
      editors: [
        {
          type: "padding",
          name: "PaddingEditor"
        },
        {
          type: "typeface",
          name: "TypefaceEditor"
        },
        {
          type: "space",
          name: "StringEditor" // TODO: 需要确认是否支持 normal、inherit、initial、unset等关键字，先跳过
        },
        {
          type: "radius",
          name: "RadiusEditor"
        },
        {
          type: "shadow",
          name: "ShadowEditor"
        },
        {
          type: "string",
          name: "StringEditor"
        },
        {
          type: "number",
          name: "NumberEditor"
        },
        {
          type: "boolean",
          name: "Switcher"
        },
        {
          type: "length",
          name: "LengthEditor"
        },
        {
          type: "fontscale",
          name: "FontscaleEditor"
        },
        {
          type: "image",
          name: "ImageEditor"
        },
        {
          type: "color",
          name: "ColorEditor"
        },
        {
          type: "layout",
          name: "BoxLayoutEditor"
        },
        {
          type: "typography",
          name: "TypographyEditor"
        }
      ]
    };
  },
  computed: {
    showName() {
      if (this.propKey.includes("|map2:")) {
        return this.propKey.replace(/\|map2:.*/gi, "");
      }
      return this.propKey;
    },
    node() {
      return this.$store.getters.foucsLayer;
    },
    componentProps() {
      return this.$store.getters.selectedComponent.props;
    },
    interfaceConnectorChecked() {
      let props = this.componentProps[this.node.id];
      if (!props) {
        return false;
      }
      if (props[this.propKey] || props[this.propKey + "|map2:" + this.layerId]) {
        return true;
      }
      return false;
    },
    isComponents() {
      return this.$store.state.ui.currentTab == "components";
    }
  },
  methods: {
    toggleInterfaceConnector() {
      if (this.isComponents) {
        this.toggleLinkPropToComponent();
      }
    },
    editorName(type) {
      /* eslint-disable-next-line */
      let editor = this.editors.find(
        e => e.type.toLowerCase() == type.toLowerCase()
      );
      if (!editor) {
        return "StringEditor";
      }
      return editor.name;
    },
    updatePropValueV2(value, key) {
      this.$emit("change", value, key);
    },
    toggleLinkPropToComponent() {
      const meta = {
        id: this.node.id,
        key: this.propKey,
        value: this.value,
        type: this.propType
      };
      if (this.layerId) {
        meta.mapTo = this.layerId;
      }
      this.$store.dispatch("toggleLinkPropToComponent", meta);
    }
  }
};
</script>
