<template lang="pug">
  .position-size-editor
    KeyValue(:label="dir")
      bInputSelector(@change="onDefaultChange" :value="size.default" :defaultOptions="['auto', tipFunction]")
    KeyValue(label="Max")
      bInputSelector(@change="onMaxChange" :value="size.max" :defaultOptions="['auto', 'off']")
    KeyValue(label="Min")
      bInputSelector(@change="onMinChange" :value="size.min" :defaultOptions="['auto', 'off']")
</template>
<style lang="stylus" scoped>
@require '../../../../styles/layout/main'
@require '../../../../styles/design/main'
.position-size-editor
   display inline-grid
   grid-template-columns repeat(3, 1fr)
   grid-gap .25rem

</style>

<script>
import KeyValue from "@/components/prop_panel/component/label.vue";

export default {
  name: "position_size_xy",
  components: {
    KeyValue
  },
  props: {
    dir: String,
    size: {
      type: Object,
      default() {
        return { default: "auto", min: "off", max: "off" };
      }
    },
    name: String
  },
  data() {
    return {
      tipFunction: this.tipFunction,
      height: 0,
      width: 0
    };
  },
  created() {
    this.$root.$on("sizeTip", ({ width, height }) => {
      this.width = width;
      this.height = height;
    });
    // #682 Position.size.x/y 编辑器增加推荐值，如果属性值不是数字，在下拉中推荐一个数字，值为 layer 当前实际的 css 渲染值(去掉 px)
    this.tipFunction = v => {
      // #721 对 double 增加判断
      if (!/^(\d*\.\d*|\d*)$/.test(v)) {
        if (this[this.name]) {
          return this[this.name];
        }
        return this.size.default;
      } else {
        return null;
      }
    };
  },
  computed: {
    xy() {
      if (this.dir.toLowerCase() === "width") return "x";
      if (this.dir.toLowerCase() === "height") return "y";
      return this.dir;
    }
  },
  methods: {
    onDefaultChange(v) {
      return this.onChange(v, `size.${this.xy}.default`);
    },
    onMaxChange(v) {
      return this.onChange(v, `size.${this.xy}.max`);
    },
    onMinChange(v) {
      return this.onChange(v, `size.${this.xy}.min`);
    },
    onChange(value, param) {
      return this.$emit("change", value, param);
    }
  }
};
</script>
