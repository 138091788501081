var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "shortkey",
          rawName: "v-shortkey",
          value: ["meta", "."],
          expression: "['meta', '.']"
        }
      ],
      staticClass: "component-editor",
      on: { shortkey: _vm.switchEditorUI }
    },
    [
      _c(
        "div",
        { staticClass: "preview abs full-xy" },
        [_c("previewArea", { attrs: { showEditorUI: _vm.showEditorUI } })],
        1
      ),
      _c(
        "div",
        { staticClass: "stack-y abs full-xy pointer-event-off" },
        [
          [
            _c(
              "transition",
              { attrs: { name: "fade-top" } },
              [_c("CompNav", { staticClass: "ani" })],
              1
            )
          ],
          _c(
            "div",
            { staticClass: "flex bottom" },
            [
              _c("transition", { attrs: { name: "fade-left" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showEditorUI,
                        expression: "showEditorUI"
                      }
                    ],
                    staticClass: "left ani"
                  },
                  [_c("Toolbar"), _c("Layertree")],
                  1
                )
              ]),
              _c(
                "transition",
                { attrs: { name: "fade-right" } },
                [
                  _c("PropPanel", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showEditorUI,
                        expression: "showEditorUI"
                      }
                    ],
                    staticClass: "right ani"
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }