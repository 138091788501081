<template lang="pug">
.img-wrap
  img(
    :style="style"
    :src="src"
  )
</template>

<style lang="stylus" scoped>
.img-wrap
  line-height 0
  // display inline-grid
img
  display inline-block
  position relative
  line-height 0
  width 100%
  height 100%
  min-width 100%
  max-width 100%
  min-height 100%
  max-height 100%
</style>

<script>
import commonStyle from "@/components/mixins/commonStyle";
import imgAlignRender from "@/render_func/img_align";
export default {
  name: "BentoImage",
  mixins: [commonStyle],
  props: {
    src: {
      type: String,
      proptype: "image",
      isRequired: true,
      /* eslint-disable-next-line */
      default: "https://images.unsplash.com/photo-1530543787849-128d94430c6b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4e822979adfe12b724d56e254fc7aec6&auto=format&fit=crop&w=1950&q=80"
    },
    padding: {
      type: String,
      isRequired: true,
      proptype: "padding",
      default: "0"
    },
    imgAlign: {
      proptype: "imgalign",
      isRequired: true,
      default() {
        return {
          objectfit: "cover",
          alignX: "center",
          alignY: "center"
        };
      }
    },
    ...commonStyle.props
  },
  computed: {
    style() {
      return {
        padding: this.padding,
        ...imgAlignRender(this.imgAlign),
        ...this.commonStyle
      };
    }
  }
};
</script>
