// this component represents all Components the user can access

<template lang="pug">
  scroll.flex(dir="y" padding="0 0.5rem" hideBar)
    //- todo at 2018-12
    //- .top
      .color-button-normal.ani-fast.radius-m.size-button-s.pointer.f-small Add more
      input.color-input-sub.size-input-s.radius-m.ani-fast.f-small(placeholder="Search")
    .lib-list
      //- built-in components
      .library
        //- .title.color-text-2.f-title.ellipsis Basic
        .library--comp-list
          ListItem(
            v-for='(item, index) in BuiltInComponents'
            :progress="item.progress"
            :label="item.label"
            :icon="icon(item.icon)"
            :id="item.id"
            :isFocus="false"
            :key="item.id"
          )
      //- user components in this project
      .library(v-if="selectedProjectComponent.length > 0")
        .library--title.ellipsis.sticky.f-title.color-text-2 Local Components
          //- .button-add.color-button-sub.oval.ani-fast.pointer.size-button-s.fz-m(@click="addNewComponent" @click.alt="openFile") +
        .library--comp-list
          ListItem(
            v-for='(item, index) in selectedProjectComponent'
            :progress="item.progress"
            :label="item.label"
            :icon="componentSlotIcon(item)"
            :id="item.id"
            :isFocus="isSeleted(item.id)"
            :key="item.id"
          )
      //- list of user components (package) todo
      //- .library(v-for="")
</template>

<style lang="stylus" scoped>

@require '../../styles/layout/main'
@require '../../styles/design/main'

// .top
//   stack(y, gap: .25rem, ali: stretch)
.lib-list
  stack(y, ali: stretch)
.library
  stack(y, ali: stretch)
  // padding .5rem 0
  padding-bottom .5rem
  &:first-child
    padding-top .5rem
.library--title
  // position
  position sticky
  top 0
  z-index 1
  // layout
  padding .5rem 0
  padding-top 1rem
  // style
  background var(--clr-bg)
  // box-shadow 0 0 8px 4px var(--clr-bg)

.library--comp-list
  stack(y, gap: .0rem, ali: stretch)

</style>

<script>
// components
import FileBrowser from "@/components/others/fileBrowser.vue";
import ListItem from "@/components/component_library/list_item.vue";
// import { component } from "@/services";
import { mapGetters } from "vuex";
import BuiltInComponents from "@/components/preview_area/built-in component/builtIn.js";

export default {
  name: "ComponentList",
  components: {
    FileBrowser,
    ListItem
  },
  data() {
    return {
      projectId: this.$route.params.id,
      BuiltInComponents
    };
  },
  computed: {
    ...mapGetters(["selectedTeamAllProjects", "selectedComponent"]),
    selectedProject() {
      // if (this.projectId == "basic") {
      //   return {
      //     editable: false
      //   };
      // }
      const getters = this.$store.getters;
      return getters.project(this.projectId);
    },
    // projectEditable() {
    //   if (this.$store.getters.selectedProject.id == this.projectId && this.$store.getters.selectedProject.editable) {
    //     return true;
    //   }
    //   return false;
    // },
    selectedProjectComponent() {
      // 对写死的 basic 进行拦截
      // if (this.projectId == "basic") {
      //   return BuiltInComponents;
      // }
      const getters = this.$store.getters;
      if (this.selectedProject && this.selectedProject.componentIds) {
        return this.selectedProject.componentIds.map(id => getters.component(id)).filter(Boolean); // 一定要过滤，有可能返回 undefined
      }
      return [];
    }
  },
  mounted() {
    this.$store.commit("setSelectedProjectId", { id: this.$route.params.id });
  },
  methods: {
    // 已经不需要判断
    // isDesign(component) {
    //   if ("root" in component) {
    //     return true;
    //   }
    //   return false;
    // },
    isSeleted(id) {
      if (!this.selectedComponent) {
        return false;
      }
      return this.selectedComponent.id == id;
    },
    handleSelect(e) {
      const val = e.target.value;
      this.projectId = val;
    },
    icon(type) {
      return this.$utils.master.getComponentIcon(type);
    },
    componentSlotIcon(item) {
      return item.slots && item.slots.length > 0 ? "component/component-has-slot" : "component/component-no-slot";
    },
    // 对应按钮已移除，方法已用不到了（这些方法应该抽象出来
    // addNewComponent() {
    //   this.$store.dispatch("createComponent", {
    //     design: true,
    //     root: null,
    //     label: "component"
    //   });
    // },
    seletedComponent(id) {
      this.$store.dispatch("setSelectedComponentId", { id });
    }
    // openFile() {
    //   this.$refs.input.click();
    // },
    // 对应按钮已移除，方法已用不到了（这些方法应该抽象出来
    // uploadComponent() {
    //   const files = Array.from(this.$refs.input.files);
    //   if (0 == files.length) {
    //     return;
    //   }
    //   Promise.all(files.map(component.compileVue)).then(() => {
    //     // 清空，要不然在次上传不会触发 change
    //     this.$refs.input.value = "";
    //   });
    // }
  }
};
</script>
