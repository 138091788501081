<template lang="pug">
.root
  v-gravatar.gravatar.oval.ani-fast(:email="email" rating='x' default-img='identicon' hash)

</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.root
  &:hover
    cursor pointer
    & > .gravatar
      box-shadow 0 0 0 2px var(--clr-content)

.gravatar
  width 2rem
  height 2rem
  background var(--clr-content)

</style>

<script>
export default {
  name: "avatar",
  components: {},
  props: {
    email: {
      type: String,
      default: ""
    }
  }
};
</script>
