var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nav-proj b-z--unset" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c(
          "router-link",
          {
            staticClass: "btn-back radius-m ani-fast nav-btn pointer",
            attrs: {
              to: {
                name: "ProjectBrowser",
                params: { id: _vm.team.selectedTeamId }
              }
            }
          },
          [
            _c("img", {
              staticClass: "logo-img",
              attrs: { src: require("@/assets/logo/empty logo.png") }
            })
          ]
        ),
        _c("icon", { staticClass: "split", attrs: { name: "nav/arrow_line" } }),
        _vm.selectedProject
          ? _c("input", {
              staticClass:
                "proj-name radius-m ani-fast size-input-m f-title nav-btn ellipsis",
              class: { hover: _vm.focus },
              domProps: { value: _vm.selectedProject.name },
              on: {
                click: function($event) {
                  _vm.focus = true
                },
                blur: function($event) {
                  _vm.focus = false
                },
                input: _vm.handleInput
              }
            })
          : _vm._e(),
        _c("icon", { staticClass: "split", attrs: { name: "nav/arrow_line" } }),
        _c("div", { staticClass: "tabs" }, [
          _c(
            "div",
            {
              staticClass: "tab ani-fast bold",
              class: [_vm.currentTab === "workspace" ? "current" : ""],
              on: {
                click: function($event) {
                  _vm.changeTab("workspace")
                }
              }
            },
            [_vm._v("Workspace")]
          ),
          _c(
            "div",
            {
              staticClass: "tab ani-fast bold",
              class: [_vm.currentTab === "components" ? "current" : ""],
              on: {
                click: function($event) {
                  _vm.changeTab("components")
                }
              }
            },
            [_vm._v("Components")]
          )
        ])
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [_c("navExportBtn")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }