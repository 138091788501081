var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFloatingEditor
    ? _c(
        "div",
        {
          staticClass: "floating-editor",
          class: { noop: _vm.isWorkspaceRootLayer },
          style: _vm.style
        },
        [
          _c("resizer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showResizer,
                expression: "showResizer"
              }
            ],
            staticClass: "full-xy",
            attrs: { positionName: _vm.positionName }
          }),
          _c("measure", {
            staticClass: "full-xy",
            attrs: { positionName: _vm.positionName }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }