var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-props" },
    [
      _c("GridXY", {
        attrs: { dir: "x", value: _vm.layoutX },
        on: { change: _vm.onChange }
      }),
      _c("GridXY", {
        attrs: { dir: "y", value: _vm.layoutY, rotate_icon: "" },
        on: { change: _vm.onChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }