var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "grow" } }, [
    _vm.state.isDragging && _vm.notZero
      ? _c(
          "div",
          {
            ref: "mousePoz",
            staticClass: "mouse-postion",
            style: _vm.state.style
          },
          [
            _vm.state.label.length > 0
              ? _c(
                  "div",
                  { staticClass: "placeholder shd-l size-button-l radius-l" },
                  [
                    _vm.state.icon.length
                      ? _c(
                          "div",
                          { staticClass: "icon-container" },
                          [
                            _c(
                              "keep-alive",
                              [
                                _c("icon", {
                                  staticClass: "icon",
                                  attrs: { name: _vm.state.icon }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "name f-p ellipsis flex" }, [
                      _vm._v(_vm._s(_vm.state.label))
                    ])
                  ]
                )
              : _vm._t("default", [
                  _c("div", {
                    staticClass: "placeholder shd-l size-button-l radius-l"
                  })
                ])
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }