<template lang="pug">
.master
  LengthEditor.fix-width.top(
    placeholder="Top"
    :value="computedTop"
    @change="onTopChange"
  )
  .middle
    LengthEditor.fix-width(
      placeholder="Left"
      :value="computedLeft"
      @change="onLeftChange"
    )
    .center.flex.border.radius-s.pointer.ani-fast(:class="{uniform: isUniform}" @click="switchUniform()")
      .cross
        .x.pill.ani-fast
        .y.pill.ani-fast
      //- .padding-t.ani-fast
      //- .padding-l.ani-fast
      //- .padding-r.ani-fast
      //- .padding-b.ani-fast

    LengthEditor.fix-width(
      placeholder="Right"
      :value="computedRight"
      @change="onRightChange"
    )
  LengthEditor.fix-width.bottom(
    placeholder="Bottom"
    :value="computedBottom"
    @change="onBottomChange"
  )
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
.master
  stack(y, gap: .25rem)
.fix-width
  width 5rem
.middle
  stack(x, gap: .25rem)
.top
.bottom
  align-self center
.center
  position relative
  box-shadow inset 0 0 0 1px var(--clr-content-3)
  .cross
    width 100%
    height 100%
    &>*
      background var(--color)
    .x
      width 1rem
      height 2px
      position absolute
      left calc(50% - .5rem)
      top calc(50% - 1px)

    .y
      height 1rem
      width 2px
      position absolute
      top calc(50% - .5rem)
      left calc(50% - 1px)

  &.uniform
    .cross
      --color var(--clr-bg-reverse-3)
    // box-shadow inset 0 0 0 1px var(--clr-content-3)
    // &>*
    //   opacity 0
  &:not(.uniform)
    .cross
      --color var(--clr-bg-3)
    // box-shadow inset 0 0 0 1px var(--clr-content-3)
    // .padding-t
    //   position absolute
    //   width 50%
    //   left 25%
    //   height 1px
    //   background var(--clr-content-3)
    // .padding-l
    //   position absolute
    //   height 50%
    //   top 25%
    //   width 1px
    //   background var(--clr-content-3)
    // .padding-r
    //   position absolute
    //   height 50%
    //   top 25%
    //   width 1px
    //   background var(--clr-content-3)
    //   right 0
    // .padding-b
    //   position absolute
    //   width 50%
    //   left 25%
    //   height 1px
    //   background var(--clr-content-3)
    //   bottom 0

</style>

<script>
import LengthEditor from "@/components/form_element/length.vue";

export default {
  name: "PaddingEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: "0"
    },
    param: String
  },
  components: {
    LengthEditor
  },
  watch: {
    value(newValue) {
      // 多人协作，同步更新 & 切换数据源更新 UI
      this.processValue(newValue);
    },
    padding(newPadding) {
      // FIXME: 切换 layer 导致额外更新操作
      this.$emit("change", newPadding, this.param);
    }
  },
  data() {
    return {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      isUniform: true
    };
  },
  computed: {
    computedTop() {
      return this.isPixel(this.paddingTop) ? Number.parseFloat(this.paddingTop) : this.paddingTop;
    },
    computedRight() {
      return this.isPixel(this.paddingRight) ? Number.parseFloat(this.paddingRight) : this.paddingRight;
    },
    computedBottom() {
      return this.isPixel(this.paddingBottom) ? Number.parseFloat(this.paddingBottom) : this.paddingBottom;
    },
    computedLeft() {
      return this.isPixel(this.paddingLeft) ? Number.parseFloat(this.paddingLeft) : this.paddingLeft;
    },
    padding() {
      return [this.paddingTop, this.paddingRight, this.paddingBottom, this.paddingLeft].join(" ");
    }
  },
  methods: {
    processValue(value) {
      if (!value) {
        this.paddingTop = 0;
        this.paddingRight = 0;
        this.paddingBottom = 0;
        this.paddingLeft = 0;
        this.isUniform = true;
      } else {
        const paddingArr = value.includes(" ") ? value.split(" ") : Array(4).fill(value);
        this.paddingTop = paddingArr[0];
        this.paddingRight = paddingArr[1];
        this.paddingBottom = paddingArr[2];
        this.paddingLeft = paddingArr[3];
        const isAllEqual = new Set(paddingArr).size === 1;
        if (isAllEqual) {
          this.isUniform = true;
        } else {
          this.isUniform = false;
        }
      }
    },
    isPixel(value) {
      const pixelRegx = /^\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    changeAllPadding(v) {
      this.paddingTop = v;
      this.paddingRight = v;
      this.paddingBottom = v;
      this.paddingLeft = v;
    },
    onTopChange(v) {
      if (this.isUniform) return this.changeAllPadding(v);
      this.paddingTop = v;
    },
    onLeftChange(v) {
      if (this.isUniform) return this.changeAllPadding(v);
      this.paddingLeft = v;
    },
    onRightChange(v) {
      if (this.isUniform) return this.changeAllPadding(v);
      this.paddingRight = v;
    },
    onBottomChange(v) {
      if (this.isUniform) return this.changeAllPadding(v);
      this.paddingBottom = v;
    },
    switchUniform() {
      this.isUniform = !this.isUniform;
      if (this.isUniform) {
        this.changeAllPadding(this.paddingTop);
      }
    }
  },
  created() {
    // padding 属性更改字符串属参数，在 PaddingEditor 内部处理数据
    // 兼容 PropEditor 自动化表单的传参模式
    // 遂在创建后初始化数据
    this.processValue(this.value);
  }
};
</script>
