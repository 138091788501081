// 创建新 team 时的表单
<template lang="pug">
  .bg.radius-l.shd-xl
    .header
      .f-h3.color-text Create a new team
    .input-name
      .color-text-2.f-title What is your new team called?
      input.size-input-m.radius-m.ani-fast.f-title.color-input-gray(v-model="teamName", :placeholder="defaultName")
    .buttonbar
      //- 替换成组件
      //- button.size-button-m.radius-m.ani-fast.f-title.pointer.color-button-normal(@click="closeTeamCreator") Cancel
      //- button.size-button-m.radius-m.ani-fast.f-title.pointer.color-button-black(@click="createTeam") Save
      b-btn(color="gray" @click.native="closeTeamCreator") Cancel
      b-btn(color="dark" @click.native="createTeam") Create
</template>

<style lang="stylus" scoped>

@require '../../styles/layout/main'
@require '../../styles/design/main'

.bg
  padding 1.5rem
  background var(--clr-bg)
  stack(y, gap: 1.5rem)
.header
  stack(y, gap: .5rem)
.input-name
  stack(y, gap: .5rem)
.buttonbar
  stack(x, gap: .5rem, arr: end)
.close
  position absolute
  top 0
  right 0
</style>

<script>
import { ramdomName } from "@/helpers";
export default {
  name: "teamEditor",
  data() {
    return {
      teamName: "",
      defaultName: ramdomName()
    };
  },
  methods: {
    capitalizeFirst: function(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    closeTeamCreator: function() {
      this.$store.commit("setUI", { key: "teamCreator", value: false });
    },
    createTeam() {
      this.$store.dispatch("createTeam", {
        teamName: this.teamName || this.defaultName
      });

      // Reset data
      this.teamName = "";

      this.closeTeamCreator();
    }
  }
};
</script>
