var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-editor" },
    [
      _vm.layout === "grid"
        ? _c("PositionGrid", {
            attrs: { align: _vm.alignGrid },
            on: { change: _vm.onChange }
          })
        : _vm._e(),
      _vm.layout === "workspace"
        ? _c("PositionWorkspace", {
            attrs: { align: _vm.alignWorkspace },
            on: { change: _vm.onChange }
          })
        : _vm._e(),
      _vm.layout === "off"
        ? _c("PositionAbsolute", {
            attrs: { align: _vm.alignAbsolute },
            on: { change: _vm.onChange }
          })
        : _vm._e(),
      _c("PositionSize", {
        attrs: { dir: "Width", name: "width", size: _vm.sizeX },
        on: { change: _vm.onChange }
      }),
      _c("PositionSize", {
        attrs: { dir: "Height", name: "height", size: _vm.sizeY },
        on: { change: _vm.onChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }