import backgroundRender from "@/render_func/background";
import shadowsRender from "@/render_func/shadows";
import radiusRender from "@/render_func/radius";

export default {
  props: {
    radius: {
      type: String,
      isRequired: true,
      proptype: "radius",
      default: "off"
    },
    // shadow: {
    //   type: Object,
    //   isRequired: true,
    //   proptype: "shadow",
    //   default() {
    //     return {
    //       x: 0,
    //       y: 0,
    //       blur: 0,
    //       spread: 0,
    //       color: "#000",
    //       inset: false
    //     };
    //   }
    // },
    shadows: {
      type: Array,
      isRequired: true,
      proptype: "shadows",
      default() {
        return [
          {
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
            color: "#000",
            inset: false
          }
        ];
      }
    },
    background: {
      type: String,
      isRequired: true,
      proptype: "color",
      default: "#ffffff00"
    }
  },
  computed: {
    commonStyle() {
      return {
        ...backgroundRender(this.background),
        // ...shadowRender(this.shadow),
        ...shadowsRender(this.shadows),
        ...radiusRender(this.radius)
      };
    }
  }
};
