<template lang="pug">
.radius-editor
  bRadioBtn(:value="type" :options="options" @change="onTypeChange")
  //- 选中 Uniform 时显示
  bInputSelector(
    v-if="type === 'uniform'"
    :value="uniformValue"
    :defaultOptions="selectOptions"
    @change="onAllChange"
  )
  //- 选中 Seprate 时显示
  .stack-x(v-if="type === 'separate'")
    bInputSelector(
      :value="computedTopLeft"
      :defaultOptions="selectOptions"
      @change="onTopLeftChange"
    )
    bInputSelector(
      :value="computedTopRight"
      :defaultOptions="selectOptions"
      @change="onTopRightChange"
    )
  .stack-x(v-if="type === 'separate'")
    bInputSelector(
      :value="computedBottomLeft"
      :defaultOptions="selectOptions"
      @change="onBottomLeftChange"
    )
    bInputSelector(
      :value="computedBottomRight"
      :defaultOptions="selectOptions"
      @change="onBottomRightChange"
    )
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
.radius-editor
  stack(y, gap: .25rem)
.stack-x
  display inline-grid
  grid-template-columns repeat(2, 1fr)
  grid-column-gap .25rem
  &>*
    position relative
</style>

<script>
export default {
  name: "RadiusEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: "off"
    },
    param: String,
    options: {
      type: Array,
      default() {
        return [
          {
            label: "",
            value: "off",
            icon: "radius/off"
          },
          {
            label: "",
            value: "pill",
            icon: "radius/pill"
          },
          {
            label: "",
            value: "oval",
            icon: "radius/oval"
          },
          {
            label: "",
            value: "uniform",
            icon: "radius/uniform"
          },
          {
            label: "",
            value: "separate",
            icon: "radius/separate"
          }
        ];
      }
    }
  },
  data() {
    return {
      topLeft: 0,
      topRight: 0,
      bottomRight: 0,
      bottomLeft: 0,
      uniformValue: 0,
      type: "off",
      selectOptions: [
        v => {
          if (v == "" || (v.indexOf && v.indexOf("%") != -1)) {
            return null;
          }
          return `${v}%`;
        }
      ]
    };
  },
  computed: {
    computedTopLeft() {
      return this.isPixel(this.topLeft) ? Number.parseFloat(this.topLeft) : this.topLeft;
    },
    computedTopRight() {
      return this.isPixel(this.topRight) ? Number.parseFloat(this.topRight) : this.topRight;
    },
    computedBottomRight() {
      return this.isPixel(this.bottomRight) ? Number.parseFloat(this.bottomRight) : this.bottomRight;
    },
    computedBottomLeft() {
      return this.isPixel(this.bottomLeft) ? Number.parseFloat(this.bottomLeft) : this.bottomLeft;
    },
    radius() {
      if (this.type === "off") return "unset";
      if (this.type === "pill") return "99999rem";
      if (this.type === "oval") return "50%";
      return [this.topLeft, this.topRight, this.bottomRight, this.bottomLeft].join(" ");
    },
    radiusSwitch: {
      get() {
        return this.type != "off";
      },
      set(val) {
        if (val == false) {
          this.type = "off";
          return;
        }
        this.type = "uniform";
      }
    }
  },
  methods: {
    processValue(value) {
      if (value.indexOf && value.indexOf("|") != -1) {
        [this.topLeft, this.topRight, this.bottomLeft, this.bottomRight] = value.split("|");
        this.type = "separate";
        return;
      }
      if (["off", "pill", "oval"].includes(value)) {
        this.type = value;
        return;
      }
      this.type = "uniform";
      if (value.includes && value.includes("%")) {
        this.uniformValue = value;
      } else {
        this.uniformValue = parseFloat(value);
      }
    },
    isPixel(value) {
      const pixelRegx = /^\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    isNum(value) {
      const numRegx = /^\d+(\.\d+)?$/;
      return numRegx.test(value);
    },
    processData(data) {
      // 如果是数字类型添加 px 单位
      let targetData = data;
      if (this.isNum(targetData)) {
        targetData = `${targetData}px`;
      }
      return targetData;
    },
    changeAllRadius(v) {
      this.topLeft = v;
      this.topRight = v;
      this.bottomRight = v;
      this.bottomLeft = v;
    },
    onTopLeftChange(v) {
      this.topLeft = v;
      this.uploadForSeparate();
    },
    onTopRightChange(v) {
      this.topRight = v;
      this.uploadForSeparate();
    },
    onBottomRightChange(v) {
      this.bottomRight = v;
      this.uploadForSeparate();
    },
    onBottomLeftChange(v) {
      this.bottomLeft = v;
      this.uploadForSeparate();
    },
    onAllChange(v) {
      this.uniformValue = v;
      this.$emit("change", v);
    },
    onTypeChange(type) {
      this.type = type;
      if (type == "uniform") {
        this.uniformValue = "0";
        this.$emit("change", "0");
        return;
      }
      if (type == "separate") {
        this.changeAllRadius("0");
        this.uploadForSeparate();
        return;
      }
      this.$emit("change", type);
    },
    uploadForSeparate() {
      const val = [this.topLeft, this.topRight, this.bottomLeft, this.bottomRight].join("|");
      this.$emit("change", val);
    }
  },
  created() {
    this.processValue(this.value);
  }
};
</script>
