var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-home b-z--unset" }, [
    _c(
      "div",
      { staticClass: "team-home--head" },
      [_vm._m(0), _c("navAvatar")],
      1
    ),
    _c(
      "div",
      { staticClass: "flex team-home--body" },
      [
        _c(
          "scroll",
          { staticClass: "flex center", attrs: { dir: "y", padding: "2rem" } },
          [
            _vm.projects.length <= 0
              ? _c(
                  "div",
                  { staticClass: "empty abs ani" },
                  [
                    _c("div", { staticClass: "message" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/image/empty-project.png")
                        }
                      }),
                      _c("div", { staticClass: "f-h3 color-text-1" }, [
                        _vm._v("No project in this team.")
                      ])
                    ]),
                    _c(
                      "b-btn",
                      {
                        attrs: { color: "dark", size: "l" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.addProject($event)
                          }
                        }
                      },
                      [_vm._v("+ New Project")]
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "center-content" }, [
                  _c("div", { staticClass: "session" }, [
                    _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _c("div", { staticClass: "f-h3" }, [
                          _vm._v("Projects")
                        ]),
                        _c(
                          "b-btn",
                          {
                            attrs: { size: "m" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.addProject($event)
                              }
                            }
                          },
                          [_vm._v("+ New Project")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "project-list",
                        model: {
                          value: _vm.projects,
                          callback: function($$v) {
                            _vm.projects = $$v
                          },
                          expression: "projects"
                        }
                      },
                      _vm._l(_vm.sortedProj, function(proj) {
                        return _c("ProjectThumbnail", {
                          key: proj.id,
                          attrs: {
                            lastModifiedTime: _vm._f("ago")(
                              proj.updatedAt || proj.createdAt
                            ),
                            lastModifiedMember:
                              (proj.createdBy && proj.createdBy.displayName) ||
                              (proj.updatedBy && proj.updatedBy.displayName),
                            projId: proj.id,
                            projName: proj.name
                          }
                        })
                      }),
                      1
                    )
                  ])
                ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "logo-img",
        attrs: { src: require("@/assets/logo/empty logo.png") }
      }),
      _c("div", { staticClass: "f-h3" }, [_vm._v("Bento")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }