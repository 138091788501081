<template lang="pug">
  .grid-props--xy
    KeyValue(:label="dir.toUpperCase() + ' Arrange'")
      ArrangeEditor.fix-width(@change="onArrangeChange" :value="value.arrange" :rotate="rotate_icon")
    .stack-x
      KeyValue(label="Count" level="s")
        NumberEditor(
          :min="1"
          :value="value.count"
          @change="onCountChange"
        )
      //- stretch 时不显示
      KeyValue(label="Size" v-show="value.arrange!=='stretch'" level="s")
        bInputSelector(
          @change="onSizeChange"
          :value="size"
          :defaultOptions="['auto']"
          )
      //- between 时不显示
      KeyValue(label="Gap" v-show="value.arrange!=='space-between'" level="s")
        SpaceEditor(
          @change="onGapChange"
          :value="gap"
          )
</template>
<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.grid-props--xy
  stack(y, gap: .25rem, arr: stretch)
.stack-x
  stack(x, gap: .25rem, arr: stretch)
.fix-width
  width 100%
</style>
<script>
import KeyValue from "@/components/prop_panel/component/label.vue";
import NumberEditor from "@/components/form_element/number.vue";
import ArrangeEditor from "@/components/form_element/arrange.vue";
import SpaceEditor from "@/components/form_element/space.vue";

export default {
  name: "GridXY",
  components: {
    KeyValue,
    NumberEditor,
    ArrangeEditor,
    SpaceEditor
  },
  props: {
    rotate_icon: false,
    value: {
      type: Object,
      default() {
        return {
          count: "0",
          arrange: "stretch",
          size: "auto",
          gap: "0"
        };
      }
    },
    dir: String
  },
  computed: {
    size() {
      return this.isPixel(this.value && this.value.size) ? Number.parseFloat(this.value.size) : this.value.size;
    },
    gap() {
      return this.isPixel(this.value && this.value.gap) ? Number.parseFloat(this.value.gap) : this.value.gap;
    }
  },
  methods: {
    isPixel(value) {
      const pixelRegx = /^\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    isNum(value) {
      const numRegx = /^\d+(\.\d+)?$/;
      return numRegx.test(value);
    },
    onArrangeChange(v) {
      return this.onChange(v, `${this.dir}.arrange`);
    },
    onCountChange(v) {
      return this.onChange(v, `${this.dir}.count`);
    },
    onSizeChange(v) {
      let formatValue = v;
      if (this.isNum(formatValue)) {
        formatValue = `${formatValue}px`;
      }
      return this.onChange(formatValue, `${this.dir}.size`);
    },
    onGapChange(v) {
      return this.onChange(v, `${this.dir}.gap`);
    },
    onChange(value, key) {
      return this.$emit("change", value, key);
    }
  }
};
</script>
