import eq from "deep-eql";
import omit from "lodash.omit";
import clone from "clone";
import * as layerService from "@/services/layer";
import * as componentService from "@/services/component";

const buildCompare = (remove, create, update) => async (c1, c2) => {
  // 判断哪个长，用长的去做对比, 保证每一个都能对比到
  let keys = null;
  if (c1.length > c2.length) {
    keys = Object.keys(c1);
  } else {
    keys = Object.keys(c2);
  }

  // 用 id 做 key， 提取的时候能不用遍历
  c1 = c1.reduce((prev, current) => {
    prev[current.id] = current;
    return prev;
  }, {});

  c2 = c2.reduce((prev, current) => {
    prev[current.id] = current;
    return prev;
  }, {});

  const _restore = async key => {
    let layer1 = c1[key];
    let layer2 = c2[key];
    // 1 状态存在，2 里面不存在，为了达到不存在的状态，需要删除
    if (typeof layer1 == "undefined" && typeof layer2 != "undefined") {
      return await remove(layer2);
    }
    // 1 状态不存在，2 里面存在，为了让他存在，需要创建，或者说恢复
    if (typeof layer1 != "undefined" && typeof layer2 == "undefined") {
      return await create(layer2);
    }
    // 假如相等就 return
    if (compare(layer1, layer2)) {
      return;
    }
    // 否者管他 37 21，直接恢复，用 create 恢复更好，因为他还会恢复被删除状态
    await update(layer2);
  };

  await Promise.all(keys.map(_restore));
};

/**
 * 判断组件是否相等
 * @param {obj} c1
 * @param {obj} c2
 */
const compare = (c1, c2) => {
  c1 = omit(c1, ["createdAt", "updatedAt"]);
  c2 = omit(c2, ["createdAt", "updatedAt"]);
  return eq(c1, c2);
};

let historyTimer;

class History {
  constructor() {
    this.oprators = []; // 存储队列
    this.index = 0; // 索引
    this.compareLayers = buildCompare(layerService.remove, layerService.create, layerService.create);
    this.compareComponents = buildCompare(componentService.remove, componentService.create, componentService.create);
  }

  get current() {
    return this.oprators[this.index];
  }

  set current(val) {
    return (this.oprators[this.index] = val);
  }

  get next() {
    return this.oprators[this.index + 1];
  }

  set next(val) {
    return (this.oprators[this.index + 1] = val);
  }

  push(obj) {
    if (this.index != this.oprators.length - 1) {
      // 执行 undo redo 之后 index 会平移，在有新的操作，被 undo 的操作可以删除了
      this.oprators.splice(0, this.index);
    }
    this.oprators.push(clone(obj));
    // 重置索引位置
    this.index = this.oprators.length - 1;
  }
  // 恢复到 updateState 状态
  async compareAndRestore(currentState, updateState) {
    await this.compareLayers(currentState.layer.layers, updateState.layer.layers);
    await this.compareComponents(currentState.component.components, updateState.component.components);
  }

  async undo(state) {
    let oldState = clone(state); // 断开引用
    if (this.current) {
      if (historyTimer) {
        clearTimeout(historyTimer);
      }
      const storeState = clone(this.current);
      historyTimer = setTimeout(async () => {
        await this.compareAndRestore(storeState, state);
      }, 3000);

      // 恢复状态
      // store.replaceState(clone(this.current))
      state.layer = clone(this.current.layer);
      state.component = clone(this.current.component);
      this.current = oldState;
      // 后移
      this.index--;
    }
  }

  async redo(state) {
    let oldState = clone(state);
    if (this.next) {
      if (historyTimer) {
        clearTimeout(historyTimer);
      }
      const storeState = clone(this.next);
      historyTimer = setTimeout(async () => {
        await this.compareAndRestore(storeState, state);
      }, 3000);

      // store.replaceState(clone(this.next))
      // 状态做替换
      state.layer = clone(this.next.layer);
      // state.component = clone(this.next.component);
      this.next = oldState;
      this.index++;
    }
  }
}

export default new History();
