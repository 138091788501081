var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "folder radius-m shadow-card ani-fast pointer" },
    [
      _c("div", { staticClass: "icon" }, [_vm._v("📁")]),
      _c("div", { staticClass: "label f-title flex" }, [
        _vm._v(_vm._s(_vm.folderName))
      ]),
      _c("div", { staticClass: "arrow ani-fast" }, [_vm._v("→")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }