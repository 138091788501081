import { eqProp, notEq } from "../helpers";
import { project as projectService, team as teamService } from "../services";
import map from "lodash.map";
import clone from "clone";

//
// ─── STATE ──────────────────────────────────────────────────────────────────────
//

const state = {
  teams: [],
  selectedTeamId: 0
};

//
// ─── GETTERS ────────────────────────────────────────────────────────────────────
//

const getters = {
  selectedTeam({ teams, selectedTeamId }) {
    return teams.find(eqProp("id", selectedTeamId));
  },
  team: state => id => {
    return state.teams.find(t => t.id == id);
  },
  teamNames({ teams }) {
    return teams.map(p => p.name);
  },
  teamName(state, getters) {
    return getters.selectedTeam ? getters.selectedTeam.name : "";
  },
  teamEditable(state, getters) {
    return getters.selectedTeam ? getters.selectedTeam.editable : false;
  }
};

//
// ─── MUTATIONS ──────────────────────────────────────────────────────────────────
//

const mutations = {
  setSelectedTeamId(state, { id }) {
    state.selectedTeamId = id;
  },
  setSelectedTeamIdByName(state, { name }) {
    const team = state.teams.find(eqProp("name", name));
    if (!team) {
      return;
    }
    state.selectedTeamId = team.id;
  },
  addNewProjectToSeletedTeam(state, { id }) {
    const team = state.teams.find(eqProp("id", state.selectedTeamId));
    if (!team) {
      return;
    }
    if (!team.projectIds.includes(id)) {
      team.projectIds.push(id);
    }
  },
  addNewExternalProjectIdsToTeam(state, { teamId, projectId }) {
    const team = state.teams.find(eqProp("id", teamId));
    if (!team) {
      return;
    }
    if (!team.externalProjectIds.includes(projectId)) {
      team.externalProjectIds.push(projectId);
    }
  },
  pushTeam(state, { team }) {
    state.teams.push(team);
  },
  replaceTeam(state, { teams }) {
    state.teams = teams;
  },
  removeProjectFromSeletedTeam(state, { id }) {
    const team = state.teams.find(eqProp("id", state.selectedTeamId));
    if (!team) {
      return;
    }
    team.projectIds = team.projectIds.filter(notEq(id));
  }
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//

const actions = {
  async changeSelectdTeam({ state }, params) {
    let team = state.teams.find(eqProp("id", state.selectedTeamId));
    Object.assign(team, params);
    await teamService.update(team);
  },
  async fetchTeamProjects(context, id) {
    id = id || context.state.selectedTeamId;
    const team = context.getters.team(id);
    const projects = await Promise.all(
      map([...team.extenalProjectIds, ...team.projectIds], id => {
        // 没有才去请求
        if (context.getters.existProject(id)) {
          return;
        }
        return projectService.findById(id);
      })
    );
    map(projects, project => project && context.commit("pushProject", { project }));
  },
  async fetchTeams({ commit, getters, dispatch, state }) {
    try {
      let teams = await teamService.list();
      await Promise.all(
        map(teams, async team => {
          const projects = await Promise.all(
            map([...team.extenalProjectIds, ...team.projectIds], id => {
              // 没有才去请求
              if (getters.existProject(id)) {
                return;
              }
              return projectService.findById(id);
            })
          );
          map(projects, project => project && commit("pushProject", { project }));
        })
      );
      // 假如没有选中的值，默认选中第一个
      if (teams && teams[0] && state.selectedTeamId == 0) {
        commit("setSelectedTeamId", {
          id: teams[0].id
        });
      }
      // 替换源数据
      commit("replaceTeam", {
        teams
      });
      await dispatch("fetchExtenalProjectComponents");
    } catch (error) {
      alert(error);
    }
  },
  async createTeam({ state, commit }, { teamName }) {
    const team = await teamService.create(teamName);
    commit("setSelectedTeamId", { id: team.id });
    commit("pushTeam", {
      team
    });
    return state;
  },
  async addNewProjectToSeletedTeam({ commit, getters }, { id }) {
    let projectId = id;
    let team = getters.selectedTeam;
    team = clone(team); // 避免更改 state
    team.projectIds.push(projectId);
    await teamService.update(team);
    commit("addNewProjectToSeletedTeam", { id });
  },
  removeProjectFromSeletedTeam({ commit, getters }, { id }) {
    if (!getters.selectedTeam) {
      return;
    }
    commit("removeProjectFromSeletedTeam", { id });
    teamService.update(getters.selectedTeam);
  },
  /**
   * 拉取选中团队的外部 project 的 component
   */
  async fetchExtenalProjectComponents({ getters, dispatch }) {
    const projects = getters.selectedTeamExtenalProject;
    for (let project of projects) {
      await Promise.all(
        map(project.componentIds, componentId => {
          if (getters.existComponent(componentId)) {
            return false;
          }
          return dispatch("fetchComponentById", { id: componentId });
        })
      );
    }
  }
};

export default { getters, actions, mutations, state };
