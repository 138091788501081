var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stack-props" },
    [
      _c(
        "KeyValue",
        { attrs: { label: "Direction" } },
        [
          _c("bRadioBtn", {
            attrs: {
              options: [{ label: "x", value: "x" }, { label: "y", value: "y" }],
              value: _vm.value.direction
            },
            on: {
              change: function(v) {
                return _vm.buildChange(v, "direction")
              }
            }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Arrange" } },
        [
          _c("ArrangeEditor", {
            attrs: { value: _vm.value.arrange, rotate: _vm.rotate_icon },
            on: {
              change: function(v) {
                return _vm.buildChange(v, "arrange")
              }
            }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Align" } },
        [
          _c("AlignEditor", {
            attrs: { value: _vm.value.align, rotate: !_vm.rotate_icon },
            on: {
              change: function(v) {
                return _vm.buildChange(v, "align")
              }
            }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Gap" } },
        [
          _c("SpaceEditor", {
            attrs: { value: _vm.gap },
            on: {
              change: function(v) {
                return _vm.buildChange(v, "gap")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }