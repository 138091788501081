import Firestore, { db } from "./init.js";

const firestoreLayersInst = new Firestore({ collectionName: "layers" });

export function list() {
  return db
    .collection(this.collectionName)
    .get()
    .then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });
}

export async function findById(id) {
  return firestoreLayersInst.findById(id);
}

export async function create(layer) {
  return firestoreLayersInst.create({
    id: layer.id,
    label: layer.label,
    props: layer.props,
    componentId: layer.componentId,
    children: layer.children,
    position: layer.position || null // FIXME: firestore 不支持 undefined 类型
  });
}

export async function update(layer) {
  return firestoreLayersInst.update({
    id: layer.id,
    label: layer.label,
    props: layer.props,
    componentId: layer.componentId,
    children: layer.children,
    position: layer.position || null // FIXME: firestore 不支持 undefined 类型
  });
}

export async function remove(layer) {
  return firestoreLayersInst.remove(layer.id);
}
