var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "radius-editor" },
    [
      _c("bRadioBtn", {
        attrs: { value: _vm.type, options: _vm.options },
        on: { change: _vm.onTypeChange }
      }),
      _vm.type === "uniform"
        ? _c("bInputSelector", {
            attrs: {
              value: _vm.uniformValue,
              defaultOptions: _vm.selectOptions
            },
            on: { change: _vm.onAllChange }
          })
        : _vm._e(),
      _vm.type === "separate"
        ? _c(
            "div",
            { staticClass: "stack-x" },
            [
              _c("bInputSelector", {
                attrs: {
                  value: _vm.computedTopLeft,
                  defaultOptions: _vm.selectOptions
                },
                on: { change: _vm.onTopLeftChange }
              }),
              _c("bInputSelector", {
                attrs: {
                  value: _vm.computedTopRight,
                  defaultOptions: _vm.selectOptions
                },
                on: { change: _vm.onTopRightChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.type === "separate"
        ? _c(
            "div",
            { staticClass: "stack-x" },
            [
              _c("bInputSelector", {
                attrs: {
                  value: _vm.computedBottomLeft,
                  defaultOptions: _vm.selectOptions
                },
                on: { change: _vm.onBottomLeftChange }
              }),
              _c("bInputSelector", {
                attrs: {
                  value: _vm.computedBottomRight,
                  defaultOptions: _vm.selectOptions
                },
                on: { change: _vm.onBottomRightChange }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }