///
/// components props oprator
///

/**
 * current component state
 */
/* eslint-disable no-unused-vars*/
const sample = {
  type: "Box",
  prop: {
    position: {
      align: {
        absolute: {
          top: "0",
          left: "0",
          right: "off",
          bottom: "off"
        }
      },
      size: {
        x: {
          default: "6rem",
          min: "",
          max: ""
        },
        y: {
          default: "8rem",
          min: "",
          max: ""
        }
      }
    },
    layout: {
      type: "off"
    },
    style: {
      bg: "white",
      shadows: "",
      radius: ""
    },
    children: {
      default: [
        {
          type: "Txt",
          prop: {
            text: {
              content: "haha",
              color: "#fff",
              fontscale: {
                fontSize: "1rem",
                lineHeight: "1.5rem"
              },
              padding: "2rem"
            },
            position: {
              align: {
                absolute: {
                  top: ".5rem",
                  left: "2rem",
                  right: "off",
                  bottom: ".5rem"
                }
              },
              size: {
                x: {
                  default: "8rem",
                  min: "",
                  max: ""
                },
                y: {
                  default: "max-content",
                  min: "",
                  max: ""
                }
              }
            },
            style: {
              bg: "#0079FF",
              shadows: "0 0 16px 0 rgba(0,105,255,0.32)",
              radius: ".5rem"
            }
          }
        }
      ]
    }
  }
};

/**
 * right operator panel state
 */
const data = {
  posType: {
    current: "default",
    items: [
      {
        name: "default",
        icon: ""
      },
      {
        name: "absolute",
        icon: ""
      },
      {
        name: "sticky",
        icon: ""
      }
    ]
  },
  alignSelf: {
    current: "start",
    items: [
      {
        name: "start",
        icon: "alignself/start"
      },
      {
        name: "center",
        icon: "alignself/center"
      },
      {
        name: "end",
        icon: "alignself/end"
      },
      {
        name: "stretch",
        icon: "alignself/stretch"
      }
    ]
  },
  layoutType: {
    current: "off",
    items: [
      {
        name: "off",
        icon: ""
      },
      {
        name: "stack",
        icon: ""
      },
      {
        name: "flow",
        icon: ""
      },
      {
        name: "grid",
        icon: ""
      }
    ]
  },
  alignItem: {
    current: "stretch",
    items: [
      {
        name: "stretch",
        icon: "alignitems/stretch"
      },
      {
        name: "start",
        icon: "alignitems/start"
      },
      {
        name: "center",
        icon: "alignitems/center"
      },
      {
        name: "end",
        icon: "alignitems/end"
      }
    ]
  },
  arrange: {
    current: "start",
    items: [
      {
        name: "start",
        icon: "arrange/start"
      },
      {
        name: "center",
        icon: "arrange/center"
      },
      {
        name: "end",
        icon: "arrange/end"
      },
      {
        name: "stretch",
        icon: "arrange/stretch"
      },
      {
        name: "between",
        icon: "arrange/between"
      }
    ]
  },
  textAlign: {
    current: "start",
    items: [
      {
        name: "start",
        icon: "textalign/start"
      },
      {
        name: "center",
        icon: "textalign/center"
      },
      {
        name: "end",
        icon: "textalign/end"
      },
      {
        name: "stretch",
        icon: "textalign/stretch"
      }
    ]
  }
};
/* eslint-disable */
// 小驼峰规则
let ComponentPropTypes = {
  color: {
    type: "string",
    validator: val => true
  },
  typeface: {
    type: "array",
    validator: val => true
  }
};

let getData = key => {
  return data[key];
};

let getValue = (props, key) => {
  let prop = props.find(p => {
    return p.key == key;
  });
  return prop ? prop.value : "";
};

let props = {
  getData: getData,
  getValue: getValue
};

/* eslint-disable */
const basicProps = {
  screen: {
    device: {
      type: "enum",
      enum: [
        {
          label: "iPhone6",
          value: "w480h750"
        }
      ],
      validator: val => true,
      default: "w480h750"
    },
    width: {
      type: "number",
      default: 750
    },
    height: {
      type: "number",
      default: 450
    },
    background: {
      type: "color",
      defalut: "#fff"
    }
  },
  box: {
    layout: {
      type: "layout"
    },
    shadows: {
      type: "shadows"
    },
    radius: {
      type: "radius"
    },
    clip: {
      type: "boolean"
    },
    background: {
      type: "color"
    }
  },
  scroll: {
    dir: {
      type: "enum",
      enum: ["x", "y", "both"]
    },
    padding: {
      type: "padding"
    },
    hideBar: {
      type: "boolean"
    },
    background: {
      type: "color"
    }
  },
  text: {
    content: {
      type: "string"
    },
    typeface: {
      type: "typefaces"
    },
    fontweight: {
      type: "number"
    },
    fontscale: {
      type: "fontscale"
    },
    letterSpacing: {
      type: "space"
    },
    wordSpacing: {
      type: "space"
    },
    textAlign: {
      type: "enum",
      enum: ["inherit" | "start" | "center" | "end" | "justify"],
      defalut: "inherit"
    },
    color: {
      type: "color"
    },
    padding: {
      type: "padding"
    }
  },
  image: {
    src: {
      type: "string"
    },
    objecFit: {
      type: "enum",
      enum: ["contain", "cover", "fill", "inherit", "initial", "none", "scale-down", "unset"],
      default: "unset"
    },
    objectPosition: {
      type: "enum",
      enum: ["top", "right", "bottom", "left", "center", "inherit", "initial", "unset"],
      defalut: "unset"
    },
    padding: {
      type: "padding"
    }
  }
};

export { basicProps };

export default props;
