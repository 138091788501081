// this component represents all Components the user can access

<template lang="pug">
  scroll.flex(dir="y" padding="0 0.5rem" hideBar)
    //- todo at 2018-12
    //- .top
      .color-button-normal.ani-fast.radius-m.size-button-s.pointer.f-small Add more
      input.color-input-sub.size-input-s.radius-m.ani-fast.f-small(placeholder="Search")
    .lib-list
      .library(v-for="project in externalProjects" v-if="project.components.length > 0")
        .title.color-text-2.f-title.sticky.ellipsis {{project.name}}
        .comp-list
          ListItem(
            v-for='(item, index) in project.components'
            :progress="item.progress"
            :label="item.label"
            :design="isDesign(item)"
            :icon="componentSlotIcon(item)"
            :id="item.id"
            :isFocus="false"
            :key="item.id"
          )

  //- FileBrowser
    template(slot='top')
      .top-bg.shd-m
        select.select.color-button-normal.color-text-2.size-button-m.f-p.ani-fast.radius-m(:value="projectId" @change="handleSelect")
          option.option(value="basic" name="basic" :selected="projectId == 'basic'") basic
          option.option(v-for="project in selectedTeamAllProjects" v-if="selectedTeamAllProjects" :key="project.id" :value="project.id" :name="project.name" :selected="projectId == project.id") {{project.name}}
        template(v-if="projectEditable")
          button.add.color-button-normal.color-text-2.size-button-m.ani-fast.f-p.radius-m(@click="addNewComponent") + New Component
          button.add.color-button-normal.color-text-2.size-button-m.ani-fast.f-p.radius-m(@click="openFile") + Upload Component
          input.add.color-button-normal.color-text-2.size-button-m.ani-fast.f-p.radius-m(ref="input" v-show="false" type="file" @change="uploadComponent" multiple="multiple")
    template(slot='content')
      .list(v-if="selectedProjectComponent.length > 0")
        ListItem(
          v-for='(item, index) in selectedProjectComponent'
          :progress="item.progress"
          :label="item.label"
          :editable="projectEditable"
          :design="isDesign(item)"
          :icon="icon(item.type)"
          :id="item.id"
          :isFocus="projectEditable && isSeleted(item.id)"
          :key="item.id"
        )  
        //- @click.native.left="projectEditable && seletedComponent(item.id)"
</template>

<style lang="stylus" scoped>

@require '../../styles/layout/main'
@require '../../styles/design/main'
// .external-component-browser
//   stack(y, gap: 1rem)
// .top-bg
//   // position
//   width 100%
//   // layout
//   gridflow(gap: .25rem, colmin: 8rem)
//   padding .5rem
//   // color 
//   background var(--clr-bg)
.top
  stack(y, gap: .25rem, ali: stretch)
.lib-list
  stack(y, ali: stretch)
.library
  stack(y, ali: stretch)
  padding-bottom .5rem
.title
  // position
  position sticky
  top 0
  z-index 1
  // layout
  padding .5rem 0
  padding-top 1rem
  // style
  backdrop-filter blur(4px)
  // background var(--clr-bg)
  background alpha(white, 0.85)
.comp-list
  stack(y, gap: 0rem, ali: stretch)
</style>

<script>
// components
// import FileBrowser from "@/components/others/fileBrowser.vue";
import ListItem from "@/components/component_library/list_item.vue";
import { component } from "@/services";
import { mapGetters } from "vuex";

export default {
  name: "ComponentList",
  components: {
    // FileBrowser,
    ListItem
  },
  data() {
    return {
      projectId: this.$route.params.id
    };
  },
  computed: {
    ...mapGetters(["selectedTeamAllProjects", "selectedComponent"]),
    selectedProject() {
      const getters = this.$store.getters;
      return getters.project(this.projectId);
    },
    projectEditable() {
      if (this.$store.getters.selectedProject.id == this.projectId && this.$store.getters.selectedProject.editable) {
        return true;
      }
      return false;
    },
    selectedProjectComponent() {
      const getters = this.$store.getters;
      return this.selectedProject.componentIds.map(id => getters.component(id)).filter(Boolean); // 一定要过滤，有可能返回 undefined
    },
    externalProjects() {
      const getters = this.$store.getters;
      const externals = this.selectedTeamAllProjects.filter(i => i.id !== this.projectId);
      const externalsWithComponents = externals.map(i => {
        if (!i.componentIds || i.componentIds.length === 0) return { ...i, components: [] };
        return {
          ...i,
          components: i.componentIds.map(id => getters.component(id)).filter(Boolean)
        };
      });
      return externalsWithComponents;
    }
  },
  mounted() {
    this.$store.commit("setSelectedProjectId", { id: this.$route.params.id });
  },
  methods: {
    isDesign(component) {
      if ("root" in component) {
        return true;
      }
      return false;
    },
    isSeleted(id) {
      if (!this.selectedComponent) {
        return false;
      }
      return this.selectedComponent.id == id;
    },
    handleSelect(e) {
      const val = e.target.value;
      this.projectId = val;
    },
    icon(type) {
      return this.$utils.master.getComponentIcon(type);
    },
    componentSlotIcon(item) {
      return item.slots && item.slots.length > 0 ? "component/component-has-slot" : "component/component-no-slot";
    },
    addNewComponent() {
      this.$store.dispatch("createComponent", {
        design: true,
        root: null,
        label: "component"
      });
    },
    seletedComponent(id) {
      this.$store.dispatch("setSelectedComponentId", { id });
    },
    openFile() {
      this.$refs.input.click();
    },
    uploadComponent() {
      const files = Array.from(this.$refs.input.files);
      if (0 == files.length) {
        return;
      }
      Promise.all(files.map(component.compileVue)).then(() => {
        // 清空，要不然在次上传不会触发 change
        this.$refs.input.value = "";
      });
    }
  }
};
</script>
