<template lang="pug">
  .folder.radius-m.shadow-card.ani-fast.pointer
    .icon 📁
    .label.f-title.flex {{folderName}}
    .arrow.ani-fast →
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.folder
  padding 1rem
  background var(--clr-bg)
  stack(x, gap: .75rem)
  &>.icon
    font-size 1.5rem
    color var(--clr-content)

  &>.label
    color var(--clr-content-2)
  &>.arrow
    opacity 0
    color var(--clr-content-3)
  &:hover
    &>.arrow
      opacity 1
  &.focus
    box-shadow inset 0 0 0 2px var(--clr-primary)

</style>

<script>
export default {
  name: "Folder",
  props: {
    folderName: {
      type: String,
      default: "{{folder name}}"
    }
  }
};
</script>
