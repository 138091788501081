<template lang="pug">
  .btn-color
    b-btn(color="gray" size="s" @click.native.left="togglePicker")
      .content(ref="content")
        .color.clip.radius-s
          .triangle
          .color--display.abs(:style="{background: value}")
        span.flex.color-text-2.f-p {{value}}
        icon.icon(name="select")
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.content
  stack(x,gap: .5rem, ali:center)
  .flex
    text-align left

.color
  width 1rem
  height 0.75rem
  background white
.triangle
  width 0
  height 0
  border-style solid
  border-width 0 0 0.75rem 1rem
  border-color transparent transparent lighten(black, 75%) transparent
.color--display
  top -1px
  left -1px
  right -1px
  bottom -1px
  position absolute
  // background alpha(red, .1)
  // box-shadow inset 0 0 0 2px var(--clr-shadow-2)
.icon
  width 1rem
  height 1rem
.btn-color
  display flex
  & > * 
    width 100%
.float-layer
  position fixed
  right 225px
  width max-content
  height max-content

</style>

<script>
// import tinycolor2 from "tinycolor2";

export default {
  name: "colorSelector",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: "red"
    }
  },
  data() {
    return {
      maxHeight: document.documentElement.scrollHeight
    };
  },
  watch: {
    ["colorPicker.value"](v) {
      if (this.showPicker) {
        this.$emit("change", v);
      }
    },
    ["colorPicker.visible"](v) {
      if (v == false && this.showPicker) {
        this.showPicker = false;
      }
    }
  },
  computed: {
    colorPicker() {
      return this.$ui.colorPicker;
    }
  },
  created() {
    this.showPicker = false;
  },
  methods: {
    togglePicker() {
      this.showPicker = true;
      if (this.showPicker) {
        const rect = this.$refs.content.getBoundingClientRect();
        const right = "230px";

        if (rect.top + 245 >= this.maxHeight) {
          this.$ui.colorPicker = {
            value: this.value,
            bottom: "8px",
            right,
            visible: true
          };
        } else {
          this.$ui.colorPicker = {
            value: this.value,
            top: rect.top + "px",
            bottom: "unset",
            right,
            visible: true
          };
        }
      }
    }
  }
};
</script>
