<template lang="pug">
.master
  .label.f-p.color-text-2 Align
  bInputSelector.fix-width.top(
    placeholder="Top"
    :value="top"
    :defaultOptions="['off']"
    @change="onTopChange"
  )
  .middle
    bInputSelector.fix-width(
      placeholder="Left" 
      :value="left"
      :defaultOptions="['off']"
      @change="onLeftChange"
    )
    .center.flex.border.radius-s
    bInputSelector.fix-width(
      placeholder="Right"
      :value="right"
      :defaultOptions="['off']"
      @change="onRightChange"
    )
  bInputSelector.fix-width.bottom(
    placeholder="Bottom"
    :value="bottom"
    :defaultOptions="['off']"
    @change="onBottomChange"
  )
</template>

<style lang="stylus" scoped>
@require '../../../../styles/layout/main'
@require '../../../../styles/design/main'

.master
  stack(y, gap: .25rem)
.fix-width
  width 5rem
.middle
  stack(x, gap: .25rem)
.border
  box-shadow inset 0 0 0 1px var(--clr-bg-3)
.top
.bottom
  align-self center
</style>

<script>
import SpaceEditor from "@/components/form_element/space.vue";

export default {
  name: "absoluteEditor",
  components: {
    SpaceEditor
  },
  props: {
    align: {
      type: Object,
      default() {
        return {
          top: "off",
          left: "off",
          right: "off",
          bottom: "off"
        };
      }
    }
  },
  computed: {
    top() {
      return this.isPixel(this.align && this.align.top) ? Number.parseFloat(this.align.top) : this.align.top;
    },
    left() {
      return this.isPixel(this.align && this.align.left) ? Number.parseFloat(this.align.left) : this.align.left;
    },
    right() {
      return this.isPixel(this.align && this.align.right) ? Number.parseFloat(this.align.right) : this.align.right;
    },
    bottom() {
      return this.isPixel(this.align && this.align.bottom) ? Number.parseFloat(this.align.bottom) : this.align.bottom;
    }
  },
  methods: {
    isPixel(value) {
      const pixelRegx = /^-?\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    onTopChange(v) {
      return this.onChange(v, "align.absolute.top");
    },
    onLeftChange(v) {
      return this.onChange(v, "align.absolute.left");
    },
    onRightChange(v) {
      return this.onChange(v, "align.absolute.right");
    },
    onBottomChange(v) {
      return this.onChange(v, "align.absolute.bottom");
    },
    onChange(value, param) {
      return this.$emit("change", value, param);
    }
  }
};
</script>
