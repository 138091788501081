var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "radio-button radius-m clip" },
    _vm._l(_vm.options, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "option ani-fast size-button-s lh-0",
          class: { active: item.value === _vm.current },
          on: {
            click: function($event) {
              _vm.handleClick(item)
            }
          }
        },
        [
          item.icon
            ? _c("icon", {
                staticClass: "icon",
                class: { rotate: _vm.rotate },
                attrs: { name: item.icon }
              })
            : _vm._e(),
          item.label
            ? _c("span", { staticClass: "f-p" }, [_vm._v(_vm._s(item.label))])
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }