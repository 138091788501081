//用于显示下拉列表中的所有选项。暂时不能工作 todo
<template lang="pug">
  .wrapper.radius-m.shd-l
    b-input.search(size="s", color="light")
    scroll.option-list.flex(padding="0.375rem 0" dir="y" v-if="options.length > 0")
      ItemDefault(
        v-for="item in options"
        :key="item"
        :is-hover="item === value"
        :is-active="item === active"
        @click.native="onClick(item)"
        :text="item"
      )
    .empty.f-p.color-text-3(v-else) Not found
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.wrapper
  stack(y, gap: .25rem)
  background var(--clr-bg)
  padding .25rem
  max-height max-content

.option-list
  min-width 8rem
.empty
  padding 0.25rem .5rem
</style>

<script>
import EditorBus from "@/eventBus/EditorBus.js";
import ItemDefault from "./items/default";
import ItemColor from "./items/color";

export default {
  name: "OptionSelector",
  components: {
    ItemDefault,
    ItemColor
  },
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    value: {
      type: String,
      default: ""
    },
    active: {
      type: String,
      default: ""
    },
    eventName: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClick(value) {
      EditorBus.$emit(this.eventName, value);
    }
  }
};
</script>
