var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bento-input radius--m ani-fast",
      class: [
        _vm.color_class,
        _vm.size_class,
        { hover: _vm.hover },
        { focus: _vm.focus }
      ],
      on: {
        touchstart: _vm.hoverStart,
        mouseenter: _vm.hoverStart,
        mouseleave: _vm.hoverEnd,
        touchend: _vm.hoverEnd,
        click: _vm.onClick
      }
    },
    [
      _vm._t("before"),
      _c("input", {
        staticClass: "input flex",
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            _vm.$emit("input", $event.target.value)
          }
        }
      }),
      _vm._t("after")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }