<template lang="pug">
.wrap
  input.f-p.size-input-s.color-input-sub.radius-m.ani-fast(
    :class="{ warn: hasError }"
    :placeholder="placeholder"
    :value="currValue"
    ref="input"
    @input="onInput"
    @change="onChange"
  )    
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.wrap
  display inline-grid
  input.warn
    box-shadow 0px 0px 4px 0px #F44336
</style>


<script>
export default {
  name: "LengthEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number],
      default: 0
    },
    additional: {
      type: Array,
      default() {
        return [];
      }
    },
    param: String
  },
  watch: {
    value() {
      this.currValue = this.value;
    }
  },
  data() {
    return {
      currValue: this.value
    };
  },
  computed: {
    hasError() {
      if (this.isNum(this.currValue)) {
        if (Number.parseFloat(this.currValue) < 0) return true;
        return false;
      } else {
        if (!this.additional.includes(this.currValue)) return true;
        return false;
      }
    }
  },
  methods: {
    isPercentage(value) {
      const percentageRegx = /^\d+(\.\d+)?(?=%$)/;
      return percentageRegx.test(value);
    },
    isNum(value) {
      const numRegx = /^\d+(\.\d+)?$/;
      return numRegx.test(value);
    },
    onInput(e) {
      const targetValue = e.target.value.toLowerCase();
      this.currValue = targetValue;
    },
    onChange(e) {
      if (this.hasError) return;
      let targetValue = e.target.value.toLowerCase();
      if (this.isNum(targetValue)) {
        targetValue = `${targetValue}px`;
      }
      this.$emit("change", targetValue, this.param);
    }
  }
};
</script>
