export default function render(param) {
  if (param == "inherit") {
    return {
      lineHeight: "inherit"
    };
  }

  if (param.includes("%")) {
    return {
      lineHeight: param
    };
  }

  return {
    lineHeight: param + "px"
  };
}
