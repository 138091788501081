var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.node
    ? _c(
        "scroll",
        {
          staticClass: "scroll pointer-event-off",
          attrs: { dir: "y", hideBar: "", padding: ".5rem" }
        },
        [
          _c(
            "div",
            { staticClass: "block-list b-z--unset pointer-event-on" },
            [
              _vm.node && _vm.node.componentId !== "workspace"
                ? _c("PropFolder", [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "label f-p color-text-2" }, [
                          _vm._v("Name")
                        ]),
                        _c("StringEditor", {
                          attrs: {
                            placeholder: "",
                            value: _vm.nodeName,
                            param: "label"
                          },
                          on: { change: _vm.updateName }
                        })
                      ],
                      1
                    ),
                    _vm.isDesign && _vm.component.status == 1
                      ? _c("div", { staticClass: "form-item" }, [
                          _c("div", { staticClass: "label f-p color-text-2" }, [
                            _vm._v("Component")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "comp-btn radius-m color-button-normal size-input-s pointer ani-fast",
                              on: { click: _vm.editCurrentComponnet }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex f-p color-text ellipsis" },
                                [_vm._v(_vm._s(_vm.component.label))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "edit radius-s ani-fast f-p color-text-3"
                                },
                                [_vm._v("Edit")]
                              )
                            ]
                          )
                        ])
                      : _vm.isDesign && _vm.component.status != 1
                        ? _c("div", { staticClass: "form-item" }, [
                            _c("div", { staticClass: "comp-btn" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  on: { click: _vm.detachComponent }
                                },
                                [_vm._v("Detach")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  on: { click: _vm.restoreComponent }
                                },
                                [_vm._v("Recover")]
                              )
                            ])
                          ])
                        : _vm._e()
                  ])
                : _vm._e(),
              !_vm.noPositionComponent
                ? _c(
                    "PropFolder",
                    { attrs: { label: "Position" } },
                    [
                      _c("PositionEditor", {
                        attrs: { position: _vm.node.position },
                        on: { change: _vm.updatePosition }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isDesign
                ? [
                    _vm._l(_vm.componentprops, function(props, id) {
                      return _vm._l(props, function(value, key) {
                        return _c(
                          "PropFolder",
                          { key: id + key + "" },
                          [
                            _c(
                              "prop-editor",
                              {
                                key: id + key + "",
                                attrs: {
                                  label: _vm.getPropName(id, key),
                                  isProp: _vm.isLinkedProp(key, id),
                                  propName: key,
                                  layerId: id,
                                  size: "l"
                                },
                                on: {
                                  toggle: function(val) {
                                    return _vm.toggleBuiltin(
                                      val,
                                      key,
                                      value.value,
                                      id
                                    )
                                  },
                                  link: function(type) {
                                    return _vm.toggleLinkPropToComponent(
                                      key,
                                      _vm.node.props[id][key],
                                      value.type,
                                      value.isRequired,
                                      id
                                    )
                                  }
                                }
                              },
                              [
                                _vm.node.props[id] &&
                                _vm.has(_vm.node.props[id], key)
                                  ? _c(_vm.getEditor(value.type), {
                                      tag: "component",
                                      attrs: {
                                        layerId: _vm.node.id,
                                        value: _vm.node.props[id][key],
                                        param: key
                                      },
                                      on: {
                                        change: function(value) {
                                          return _vm.updatePropIdValue(
                                            id,
                                            value,
                                            key,
                                            _vm.isLinkedProp(key, id)
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    })
                  ]
                : _vm._e(),
              !_vm.isDesign
                ? _vm._l(_vm.componentprops, function(value, key) {
                    return _c(
                      "PropFolder",
                      { key: key },
                      [
                        _c(
                          "prop-editor",
                          {
                            key: key,
                            attrs: {
                              label: key,
                              isProp: _vm.isLinkedProp(key),
                              propName: key,
                              required: value.isRequired,
                              size: "l"
                            },
                            on: {
                              toggle: function(val) {
                                return _vm.toggleBuiltin(
                                  val,
                                  key,
                                  value.default
                                )
                              },
                              link: function(type) {
                                return _vm.toggleLinkPropToComponent(
                                  key,
                                  _vm.node.props[key],
                                  value.proptype,
                                  value.isRequired
                                )
                              }
                            }
                          },
                          [
                            _vm.has(_vm.node.props, key)
                              ? _c(_vm.getEditor(value.proptype), {
                                  tag: "component",
                                  attrs: {
                                    layerId: _vm.node.id,
                                    value: _vm.node.props[key],
                                    param: key
                                  },
                                  on: {
                                    change: function(value) {
                                      return _vm.updatePropIdValue(
                                        null,
                                        value,
                                        key,
                                        _vm.isLinkedProp(key)
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }