<template lang="pug">
.dragger.ani(
  v-if="selectedNotUndefined"
  @mousedown="start('move', $event)"
  )
  //- 开始拖动后隐藏，防止遮挡视线
  transition(name="fade")
    .content(v-show="!isStart && !isWorkspaceRootLayer")
      edge.y.left( @mousedown.native.stop="start('left', $event)")
      edge.y.right( @mousedown.native.stop="start('right', $event)" )
      edge.x.top( @mousedown.native.stop="start('top', $event)" )
      edge.x.bottom( @mousedown.native.stop="start('bottom', $event)" )
      corner.left.top( @mousedown.native.stop="start('lefttop', $event)" )
      corner.right.top( @mousedown.native.stop="start('righttop', $event)" )
      corner.left.bottom( @mousedown.native.stop="start('leftbottom', $event)" )
      corner.right.bottom( @mousedown.native.stop="start('rightbottom', $event)" )
  //- 用于遮盖后面的 workspace，防止拖拽完成后选中后面的元素
  .cover(v-show="isStart")
</template>

<style lang="stylus" scoped>
.dragger
  display inline-grid
  box-shadow 0 0 0 1px var(--clr-primary)
  &:hover
    cursor move
.content
  // place-items stretch
  // display inline-block
  &>*
    position absolute
// variable
$anchor = 1rem
$offset = 100% //"calc(100% - %s / 2)" % $anchor

// set size
.corner
  width $anchor
  height $anchor
.edge.y
  height 100%
  width $anchor
.edge.x
  width 100%
  height $anchor

.left
  right $offset
.top
  bottom $offset
.right
  left $offset
.bottom
  top $offset

.left.top
  cursor nwse-resize
.right.top
  cursor nesw-resize
.left.bottom
  cursor nesw-resize
.right.bottom
  cursor nwse-resize

.cover
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 2


// animation
.fade-enter, .fade-leave-to
  opacity: 0;
</style>


<script>
import corner from "./component/corner";
import edge from "./component/edge";

export default {
  name: "resizer",
  components: {
    corner,
    edge
  },
  props: {
    positionName: {
      type: String,
      default: "absolute"
    }
  },
  computed: {
    isWorkspaceRootLayer() {
      return (
        this.$store.getters.selectedProject &&
        this.$store.getters.selectedProject.workspace == this.$store.state.layer.layerId
      );
    },
    selectedNotUndefined() {
      return typeof this.$store.getters.foucsLayer != "undefined";
    },
    isStart() {
      return this.$store.state.ui.floatingStartDrop;
    },
    hoverLayer() {
      return this.$store.getters.layer(this.$store.state.ui.hoverLayerId);
    },
    layer() {
      if (typeof this.$store.getters.foucsLayer == "undefined") {
        return this.hoverLayer;
      }
      return this.$store.getters.foucsLayer;
    },
    isWorkspace() {
      return this.layer && this.layer.position && this.layer.position.activeLayout == "workspace";
    },
    lockLeft() {
      if (this.layer && this.layer.position && this.isWorkspace) {
        return false;
      }
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName].left == "off"
      );
    },
    lockRight() {
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName].right == "off"
      );
    },
    lockTop() {
      if (this.layer && this.layer.position && this.isWorkspace) {
        return false;
      }
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName].top == "off"
      );
    },
    lockBottom() {
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName].bottom == "off"
      );
    },
    lockWidth() {
      return (
        this.layer && this.layer.position && this.layer.position.size && this.layer.position.size.x.default == "auto"
      );
    },
    lockHeight() {
      return (
        this.layer && this.layer.position && this.layer.position.size && this.layer.position.size.y.default == "auto"
      );
    },
    allPozLock() {
      return this.lockLeft && this.lockRight && this.lockTop && this.lockBottom;
    },
    z() {
      return this.$store.state.ui.floatingPoz;
    }
  },
  watch: {
    z(position) {
      if (!this.lockLeft && !this.lockRight && !this.lockTop && !this.lockBottom && position) {
        if (!this.lockWidth && position.width && position.width != 0 && !Number.isNaN(position.width)) {
          if (this.layer && this.layer.position && this.layer.position.size) {
            this.layer.position.size.x.default = position.width;
          }
        }
        if (!this.lockHeight && position.height && position.height != 0 && !Number.isNaN(position.height)) {
          if (this.layer && this.layer.position && this.layer.position.size) {
            this.layer.position.size.y.default = position.height;
          }
        }
      }
    }
  },
  methods: {
    move(e) {
      let stepx = e.clientX - this.moveStartPoz.x;
      let stepy = e.clientY - this.moveStartPoz.y;

      if (!Number.isNaN(stepx) && !this.lockRight) {
        this.layer.position.align[this.positionName].right = Math.round(this.moveStartPoz.right - stepx);
      }

      if (!Number.isNaN(stepx) && !this.lockLeft) {
        this.layer.position.align[this.positionName].left = Math.round(this.moveStartPoz.left + stepx);
      }

      if (!Number.isNaN(stepy) && !this.lockTop) {
        this.layer.position.align[this.positionName].top = Math.round(this.moveStartPoz.top + stepy);
      }

      if (!Number.isNaN(stepy) && !this.lockBottom) {
        this.layer.position.align[this.positionName].bottom = Math.round(this.moveStartPoz.bottom - stepy);
      }
    },
    left(e) {
      let stepx = e.clientX - this.moveStartPoz.x;

      if (this.lockLeft && this.lockRight && !this.lockWidth) {
        // 所有都锁定的情况下，设置宽高
        this.layer.position.size.x.default = Math.round(Math.max(this.moveStartPoz.width - stepx, 0));
        return;
      }

      if (this.lockRight && !this.lockLeft) {
        this.layer.position.align[this.positionName].left = Math.round(this.moveStartPoz.left + stepx);
        if (!this.lockWidth) {
          this.layer.position.size.x.default = Math.round(Math.max(this.moveStartPoz.width - stepx, 0));
        }
        // 守护边界
        if (this.layer.position.size.x.default == 0) {
          this.layer.position.align[this.positionName].left = this.max.left;
        }
        return;
      }

      if (this.lockLeft && !this.lockRight && !this.lockWidth) {
        this.layer.position.size.x.default = Math.round(Math.max(this.moveStartPoz.width - stepx, 0));
        return;
      }

      // 直接改，在这里 copy 会有性能消耗，assign 会绕过 setter 不会动态更新。
      if (!Number.isNaN(stepx) && !this.lockLeft) {
        this.layer.position.align[this.positionName].left = Math.round(this.moveStartPoz.left + stepx);
      }
    },
    right(e) {
      let stepx = e.clientX - this.moveStartPoz.x;

      if (this.lockLeft && this.lockRight && !this.lockWidth) {
        // 所有都锁定的情况下，设置宽高
        this.layer.position.size.x.default = Math.round(Math.max(this.moveStartPoz.width + stepx, 0));
        return;
      }

      if (this.lockLeft && !this.lockRight) {
        if (!this.lockWidth) {
          this.layer.position.size.x.default = Math.round(Math.max(this.moveStartPoz.width + stepx, 0));
        }
        this.layer.position.align[this.positionName].right = Math.round(this.moveStartPoz.right - stepx);

        if (this.layer.position.size.x.default == 0) {
          this.layer.position.align[this.positionName].right = this.max.right;
        }
        return;
      }

      if (this.lockRight && !this.lockLeft && !this.lockWidth) {
        this.layer.position.size.x.default = Math.round(Math.max(this.moveStartPoz.width + stepx, 0));
      }

      if (!Number.isNaN(stepx) && !this.lockRight) {
        this.layer.position.align[this.positionName].right = Math.round(this.moveStartPoz.right - stepx);
      }
    },
    top(e) {
      let stepy = e.clientY - this.moveStartPoz.y;

      if (this.lockTop && this.lockBottom && !this.lockHeight) {
        // 所有都锁定的情况下，设置宽高
        this.layer.position.size.y.default = Math.round(Math.max(this.moveStartPoz.height - stepy, 0));
        return;
      }

      if (this.lockBottom && !this.lockTop) {
        this.layer.position.align[this.positionName].top = Math.round(
          Math.min(this.moveStartPoz.top + stepy, this.max.top)
        );
        if (!this.lockHeight) {
          this.layer.position.size.y.default = Math.round(Math.max(this.moveStartPoz.height - stepy, 0));
        }

        if (this.layer.position.size.y.default == 0) {
          this.layer.position.align[this.positionName].top = this.max.top;
        }
        return;
      }

      if (this.lockTop && !this.lockBottom && !this.lockHeight) {
        this.layer.position.size.y.default = Math.round(Math.max(this.moveStartPoz.height - stepy, 0));
        return;
      }
      if (!Number.isNaN(stepy) && !this.lockTop) {
        this.layer.position.align[this.positionName].top = Math.round(this.moveStartPoz.top + stepy);
      }
    },
    bottom(e) {
      let stepy = e.clientY - this.moveStartPoz.y;

      if (this.lockTop && this.lockBottom && !this.lockHeight) {
        // 所有都锁定的情况下，设置宽高
        this.layer.position.size.y.default = Math.round(Math.max(this.moveStartPoz.height + stepy, 0));
        return;
      }

      if (this.lockTop && !this.lockBottom) {
        this.layer.position.size.y.default = Math.round(Math.max(this.moveStartPoz.height + stepy, 0));
        if (!this.lockHeight) {
          this.layer.position.align[this.positionName].bottom = Math.round(this.moveStartPoz.bottom - stepy);
        }
        if (this.layer.position.size.y.default == 0) {
          this.layer.position.align[this.positionName].bottom = this.max.bottom;
        }
        return;
      }

      if (this.lockBottom && !this.lockTop && !this.lockHeight) {
        this.layer.position.size.y.default = Math.round(Math.max(this.moveStartPoz.height + stepy, 0));
        return;
      }

      if (!Number.isNaN(stepy) && !this.lockBottom) {
        this.layer.position.align[this.positionName].bottom = Math.round(this.moveStartPoz.bottom - stepy);
      }
    },
    lefttop(e) {
      this.left(e);
      this.top(e);
    },
    righttop(e) {
      this.right(e);
      this.top(e);
    },
    leftbottom(e) {
      this.left(e);
      this.bottom(e);
    },
    rightbottom(e) {
      this.right(e);
      this.bottom(e);
    },
    start(type, e) {
      this.type = type;
      this.canReadMourse = true;
      // const poz = this.$el.getBoundingClientRect();
      // 相对于 this.$el 定点 x y偏移量

      this.moveStartPoz.x = e.clientX - this.$el.offsetLeft;
      this.moveStartPoz.y = e.clientY - this.$el.offsetTop;

      if (
        this.layer.position.activeLayout == "workspace" &&
        this.layer.position.align[this.positionName].top == "off"
      ) {
        this.moveStartPoz.top = 0;
      } else {
        this.moveStartPoz.top = this.px2num(this.layer.position.align[this.positionName].top);
      }

      if (
        this.layer.position.activeLayout == "workspace" &&
        this.layer.position.align[this.positionName].left == "off"
      ) {
        this.moveStartPoz.left = 0;
      } else {
        this.moveStartPoz.left = this.px2num(this.layer.position.align[this.positionName].left);
      }

      this.moveStartPoz.right = this.px2num(this.layer.position.align[this.positionName].right);
      this.moveStartPoz.bottom = this.px2num(this.layer.position.align[this.positionName].bottom);

      this.moveStartPoz.height = parseInt(this.layer.position.size.y.default);
      this.moveStartPoz.width = parseInt(this.layer.position.size.x.default);

      this.max = {
        left: Math.round(this.$parent.z.left + this.$parent.z.width),
        right: Math.round(this.$parent.z.right + this.$parent.z.width),
        top: Math.round(this.$parent.z.top + this.$parent.z.height),
        bottom: Math.round(this.$parent.z.bottom + this.$parent.z.height)
      };
    },
    reset() {
      this.canReadMourse = false;
      this.moveStartPoz = {
        x: 0,
        y: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      };
      this.max = {
        left: 999999,
        right: 999999,
        top: 999999,
        bottom: 999999
      };
      this.$store.state.ui.showMeasure = false;
      this.$store.state.ui.floatingStartDrop = false;
    },
    sync() {
      // 1 秒后同步定位数据到后端
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("updateLayer", this.layer);
      }, 1000);
    },
    mousemove(e) {
      if (this.canReadMourse) {
        if (this.$store.state.ui.floatingStartDrop == false) {
          this.$store.state.ui.floatingStartDrop = true;
        }
        if (this.$store.state.ui.showMeasure == false) {
          this.$store.state.ui.showMeasure = true;
        }
        this[this.type](e);
        this.sync();
      }
    },
    px2num(val) {
      if (Number.isInteger(val)) {
        return val;
      }
      try {
        const value = parseInt(val.replace("px", ""));
        return value;
      } catch (error) {
        /* eslint-disable-next-line */
        console.error(error);
      }
    }
  },
  data() {
    return {
      canReadMourse: false,
      max: {
        left: 999999,
        right: 999999,
        top: 999999,
        bottom: 999999
      }
    };
  },
  mounted() {
    this.moveStartPoz = {};
    this.$root.$on("resizeMoveStart", ({ event: e, layer }) => {
      this.type = "move";
      this.canReadMourse = true;
      this.moveStartPoz.x = e.clientX - this.$el.offsetLeft;
      this.moveStartPoz.y = e.clientY - this.$el.offsetTop;

      let positionName;
      if (layer && layer.position.activeLayout == "previewArea") {
        positionName = "previewArea";
      } else {
        positionName = "absolute";
      }

      this.moveStartPoz.left = this.px2num(layer.position.align[positionName].left);
      this.moveStartPoz.top = this.px2num(layer.position.align[positionName].top);

      this.moveStartPoz.height = parseInt(layer.position.size.y.default);
      this.moveStartPoz.width = parseInt(layer.position.size.x.default);
    });
    window.addEventListener("mousemove", this.mousemove.bind(this), true);
    window.addEventListener("mouseup", this.reset.bind(this), true);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mousemove.bind(this), true);
    window.removeEventListener("mouseup", this.reset.bind(this), true);
  }
};
</script>
