// import clone from "clone";
// import { eqProp, notEq } from "../helpers";
import search from "@/services/unsplash.js";

//
// ─── STATE ──────────────────────────────────────────────────────────────────────
//

const state = {
  results: [],
  total: 1,
  totalPages: 1,
  page: 1,
  pageSize: 30,
  keyword: ""
};

//
// ─── GETTERS ────────────────────────────────────────────────────────────────────
//

const getters = {
  hasPrev({ page }) {
    return page > 1;
  },
  hasNext({ page, totalPages }) {
    return page < totalPages;
  }
};

//
// ─── MUTATIONS ──────────────────────────────────────────────────────────────────
//

const mutations = {
  updateUnsplashState(state, payload) {
    for (let key of Object.keys(payload)) {
      state[key] = payload[key];
    }
  }
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//

const actions = {
  async searchImage({ commit, state }, payload) {
    try {
      const params = {
        keyword: payload.keyword,
        page: payload.page,
        pageSize: state.pageSize
      };
      const data = await search(params);
      commit("updateUnsplashState", {
        results: data.results,
        total: data.total,
        totalPages: data.total_pages,
        page: params.page,
        keyword: params.keyword
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }
};

export default { getters, actions, mutations, state };
