var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tree
    ? _c(
        "layout",
        {
          attrs: {
            root: _vm.tree.root,
            id: _vm.tree.id,
            hasChildren: _vm.hasChildren,
            noFirst: true
          }
        },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _c("label-layer", {
                attrs: {
                  name: _vm.tree.label,
                  hasChildren: _vm.hasChildren,
                  isFocused: _vm.isFocused && !_vm.isDrag,
                  icon: _vm.icon
                }
              })
            ],
            1
          ),
          _vm.isExpand
            ? _c(
                "div",
                { staticClass: "slot-list" },
                _vm._l(Object.keys(_vm.tree.children), function(slot) {
                  return _c(
                    "layout",
                    {
                      key: slot,
                      attrs: {
                        root: _vm.tree.root,
                        targetSlot: slot,
                        id: _vm.tree.id,
                        hasChildren: _vm.hasChildren,
                        isSlotLayout: true
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c("label-slot", {
                            attrs: { name: _vm.handleSlotName(slot) || slot }
                          })
                        ],
                        1
                      ),
                      _vm._l(_vm.tree.children[slot], function(id) {
                        return [
                          _vm.layerIsPure(_vm.layer(id))
                            ? _c("nodePure", {
                                key: id,
                                attrs: { tree: _vm.layer(id) }
                              })
                            : _c("node-slots", {
                                key: id,
                                attrs: { tree: _vm.layer(id) }
                              })
                        ]
                      })
                    ],
                    2
                  )
                }),
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }