var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-radio-btn", {
    attrs: { options: _vm.options, value: _vm.value, rotate: _vm.rotate },
    on: {
      change: function($event) {
        _vm.$emit("change", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }