var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prop-editor" },
    [
      _c(
        "div",
        {
          staticClass: "top pointer",
          on: { click: _vm.toggleInterfaceConnector }
        },
        [
          _c("div", { staticClass: "label f-p color-text-2" }, [
            _vm._v(_vm._s(_vm.$utils.upperCase(_vm.showName)))
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isComponents,
                  expression: "isComponents"
                }
              ],
              staticClass: "interface-connector radius-s ani-fast"
            },
            [
              _c("div", {
                staticClass: "dot oval ani-fast",
                class: { checked: _vm.interfaceConnectorChecked }
              })
            ]
          )
        ]
      ),
      _c(_vm.editorName(_vm.propType), {
        tag: "component",
        attrs: { value: _vm.value, param: _vm.propKey },
        on: {
          change: function(value) {
            return _vm.updatePropValueV2(value, _vm.propKey)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }