<template lang="pug">
.rootContainerCutline
  .line.line-t.start
  .line.line-t.end
  .line.line-b.start
  .line.line-b.end
  .line.line-r.start
  .line.line-r.end
  .line.line-l.start
  .line.line-l.end
</template>

<style lang="stylus" scoped>
offset = .5rem
lineW = 1px
lineH = 1rem

.rootContainerCutline
  &>*
    position absolute

.line
  background var(--clr-content-3)
.line-t
  bottom "calc(100% + %s)" % (offset)
.line-t
.line-b
  width lineW
  height lineH
  &.start
    left  -0.5 * lineW
  &.end
    right -0.5 * lineW
.line-l
.line-r
  height lineW
  width lineH
  &.start
    top  -0.5 * lineW
  &.end
    bottom -0.5 * lineW
.line-b
  top "calc(100% + %s)" % (offset)
.line-l
  right "calc(100% + %s)" % (offset)
.line-r
  left "calc(100% + %s)" % (offset)
</style>

<script>
// import node from "./node";
export default {
  // name: "layerTree",
  // components: {
  // 	node
  // }
};
</script>
