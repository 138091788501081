<template lang="pug">
  .shadows
    //- a.radio-button(@click="addShadow") +
    template(v-for="(item,i) in value" )
      //- ShadowEditor(:key="'shadow-'+i" :value="item" @change="v => change(v,i)" @click.native.right="remove(i)")
      ShadowEditor(:key="'shadow-'+i" :value="item" @change="v => change(v,i)")
</template>

<style lang="stylus" scoped>
.shadows > *
  width 100%
.shadows > *:not(:last-child)
  margin-bottom .5rem
.radio-button
  position: absolute;
  right: 0px;
  top: -28px;
  // margin-bottom .5rem
  display inline-block
  width 1.5rem
  height 1.5rem
  line-height 1.5rem
  text-align center
  background var(--clr-bg-2)
  color var(--clr-content-2)
  &:hover
    background var(--clr-primary-shade-3)
    box-shadow inset 0 0 0 1px var(--clr-primary-shade-1)
</style>


<script>
import ShadowEditor from "./shadow.vue";
const defaultValue = {
  x: 0,
  y: 0,
  blur: 0,
  spread: 0,
  color: "#000",
  inset: false
};
export default {
  components: {
    ShadowEditor
  },
  name: "shadows-editor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: Array
    },
    param: String
  },
  methods: {
    change(v, i) {
      this.value[i] = v;
      this.$emit("change", this.value);
    },
    // remove(i) {
    // this.value.splice(i, 1);
    // this.$emit("change", this.value);
    // },
    addShadow() {
      this.value.push(Object.assign({}, defaultValue));
      this.$emit("change", this.value);
    }
  }
};
</script>
