<template lang="pug">
.floating-editor(:style="style" 
 :class="{noop: isWorkspaceRootLayer}"
 v-if="showFloatingEditor")
  resizer.full-xy(v-show="showResizer" :positionName="positionName")
  measure.full-xy(:positionName="positionName")
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.noop
  pointer-events none
.floating-editor
  // transition all .1s;
  // transform translateZ(0)
  will-change all
  off()
.full-xy
  top 0
  bottom 0
  left 0
  right 0
</style>

<script>
import resizer from "./resizer/resizer.vue";
import measure from "./measure/measure.vue";

export default {
  components: {
    resizer,
    measure
  },
  data() {
    return {
      z: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: 0,
        height: 0
      }
    };
  },
  mounted() {
    this.$root.$floating = node => {
      if (node.$el && node.focus && node.$el.getBoundingClientRect) {
        const root = document.querySelector("#rootRender");
        if (root && root.getBoundingClientRect && node.$el.getBoundingClientRect) {
          const rpoz = root.getBoundingClientRect();
          const poz = node.$el.getBoundingClientRect();
          this.$root.$emit("sizeTip", {
            width: poz.width,
            height: poz.height
          });
          this.z = {
            left: poz.left - rpoz.x,
            right: poz.right - rpoz.x,
            top: poz.top - rpoz.y,
            bottom: poz.bottom - rpoz.y,
            width: poz.width,
            height: poz.height
          };
        }
      }
    };
  },
  computed: {
    // 初始化状态为 0 的时候不显示
    notInitialState() {
      return !Object.keys(this.z).every(key => this.z[key] == 0);
    },
    positionName() {
      if (this.layer && this.layer.position.activeLayout == "previewArea") {
        return "previewArea";
      } else {
        return "absolute";
      }
    },
    style() {
      return Object.keys(this.z).reduce((obj, key) => {
        obj[key] = this.z[key] + "px";
        return obj;
      }, {});
    },
    layer() {
      return this.$store.getters.foucsLayer;
    },
    hoverLayer() {
      return this.$store.getters.layer(this.$store.state.ui.hoverLayerId);
    },
    showResizer() {
      return Boolean(this.layer && this.layer.position) || Boolean(this.hoverLayer && this.hoverLayer.position);
    },
    isWorkspace() {
      if (this.$store.selectedComponent.id == "workspace") {
        return true;
      }
    },
    isWorkspaceRootLayer() {
      return (
        this.$store.getters.selectedProject &&
        this.$store.getters.selectedProject.workspace == this.$store.state.layer.layerId
      );
    },
    showFloatingEditor() {
      return this.$store.state.ui.showFloatingEditor && this.notInitialState;
    }
  }
};
</script>
