import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/User/Login";
import Verification from "@/components/User/Verification";
import projectEditor from "@/components/project_editor/projectEditor.vue";
// import CompEditor from "@/components/Project/editor/Editor";
import ComponentEditor from "@/components/component_editor/componentEditor.vue";

import CompBrowser from "@/components/Project/editor/Browser";
import ProjectBrowser from "@/components/team_home/teamHome.vue";
import { isAuthenticated } from "@/services/init.js";

Vue.use(Router);

export const router = new Router({
  // only in history can catch auth0 callback
  mode: "history", // Enable this to auto logout when URL access to link
  routes: [
    { path: "/callback", name: "Verification", component: Verification },
    {
      path: "/project/:id",
      name: "ProjWorkspace",
      component: projectEditor,
      children: [
        {
          path: "",
          name: "ProjEditorIndex",
          component: CompBrowser
        },
        {
          path: ":componentId",
          name: "ProjEditorDetail",
          component: ComponentEditor
        }
      ]
    },
    // { path: "/component/:id", name: "CompEditor", component: CompEditor },
    { path: "/team/:id", name: "ProjectBrowser", component: ProjectBrowser },

    { path: "/login", name: "Login", component: Login },
    { path: "*", redirect: "/login" }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/callback"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = isAuthenticated();

  if (authRequired && !loggedIn) {
    return next({
      path: "/login",
      query: { returnUrl: to.path }
    });
  }

  next();
});
