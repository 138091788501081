var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show
      ? _c(
          "div",
          { staticClass: "measure ani-fast" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.left != "off",
                    expression: "left != 'off'"
                  }
                ],
                staticClass: "ruler-container left",
                class: { revert: _vm.toInt(_vm.left).negative }
              },
              [
                _c(
                  "ruler",
                  {
                    staticClass: "x",
                    style: { width: _vm.toInt(_vm.left).value + "px" }
                  },
                  [
                    _c("value", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.left != "off",
                          expression: "left != 'off'"
                        }
                      ],
                      attrs: { valueText: _vm.toInt(_vm.left).raw }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.right != "off" && _vm.notWorkspace,
                    expression: "right != 'off' && notWorkspace"
                  }
                ],
                staticClass: "ruler-container right",
                class: { revert: _vm.toInt(_vm.right).negative }
              },
              [
                _c(
                  "ruler",
                  {
                    staticClass: "x",
                    style: { width: _vm.toInt(_vm.right).value + "px" }
                  },
                  [
                    _c("value", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.right != "off",
                          expression: "right != 'off'"
                        }
                      ],
                      attrs: { valueText: _vm.toInt(_vm.right).raw }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.top != "off",
                    expression: "top != 'off'"
                  }
                ],
                staticClass: "ruler-container top",
                class: { revert: _vm.toInt(_vm.top).negative }
              },
              [
                _c(
                  "ruler",
                  {
                    staticClass: "y",
                    style: { height: _vm.toInt(_vm.top).value + "px" }
                  },
                  [
                    _c("value", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.top != "off",
                          expression: "top != 'off'"
                        }
                      ],
                      attrs: { valueText: _vm.toInt(_vm.top).raw }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.bottom != "off" && _vm.notWorkspace,
                    expression: "bottom != 'off' && notWorkspace"
                  }
                ],
                staticClass: "ruler-container bottom",
                class: { revert: _vm.toInt(_vm.bottom).negative }
              },
              [
                _c(
                  "ruler",
                  {
                    staticClass: "y",
                    style: { height: _vm.toInt(_vm.bottom).value + "px" }
                  },
                  [
                    _c("value", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.bottom != "off",
                          expression: "bottom != 'off'"
                        }
                      ],
                      attrs: { valueText: _vm.toInt(_vm.bottom).raw }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("value", { staticClass: "pin", attrs: { valueText: _vm.info } })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }