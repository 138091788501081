<template lang="pug">
.login
  .card
    .head
      img.logo(src="@/assets/logo/logo-dev.png")
      .f-h3.title Welcome to Bento
        span.beta.pill.f-p.bold Dev
    .enter
      //- TODO: 优化加载、成功、失败提示 UI 界面
      .method(v-if="isLoading")
        .f-p Confirming
      .method(v-if="error")
        .f-p {{ error }}
        button.pointer.color-button-black.size-button-m.radius-m.ani(
          @click="back2Login"
        ) Back
      .method(v-if="isSuccess")
        .f-p Successfully
      .method(v-if="needProvideEmail")
        .f-p Please provide your email for confirmation
        form.form(@submit.prevent="handleSubmit")
          input.size-input-m.f-p.color-input-gray.radius-m.ani(
            type="email"
            v-model="email"
            name="email"
            placeholder="Your email address"
            autofocus
          )
          button.pointer.color-button-black.size-button-m.radius-m.ani(
            v-show="email"
            type="submit"
            :disabled="isLoading"
          ) Confirm
</template>
<style lang="stylus" scoped>
@require '../../styles/design/main'
@require '../../styles/layout/main'
.login
  width 100%
  height 100%
  off()
.card
  // position 
  pos-abs(x:center, y:start, t:25%, w:24rem, h: max-content)
  // layout
  stack(y, gap: 2rem, ali: stretch)

.head
  stack(y, gap: 1rem, ali: center)
.logo
  width 64px
.beta
  // position
  position relative
  top -.75rem
  left .5rem
  // style
  background var(--clr-bg-3)
  // layout
  padding .25rem .5rem
.enter
  stack(y, gap: 1rem, ali: stretch)
.method
  stack(y, gap: .25rem, ali: stretch)
  color var(--clr-content-2)
.form
  stack(y, gap: .5rem, ali: stretch)

.pointer
  cursor pointer

input:invalid
  box-shadow 0 0 4px 0 red

.loading
  color #69c0ff

.success
 color #52c41a

.errorMessage
  color red

</style>

<script>
import { captureException } from "@sentry/browser";
import { isSignInWithEmailLink, signInWithEmailLink } from "@/services/init.js";
import { EMAILFORSIGNIN } from "@/common/constants";

export default {
  name: "Verification",
  data() {
    return {
      error: "",
      needProvideEmail: false,
      isSuccess: false,
      isLoading: false,
      email: window.localStorage.getItem(EMAILFORSIGNIN)
    };
  },
  async created() {
    try {
      if (!isSignInWithEmailLink()) throw new Error("Invalid email link!");
      if (this.email) {
        await this.handleSubmit();
      } else {
        this.needProvideEmail = true;
      }
    } catch (e) {
      captureException(e);
      this.isLoading = false;
      this.error = e.message;
    }
  },
  methods: {
    back2Login() {
      this.$router.push("/login");
    },
    async handleSubmit() {
      try {
        this.error = "";
        this.needProvideEmail = false;
        this.isLoading = true;
        const data = await signInWithEmailLink(this.email);
        this.isLoading = false;
        this.isSuccess = true;
        if (data.additionalUserInfo.isNewUser) {
          await this.$store.dispatch("createTeam", {
            teamName: "Primary"
          });
        }
        this.$router.push("/team/0");
      } catch (e) {
        this.isLoading = false;
        this.isSuccess = false;
        this.error = e.message;
      }
    }
  }
};
</script>
