<template lang="pug">
  scroll.scroll.pointer-event-off(dir='y' hideBar padding='.5rem' v-if="node")
    //- block list
    .block-list.b-z--unset.pointer-event-on
      //- layer general props
      PropFolder(
        v-if="node && node.componentId !== 'workspace'"
        )
        .form-item
          .label.f-p.color-text-2 Name
          StringEditor(
            placeholder=''
            :value="nodeName" 
            param="label"
            v-on:change="updateName"
            )
        //- 如果是 user component，则显示
        .form-item(v-if="isDesign && component.status == 1")
          .label.f-p.color-text-2 Component
          .comp-btn.radius-m.color-button-normal.size-input-s.pointer.ani-fast(@click="editCurrentComponnet")
            .flex.f-p.color-text.ellipsis {{ component.label }}
            .edit.radius-s.ani-fast.f-p.color-text-3 Edit
        .form-item(v-else-if="isDesign && component.status != 1")
          .comp-btn
            .btn(@click="detachComponent") Detach
            .btn(@click="restoreComponent") Recover
      //- layer position
      PropFolder(
        label="Position"
        v-if="!noPositionComponent")
        PositionEditor(
          v-on:change="updatePosition"
          :position="node.position"
        )
      //- user component props
      template(v-if="isDesign")
        template(v-for="(props,id) in componentprops")
          PropFolder(
            v-for="(value, key) in props"
            :key="id+key+''"
          )
            prop-editor(
              :label="getPropName(id, key)"
              :key="id+key+''"
              :isProp="isLinkedProp(key, id)"
              :propName="key"
              :layerId="id"
              size="l"
              @toggle="(val) => toggleBuiltin(val, key, value.value ,id)"
              @link="(type) => toggleLinkPropToComponent(key, node.props[id][key], value.type, value.isRequired, id)"
            )
              component(
                :is="getEditor(value.type)"
                :layerId="node.id"
                v-if="node.props[id] && has(node.props[id], key)"
                :value="node.props[id][key]"
                :param="key"
                @change="(value) => updatePropIdValue(id, value, key, isLinkedProp(key, id))"
              )
      //- builtin component props
      template(v-if="!isDesign")
        PropFolder(
          v-for="(value,key) in componentprops"
          :key="key"
        )
          prop-editor(
            :label="key"
            :isProp="isLinkedProp(key)"
            :key="key"
            :propName="key"
            :required="value.isRequired"
            size="l"
            @toggle="(val) => toggleBuiltin(val, key, value.default)"
            @link="(type) => toggleLinkPropToComponent(key, node.props[key], value.proptype, value.isRequired)"
          )
            component(
              :is="getEditor(value.proptype)"
              :layerId="node.id"
              v-if="has(node.props, key)"
              :value="node.props[key]"
              :param="key"
              @change="(value) => updatePropIdValue(null, value, key, isLinkedProp(key))"
            )
      //- template(v-if="!isDesign && hasProps")
        //- layout
        PropFolder(label="" v-if="hasDefined(node.props.layout)")
          BoxLayoutEditor(:value="node.props.layout" param="layout" @change="updatePropValue")
        //- text only
        PropFolder(label="Text"  v-show="this.node.componentId == 'bento-txt'")
          //- content
          PropLabel(label="Content")
            StringEditor(:value="node.props.content" @change="(value) => updatePropValue(value, 'content')")
          //- padding
          PropLabel(label="Padding")
            PaddingEditor(:value="node.props.padding" @change="(value) => updatePropValue(value, 'padding')")
          //- clip
          PropLabel(label="Clip" x extend)
            b-switcher(:value="node.props.clip" @change="(value) => updatePropValue(value, 'clip')")
        //- box only
        PropFolder(label="Box" v-show="this.node.componentId == 'box'")
          //- clip
          PropLabel(label="Clip" x extend)
            b-switcher(:value="node.props.clip" @change="(value) => updatePropValue(value, 'clip')")
        //- image only
        PropFolder(label="Image" v-show="this.node.componentId == 'bento-image'")
          //- src
          PropLabel(label="Content")
            ImgSrcEditor(:value="node.props.src" @change="(value) => updatePropValue(value, 'src')")
          //- content align
          PropLabel(label="Align")
            ImgalignEditor
            //- StringEditor(:value="node.props.objectFit" @change="(value) => updatePropValue(value, 'objectFit')")
            //- StringEditor(:value="node.props.objectPosition" @change="(value) => updatePropValue(value, 'objectPosition')")
          //- padding
          PropLabel(label="Padding")
            PaddingEditor(:value="node.props.padding" @change="(value) => updatePropValue(value, 'padding')")
        //- scroll only
        PropFolder(label="Scroll" v-show="this.node.componentId == 'bento-scroll'")
          PropLabel(label="Direction")
            bRadioBtn(:value="node.props.dir" :options="[{label: 'X', value: 'x'}, {label: 'Y', value: 'y'}, {label: 'Both', value: 'both'}]" @change="(value) => updatePropValue(value, 'dir')")
          PropLabel(label="Padding")
            PaddingEditor(:value="node.props.padding" @change="(value) => updatePropValue(value, 'padding')")
          //- PropLabel(label="Hide Scroll Bar")
          //-   b-switcher(:value="node.props.hideBar" @change="(value) => updatePropValue(value, 'hideBar')")
        //- typography
        PropFolder(label="" v-if="hasDefined(node.props.typography)")
          TypographyEditor(:value="node.props.typography" @change="(value) => updatePropValue(value, 'typography')")
        //- backgrounds
        PropFolder(label="" v-if="hasDefined(node.props.background)")
          b-btn-color(:value="node.props.background" @change="(value) => updatePropValue(value, 'background')")
        //- radius
        PropFolder(label="" v-if="hasDefined(node.props.radius)")
          RadiusEditor(:value="node.props.radius" @change="(value) => updatePropValue(value, 'radius')")
        //- shadows
        PropFolder(label="" v-if="hasDefined(node.props.shadow)")
          ShadowsEditor(:value="node.props.shadow" @change="(value) => updatePropValue(value, 'shadow')")
</template>

<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

// .scroll
  // max-height max-content
  // $bg = lighten(black, 90%)
  // background alpha($bg, 90%)
  // backdrop-filter blur(8px)
.block-list
  stack(y, gap: 0.25rem)
.form-item
  stack(y, gap: 0.25rem)
.comp-btn
  stack(x, gap: 0.25rem)
  .edit
    opacity 0
    padding 0 .5rem
  &:hover
    .edit
      opacity 1
</style>

<script>
import PropFolder from "@/components/prop_panel/component/prop_folder.vue";
import PropLabel from "@/components/prop_panel/component/label.vue";
import LabelWidthPropConnector from "@/components/prop_panel/component/label_width_prop_connector.vue";
import PropEditor from "@/components/prop_panel/component/prop_editor.vue";

import StringEditor from "@/components/form_element/string.vue";
import ImgSrcEditor from "@/components/form_element/image_src.vue";
import PositionEditor from "@/components/form_element/position/position.vue";
import BackgroundEditor from "@/components/form_element/background.vue";
import RadiusEditor from "@/components/form_element/radius.vue";
import PaddingEditor from "@/components/form_element/padding.vue";
import BoxLayoutEditor from "@/components/form_element/box_layout/box_layout.vue";
import TypographyEditor from "@/components/form_element/typography/typography.vue";
import ImgalignEditor from "@/components/form_element/imgalign.vue";

import TypefaceEditor from "@/components/form_element/typography/typeface.vue";
import SpaceEditor from "@/components/form_element/space.vue"; // TODO: 待确认
import ShadowEditor from "@/components/form_element/shadow.vue";
import ShadowsEditor from "@/components/form_element/shadows.vue";
import Switcher from "@/components/form_element/boolean/switcher.vue";
import LengthEditor from "@/components/form_element/length.vue";
import NumberEditor from "@/components/form_element/number.vue";
import FontscaleEditor from "@/components/form_element/typography/fontscale.vue";
import ImageEditor from "@/components/form_element/image_src.vue";
// import ColorEditor from "@/components/form_element/color.vue";
import BoolRadioEditor from "@/components/form_element/boolean/select.vue";
import TextEditor from "@/components/form_element/textarea.vue";
import DirEditor from "@/components/form_element/dir.vue";

import ColorEditor from "@/components/form_element/color_editor.vue";

const editors = [
  {
    type: "padding",
    name: "PaddingEditor"
  },
  {
    type: "typeface",
    name: "TypefaceEditor"
  },
  {
    type: "space",
    name: "StringEditor" // TODO: 需要确认是否支持 normal、inherit、initial、unset等关键字，先跳过
  },
  {
    type: "radius",
    name: "RadiusEditor"
  },
  {
    type: "shadow",
    name: "ShadowEditor"
  },
  {
    type: "shadows",
    name: "ShadowsEditor"
  },
  {
    type: "string",
    name: "StringEditor"
  },
  {
    type: "number",
    name: "NumberEditor"
  },
  {
    type: "boolean",
    name: "Switcher"
  },
  {
    type: "length",
    name: "LengthEditor"
  },
  {
    type: "fontscale",
    name: "FontscaleEditor"
  },
  {
    type: "image",
    name: "ImageEditor"
  },
  {
    type: "color",
    name: "ColorEditor"
  },
  {
    type: "layout",
    name: "BoxLayoutEditor"
  },
  {
    type: "typography",
    name: "TypographyEditor"
  },
  {
    type: "booleanradio",
    name: "BoolRadioEditor"
  },
  {
    type: "imgalign",
    name: "ImgalignEditor"
  },
  {
    type: "text",
    name: "TextEditor"
  },
  {
    type: "dir",
    name: "DirEditor"
  }
];

import dotProp from "dot-prop";
// ？？？？？
import Vue from "vue";

// 为啥要引入内置组件？

import Box from "@/components/preview_area/built-in component/Box.vue";

import BentoImage from "@/components/preview_area/built-in component/Image.vue";
import BentoSlot from "@/components/preview_area/built-in component/Slot.vue";
import BentoTxt from "@/components/preview_area/built-in component/Txt.vue";
import BentoScroll from "@/components/preview_area/built-in component/Scroll.vue";

const inner = {
  Box,
  BentoImage,
  BentoSlot,
  BentoScroll,
  BentoTxt
};

export default {
  components: {
    PropFolder,
    PropLabel,
    PropEditor,
    LabelWidthPropConnector,
    ImgSrcEditor,
    PaddingEditor,
    StringEditor,
    PositionEditor,
    BackgroundEditor,
    ShadowsEditor,
    RadiusEditor,
    TypographyEditor,
    BoxLayoutEditor,
    TypefaceEditor,
    SpaceEditor,
    ShadowEditor,
    Switcher,
    LengthEditor,
    NumberEditor,
    FontscaleEditor,
    ImageEditor,
    BoolRadioEditor,
    ImgalignEditor,
    ColorEditor,
    TextEditor,
    DirEditor
  },
  methods: {
    restoreComponent() {
      this.$store.dispatch("restoreComponent", { id: this.component.id });
    },
    detachComponent() {
      this.$store.dispatch("componentToLayer", { id: this.node.id });
    },
    editCurrentComponnet() {
      const allComponent = this.$store.getters.selectedTeamAllProjects.reduce((acc, proj) => {
        acc = acc.concat(proj.componentIds);
        return acc;
      }, []);
      if (this.component && allComponent.some(id => id == this.component.id)) {
        this.$ui.currentTab = "components";
        this.$router.push({
          name: "ProjEditorDetail",
          params: {
            id: this.$route.params.id,
            componentId: this.component.id
          }
        });
        this.$store.commit("setSelectedComponentId", {
          id: this.component.id
        });
        this.$nextTick(() => {
          this.$store.commit("setLayerId", this.component.root);
        });
      }
    },
    has(obj, key) {
      return obj.hasOwnProperty(key);
    },
    async toggleBuiltin(val, key, value, layerId) {
      await this.$store.dispatch("toggleProp", {
        key,
        value: typeof value == "function" ? value() : value,
        layerId,
        id: this.node.id
      });
    },
    getPropName(id, name) {
      const layer = this.$store.getters.layer(id);
      if (!layer) {
        return name.replace(/\|map2:.*/gi, "");
      }
      return `${layer.label}.${name.replace(/\|map2:.*/gi, "")}`;
    },
    getEditor(type) {
      const editor = editors.find(e => e.type == type);
      if (!editor) {
        return "StringEditor";
      }
      return editor.name;
    },
    hasDefined(val) {
      return typeof val != "undefined";
    },
    //get node value by key
    propValue(key, node) {
      node = node || this.node;
      if (!node) {
        return null;
      }
      return node.props[key] && node.props[key].value;
    },
    //get parent node value by key
    parentPropValue(key) {
      if (!this.parentNode) {
        return null;
      }
      return this.propValue(key, this.parentNode);
    },
    //get category data by name
    propCate(propName) {
      return this.$utils.props.getData(propName);
    },
    //get prop type by value
    propType(key, id) {
      if (!this.component.props) {
        let component = Vue.component("code:" + this.node.componentId);
        if (component) {
          /* eslint-disable-next-line */
          const props = dotProp.get(
            component,
            "$vnode.componentOptions.Ctor.extendOptions.props",
            {}
          );
          if (props && props[key] && props[key].proptype) {
            return props[key].proptype;
          }
        }
        if (!component && this.$utils.isInnerComponent(this.node.componentId)) {
          const innerName = this.$utils.kebab2Pascal(this.node.componentId);
          component = inner[innerName];

          if (!component || !component.props[key] || !component.props[key].proptype) return "string";
          return component.props[key].proptype;
        }
      } else {
        // design 下有 id 传递的情况
        /* eslint-disable-next-line */
        if (
          id &&
          this.component.props[id][key] &&
          this.component.props[id][key].type
        ) {
          return this.component.props[id][key].type;
        }
        // 无id
        if (this.component.props[key] && this.component.props[key].type) {
          return this.component.props[key].type;
        }
      }
      return "string";
    },
    isLinkedProp(key, id) {
      const selectedComponent = this.$store.getters.selectedComponent;
      if (!this.component) {
        return false;
      }
      const props = selectedComponent.props[this.node.id];
      if (!props) {
        return false;
      }
      const propsArr = Object.keys(props);
      if (props[key]) {
        return true;
      } else if (propsArr.find(p => p.includes(key) && p.includes(id))) {
        return true;
      }
    },
    toggleLinkPropToComponent(key, value, type, isRequired, layerId) {
      const meta = {
        id: this.node.id,
        key,
        value,
        type,
        isRequired
      };
      if (layerId) {
        meta.mapTo = layerId;
      }
      this.$store.dispatch("toggleLinkPropToComponent", meta);
    },
    //update current node prop value
    async updatePropIdValue(id, value, key, linked) {
      await this.$store.dispatch({
        type: "updateLayerProp",
        id: this.node.id,
        layerId: id,
        key: key,
        value: value
      });

      if (linked) {
        await this.$store.dispatch({
          type: "updatePropDefaultValue",
          id: this.node.id,
          layerId: id,
          key: key,
          value: value
        });
      }
    },
    updatePropValue(value, key) {
      if (!this.node) {
        return;
      }
      this.$store.dispatch({
        type: "updateLayerProp",
        id: this.node.id,
        key: key,
        value: value
      });
    },
    //update current node name
    updateName(name) {
      this.$store.dispatch({
        type: "updateLayerName",
        id: this.node.id,
        label: name
      });
    },
    //update current node position
    updatePosition(v, param) {
      dotProp.set(this.node.position, param, v);
      this.$store.dispatch("updateLayer", this.node);
    },
    layerLabel(id) {
      if (!this.$store.getters.layer(id)) {
        // 没有该 layer 就删除，先 fetch 没有就删除
        this.$store.dispatch("fetchLayer", id).catch(() => {
          if (!this.$store.getters.layer(id)) {
            this.$store.dispatch("componentDeleteProp", {
              componentId: this.node.componentId,
              id // propName
            });
            this.$store.dispatch("deleteLayerProps", {
              name: id, // propName
              id: this.node.id
            });
          }
        });
      }
      /* eslint-disable-next-line */
      return (
        (this.$store.getters.layer(id) &&
          this.$store.getters.layer(id).label) ||
        id
      );
    }
  },
  computed: {
    hasProps() {
      /* eslint-disable-next-line */
      return (
        this.node && this.node.props && Object.keys(this.node.props).length > 0
      );
    },
    parentNode() {
      return this.$store.getters.layerParent(this.$store.state.layer.layerId);
    },
    node() {
      return this.$store.getters.foucsLayer;
    },
    noPositionComponent() {
      const componens = ["bento-slot", "workspace"];
      return this.node && componens.includes(this.node.componentId);
    },
    component() {
      /* eslint-disable-next-line */
      return (
        (this.node && this.$store.getters.component(this.node.componentId)) ||
        {}
      );
    },
    componentprops() {
      if (this.component && this.component.props) {
        return this.component.props;
      } else {
        let component = Vue.component("code:" + this.node.componentId);
        if (component) {
          /* eslint-disable-next-line */
          const props = dotProp.get(
            component,
            "$vnode.componentOptions.Ctor.extendOptions.props",
            {}
          );
          return props;
        }
        if (!component && this.$utils.isInnerComponent(this.node.componentId)) {
          const innerName = this.$utils.kebab2Pascal(this.node.componentId);
          component = inner[innerName];
          return component.props;
        }
      }
    },
    isDesign() {
      return Reflect.has(this.component, "root");
    },
    nodeName() {
      if (this.node) {
        return this.node.label;
      }
    },
    panelName() {
      if (this.node && this.node.componentId) {
        const nameMap = {
          "bento-txt": "Text",
          "bento-image": "Image",
          "bento-scroll": "Scroll",
          "bento-layout": "Box"
        };
        return nameMap[this.node.componentId];
      }
    },
    builtinComponentProps() {
      const needDelete = ["layout", "typography", "background", "radius", "shadow"];
      if (this.node && this.node.props) {
        let obj = { ...this.node.props };
        for (const key of needDelete) {
          if (obj[key]) {
            delete obj[key];
          }
        }
        return obj;
      }
      return {};
    }
  }
};
</script>
