var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "scroll",
    {
      directives: [
        {
          name: "shortkey",
          rawName: "v-shortkey.once",
          value: ["shift", "d"],
          expression: "['shift', 'd']",
          modifiers: { once: true }
        }
      ],
      staticClass: "pointer-event-off",
      attrs: { dir: "y", padding: ".5rem", hideBar: "" },
      nativeOn: {
        shortkey: function($event) {
          return _vm.deleteCurrentNode($event)
        }
      }
    },
    [
      _vm.rootLayer
        ? _c(
            "div",
            {
              staticClass: "tree pointer-event-on radius-m shd-m",
              attrs: { layout: "stack", dir: "y", padding: ".5rem" }
            },
            [
              _vm.isPure
                ? _c("nodePure", { attrs: { tree: _vm.rootLayer } })
                : _c("nodeSlotly", { attrs: { tree: _vm.rootLayer } })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }