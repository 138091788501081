var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "popup" } }, [
    _c(
      "div",
      {
        staticClass:
          "comp-thumbnail ani radius-l color-text-2 clip shadow-card pointer",
        on: {
          contextmenu: function($event) {
            return _vm.showMenus($event)
          }
        }
      },
      [
        _c("div", { staticClass: "comp-thumbnail--preview b-layer" }, [
          _vm.showProgress
            ? _c(
                "div",
                { staticClass: "comp-thumbnail--preview--loading f-p" },
                [_vm._v(_vm._s(_vm.progress) + "%")]
              )
            : false
              ? _c(
                  "div",
                  { staticClass: "comp-thumbnail--preview--img b-layer" },
                  [_c("img", { staticClass: "img", attrs: { src: "" } })]
                )
              : _c(
                  "div",
                  { staticClass: "comp-thumbnail--preview--icon" },
                  [
                    _c("icon", {
                      staticClass: "comp-icon",
                      attrs: { name: _vm.icon }
                    })
                  ],
                  1
                )
        ]),
        _c("div", { staticClass: "comp-thumbnail--comp-name f-p ellipsis" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }