var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background-editor" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("div", { staticClass: "flex f-title color-text" }, [
          _vm._v("Background")
        ]),
        _c("b-switcher", {
          attrs: { value: _vm.backgroundSwitch, size: "l" },
          on: {
            change: function(val) {
              return (this$1.backgroundSwitch = val)
            }
          }
        })
      ],
      1
    ),
    _vm.backgroundSwitch
      ? _c("div", { staticClass: "form" }, [
          _c("input", {
            staticClass:
              "fix-width color-input-sub radius-m ani-fast size-input-s",
            style: _vm.style,
            attrs: { placeholder: "Custom " },
            domProps: { value: _vm.value },
            on: { change: _vm.onValueChange }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }