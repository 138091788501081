import { firstNames, lastNames } from "./names";
import Vue from "vue";

function ready() {
  let done, end;
  const wait = new Promise((resolve, reject) => {
    done = resolve;
    end = reject;
  });
  return { wait, done, end };
}

const eq = a => b => a == b;

const notEq = a => b => a !== b;

const eqProp = (prop, a) => b => {
  if (b && b[prop]) {
    return b[prop] == a;
  }
  return false;
};

const notEqProp = (prop, a) => b => {
  if (b && b[prop]) {
    return b[prop] !== a;
  }
  return false;
};

const ramdomName = () => {
  const id23 = Math.ceil(Math.random() * 23);
  const id1351 = Math.ceil(Math.random() * 1351);
  return firstNames[id1351].slice(0, 1).toUpperCase() + firstNames[id1351].slice(1) + " " + lastNames[id23];
};

const stateMerge = (state, value, propName, ignoreNull) => {
  if (!value) {
    return;
  }
  if (
    Object.prototype.toString.call(value) === "[object Object]" &&
    (propName == null || state.hasOwnProperty(propName))
  ) {
    const o = propName == null ? state : state[propName];
    for (var prop in value) {
      stateMerge(o, value[prop], prop, ignoreNull);
    }
    return;
  }
  if (!ignoreNull || value !== null) Vue.set(state, propName, value);
};

export { ready, eq, eqProp, notEqProp, notEq, ramdomName, stateMerge };
