<template lang="pug">
  .root.radius-l.shd-l
    input.f-p.bold.input-name.size-input-s.color-input-gray.radius-m.ani-fast(placeholder='name')
    //- this area below is the form for each Type, you should insert different Type editor below and  remove this text
    component.type-editor(is='color-picker-chrome' value='blue')
    .buttons
      button.f-p.bold.size-button-s.color-button-normal.pointer.radius-m.ani-fast Cancel
      button.f-p.bold.size-button-s.color-button-black.pointer.radius-m.ani-fast Save
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.root
  padding 1rem
  background var(--clr-bg)
  // width 16rem
  stack(y, gap: 1rem)
.buttons
  stack(x,  gap: .25rem, arr: stretch)
</style>

<script>
export default {};
</script>
