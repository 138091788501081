<template lang="pug">
  transition(name="popup")
    router-link.proj-thumbnail.radius-l.ani-fast.pointer.shadow-card(:to="{ name: 'ProjEditorIndex', params: { id: projId }}" @click.native.right="openMenu")
      .proj-name.f-title.color-text-2 {{projName}}
      .proj-info.f-p.color-text-3
        | modified 
        span.color-text-2 {{lastModifiedTime}} 
      .proj-info.f-p.color-text-3
        | by 
        span.color-text-2 {{lastModifiedMember}}
</template>
<style lang="stylus" scoped>

@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.proj-thumbnail
  // layout
  padding 1rem
  stack(y, gap: .5rem)
  // color
  background var(--clr-bg)
  &:hover
    &>.icon
      opacity 1
.icon
  opacity 0
  color var(--clr-content-3)
</style>


<script>
export default {
  name: "ProjItem",
  data() {
    return {};
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    openMenu(e) {
      this.$utils.setMenuPoz(e);
      this.$store.commit("setUI", {
        key: "menus",
        value: [
          {
            text: "Delete",
            operation: () => {
              this.$store.dispatch("deleteProject", { id: this.projId });
            }
          }
        ]
      });
    }
  },
  props: {
    projName: {
      type: String,
      default: "{{Project Name}}"
    },
    lastModifiedMember: {
      type: String,
      default: "{{Name}}"
    },
    lastModifiedTime: {
      type: String,
      default: "{{date}}"
    },
    projId: {
      type: String,
      default: ""
    }
  }
};
</script>
