<template lang="pug">
.ruler
  .line
    .dot
    .center
    .dot
  .value-container
    slot
</template>

<style lang="stylus" scoped>
$lineW = 1px
.ruler
  position relative
  &>*
    position absolute

.ruler.x
  .line
    grid-template-columns $lineW 1fr $lineW
    align-items center
  .dot
    width $lineW
    height 100%
  .center
    height $lineW

.ruler.y
  .line
    grid-template-rows $lineW 1fr $lineW
    justify-items center
  .dot
    height $lineW
    width 100%
  .center
    width $lineW

.line
  top 0
  bottom 0
  left 0
  right 0
  display inline-grid
.dot
  background var(--clr-content-3)
.center
  background var(--clr-content-3)

.value-container
  // position
  position absolute
  top 0
  bottom 0
  left 0
  right 0
  // layout
  display inline-grid
  place-content center

</style>

<script>
export default {
  name: "ruler-line"
};
</script>
