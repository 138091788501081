var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "master" },
    [
      _c("bRadioBtn", {
        attrs: { options: _vm.options },
        on: { change: _vm.onTypeChange },
        model: {
          value: _vm.type,
          callback: function($$v) {
            _vm.type = $$v
          },
          expression: "type"
        }
      }),
      _vm.type != "off"
        ? _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "KeyValue",
                { staticClass: "flex", attrs: { label: "Color" } },
                [
                  _c("bBtnColor", {
                    attrs: { value: _vm.color },
                    on: { change: _vm.onColorChange }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "stack-x" },
                [
                  _c(
                    "KeyValue",
                    { attrs: { label: "X" } },
                    [
                      _c("SpaceEditor", {
                        attrs: { value: _vm.xOffset },
                        on: { change: _vm.onxOffsetChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "KeyValue",
                    { attrs: { label: "Y" } },
                    [
                      _c("SpaceEditor", {
                        attrs: { value: _vm.yOffset },
                        on: { change: _vm.onyOffsetChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "KeyValue",
                    { attrs: { label: "Blur" } },
                    [
                      _c("LengthEditor", {
                        attrs: { value: _vm.blur },
                        on: { change: _vm.onBlurChange }
                      })
                    ],
                    1
                  ),
                  _c(
                    "KeyValue",
                    { attrs: { label: "Spread" } },
                    [
                      _c("SpaceEditor", {
                        attrs: { value: _vm.spread },
                        on: { change: _vm.onSpreadChange }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }