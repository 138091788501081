<template lang="pug">
  layout(:root="tree.root" :id="tree.id" :hasChildren="hasChildren" :noFirst="true" v-if="tree")
    //- label slot 的内容
    template(slot="label")
      label-layer(:name="tree.label" :hasChildren="hasChildren" :isFocused="isFocused && !isDrag" :icon="icon")
    //- default slot 的内容
    .slot-list(v-if="isExpand")
      layout(v-for="slot in Object.keys(tree.children)" :key="slot" :root="tree.root" :targetSlot="slot" :id="tree.id" :hasChildren="hasChildren" :isSlotLayout="true")
        //- label slot 的内容
        template(slot="label")
          label-slot(:name="handleSlotName(slot) || slot")
        //- default slot 的内容
        //- 放 slot 里的 layer
        template(v-for="id in tree.children[slot]")
          nodePure(v-if="layerIsPure(layer(id))" :key="id" :tree="layer(id)" )
          node-slots(v-else :key="id" :tree="layer(id)")

</template>

<script>
import layout from "../layout";
import labelLayer from "../label/layer";
import labelSlot from "../label/slot";
import { mapGetters } from "vuex";

export default {
  name: "node-slots",
  components: {
    layout,
    labelLayer,
    labelSlot
  },
  beforeCreate: function() {
    // 处理循环引用
    this.$options.components.nodePure = require("./pure.vue").default;
  },
  props: ["tree"],
  computed: {
    isDrag() {
      return this.$store.state.ui.isDrag;
    },
    hasChildren() {
      return Object.keys(this.tree.children).length >= 1;
    },
    icon() {
      const component = this.$utils.inners.find(c => c.id == this.tree.componentId);
      if (component) {
        return "component/builtin/" + component.icon;
      }
    },
    isFocused() {
      return this.layerId == this.tree.id;
    },
    layerId() {
      return this.$store.state.layer.layerId;
    },
    isExpand() {
      return this.tree && this.tree.isExpand;
    },
    ...mapGetters(["layer"])
  },
  methods: {
    layerIsPure(layer) {
      return layer && layer.children && Object.keys(layer.children).length <= 1;
    },
    handleSlotName(name) {
      if (name.startsWith("lid:")) {
        const layer = this.$store.getters.layer(name.replace("lid:", ""));
        return layer && layer.label;
      }
      return name;
    }
  }
};
</script>
