import { unset_height, unset_width } from "../size/size";

const item = item => {
  const top = parseInt(item.position.align.grid.top, 10);
  const bottom = parseInt(item.position.align.grid.bottom, 10);
  const left = parseInt(item.position.align.grid.left, 10);
  const right = parseInt(item.position.align.grid.right, 10);

  return {
    ...unset_height(),
    ...unset_width(),
    position: "relative",
    gridColumnStart: left,
    gridColumnEnd: right + 1,
    gridRowStart: top,
    gridRowEnd: bottom + 1
  };
};

export default item;
