<template lang="pug">
  div.bento-slot
    slot
</template>

<script>
export default {
  name: "BentoSlot",
  slots: ["default"]
};
</script>
