<template lang="pug">
  .toolbar-item.ani-fast.clip(
    :class="{focus: isFocus}"
  )
    icon.icon(:name="icon")
    .selected.ani.pill
    //- .hint.f-small.pill.shd-m.ani {{hint}}
</template>
<style lang="stylus" scoped>
@require '../../../../../styles/layout/main'
@require '../../../../../styles/design/main'

.toolbar-item
  padding .75rem 1rem
  line-height 0
  color var(--clr-content-2)
  .selected
    position absolute
    top .25rem
    bottom .25rem
    // top 0
    // bottom 0
    left -3px
    width 6px
    background var(--clr-content)

.toolbar-item:hover
  cursor pointer
  background var(--clr-bg-2)
  // color var(--clr-content-3)
.toolbar-item:not(.focus)
  // background var(--clr-primary)
  // color var(--clr-content-reverse)
  .selected
    opacity 0
.toolbar-item.focus
  // background var(--clr-primary)
  // color var(--clr-content-reverse)
  .selected
    opacity 1
    // box-shadow inset 2px 0 0 #000
.icon
  width 1rem
  height 1rem
  // color var(--clr-content-2)

</style>

<script>
export default {
  name: "ToggleButton",
  data() {
    return {
      // 自己是否正被拖动
      // isBeingDragging: false
    };
  },
  props: {
    // which Type of drawer I will open after hit me?
    masterType: {
      type: String,
      default: ""
    },
    // show some info to users when hover me
    hint: {
      type: String,
      default: ""
    },
    // am I opened?
    isFocus: {
      type: Boolean,
      default: false
    },
    // which icon to show
    icon: {
      type: String,
      default: ""
    }
  },
  methods: {
    // dragstart(e) {
    //   this.isBeingDragging = true;
    //   e.dataTransfer.setData("sourceFrom", "master");
    //   e.dataTransfer.setData("sourceIdOrType", this.masterType);
    // },
    // dragend() {
    //   this.isBeingDragging = false;
    // },
    // // fix drop not working
    // preventEvent(e) {
    //   e.dataTransfer.dropEffect = "move";
    //   e.preventDefault()
    //   return false;
    // }
  }
};
</script>
