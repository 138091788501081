var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg pointer-event-on" }, [
    _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "path" }, [
        _c(
          "div",
          { staticClass: "path-node color-input-sub radius-m ani-fast" },
          [
            _c("icon", {
              staticClass: "icon",
              attrs: { name: "component/component" }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedComponentName,
                  expression: "selectedComponentName"
                }
              ],
              staticClass: "input comp-name size-input-s color-text-2 f-p",
              attrs: { size: "12" },
              domProps: { value: _vm.selectedComponentName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.selectedComponentName = $event.target.value
                }
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }