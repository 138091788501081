import { render, staticRenderFns } from "./typography.vue?vue&type=template&id=7910d691&scoped=true&lang=pug&"
import script from "./typography.vue?vue&type=script&lang=js&"
export * from "./typography.vue?vue&type=script&lang=js&"
import style0 from "./typography.vue?vue&type=style&index=0&id=7910d691&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7910d691",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('7910d691', component.options)
    } else {
      api.reload('7910d691', component.options)
    }
    module.hot.accept("./typography.vue?vue&type=template&id=7910d691&scoped=true&lang=pug&", function () {
      api.rerender('7910d691', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form_element/typography/typography.vue"
export default component.exports