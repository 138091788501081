var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("v-gravatar", {
        staticClass: "gravatar oval ani-fast",
        attrs: {
          email: _vm.email,
          rating: "x",
          "default-img": "identicon",
          hash: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }