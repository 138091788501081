import { set_height, set_width } from "../size/size";
import merge from "lodash.merge";

const item = layer => {
  const position = layer.position.align;
  const size = layer.position.size;

  const t = parseInt(position.previewArea.top, 10);
  const r = parseInt(position.previewArea.right, 10);
  const b = parseInt(position.previewArea.bottom, 10);
  const l = parseInt(position.previewArea.left, 10);

  return merge(set_height(size.y.default, size.y.max, size.y.min), set_width(size.x.default, size.x.max, size.x.min), {
    top: t + "px",
    right: r + "px",
    bottom: b + "px",
    left: l + "px",
    position: "absolute"
  });
};

export default item;
