var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prop-folder radius-m shd-m ani" },
    [
      _vm.label
        ? _c("div", { staticClass: "f-title color-text" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }