var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toolbar-item ani-fast clip",
      class: { focus: _vm.isFocus }
    },
    [
      _c("icon", { staticClass: "icon", attrs: { name: _vm.icon } }),
      _c("div", { staticClass: "selected ani pill" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }