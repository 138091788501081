// gird 布局

const container = props => {
  const xCount = props.x.count;
  const xSize = props.x.size;
  const xArr = props.x.arrange;
  const xGap = props.x.gap;

  const yCount = props.y.count;
  const ySize = props.y.size;
  const yArr = props.y.arrange;
  const yGap = props.y.gap;

  let cellWidth, cellHeight;

  if (xArr === "stretch") {
    cellWidth = "1fr";
  } else {
    cellWidth = xSize;
  }

  if (yArr === "stretch") {
    cellHeight = "1fr";
  } else {
    cellHeight = ySize;
  }
  return {
    display: "inline-grid",
    gridTemplateColumns: `repeat(${xCount}, ${cellWidth})`,
    gridTemplateRows: `repeat(${yCount}, ${cellHeight})`,
    gridColumnGap: xGap,
    gridRowGap: yGap,
    justifyContent: xArr,
    alignContent: yArr
  };
};

export default container;
