<template lang="pug">
.master
  .label.f-p.color-text-2 Align
  NumberEditor.fix-width.top(
    :min="1"
    :value="align.top"
    @change="onTopChange"
    placeholder="Top"
  )
  .middle
    NumberEditor.fix-width(
      :min="1"
      :value="align.left"
      @change="onLeftChange"
      placeholder="Left" 
    )
    .center.flex.border.radius-s
    NumberEditor.fix-width(
      :min="1"
      :value="align.right"
      @change="onRightChange"
      placeholder="Right"
    )
  NumberEditor.fix-width.bottom(
    :min="1"
    :value="align.bottom"
    @change="onBottomChange"
    placeholder="Bottom"
  )
</template>

<style lang="stylus" scoped>
@require '../../../../styles/layout/main'
@require '../../../../styles/design/main'

.master
  stack(y, gap: .25rem)
.fix-width
  width 5rem
.middle
  stack(x, gap: .25rem)
.border
  box-shadow inset 0 0 0 1px var(--clr-bg-3)
.top
.bottom
  align-self center
</style>

<script>
import NumberEditor from "@/components/form_element/number.vue";

export default {
  name: "gridEditor",
  components: {
    NumberEditor
  },
  props: {
    align: {
      type: Object,
      default() {
        return {
          bottom: 1,
          left: 1,
          right: 1,
          top: 1
        };
      }
    }
  },
  methods: {
    onTopChange(v) {
      return this.onChange(v, "align.grid.top");
    },
    onLeftChange(v) {
      return this.onChange(v, "align.grid.left");
    },
    onRightChange(v) {
      return this.onChange(v, "align.grid.right");
    },
    onBottomChange(v) {
      return this.onChange(v, "align.grid.bottom");
    },
    onChange(value, param) {
      return this.$emit("change", value, param);
    }
  }
};
</script>
