<template lang="pug">
  <color-picker-chrome :value="color" @input="updateValue" />
</template>
<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

</style>

<script>
// import { mapState, mapGetters } from "vuex";
// import EditorBus from "@/eventBus/EitorBus.js";

export default {
  name: "colorPicker",
  props: {
    value: {
      type: String,
      default: "#000000"
    }
  },
  data() {
    return {
      color: this.value
    };
  },
  computed: {},
  methods: {
    closeColorPicker() {
      this.$store.commit("setUI", {
        key: "colorPicker",
        value: {
          visible: false,
          value: this.color,
          top: 0,
          left: 0
        }
      });
    },
    updateValue(value) {
      this.color = value;
      this.$ui.colorPicker.value = value.hex8;
    }
  }
};
</script>
