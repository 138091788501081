<template lang="pug">
transition(name="card")
  .item.ani.radius-m.color-text-2.clip(
    :class="{isBeingDragging: isBeingDragging, focus: isFocus}"
    draggable
    @dragstart="dragstart"
    @drag="drag"
    @dragover="preventEvent"
    @dragend="dragend"
    @click.right="showMenus"
  )
    .basic.ani
      .icon-container
        icon.icon(:name="icon")
      .name.f-p.ellipsis.flex {{label}}
      .label.f-p.color-text-3(v-if="showProgress") {{progress}}%
      .label.f-p.color-text-3(v-else-if="shortcut") {{shortcut}}
    .actions.abs.ani-fast
      .action-button.insert.shd-m.size-button-s.radius-m.ani-fast.pointer(@click="insert" v-if="selectedComponent") insert
</template>
<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

// 初始样式
.item
  position relative
  &>.basic
    width 100%
    stack(x, gap: .5rem, ali:center)
    padding .75rem
    padding-right .5rem
    padding-left 0
    background var(--clr-bg)

// 初始样式 - 正在编辑时
.item
  &.focus
    color var(--clr-primary)
    .actions
      display none

// 初始样式 - 不在编辑时
.item:not(.focus)
  &>.actions
    opacity 0

// hover 时
.item:not(.focus):hover
  // 内容缩进
  &>.basic
    cursor grab
    color var(--clr-content-3)
    padding-left .25rem
  // 显示 action
  &>.actions
    opacity 1

// 在其余位置按下
.item:not(.focus)
  &>.basic:active
    // 内容进一步缩进
    padding-left .75rem
    background var(--clr-bg-3)
    // 隐藏 action
    &~.actions
      opacity 0

// 拖拽时
.item.isBeingDragging:not(.focus)
  // 内容进一步缩进
  &>.basic
    padding-left .75rem
    background var(--clr-bg-3)
  // 隐藏 action
  &>.actions
    opacity 0

// basic
.basic
  &>.icon-container
    line-height 0
    text-align center
    &>.icon
      width 1rem
      height 1rem

// actions
.actions
  // position
  pos-abs(x:end, y: center, r:.5rem)
  // layout
  stack(x, gap: .5rem)
  &>.action-button
    background var(--clr-primary)
    color var(--clr-content-reverse)
    &:hover
      background var(--clr-primary-dark-1)

</style>

<script>
import { mapGetters } from "vuex";
import builtInComp from "@/components/preview_area/built-in component/builtIn.js";

import throttle from "lodash.throttle";

export default {
  name: "ToggleButton",
  data() {
    return {
      // 自己是否正被拖动
      isBeingDragging: false
    };
  },
  props: {
    // editable: {
    //   type: Boolean,
    //   default: false
    // },
    id: {
      type: String,
      default: ""
    },
    masterType: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    isFocus: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    progress: {
      type: Number,
      default: 0
    },
    shortcut: {
      type: String,
      default: ""
    }
    // design: {
    //   type: Boolean,
    //   default: false
    // }
  },
  computed: {
    showProgress() {
      if (this.design) {
        return false;
      }
      if (this.progress > 0 && this.progress < 100) {
        return true;
      }
      return false;
    },
    ...mapGetters(["selectedComponent"])
  },
  methods: {
    dragstart(e) {
      if (this.isFocus) {
        e.preventDefault();
        return;
      }
      // 清空默认的样式
      this.$utils.resetDropImage(e);
      this.isBeingDragging = true;
      /* eslint-disable-next-line */
      this.$store.commit("dragThumbnailLabel", {
        icon: this.icon.length > 0 ? this.icon : "component/component",
        label: this.label
      });
      this.$store.commit("setUI", {
        key: "isDrag",
        value: true
      });
      e.dataTransfer.setData("from", "master");
      e.dataTransfer.setData("label", this.label);
      e.dataTransfer.setData("design", this.design);
      if (!this.design && !builtInComp.map(c => c.id).includes(this.id)) {
        // 不是设计组件是代码
        e.dataTransfer.setData("componentId", "code" + this.id);
      } else {
        e.dataTransfer.setData("componentId", this.id);
      }
      this.$store.commit("mousePozToggle", true);
    },
    dragend() {
      this.isBeingDragging = false;
      this.$store.commit("mousePozToggle", false);
      this.$store.commit("setUI", {
        key: "isDrag",
        value: false
      });
    },
    // fix drop not working
    preventEvent(e) {
      // e.dataTransfer.dropEffect = "move";
      e.preventDefault();
      return false;
    },
    drag: throttle(function(e) {
      let x = e.clientX;
      let y = e.clientY;
      this.$store.commit("mousePozSet", { x, y });
    }, 0), // 节流会有卡顿
    // 编辑按钮已废弃，注释此方法（这种方法应该抽象出来而不是写在这
    // edit() {
    //   this.$store.dispatch("setSelectedComponentId", {
    //     id: this.id
    //   });
    //   this.$store.commit("setUI", {
    //     key: "currentTab",
    //     value: "components"
    //   });
    //   this.$router.push({
    //     name: "ProjEditorDetail",
    //     params: {
    //       id: this.$store.state.project.selectedProjectId,
    //       componentId: this.id
    //     }
    //   });
    // },
    showMenus(e) {
      if (!this.editable) {
        return;
      }
      this.$utils.setMenuPoz(e);
      this.$store.commit("setUI", {
        key: "menus",
        value: [
          {
            text: "Delete",
            operation: () => {
              this.$store.dispatch("deleteComponent", { id: this.id });
            }
          }
        ]
      });
    },
    insert() {
      this.$store.dispatch("insertLayer", {
        label: this.label,
        componentId: this.id
      });
    }
  }
};
</script>
