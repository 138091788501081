<template lang="pug">
  .component-editor(v-shortkey="['meta', '.']" @shortkey="switchEditorUI")
    //- 布局预览层
    .preview.abs.full-xy
      previewArea(:showEditorUI="showEditorUI")
      //- workspace(
      //-   v-if="previewContainer == 'workspace'"
      //-   :workspaceLayer="workspaceLayer"
      //-   :showEditorUI="showEditorUI")
      //- componentPreview(
      //-   v-else="previewContainer == 'component'"
      //-   :showEditorUI="showEditorUI")
    //- editor form
    .stack-y.abs.full-xy.pointer-event-off
      //- top (comp nav)
      //- todo: workspace 下隐藏 compnav
      template(v-show="")
        transition(name='fade-top')
          CompNav.ani
      //- bottom
      .flex.bottom
        //- left
        transition(name='fade-left')
          .left.ani(v-show="showEditorUI")
            Toolbar
            Layertree
        //- right
        transition(name='fade-right')
          PropPanel.right.ani(v-show="showEditorUI")
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.component-editor
  // layout
  off()
.full-xy
  top 0
  left 0
  width 100%
  height 100%

.stack-y
  stack(y)
.bottom
  // layout
  stack(x, arr: between)
  // pointer
  pointer-events none
.left
  stack(x)
.right
  width 14rem
.preview
  display inline-grid
.pointer-event
  pointer-events auto
.fade-left-enter, .fade-left-leave-to
  opacity 0
  transform translateX(-2rem)

.fade-right-enter, .fade-right-leave-to
  opacity 0
  transform translateX(2rem)

.fade-top-enter, .fade-top-leave-to
  opacity 0
  // transform translateY(-2rem)
</style>

<script>
import { mapGetters } from "vuex";
// comp nav
import CompNav from "@/components/component_editor/component/nav.vue";
// master browser (component library)
import Toolbar from "@/components/component_editor/component/toolbar/toolbar.vue";
// instance browser (layer tree)
import Layertree from "@/components/layertree/layertree.vue";
// instance props viewer
import PropPanel from "@/components/prop_panel/prop_panel.vue";
// preview area
import previewArea from "@/components/preview_area/previewArea.vue";

export default {
  components: {
    Toolbar,
    Layertree,
    PropPanel,
    previewArea,
    CompNav
  },
  data() {
    return {
      showEditorUI: true
    };
  },
  watch: {
    ["$route"](route) {
      const id = route.params.componentId;
      if (!id) return;
      this.$store.commit("setSelectedComponentId", {
        id
      });
      const comp = this.$store.getters.component(id);
      if (comp) {
        this.$store.commit("setLayerId", comp.root);
      }
      this.$forceUpdate();
    }
  },
  methods: {
    switchEditorUI() {
      this.showEditorUI = !this.showEditorUI;
    }
  },
  props: {
    previewContainer: {
      type: String, // "workspace" or "component"
      default: "component"
    }
  },
  computed: {
    ...mapGetters(["selectedProject"]),
    workspaceLayer() {
      return this.selectedProject && this.selectedProject.workspace;
    }
  }
};
</script>
