const inners = [
  {
    id: "box",
    label: "Box",
    shortkey: "r",
    icon: "box"
  },
  // {
  //   id: "bento-layout-grid",
  //   label: "Grid",
  //   shortkey: "g",
  //   icon: "grid"
  // },
  // {
  //   id: "bento-layout-stack",
  //   label: "Stack",
  //   shortkey: "k",
  //   icon: "stack-y"
  // },
  // {
  //   id: "box",
  //   label: "Box",
  //   shortkey: "r",
  //   icon: "box"
  // },
  {
    id: "bento-txt",
    label: "Text",
    shortkey: "t",
    icon: "txt"
  },
  {
    id: "bento-image",
    label: "Image",
    shortkey: "p",
    icon: "image"
  },
  {
    id: "bento-scroll",
    label: "Scroll",
    shortkey: "s",
    icon: "scroll"
  }
  // {
  //   id: "bento-slot",
  //   label: "Slot",
  //   shortkey: "x",
  //   icon: "slot"
  // }
];

function isInnerComponent(name) {
  return inners.map(c => c.id).includes(name);
}

const bentoLayoutList = ["bento-layout", "bento-layout-grid", "bento-layout-stack", "workspace", "componentpreview"];

export { isInnerComponent, bentoLayoutList };

export default inners;
