<template lang="pug">
  transition(name='overlay-fade')
    .overlay
      //- 半透明阴影，可以点击关闭浮层
      .shadow.abs(:class="{visible: shadowVisible}" @click.self="onClick")
      slot
</template>

<style lang="stylus" scoped>
@require '~@/styles/design/main.styl'
@require '~@/styles/layout/main.styl'

.overlay
  off()
  transition all .2s
.shadow
  pos-abs(x:stretch, y:stretch)
  width 100%
  height 100%
  // aniamtion
  transition .35s ease-in-out
  &.visible
    background alpha(black, 25%)
  &:not(.visible)
    background transparent

// aniamtion class
.overlay-fade-enter
.overlay-fade-leave-to
  & > .shadow
    opacity 0
  & > .entity >>> > :not(.wtf)
    opacity 0
    transform scale(1.05)
.overlay-fade-enter-to
.overlay-fade-leave
  & > .shadow
    opacity 1
  & > .entity >>> > :not(.wtf)
    opacity 1
</style>

<script>
export default {
  props: {
    shadowVisible: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    shadowClickable: {
      type: Boolean,
      default: false
    },
    close: Function
  },
  methods: {
    onClick() {
      if (typeof this.close === "function") return this.close();
    }
  }
};
</script>
