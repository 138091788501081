var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "insert ani-fast",
      class: { dropin: _vm.isDropIn },
      on: {
        dragover: function($event) {
          _vm.dragover()
        },
        dragleave: function($event) {
          _vm.dragleave()
        },
        drop: _vm.drop
      }
    },
    [_c("div", { staticClass: "line pill" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }