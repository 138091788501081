var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedNotUndefined
    ? _c(
        "div",
        {
          staticClass: "dragger ani",
          on: {
            mousedown: function($event) {
              _vm.start("move", $event)
            }
          }
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isStart && !_vm.isWorkspaceRootLayer,
                    expression: "!isStart && !isWorkspaceRootLayer"
                  }
                ],
                staticClass: "content"
              },
              [
                _c("edge", {
                  staticClass: "y left",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("left", $event)
                    }
                  }
                }),
                _c("edge", {
                  staticClass: "y right",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("right", $event)
                    }
                  }
                }),
                _c("edge", {
                  staticClass: "x top",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("top", $event)
                    }
                  }
                }),
                _c("edge", {
                  staticClass: "x bottom",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("bottom", $event)
                    }
                  }
                }),
                _c("corner", {
                  staticClass: "left top",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("lefttop", $event)
                    }
                  }
                }),
                _c("corner", {
                  staticClass: "right top",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("righttop", $event)
                    }
                  }
                }),
                _c("corner", {
                  staticClass: "left bottom",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("leftbottom", $event)
                    }
                  }
                }),
                _c("corner", {
                  staticClass: "right bottom",
                  nativeOn: {
                    mousedown: function($event) {
                      $event.stopPropagation()
                      _vm.start("rightbottom", $event)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isStart,
                expression: "isStart"
              }
            ],
            staticClass: "cover"
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }