<template lang="pug">
#app.f-p.color-light(@contextmenu.prevent)
  router-view.full-xy
  overlay.full-xy.abs(v-if="ui.teamCreator" center @click.native="closeMenus" shadowVisible shadowClickable)
    teamCreator.center-xy.abs
  //- context menu
  overlay.full-xy.abs(v-if="ui.menus && ui.menus.length > 0" @click.native="closeMenus" @click.right.native="closeMenus" )
    contextMenu.abs
  //- image selector
  overlay.full-xy.abs(v-if="ui.imageSelector" :close="closeImageSelector" shadowVisible shadowClickable)
    imageSelector.full-xy-90.abs
  //- color picker
  overlay.full-xy.abs(v-if="ui.colorPicker.visible" :close="closeColorPicker")
    .position(
      :style="colorPositionStyle"
    )
      colorPicker.picker(
        :value="ui.colorPicker.value"
      )
  //- ???
  overlay.full-xy.abs(v-if="ui.optionSelector.visible")  
    optionSelector.abs(
      :options="ui.optionSelector.options"
      :value="ui.optionSelector.value"
      :active="ui.optionSelector.active"
      :eventName="ui.optionSelector.eventName"
      :style="optionSelectorStyle"
    )
  dragThumbnail
</template>

<style lang="stylus">
@require './styles/reset'
@require './styles/design/main.styl'
@require './styles/layout/main.styl'

body
  font-family var(--typeface-default)
  overflow hidden
  color var(--clr-content-2)
</style>

<style lang="stylus" scoped>
@require './styles/design/main.styl'
@require './styles/layout/main.styl'


.dev-position-temp
  top 100px
  left 200px
  width 200px
  position absolute

#app
  width 100%
  height 100%
  overflow hidden
  position absolute
  user-select none
  perspective 1000px

.feedback
  position absolute
  left 1rem
  bottom 1rem
  padding .5rem .75rem
  cursor pointer
  background var(--clr-bg)
  &:hover
    transform scale(1.1)
  // width 2rem
  // height 2rem
.center-xy
  pos-abs()

.full-xy
  width 100%
  height 100%
  top 0
  left 0
.full-xy-90
  width calc(100% - 2rem)
  height calc(100% - 2rem)
  min-width 90%
  min-height 90%
  top 1rem
  left 1rem

.position
  position fixed
  width max-content
  height max-content

</style>

<script>
// import contextMenu from "@/components/contextmenu/contextMenu.vue";
import tokenEditor from "@/components/design_token/DesignTokenEditor.vue";
import teamCreator from "@/components/team_home/creator.vue";
import overlay from "@/components/others/overlay.vue";
import contextMenu from "@/components/others/contextmenu/contextMenu.vue";
import dragThumbnail from "@/components/others/dragThumbnail.vue";
import optionSelector from "@/components/form_element/option_selector/option_selector.vue";
import imageSelector from "@/components/others/imageSelector.vue";
import colorPicker from "@/components/others/colorPicker.vue";

import { mapState } from "vuex";

export default {
  name: "app",
  components: {
    tokenEditor,
    teamCreator,
    overlay,
    dragThumbnail,
    contextMenu,
    optionSelector,
    imageSelector,
    colorPicker
  },
  created() {
    // 缓存初始化
    this.$store.dispatch("fetchWebFonts");
    this.$utils.emptyPng();
  },
  computed: {
    ...mapState(["ui"]),
    optionSelectorStyle() {
      return {
        top: `${this.ui.optionSelector.top}px`,
        left: `${this.ui.optionSelector.left}px`,
        width: `${this.ui.optionSelector.width}px`,
        height: `${this.ui.optionSelector.height}px`
      };
    },
    colorPositionStyle() {
      return {
        top: this.ui.colorPicker.top,
        bottom: this.ui.colorPicker.bottom,
        left: this.ui.colorPicker.left,
        right: this.ui.colorPicker.right
      };
    }
  },
  methods: {
    closeColorPicker() {
      this.$store.commit("setUI", {
        key: "colorPicker",
        value: {
          ...this.ui.colorPicker,
          visible: false
        }
      });
    },
    closeImageSelector() {
      this.$store.commit("setUI", {
        key: "imageSelector",
        value: false
      });
    },
    closeMenus() {
      this.$store.commit("setUI", {
        key: "menus",
        value: []
      });
    }
  }
};
</script>
