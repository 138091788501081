var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "master radius-m clip pointer", on: { click: _vm.onClick } },
    [
      _c("div", { staticClass: "preview", style: _vm.style }),
      _c("div", { staticClass: "hover abs ani-fast f-title" }, [
        _vm._v("Update")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }