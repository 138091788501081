// 得到 css value 的 default size

function set_el_default_size(a, type) {
  // auto -> auto
  if (a == "auto") {
    if (type == "w") {
      return "max-content";
    }
    return "auto";
    // *% -> *%
  } else if (/%/gi.test(a)) {
    return a;
    // 100 -> 100px
  } else if (Number.isInteger(a) || /\d+/.test(a)) {
    return `${a}px`;
  }
}

// 得到 css value 的 minmax size

function set_el_minmax_size(a, type) {
  if (a == "" || a == "off") {
    return "unset";
  } else {
    return set_el_default_size(a, type);
  }
}

// 生成 css props -- width

export function set_width(def, max, min) {
  return {
    width: set_el_default_size(def, "w"),
    "max-width": set_el_minmax_size(max, "w"),
    "min-width": set_el_minmax_size(min, "w")
  };
}

export function unset_width() {
  return {
    width: "unset",
    "max-width": "unset",
    "min-width": "unset"
  };
}

// 生成 css props -- height

export function set_height(def, max, min) {
  return {
    height: set_el_default_size(def, "h"),
    "max-height": set_el_minmax_size(max),
    "min-height": set_el_minmax_size(min)
  };
}

export function unset_height() {
  return {
    height: "unset",
    "max-height": "unset",
    "min-height": "unset"
  };
}
