var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dt--list-item--font" }, [
      _c("div", { staticClass: "name f-title" }, [_vm._v("Font Name")]),
      _c(
        "div",
        { staticClass: "preview radius-l shadow-card ani-fast pointer" },
        [_c("div", { staticClass: "text" }, [_vm._v("Aa")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }