var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("bDropSelector", {
    attrs: {
      options: ["Inherit", "Break", "Ellipsis", "Hidden", "Visible"],
      value: _vm.value
    },
    on: {
      change: function($event) {
        _vm.$emit("change", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }