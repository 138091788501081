import { set_width, set_height } from "../size/size";
import merge from "lodash.merge";

const item = layer => {
  const size = layer.position.size;

  return merge(set_height(size.y.default, size.y.max, size.y.min), set_width(size.x.default, size.x.max, size.x.min));
};

export default item;
