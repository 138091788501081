<template lang="pug">
  transition(name="grow")
    .mouse-postion(v-if="state.isDragging && notZero" ref="mousePoz" :style="state.style")
      .placeholder.shd-l.size-button-l.radius-l(v-if="state.label.length > 0")
        .icon-container(v-if="state.icon.length")
          keep-alive
            icon.icon(:name="state.icon")
        .name.f-p.ellipsis.flex {{state.label}}
      slot(v-else)
        .placeholder.shd-l.size-button-l.radius-l
</template>

<style lang="stylus" scoped>
.mouse-postion
  position fixed
  pointer-events none
  will-change: top, left;
  transform-origin top left
  &>*
    position absolute
    top 0
    left 1rem

.placeholder
  background white
  color black
  width max-content
  display flex

// animation
.grow-enter-active
  transition opacity .25s ease-out
.grow-leave-active
  transition opacity .1s ease-in, transform .1s ease-in
.grow-enter
.grow-leave-to
  opacity 0
.grow-leave-to
  transform scale(.9)

.icon-container
  line-height 0
  text-align center
  margin-right .5rem
.icon
  width 1rem
  height 1rem
</style>

<script>
export default {
  name: "drag-thumbnail",
  data() {
    return {
      isDragging: false,
      icon: "",
      label: "",
      style: {
        top: 0,
        left: 0
      }
    };
  },
  computed: {
    state() {
      return this.$store.state.ui.dragThumbnail;
    },
    // 避免出现闪现，初始 0 位置不显示
    notZero() {
      return !(this.state.style.top == 0 && this.state.style.left == 0);
    }
  },
  mounted() {
    this.$root.$mousePoz = this.$refs.mousePoz;
  }
};
</script>
