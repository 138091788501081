// todo 不能工作 ing
<template lang="pug">
  .bento-input.radius--m.ani-fast(
    @touchstart="hoverStart"
    @mouseenter="hoverStart"
    @mouseleave="hoverEnd"
    @touchend="hoverEnd"
    @click="onClick"
    :class="[color_class, size_class, {hover: hover}, {focus: focus}]"
    )
    //- 前后 slot 用于插入 icon/text 等内容
    slot(name="before") 
    input.input.flex(
      :value="value"
      @input="$emit('input', $event.target.value)"
      )
    slot(name="after")
</template>

<script>
export default {
  name: "bento-button",
  data() {
    return {
      hover: false,
      focus: true,
      value: ""
    };
  },
  methods: {
    hoverStart() {
      this.hover = true;
    },
    hoverEnd() {
      this.hover = false;
    },
    onClick() {
      this.focus = true;
    }
  },
  props: {
    color: {
      type: String,
      default: "primary" // dark, gray, light, danger, primary
    },
    size: {
      type: String,
      default: "m" // s, m, l
    }
  },
  computed: {
    color_class() {
      return `clr--${this.color}`;
    },
    size_class() {
      return `size--${this.size}`;
    }
  }
};
</script>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.bento-input
  stack(x)
  cursor text
.input
  -webkit-tap-highlight-color transparent
  tap-highlight-color transparent
  background transparent

// color
.clr-state--gray
  background var(--clr-bg-2)
  color var(--clr-content-2)
.clr-state--lightgray
  background var(--clr-bg-3)
  color var(--clr-content-2)
.clr-state--focus
  background var(--clr-bg)
  box-shadow inset 0 0 0 2px var(--clr-shadow-2)

.clr--primary
  &>.input::placeholder
    color var(--clr-content-3)
  &:not(.focus)
    @extend .clr-state--gray
    &.hover
      @extend .clr-state--lightgray

// size
.size--s
  &>.input
    padding .25rem .5rem
    @extend .f-p
    // @extend .bold
  &.focus
    box-shadow inset 0 0 0 2px var(--clr-shadow-2)
.size--m
  &>.input
    padding .5rem
    @extend .f-title
  &.focus
    box-shadow 0 0 0 3px var(--clr-shadow-2)
.size--l
  &>.input
    padding .75rem
    @extend .f-title
  &.focus
    box-shadow 0 0 0 3px var(--clr-shadow-2)

</style>
