<template lang="pug">
  .master
    //- .type
      //- .flex.f-title.color-text Shadow
      //- b-switcher(:value="shadowSwitch" @change="val => this.shadowSwitch = val" size="l")
    bRadioBtn(v-model="type" :options="options" @change="onTypeChange")
    .form(v-if="type != 'off'")
      KeyValue.flex(label="Color")
        bBtnColor(:value="color" @change="onColorChange")
          //- bBtnColor
        //- KeyValue.flex(label="Inset")
        //-   Switcher(v-model="inset")
      .stack-x
        KeyValue(label="X")
          SpaceEditor(:value="xOffset" @change="onxOffsetChange")
        KeyValue(label="Y")
          SpaceEditor(:value="yOffset" @change="onyOffsetChange")
        KeyValue(label="Blur")
          LengthEditor(:value="blur" @change="onBlurChange")
        KeyValue(label="Spread")
          SpaceEditor(:value="spread" @change="onSpreadChange")
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.master
  stack(y, gap: .5rem)
.stack-x
  display inline-grid
  grid-template-columns repeat(4, 1fr)
  grid-column-gap .25rem
.title
  stack(x, gap:.5rem)
.form
  stack(y, gap: .5rem)

</style>

<script>
import KeyValue from "@/components/prop_panel/component/label.vue";
import SpaceEditor from "@/components/form_element/space.vue";
import Switcher from "@/components/form_element/boolean/switcher.vue";
import Color from "@/components/form_element/color.vue";
import LengthEditor from "@/components/form_element/length.vue";
export default {
  name: "ShadowEditor",
  model: {
    prop: "value",
    event: "change"
  },
  components: {
    KeyValue,
    SpaceEditor,
    Switcher,
    Color,
    LengthEditor
  },
  props: {
    value: {
      type: Object
    },
    param: String
  },
  watch: {
    value(v) {
      (this.inset = v.inset),
        (this.xOffset = v.x),
        (this.yOffset = v.y),
        (this.blur = v.blur),
        (this.spread = v.spread),
        (this.color = v.color);
      this.handleType();
    }
  },
  data() {
    return {
      type: "off",
      options: [
        { label: "Off", value: "off" },
        { label: "Outside", value: "outside" },
        { label: "Inside", value: "inside" }
      ],
      inset: this.value.inset,
      xOffset: this.value.x,
      yOffset: this.value.y,
      blur: this.value.blur,
      spread: this.value.spread,
      color: this.value.color
    };
  },
  methods: {
    onColorChange(v) {
      this.color = v;
      this.upload();
    },
    onTypeChange(type) {
      this.type = type;
      this.xOffset = 0;
      this.yOffset = 2;
      this.spread = 0;
      this.blur = 8;
      this.color = "#0000001A";
      if (type == "off") {
        this.xOffset = 0;
        this.yOffset = 0;
        this.spread = 0;
        this.blur = 0;
        this.upload();
        return;
      }
      if (type == "outside") {
        this.inset = false;
      } else {
        this.inset = true;
      }
      this.upload();
    },
    isPixel(value) {
      const pixelRegx = /^\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    onxOffsetChange(v) {
      this.xOffset = parseFloat(v);
      this.upload();
    },
    onyOffsetChange(v) {
      this.yOffset = parseFloat(v);
      this.upload();
    },
    onBlurChange(v) {
      this.blur = parseFloat(v);
      this.upload();
    },
    onSpreadChange(v) {
      this.spread = parseFloat(v);
      this.upload();
    },
    upload() {
      const val = {
        x: this.xOffset,
        y: this.yOffset,
        color: this.color,
        spread: this.spread,
        blur: this.blur,
        inset: this.inset
      };
      this.$emit("change", val, this.param);
    },
    handleType() {
      if (this.value.x == 0 && this.value.y == 0 && this.value.spread == 0 && this.value.blur == 0) {
        this.type = "off";
        return;
      }
      if (this.inset) {
        this.type = "inside";
      } else {
        this.type = "outside";
      }
    }
  },
  created() {
    this.handleType();
  }
};
</script>
