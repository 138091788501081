<template lang="pug">
  .color-editor 
    bInputSelector(:value="inputValue" @change="inputChange" :defaultOptions="defaultOptions")
    bBtnColor.color-picker(v-if="inputValue == 'custom'" :value="value" @change="change")
</template>

<style lang="stylus" scoped>
.color-editor *
  width 100%
.color-picker
  margin-top .5rem
</style>


<script>
export default {
  name: "color-editor",
  props: ["value", "param"],
  model: {
    prop: "value",
    event: "change"
  },
  watch: {
    value(newValue) {
      this.inputValue = this.handleValue(newValue);
    }
  },
  data() {
    const param = this.param || "";
    return {
      inputValue: this.handleValue(this.value),
      defaultOptions: [param.toLowerCase().includes("textcolor") ? "inherit" : null, "transparent", "custom"].filter(
        Boolean
      )
    };
  },
  methods: {
    handleValue(newValue) {
      let value = "custom";
      if (newValue == "inherit" || newValue == "transparent") {
        value = newValue;
      }
      return value;
    },
    inputChange(v) {
      if (v == "custom") {
        this.inputValue = "custom";
        this.$emit("change", "#fff", this.param);
        return;
      }
      this.inputValue = v;
      this.change(v);
    },
    change(v) {
      this.$emit("change", v, this.param);
    }
  }
};
</script>
