var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer-container" },
    [
      _vm.drawerType == "External"
        ? _c("External", { staticClass: "drawer" })
        : _vm.drawerType == "Internal"
          ? _c("Internal", { staticClass: "drawer" })
          : _c("div", { staticClass: "bug radius-m" }, [
              _vm._v("Congrats! You've found a bug! Please tell us.")
            ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }