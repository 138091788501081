import Firestore from "./init.js";
import { getCurrentUser } from "@/services/init.js";

const firestoreTeamsInst = new Firestore({ collectionName: "teams" });

function teamsFilter(data) {
  const user = getCurrentUser();
  return data.filter(team => team && team.members && team.members.map(i => i.uid).includes(user.uid));
}

export function list() {
  return firestoreTeamsInst.list(teamsFilter);
}

export async function create(name) {
  return await firestoreTeamsInst.create({
    id: Math.random()
      .toString(36)
      .substr(2),
    name,
    members: [getCurrentUser()],
    editable: true,
    projectIds: [],
    extenalProjectIds: [] // Use empty array to avoid data differences in different environments
  });
}

export async function update(team) {
  return firestoreTeamsInst.update({
    id: team.id,
    name: team.name,
    members: team.members,
    editable: team.editable,
    projectIds: team.projectIds,
    extenalProjectIds: team.extenalProjectIds
  });
}
