<template lang="pug">
  .bento-button.ani-fast.pointer.radius--m.b-layer(
    @touchstart="hoverStart"
    @mouseenter="hoverStart"
    @mouseleave="hoverEnd"
    @touchend="hoverEnd"
    :class="[color_class, size_class, {hover: hover}]"
    )
      slot
</template>

<script>
export default {
  name: "bento-button",
  data() {
    return {
      hover: false
    };
  },
  methods: {
    hoverStart() {
      this.hover = true;
    },
    hoverEnd() {
      this.hover = false;
    }
  },
  props: {
    color: {
      type: String,
      default: "dark" // dark, gray, light, danger, primary
    },
    size: {
      type: String,
      default: "m" // s, m, l
    }
  },
  computed: {
    color_class() {
      return `clr--${this.color}`;
    },
    size_class() {
      return `size--${this.size}`;
    }
  }
};
</script>
<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.bento-button
  -webkit-tap-highlight-color transparent
  tap-highlight-color transparent
  stack(x, gap:.5rem)

// color
.clr-state--dark
  background var(--clr-bg-reverse)
  color var(--clr-content-reverse)
.clr-state--gray
  background var(--clr-bg-2)
  color var(--clr-content-2)
.clr-state--lightgray
  background var(--clr-bg-3)
  color var(--clr-content-2)
.clr-state--light
  background var(--clr-bg)
  color var(--clr-content-2)
.clr-state--danger
  background var(--clr-bg-danger)
  color var(--clr-content-reverse)
.clr-state--primary
  background var(--clr-primary)
  color var(--clr-content-reverse)
.clr-state--primary-dark
  background var(--clr-primary-dark-1)
  color var(--clr-content-reverse)

.clr--primary // blue
  @extend .clr-state--primary
  @extend .shd-s
  &.hover
    @extend .clr-state--primary-dark
.clr--dark // black
  @extend .clr-state--dark
  &.hover
    @extend .clr-state--primary
.clr--gray // gray
  @extend .clr-state--gray
  &.hover
    @extend .clr-state--lightgray
.clr--light // light
  @extend .clr-state--light
  &.hover
    @extend .clr-state--gray
.clr--danger // hover red
  @extend .clr-state--gray
  &.hover
    @extend .clr-state--danger

// size
.size--s
  padding .25rem .5rem
  text-align center
  @extend .f-p
  // @extend .bold

.size--m
  padding .5rem 1rem
  text-align center
  @extend .f-title

.size--l
  padding .75rem 1.5rem
  text-align center
  @extend .f-title
</style>
