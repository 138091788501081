import * as size from "../size/size";

const mainSet = (item, container) => {
  const sizeX = item.position.size.x;
  const sizeY = item.position.size.y;

  if (container.direction === "x") {
    return size.set_width(sizeX.default, sizeX.max, sizeX.min);
  } else if (container.direction === "y") {
    return size.set_height(sizeY.default, sizeY.max, sizeY.min);
  }
};

const crossSet = (item, container) => {
  const sizeX = item.position.size.x;
  const sizeY = item.position.size.y;

  if (container.direction === "x") {
    return size.set_height(sizeY.default, sizeY.max, sizeY.min);
  } else if (container.direction === "y") {
    return size.set_width(sizeX.default, sizeX.max, sizeX.min);
  }
};

const mainUnset = container => {
  if (container.direction === "x") {
    return size.unset_width();
  } else if (container.direction === "y") {
    return size.unset_height();
  }
};

const crossUnset = container => {
  if (container.direction === "x") {
    return size.unset_height();
  } else if (container.direction === "y") {
    return size.unset_width();
  }
};

function item(layer, container) {
  const { flex, alignSelf } = layer.position.align.stack;

  let childMainSizeStyle, childCrossSizeStyle;

  // set main size
  if (flex === false && container.arrange !== "stretch") {
    childMainSizeStyle = mainSet(layer, container);
  } else {
    childMainSizeStyle = mainUnset(layer, container);
  }

  // set cross size
  if (alignSelf === "stretch") {
    childCrossSizeStyle = crossUnset(layer, container);
  } else if (alignSelf === "off" && container.align === "stretch") {
    childCrossSizeStyle = crossUnset(layer, container);
  } else {
    childCrossSizeStyle = crossSet(layer, container);
  }

  let childAlignSelfStyle = {};
  if (alignSelf !== "off") {
    if (container.direction === "x") {
      childAlignSelfStyle = {
        alignSelf: alignSelf
      };
    } else if (container.direction == "y") {
      childAlignSelfStyle = {
        justifySelf: alignSelf
      };
    }
  }

  return {
    position: "relative",
    ...childAlignSelfStyle,
    ...childMainSizeStyle,
    ...childCrossSizeStyle
  };
}

export default item;
