var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "menu-item ani-fast pointer f-p",
      class: { "no-hover": !_vm.hover },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.close($event)
        }
      }
    },
    [_vm._v(_vm._s(_vm.menuText))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }