<template lang="pug">
  scroll.file-browser(dir='y')
    .content
      .top.sticky
        slot(name='top')
      slot(name='content')
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.content
  stack(y)
.top
  // position
  top 0
  position sticky
  z-index 1
  // pointer
  // pointer-events none
  // &>>> >*
  // &>*
  //   pointer-events auto

</style>
