<template lang="pug">
.master.radius-m.clip.pointer(@click="onClick")
  .preview(:style="style")
  .hover.abs.ani-fast.f-title Update
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.master
  stack(y, gap: .5rem, arr: stretch)
  padding .25rem
  box-shadow inset 0 0 0 1px var(--clr-shadow-2)
  .hover
    background var(--clr-shadow-2)
    opacity 0
    width 100%
    height 100%
    top 0
    left 0
    color var(--clr-content-reverse)
    display inline-grid
    place-items center

.master:hover
  .hover
    opacity 1
.preview
  width 100%
  height 3rem
  background-position center
  background-repeat no-repeat
  background-size cover
</style>

<script>
import EditorBus from "@/eventBus/EditorBus.js";

export default {
  name: "ImageEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    param: String
  },
  computed: {
    style() {
      return {
        backgroundImage: this.value ? `url(${this.value})` : "none"
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover"
      };
    }
  },
  methods: {
    onClick() {
      this.$store.commit("setUI", {
        key: "imageSelector",
        value: true
      });
    },
    updateImage(url) {
      this.$emit("change", url, this.param);
    }
  },
  created() {
    EditorBus.$on("imageChange", this.updateImage);
  },
  beforeDestroy() {
    EditorBus.$off("imageChange", this.updateImage);
  }
};
</script>
