<template lang="pug">
  .grid-flow-props
    KeyValue(label="Wrap Size")
      SpaceEditor(
        @change="onSizeChange"
        :value="value.col_min_width"
      )
    KeyValue(label="Gap X")
      SpaceEditor(
        @change="onGapXChange"
        :value="value.gap_x"
      )
    KeyValue(label="Gap Y")
      SpaceEditor(
        @change="onGapYChange"
        :value="value.gap_y"
      )
</template>
<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.grid-flow-props
  stack(y, gap: .25rem, arr: stretch)
.stack-x
  stack(x, gap: .25rem, arr: stretch)
.fix-width
  width 100%
</style>
<script>
import KeyValue from "@/components/prop_panel/component/label.vue";
import SpaceEditor from "@/components/form_element/space.vue";

export default {
  name: "GridFlow",
  components: {
    KeyValue,
    SpaceEditor
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          gap_x: 0,
          gap_y: 0,
          col_min_width: 0
        };
      }
    }
  },
  methods: {
    onSizeChange(v) {
      return this.onChange(v, `col_min_width`);
    },
    onGapXChange(v) {
      return this.onChange(v, `gap_x`);
    },
    onGapYChange(v) {
      return this.onChange(v, `gap_y`);
    },
    onChange(value, key) {
      return this.$emit("change", parseFloat(value), key);
    }
  }
};
</script>
