var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      attrs: {
        root: _vm.tree.root,
        id: _vm.tree.id,
        hasChildren: _vm.hasChildren,
        targetSlot: "default",
        icon: _vm.icon
      }
    },
    [
      _c(
        "template",
        { slot: "label" },
        [
          _c("label-layer", {
            attrs: {
              name: _vm.tree.label,
              hasChildren: _vm.hasChildren,
              isFocused: _vm.isFocused && !_vm.isDrag,
              icon: _vm.icon
            }
          })
        ],
        1
      ),
      _vm._l(_vm.tree.children, function(val, key) {
        return [
          _vm._l(val, function(id) {
            return _vm.isExpand
              ? [
                  _vm.layerIsPure(_vm.layer(id))
                    ? _c("node-pure", {
                        key: id,
                        attrs: { tree: _vm.layer(id) }
                      })
                    : _c("nodeSlotly", {
                        key: id,
                        attrs: { tree: _vm.layer(id) }
                      })
                ]
              : _vm._e()
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }