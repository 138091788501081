// prop editor 出现在自动接口的 prop folder 中，用来让用户编辑自动生成的组件接口，并可以把这个字段连接到当前组件的自动接口。
<template lang="pug">
  //- 没有 prop connector 按钮的 prop label
  .prop-editor(:class="size")
    .top
      //- 右键点击标题可以让对应 key 成为本组件的 prop
      .prop-editor--label.flex(@click.right="rightClickLabel()") {{label}}
        //- prop-connector 被当做本组件的 prop 时显示
        span.prop-connector.ani-fast(:class="{'is-on': isProp}")
          .oval
      //- 当实例不传值的时候，开关关闭
      bSwitcher(:size="size" v-if="!required" :value="show" @change="$emit('toggle', !show)")
    //-  slot 用于放置表单，用来编辑 value
    slot(v-if="show")
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'
// general
.top
  stack(x, gap: .5rem)
.prop-editor
  stack(y, var(--kv-gap))
  &.s
    --kv-gap: .25rem
    .prop-editor--label
      @extend .f-small
      @extend .color-text-3
  &.m
    --kv-gap: .25rem
    .prop-editor--label
      @extend .f-p
      @extend .color-text-2
  &.l
    --kv-gap: .5rem
    .prop-editor--label
      @extend .f-title
      @extend .color-text
// prop-connector
.prop-connector
  display inline-block
  width 0rem
  height 0rem
  position relative
  .oval
    width .25rem
    height .25rem
    position absolute
    bottom .35rem
    left .125rem
    background var(--clr-primary)
.prop-connector:not(.is-on)
  opacity 0
.prop-connector.is-on
  opacity 1
.prop-editor--label
  text-transform capitalize
</style>

<script>
export default {
  name: "prop_editor",
  props: {
    // key label
    label: {
      type: String,
      default: ""
    },
    // 标题字的粗细
    size: {
      type: String,
      default: "m" // s, m, l
    },
    // 本字段是否能够成为 comp prop
    canProp: {
      type: Boolean,
      default: false
    },
    // 本字段是否已经成为 comp prop
    isProp: {
      type: Boolean,
      default: false
    },
    layerId: {
      default: ""
    },
    propName: {
      require: true
    },
    // required 的字段用户必须定义，不用显示 switcher
    required: {
      default: false
    }
  },
  computed: {
    show() {
      return (
        this.nodeProps &&
        (Reflect.has(this.nodeProps, this.propName) ||
          (Reflect.has(this.nodeProps, this.layerId) && Reflect.has(this.nodeProps[this.layerId], this.propName)))
      );
    },
    nodeProps() {
      return this.$store.getters.foucsLayer && this.$store.getters.foucsLayer.props;
    }
  },
  methods: {
    rightClickLabel() {
      if (this.$ui.currentTab == "components") {
        this.$emit("link");
      }
    }
  }
};
</script>
