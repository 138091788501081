import auth0 from "auth0-js";
import * as pify from "pify";

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientID = process.env.VUE_APP_AUTH0_CLIENT_ID;
const url = process.env.VUE_APP_PRODUCTION_URL;

const webAuth = pify(
  new auth0.WebAuth({
    domain,
    clientID,
    responseType: "token id_token"
  })
);

export async function sendLogInLink(email) {
  return webAuth.passwordlessStart({
    connection: "email",
    send: "link",
    email,
    redirectUri: `${url}/callback?email=${email}`
  });
}

export async function getUserInfomation(hash) {
  const authResult = await webAuth.parseHash({ hash });
  return {
    authResult,
    userInfo: await pify(webAuth.client).userInfo(authResult.accessToken)
  };
}

export function googleLogin() {
  webAuth.authorize({
    connection: "google-oauth2",
    responseType: "token id_token",
    state: "profile",
    redirectUri: `${url}/callback` // Wranning: localhost not support, i dont know wath happen , but i know the earth will be boom!!
  });
}

export function isAuthenticated() {
  return true;
  // let expiresAt = new Date(parseInt(localStorage.getItem("expires_at"))).getTime();
  // return new Date().getTime() < expiresAt;
}

export function auth0Logout() {
  ["email", "id", "token", "expires_at"].forEach(val => localStorage.removeItem(val));
}

export { webAuth };
