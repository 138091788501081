<template lang="pug">
  .dt--list-item--font
    .name.f-title Font Name
    .preview.radius-l.shadow-card.ani-fast.pointer
      .text Aa
  //- .dt-thumbnail.radius-m.shadow-card.pointer.ani-fast()
    slot
      //- default thumbnail
      .default
        .name.f-title {{key}}
        .value.f-p {{value}}
</template>
<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.dt--list-item--font
  stack(y, gap: 1rem)

.preview
  background white
  height 10rem
  display inline-flex
  place-items center
  font-size 4rem
  line-height 4rem
  .text
    text-align center
    width 100%
.dt-thumbnail
  background var(--clr-bg)

.default
  stack(y, gap: .25rem)
  padding 1rem
.name
  color var(--clr-content-2)
  // padding 0 .5rem
.value
  color var(--clr-content-3)
</style>
<script>
export default {
  props: {
    key: {
      type: String,
      default: "{{DesignToken.name}}"
    },
    value: {
      type: String,
      default: "{{DesignToken.value}}"
    }
  }
};
</script>
