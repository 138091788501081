<template lang="pug">
  //- bDropSelector(
  //-   :options="['start','center','end','stretch','space-between']"
  //-   :value="value"
  //-   @change="$emit('change', $event)"
  //- )
  b-radio-btn(
    :options="options"
    :value="value"
    @change="$emit('change', $event)"
    :rotate="rotate"
  )
</template>

<script>
export default {
  name: "ArrangeEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    rotate: false,
    options: {
      type: Array,
      default() {
        return [
          {
            label: "",
            value: "stretch",
            icon: "arrange/stretch"
          },
          {
            label: "",
            value: "space-between",
            icon: "arrange/between"
          },
          {
            label: "",
            value: "start",
            icon: "arrange/start"
          },
          {
            label: "",
            value: "center",
            icon: "arrange/center"
          },
          {
            label: "",
            value: "end",
            icon: "arrange/end"
          }
        ];
      }
    },
    value: {
      type: String,
      default: "start"
    }
  }
};
</script>
