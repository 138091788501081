var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "overlay-fade" } }, [
    _c(
      "div",
      { staticClass: "overlay" },
      [
        _c("div", {
          staticClass: "shadow abs",
          class: { visible: _vm.shadowVisible },
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.onClick($event)
            }
          }
        }),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }