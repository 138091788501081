var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-props" },
    [
      _c("bRadioBtn", {
        attrs: {
          options: [
            { label: "Off", value: "off" },
            { label: "Stack", value: "stack" },
            { label: "Grid", value: "grid" },
            { label: "Flow", value: "gridflow" }
          ],
          value: _vm.value.layoutType
        },
        on: { change: _vm.onLayoutTypeChange }
      }),
      _vm.isOtherLayout
        ? _c(_vm.layoutType, {
            tag: "Component",
            attrs: { value: _vm.layout },
            on: { change: _vm.onInnerChange }
          })
        : _vm._e(),
      _c(
        "KeyValue",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOtherLayout,
              expression: "isOtherLayout"
            }
          ],
          attrs: { label: "Padding" }
        },
        [
          _c("PaddingEditor", {
            attrs: { value: _vm.value.padding },
            on: { change: _vm.onPaddingChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }