var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "master" },
    [
      _c("div", { staticClass: "label f-p color-text-2" }, [_vm._v("Align")]),
      _c("bInputSelector", {
        staticClass: "fix-width top",
        attrs: { placeholder: "Top", value: _vm.top, defaultOptions: ["off"] },
        on: { change: _vm.onTopChange }
      }),
      _c(
        "div",
        { staticClass: "middle" },
        [
          _c("bInputSelector", {
            staticClass: "fix-width",
            attrs: {
              placeholder: "Left",
              value: _vm.left,
              defaultOptions: ["off"]
            },
            on: { change: _vm.onLeftChange }
          }),
          _c("div", { staticClass: "center flex border radius-s" }),
          _c("bInputSelector", {
            staticClass: "fix-width",
            attrs: {
              placeholder: "Right",
              value: _vm.right,
              defaultOptions: ["off"]
            },
            on: { change: _vm.onRightChange }
          })
        ],
        1
      ),
      _c("bInputSelector", {
        staticClass: "fix-width bottom",
        attrs: {
          placeholder: "Bottom",
          value: _vm.bottom,
          defaultOptions: ["off"]
        },
        on: { change: _vm.onBottomChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }