<template lang="pug">
  layout(:root="tree.root" :id="tree.id" :hasChildren="hasChildren" targetSlot="default" :icon="icon")
    //- label slot 的内容
    template(slot="label")
      label-layer(:name="tree.label" :hasChildren="hasChildren" :isFocused="isFocused && !isDrag" :icon="icon")
    //- default slot 的内容
    //- 依然是一个 slot，用来放子节点
    template(v-for="(val, key) in tree.children")
      template(v-if="isExpand" v-for='id in val')
        node-pure(v-if="layerIsPure(layer(id))" :key="id" :tree="layer(id)" )
        nodeSlotly(v-else :key="id" :tree="layer(id)")
</template>

<script>
import layout from "../layout";
import labelLayer from "../label/layer";
import nodeSlotly from "./slotly.vue";
import { mapGetters } from "vuex";

export default {
  name: "node-pure",
  components: {
    layout,
    labelLayer,
    nodeSlotly
  },
  props: ["tree"],
  data() {
    return {
      fixBug: this.tree
    };
  },
  computed: {
    isDrag() {
      return this.$store.state.ui.isDrag;
    },
    icon() {
      const component = this.$utils.inners.find(c => c.id == this.tree.componentId);
      if (component) {
        return "component/builtin/" + component.icon;
      }
    },
    hasChildren() {
      return Object.keys(this.tree.children).length >= 1;
    },
    isFocused() {
      return this.layerId == this.tree.id;
    },
    layerId() {
      return this.$store.state.layer.layerId;
    },
    isExpand() {
      if (Reflect.has(this.tree, "isExpand")) {
        return this.tree["isExpand"];
      }
      return true;
    },
    ...mapGetters(["layer"])
  },
  methods: {
    layerIsPure(layer) {
      return layer && layer.children && Object.keys(layer.children).length <= 1;
    }
  }
};
</script>
