import { generate } from "shortid";
import { basicProps } from "../utils/props";
import { eqProp, notEqProp, stateMerge } from "../helpers";
import { layer as layerService } from "../services";
import clone from "clone";
import { utils } from "../utils/utils";
import map from "lodash.map";
import dotProp from "dot-prop";
import { isInnerComponent } from "@/components/preview_area/built-in component/builtIn.js";
import history from "./history";
import Vue from "vue";

import { defaultPosition } from "@/render_func/layout_system/layoutRender/layout.js";

let createLayer = ({ label, componentId, children = {}, props, positionType }) => {
  return {
    id: generate(),
    label,
    children,
    isExpand: false,
    componentId,
    props: basicProps[label] || props,
    position: defaultPosition(positionType)
  };
};

const layerFactory = ({ commit, getters, dispatch }) => async ({
  from,
  layerId,
  componentId,
  label,
  positionType,
  position,
  size
}) => {
  let layer = null;

  const replaceSize = layer => {
    if (size) {
      // 这里是保持引用的，自行 clone 断开引用
      layer.position.size = size;
    }
  };
  const replacePosition = layer => {
    if (position) {
      // 这里是保持引用的，自行 clone 断开引用
      layer.position = position;
    }
  };

  if (from === "layer") {
    // exists Node
    layer = getters.layer(layerId);
    // remove id
    await dispatch("deleteLayerFromTree", layer);
  } else if (from === "master") {
    // 判断是非内部的设计组件，则 childrent 则需要手动初始化。
    let children = {};
    let props = {};
    if (!utils.isInnerComponent(componentId)) {
      const comp = await getters.component(componentId);

      // 把 lid:layerId 做为 key
      if (comp && comp.slots && comp.slots.length > 0) {
        children = comp.slots.reduce((prev, key) => {
          prev["lid:" + key] = [];
          return prev;
        }, {});
      }

      if (comp && comp.props && Object.keys(comp.props).length > 0) {
        map(comp.props, (value, key) => {
          props[key] = Object.keys(value).reduce((prev, current) => {
            prev[current] = value[current].value;
            return prev;
          }, {});
        });
      }
    }
    layer = createLayer({ componentId, label, children, props, positionType });
    replacePosition(layer);
    replaceSize(layer);
    layerService.create(layer);
    commit("pushLayer", layer);
  } else if (from === "project") {
    let children = {
      default: []
    };
    let props = {};
    layer = createLayer({ componentId, label, children, props, positionType });
    replacePosition(layer);
    replaceSize(layer);
    await layerService.create(layer);
    commit("pushLayer", layer);
  }
  return layer;
};

let findChildLayerIndex = (layerParent, id) => {
  for (let slotName of Object.keys(layerParent.children)) {
    let index = 0;
    for (let child of layerParent.children[slotName]) {
      if (child === id) {
        return [slotName, index];
      }
      index++;
    }
  }
};

// 将设计组件的 slot layer 记录到选中的 component 上面去，便于插入。
const addSlotToComponent = context => async layer => {
  if (layer.componentId == "bento-slot") {
    await context.dispatch("selectedComponentRegisterSlot", layer);
  }
};

const scanSlots = (context, layer, slots = []) => {
  if (layer.componentId == "bento-slot") {
    slots.push(layer.id);
  }

  if (layer.children) {
    for (let key of Object.keys(layer.children)) {
      for (let id of layer.children[key]) {
        scanSlots(context, context.getters.layer(id), slots);
      }
    }
  }

  return slots;
};

const state = {
  layers: [],
  layerId: 0
};

const getters = {
  layer: state => id => {
    return state.layers.find(eqProp("id", id));
  },
  hasFocusLayer: state => id => {
    return state.layerId == id;
  },
  layerParent: state => id => {
    return state.layers.find(layer => {
      for (let slot of Object.keys(layer.children)) {
        for (let _id of layer.children[slot]) {
          if (_id === id) {
            return true;
          }
        }
      }
      return false;
    });
  },
  foucsLayer: (state, getters) => {
    return getters.layer(state.layerId);
  }
};

const mutations = {
  setLayerId(state, id) {
    state.layerId = id;
  },
  replaceLayer(state, { oldId, newId }) {
    return state.layers.find(layer => {
      for (let slot of Object.keys(layer.children)) {
        layer.children[slot] = layer.children[slot].map(val => {
          if (val == oldId) {
            return newId;
          }
          return val;
        });
      }
    });
  },
  pushLayer(state, layer) {
    // 不许要补全属性才能响应式
    layer.isExpand = true;
    // 不存在就插入
    if (state.layers.every(notEqProp("id", layer.id))) {
      state.layers.push(layer);
    } else {
      // 已存在就替换
      let index = state.layers.findIndex(eqProp("id", layer.id));
      state.layers[index] = layer;
    }
  },
  updateLayerInfomation(state, newLayer) {
    let layer = state.layers.find(eqProp("id", newLayer.id));
    if (!layer) {
      alert("没有找到layer");
      return;
    }
    try {
      stateMerge(layer, newLayer, null, true);
    } catch (error) {
      /* eslint-disable-next-line */
      console.log(error);
    }
  },
  closeAllLayer(state) {
    state.layers = state.layers.map(layer => ((layer.isExpand = false), layer));
  },
  updateLayerProp(state, { id, key, value, layerId }) {
    let layer = state.layers.find(eqProp("id", id));
    if (!layer) {
      alert("no layer");
      return;
    }
    if (layerId) {
      layer.props[layerId][key] = value;
    } else {
      layer.props[key] = value;
    }
    layer.props = clone(layer.props);
  },
  deleteLayerProps(state, { id, name }) {
    let layer = state.layers.find(eqProp("id", id || state.layerId));
    if (layer.props && name.includes(".")) {
      dotProp.delete(layer.props, name);
    }
    if (layer.props && layer.props[name]) {
      delete layer.props[name];
    }
  },
  toggleLayerExpand(state, { id }) {
    const layer = state.layers.find(eqProp("id", id));
    if (layer.isExpand) {
      layer.isExpand = !layer.isExpand;
      return;
    }
    layer.isExpand = true;
  },
  deleteLayer(state, id) {
    state.layers = state.layers.filter(notEqProp("id", id));
  },
  deleteLayerFromTree(state, { layerIndex, slot, index }) {
    state.layers[layerIndex].children[slot].splice(index, 1);
  },
  resetPosition(state, id) {
    const layer = state.layers.find(eqProp("id", id));
    if (!layer) {
      return;
    }
    layer.position.activeLayout = "componentpreview";
    /* eslint-disable */
    layer.position.align && (layer.position.align.absolute.left = 'off')
    layer.position.align && (layer.position.align.absolute.right = 'off')
    layer.position.align && (layer.position.align.absolute.top = 'off')
    layer.position.align && (layer.position.align.absolute.bottom = 'off')
  }
  
};

const actions = {
  // 插入到 layer 为 targetId 的 [target] slot 的第一个位置
  async insertLayerFirst(context, params) {
    const { getters, commit, dispatch } = context;
    const { target, from, layerId, targetId, design, componentId, label } = params;
    history.push(context.rootState);
    const getLayer = layerFactory({ commit, getters, dispatch });

    let pozLayer = getters.layer(targetId);
    let layer = await getLayer({ from, layerId, design, componentId, label });
    pozLayer.children[target].unshift(layer.id);
    await addSlotToComponent({ dispatch })(layer);
    layerService.update(pozLayer);
    commit("setUI", {
      key: "hoverLayerId",
      value: 0
    });
  },
  // target 的后面，先找到 target 在 parent 的 index，然后插入
  async insertLayerAfter(context, params) {
    const { getters, commit, dispatch } = context;
    history.push(context.rootState);
    const { from, layerId, targetId, design, componentId, label } = params;
    const getLayer = layerFactory({ commit, getters, dispatch });
    let parent = getters.layerParent(targetId);
    if (!parent) {
      // 是 root
      alert("没有找到父节点");
    }
    let [_slot, index] = findChildLayerIndex(parent, targetId);
    let layer = await getLayer({ from, layerId, design, componentId, label });
    parent.children[_slot].splice(index + 1, 0, layer.id);
    await addSlotToComponent({ dispatch })(layer);
    layerService.update(parent);
    commit("setUI", {
      key: "hoverLayerId",
      value: 0
    });
  },
  // target default 的最后
  async insertLayerLast(context, params) {
    const { getters, commit, dispatch } = context;
    const { target = "default", from, layerId, targetId, design, componentId, label } = params;
    history.push(context.rootState);
    const getLayer = layerFactory({ commit, getters, dispatch });
    let pozLayer = getters.layer(targetId);
    if (!pozLayer.children[target]) {
      alert("该节点不可再插入");
      return;
    }
    let layer = await getLayer({ from, layerId, design, componentId, label });
    pozLayer.children[target].push(layer.id);
    await addSlotToComponent({ dispatch })(layer);
    layerService.update(pozLayer);
    setTimeout(() => {
      commit("setUI", {
        key: "hoverLayerId",
        value: 0
      });
    }, 400);
  },
  // 直接插入，有选择的 layer 插入到 layer 的 default，没有就插入到 root，没有 root 就成为 root
  async insertLayer(context, { componentId, label }) {
    const { getters, commit, dispatch, rootState } = context;
    history.push(context.rootState);

    const getLayer = layerFactory({ getters, commit, dispatch });

    if (getters.selectedComponent.id == componentId) {
      // 需要删除自己，因为没有插入成功
      alert("不能自己插入自己");
      return;
    }

    // 没有选中的 layer 但是是 workspace
    if (!getters.foucsLayer && rootState.ui.currentTab == "workspace") {
      const targetLayer = getters.layer(getters.selectedProject.workspace)
      if (!targetLayer) {
        return
      }

      const layer = await getLayer({ from: "master", label, componentId });
      // 有 default 插入到 slots 里面
      targetLayer.children['default'].push(layer.id);
      commit("pushLayer", layer);
      commit("updateLayerInfomation", targetLayer);
      layerService.update(targetLayer);
      await addSlotToComponent({ dispatch })(layer);
      await dispatch("selectLayer", layer);
      return;
    }

    // 有选中的 layer 插入到后面，并且有 default
    if (getters.foucsLayer && getters.foucsLayer.children) {
      const defaultKey = Object.keys(getters.foucsLayer.children)[0] || "default";
      if (getters.foucsLayer.children[defaultKey]) {
        const foucsLayer = clone(getters.foucsLayer);
        const layer = await getLayer({ from: "master", label, componentId });

        // 有 default 插入到 slots 里面
        foucsLayer.children[defaultKey].push(layer.id);
        commit("pushLayer", layer);
        commit("updateLayerInfomation", foucsLayer);
        layerService.update(foucsLayer);
        await addSlotToComponent({ dispatch })(layer);
        await dispatch("selectLayer", layer);
        return;
      }
      // 有选中的 layer 插入到后面，并且有 default
      if (getters.foucsLayer && getters.foucsLayer.children) {
        const defaultKey = Object.keys(getters.foucsLayer.children)[0] || "default";
        if (getters.foucsLayer.children[defaultKey]) {
          const foucsLayer = clone(getters.foucsLayer);
          const layer = await getLayer({ from: "master", label, componentId });
          // 有 default 插入到 slots 里面
          foucsLayer.children[defaultKey].push(layer.id);
          commit("pushLayer", layer);
          commit("updateLayerInfomation", foucsLayer);
          layerService.update(foucsLayer);
          await addSlotToComponent({ dispatch })(layer);
          await dispatch("selectLayer", layer);
          return;
        }
        // 没有就插入到该节点之后
        else {
          let parent = clone(getters.layerParent(getters.foucsLayer.id));
          if (!parent) {
            // 是 root
            alert("没有找到父节点");
            return;
          }
          let [_slot, index] = findChildLayerIndex(parent, getters.foucsLayer.id);
          const layer = await getLayer({ from: "master", label, componentId });
          parent.children[_slot].splice(index + 1, 0, layer.id);
          // push 一定要在 update 之前，要不然会出现拿不到 data 的一段真空期试产安
          commit("pushLayer", layer);
          commit("updateLayerInfomation", parent);
          await addSlotToComponent({ dispatch })(layer);
          layerService.update(parent);
          await dispatch("selectLayer", layer);
          return;
        }
      }
    }
    if (!getters.hasRootLayer) {
      // 没有 root 成为 root
      const component = clone(getters.selectedComponent);
      const layer = await getLayer({ from: "master", label, componentId });
      component.root = layer.id;
      layer.root = true;
      commit("pushLayer", layer);
      await addSlotToComponent({ dispatch })(layer);
      await dispatch("updateComponentInfomation", component);
      await dispatch("selectLayer", layer);
    } else {
      alert("请先选中可插入的 layer 之后再进行插入");
    }
  },
  async createWorkspaceLayer(context) {
    const { getters, commit, dispatch } = context;
    const getLayer = layerFactory({ getters, commit, dispatch });
    const layer = await getLayer({ from: "project", label: "Workspace", componentId: "workspace" });
    layer.root = true;
    commit("pushLayer", layer);
    return layer.id;
  },
  // 挂在之后，对未定义的 props 和 slots 进行初始化
  layerMountedInit: ({ getters }, { id, props, slots }) => {
    let layer = getters.layer(id);
    if (layer && props) {
      map(props, (prop, key) => {
        if (!layer.props[key]) {
          Vue.set(layer.props, key, prop.value);
        }
      });
    }
    if (layer && slots && slots.length > 0) {
      layer.children = slots.reduce((children, name) => {
        if (layer.children && layer.children[name]) {
          // 已经存在的 slot, 使用原来的
          children[name] = layer.children[name];
          return children;
        }
        Vue.set(children, name, []);
        return children;
      }, {});
    }
    layerService.update(layer);
  },
  async updateLayerProp(context, { id, key, value, layerId }) {
    history.push(context.rootState);
    const { getters, commit } = context;
    commit("updateLayerProp", { id, key, value, layerId });
    let layer = getters.layer(id);
    await layerService.update(layer);
  },
  async updateLayerName({ commit, getters, rootState }, { id, label }) {
    history.push(rootState);
    commit("updateLayerInfomation", { id, label });
    let layer = getters.layer(id);
    await layerService.update(layer);
  },
  async updateLayer({ commit, rootState }, payload) {
    history.push(rootState);
    const layer = clone(payload); // fix #199
    commit("updateLayerInfomation", layer);
    await layerService.update(layer);
  },
  async fetchLayer({ commit, dispatch, rootState }, id) {
    const layer = await layerService.findById(id);
    if (layer == null || !layer.id) {
      // 拿到的是空对象
      await dispatch("deleteLayer", { id });
      await dispatch("deleteAllLayerPropsIsId", { id });
      return;
    }
    // 假如 id 是 root 在 fetch 的时候初始化
    if (rootState.component.components.some(c => c.root == id)) {
      layer.root = true;
    }
    if (layer.children && Object.keys(layer.children).length > 0) {
      // 有子节点需要处理
      for (let slot of Object.keys(layer.children)) {
        for (let childId of layer.children[slot]) {
          await dispatch("fetchLayer", childId);
        }
      }
    }
    if (Reflect.has(layer, "id")) {
      commit("pushLayer", layer);
    }
  },
  deleteSelectedLayer(context){
    if (context.state.layerId) {
      return context.dispatch('deleteLayer', { id: context.state.layerId })
    }
  },
  async deleteLayer({ commit, getters, dispatch, rootState }, { id }) {
    if (getters.selectedComponent.root == id && getters.selectedComponent.id == "workspace") {
      console.log("无法 delete workspace");
      return;
    }
    history.push(rootState);
    console.log(id);
    const layer = getters.layer(id);
    if (!layer) {
      return;
    }
    // 有子节点需要删除
    for (let slot of Object.keys(layer.children)) {
      for (let id of layer.children[slot]) {
        dispatch("deleteLayer", { id });
      }
    }
    commit("deleteLayer", layer.id);
    dispatch("componentDeleteSlot", layer);
    dispatch("componentDeleteProp", layer);
    layerService.remove(layer);
    dispatch("deleteLayerFromTree", { id });
    if (getters.selectedComponent && getters.selectedComponent.root == id) {
      // 是 root 节点，删除 root 节点还需要修改 component 的 root 属性
      dispatch("updateComponentInfomation", {
        id: getters.selectedComponent.id,
        root: null
      });
      commit("setLayerId", 0);
    }
  },
  // 用户操作的 copy
  async userCopyLayer(context, { id }) {
    const { commit, dispatch, getters } = context;
    const layer = await context.dispatch("copyLayer", { id, delRoot: false });
    let parent = clone(getters.layerParent(getters.foucsLayer.id || id));
    if (!parent) {
      // 是 root
      alert("没有找到父节点");
      return;
    }
    let [_slot, index] = findChildLayerIndex(parent, getters.foucsLayer.id);
    parent.children[_slot].splice(index + 1, 0, layer.id);
    commit("updateLayerInfomation", parent);
    await addSlotToComponent({ dispatch })(layer);
    layerService.update(parent);
    await dispatch("selectLayer", layer);
    return;
  },
  // 用于内部逻辑的 copy、
  async copyLayer({ commit, getters, dispatch, state }, { id = state.layerId, label, delRoot = true }) {
    // 复制当前层
    const layer = Object.assign({}, getters.layer(id), { id: generate() });

    if (delRoot) {
      // 是 root 节点, 替换 root 节点
      if (layer.root) {
        commit("updateComponentInfomation", { root: layer.id });
      }
      delete layer.root; // 删除本来的属性
    }
    delete layer.createdAt;
    delete layer.updatedAt;
    delete layer.status;
    label && (layer.label = label);
    // 复制子节点
    const children = {}; // 新复制的子节点
    for (let slot of Object.keys(layer.children)) {
      children[slot] = [];
      for (let id of layer.children[slot]) {
        const _layer = await dispatch("copyLayer", { id, delRoot: false });
        children[slot].push(_layer.id);
      }
    }
    layer.children = children;
    // 虽然 id 改了，然是由于 vue 代理了属性，同样会保持原始引用，导致修改同时生效，天坑
    commit("pushLayer", clone(layer));
    layerService.create(layer);
    return layer;
  },
  // 假如父设计组件某个暴露出 props 的被删除了，会找不到，那么就删除它
  async deleteAllLayerPropsIsId({ state, dispatch }, { id }) {
    const layer = state.layers.find(layer => {
      return layer.props[id];
    });
    if (layer) {
      await dispatch("deleteLayerProps", { id: layer.id, name: id });
    }
  },
  async deleteLayerProps({ getters, commit }, { id, name }) {
    if (!getters.layer(id)) {
      return;
    }
    commit("deleteLayerProps", { id, name });
    await layerService.update(getters.layer(id));
  },
  selectLayer({ getters, commit, rootState }, { id }) {
    commit("closeAllLayer");
    commit("setLayerId", id);
    let layer = getters.layer(id);
    getters.currentComponentAllLayersHasLevel.forEach((arr, index) => {
      if (arr.find(v => v.id == id)) {
        rootState.ui.selectedLevel = index;
      }
    });
    layer && (layer.isExpand = true);
    rootState.ui.showFloatingEditor = true;
    function open(_id) {
      let parent = getters.layerParent(_id);
      // 没有或者是 root 节点
      if (typeof parent == "undefined") return;
      if (parent.id) {
        parent.isExpand = true;
        open(parent.id);
      }
    }
    open(id);
  },
  async deleteLayerFromTree({ getters, dispatch }, { id }) {
    const parent = getters.layerParent(id);
    if (!parent) {
      return;
    }
    const [slot, index] = findChildLayerIndex(parent, id);
    parent.children[slot].splice(index, 1);
    layerService.update(parent);
    // 选中后面一个，由于前一个已经删除，所以当前位置为后面layer
    if (parent.children[slot][index]) {
      await dispatch("selectLayer", { id: parent.children[slot][index] });
      return;
    }
    // 前面一个
    if (parent.children[slot][index - 1]) {
      await dispatch("selectLayer", { id: parent.children[slot][index - 1] });
      return;
    }
    // 选中父亲
    await dispatch("selectLayer", { id: parent.id });
    return [parent, slot, index];
  },
  async convertLayerToComponent(context, { id }) {
    history.push(context.rootState);
    const layer = context.getters.layer(id);
    const slots = scanSlots(context, layer)

    const component = await context.dispatch("createComponent", {
      design: true,
      root: layer.id,
      label: layer.label,
      slots
    });

    const getLayer = layerFactory(context);
    
    const newLayer = await getLayer({ from: "master", label: layer.label, componentId: component.id, position: clone(layer.position) });
    context.commit('resetPosition', id);
    await context.dispatch("replaceLayer", { newId: newLayer.id, oldId: layer.id });
    layerService.update(layer);
    context.commit("setLayerId", newLayer.id);
  },
  async replaceLayer({ state }, { oldId, newId }) {
    for (let layer of state.layers) {
      let changed = false;
      for (let slot of Object.keys(layer.children)) {
        layer.children[slot] = layer.children[slot].map(val => {
          if (val == oldId) {
            changed = true;
            return newId;
          }
          return val;
        });
      }
      changed && layerService.update(layer);
    }
  },
  async componentToLayer(context, { id }) {
    const layer = context.getters.layer(id);

    if (isInnerComponent(layer.componentId)) {
      return;
    }

    const component = context.getters.component(layer.componentId);
    // 当选 Team 所有外部项目的 componetId 数组
    if (context.getters.selectedTeamExtenalProjectComponentIds.includes(layer.componentId)) {
      return;
    }

    // 是当前项目的组件
    if (context.getters.selectedProject.componentIds.includes(layer.componentId)) {
      history.push(context.rootState);
      const newLayer = await context.dispatch("copyLayer", { id: component.root, label: layer.label, delRoot: false });
      await context.dispatch("replaceLayer", { newId: newLayer.id, oldId: layer.id });
      return;
    }

    if (context.getters.selectedTeamProjectComponent.map(c => c.id).includes(layer.componentId)) {
      // 需要确认
      if (window.confirm("是否解开该组件")) {
        history.push(context.rootState);
        const newLayer = await context.dispatch("copyLayer", { id: component.root, label: layer.label, delRoot: false });
        await context.dispatch("replaceLayer", { newId: newLayer.id, oldId: layer.id });
      }
    }
  },
  // 通知 layer 更新 slots 和 props
  async noticeLayerUpdate({ getters, dispatch }, { slots, props, componentId }) {
    
    const components = getters.selectedProjectComponent.concat({ root: getters.selectedProject.workspace });
    if (components.length == 0) {
      return;
    }
    const rootLayers = components.map(c => c.root && getters.layer(c.root)).filter(Boolean);
    for (let rootLayer of rootLayers) {
      findChildren(rootLayer);
    }
    /* eslint-disable-next-line */
    function findChildren(layer) {
      if (layer.componentId == componentId) {
        let needUpdate = false;
        // 更新 slots 逻辑
        if (slots) {
          const children = clone(layer.children);
          const newSlotKeys = slots;
          const oldSlotKeys = Object.keys(children).map(v => v.replace("lid:", ""));
          const longSlotKeys = newSlotKeys.length > oldSlotKeys.length ? newSlotKeys : oldSlotKeys;
          for (const key of longSlotKeys) {
            if (newSlotKeys.includes(key) && !oldSlotKeys.includes(key)) {
              // add
              needUpdate = true;
              children["lid:" + key] = [];
            }
            if (!newSlotKeys.includes(key) && oldSlotKeys.includes(key)) {
              // delete
              needUpdate = true;
              delete children["lid:" + key];
            }
          }
          layer.children = children;
        }

        if (props) {
          const cloneProps = clone(layer.props);
          const newPropKeys = Object.keys(props);
          const oldPropKeys = Object.keys(cloneProps);
          const longSlotKeys = newPropKeys.length > oldPropKeys.length ? newPropKeys : oldPropKeys;
          for (const key of longSlotKeys) {
            if (props[key] && cloneProps[key] && (Object.keys(props[key]).length > Object.keys(cloneProps[key]).length)) {
              // add
              needUpdate = true;
              cloneProps[key] = Object.keys(props[key]).reduce((prev, current) => {
                if (prev[current]) {
                  return prev;
                }
                prev[current] = props[key][current].value;
                return prev;
              }, cloneProps[key]);
            }
            if (props[key] && cloneProps[key] && (Object.keys(props[key]).length < Object.keys(cloneProps[key]).length)) {
              // delete
              needUpdate = true;
              // 遍历少了值那边，按照key，从 cloneProps 拷贝过来，实现删除
              cloneProps[key] = Object.keys(props[key]).reduce((prev, current) => {
                prev[current] = cloneProps[key][current];
                return prev;
              }, {});
            }
          }
          layer.props = cloneProps;
        }

        needUpdate && layerService.update(layer);
      }

      // 递归调用逻辑
      for (let key of Object.keys(layer.children)) {
        for (let layerId of layer.children[key]) {
          const layer = getters.layer(layerId);
          if (!layer) {
            dispatch("fetchLayer", layerId).then(() => {
              const layer = getters.layer(layerId);
              layer && findChildren(layer);
            });
            return;
          }
          findChildren(layer);
        }
      }
    }
  },
  // 更新当前布局的方式
  async updateLayoutType(context, {id, layoutType}){
    const layer  = context.getters.layer(id)
    if (layer) {
      layer.position.activeLayout = layoutType
      await layerService.update(layer)
    }
  },
  async addLayoutType(context, {id, type, value}) {
    const layer = context.getters.layer(id)
    if (layer) {
      Vue.set(layer.props.layout, type, value)
      await layerService.update(layer)
    }
  },
  async toggleProp(context, {key, value, layerId, id }) {
    const layer = context.getters.layer(id)
    const props = layer.props;
    
    if (layerId) {
      if(typeof props[layerId] == 'undefined') {
        Vue.set(props, layerId, {})
      }
      if (typeof props[layerId][key] != 'undefined') {
        Vue.delete(props[layerId], key)
      } else {
        Vue.set(props[layerId], key, value)
      }
    }else {
      if (typeof props[key] != 'undefined') {
        Vue.delete(props, key)
      } else {
        Vue.set(props, key, value)
      }
    }
    
    layer.props = props
    await layerService.update(layer)
  }
};

export default { getters, actions, mutations, state };

export { createLayer };
