<template lang="pug">
  .grid-props
    GridXY(dir="x" @change="onChange" :value="layoutX")
    GridXY(dir="y" @change="onChange" :value="layoutY" rotate_icon)
</template>
<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.grid-props
  stack(y, gap: .5rem, arr: stretch)

</style>
<script>
import KeyValue from "@/components/prop_panel/component/label.vue";
import GridXY from "./grid_xy.vue";

export default {
  name: "LayoutGrid",
  components: {
    KeyValue,
    GridXY
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          x: {
            count: 0,
            arrange: "stretch",
            size: "auto",
            gap: 0
          },
          y: {
            count: 0,
            arrange: "stretch",
            size: "auto",
            gap: 0
          }
        };
      }
    }
  },
  computed: {
    layoutX() {
      return {
        count: this.value ? this.value.x.count : "3",
        arrange: this.value ? this.value.x.arrange : "stretch",
        size: this.value ? this.value.x.size : "auto",
        gap: this.value ? this.value.x.gap : "0"
      };
    },
    layoutY() {
      return {
        count: this.value ? this.value.y.count : "3",
        arrange: this.value ? this.value.y.arrange : "stretch",
        size: this.value ? this.value.y.size : "auto",
        gap: this.value ? this.value.y.gap : "0"
      };
    }
  },
  methods: {
    onChange(value, key) {
      return this.$emit("change", value, key);
    }
  }
};
</script>
