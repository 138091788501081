var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-editor" },
    [
      _c("bInputSelector", {
        attrs: { value: _vm.inputValue, defaultOptions: _vm.defaultOptions },
        on: { change: _vm.inputChange }
      }),
      _vm.inputValue == "custom"
        ? _c("bBtnColor", {
            staticClass: "color-picker",
            attrs: { value: _vm.value },
            on: { change: _vm.change }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }