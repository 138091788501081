import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import omit from "lodash.omit";
import clone from "clone";
import getConfig from "../utils/config";
import { FIREBASE, EMAILFORSIGNIN, BENTOUSER } from "@/common/constants";

firebase.initializeApp(getConfig(FIREBASE));

// Initialize Cloud Firestore through Firebase
const db = firebase.firestore();
// Timestamps stored in Cloud Firestore will be read back as
// Firebase Timestamp objects instead of as system Date objects.
const settings = {
  timestampsInSnapshots: true
};
db.settings(settings);

function serverTimestamp() {
  return firebase.firestore.FieldValue.serverTimestamp();
}

export default class Firestore {
  constructor(props) {
    this.collectionName = props.collectionName;
  }

  list(filter, field = "status", operator = "<=", value = 1) {
    /**
     * status 数据状态
     *  1 正常
     *  2 不可用
     *  3 已删除
     */
    return db
      .collection(this.collectionName)
      .where(field, operator, value)
      .get()
      .then(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id
          });
        });
        if (typeof filter === "function") return filter(data);
        return data;
      });
  }

  findById(id) {
    if (!id) {
      return;
    }
    return db
      .collection(this.collectionName)
      .doc(id)
      .get()
      .then(doc => {
        if (!doc.exists) {
          return null;
        } else {
          const data = doc.data();
          // 通过 ID 查询时，有数据就返回数据
          return {
            ...data,
            id: doc.id
          };
        }
      });
  }

  create(data) {
    if (Object.prototype.hasOwnProperty.call(data, "id")) {
      return db
        .collection(this.collectionName)
        .doc(data.id)
        .set({
          ...data,
          createdAt: serverTimestamp(),
          createdBy: getCurrentUser(),
          status: 1 // 正常
        })
        .then(() => data);
    } else {
      return db
        .collection(this.collectionName)
        .add({
          ...data,
          createdAt: serverTimestamp(),
          createdBy: getCurrentUser(),
          status: 1 // 正常
        })
        .then(docRef => ({ ...data, id: docRef.id }));
    }
  }

  update(data) {
    const copyDataWithoutId = omit(clone(data), ["id"]);
    return db
      .collection(this.collectionName)
      .doc(data.id)
      .update({
        ...copyDataWithoutId,
        updatedAt: serverTimestamp(),
        updatedBy: getCurrentUser()
      })
      .then(() => data);
  }

  remove(id) {
    return db
      .collection(this.collectionName)
      .doc(id)
      .update({
        deletedAt: serverTimestamp(),
        deletedBy: getCurrentUser(),
        status: 3 // 软删除
      })
      .then(() => ({ id }));
  }
}

// Firebase 身份验证
// 设置本地语言
firebase.auth().useDeviceLanguage();

const url = process.env.VUE_APP_PRODUCTION_URL;

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `${url}/callback`,
  // This must be true.
  handleCodeInApp: true
};

// 身份验证状态观察者
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    // User is signed in.
    localStorage.setItem(BENTOUSER, JSON.stringify(user));
  } else {
    // User is signed out.
    localStorage.removeItem(BENTOUSER);
  }
});

function getCurrentUser() {
  const user = JSON.parse(localStorage.getItem(BENTOUSER));
  // const user = firebase.auth().currentUser;
  return user
    ? {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        isAnonymous: user.isAnonymous,
        uid: user.uid
        // providerData: user.providerData
      }
    : user;
}

function isAuthenticated() {
  return !!getCurrentUser();
}

/**
 *
 * @param {Object} value 个人资料基本信息
 * {
 *   displayName: 用户的显示名
 *   photoURL: 个人资料照片网址
 * }
 */
function updateProfile(value) {
  const user = JSON.parse(localStorage.getItem(BENTOUSER));
  // const user = firebase.auth().currentUser;
  return new Promise((resolve, reject) => {
    user
      .updateProfile(value)
      .then(function() {
        resolve();
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
/**
 *
 * @param {String} email 电子邮件地址
 */
function updateEmail(email) {
  const user = JSON.parse(localStorage.getItem(BENTOUSER));
  // const user = firebase.auth().currentUser;
  return new Promise((resolve, reject) => {
    user
      .updateEmail(email)
      .then(function() {
        resolve();
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
/**
 *
 * @param {String} email 电子邮件地址
 */
function sendSignInLinkToEmail(email) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function() {
        window.localStorage.setItem(EMAILFORSIGNIN, email);
        resolve();
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function isSignInWithEmailLink() {
  return firebase.auth().isSignInWithEmailLink(window.location.href);
}
/**
 *
 * @param {String} email 电子邮件地址
 */
function signInWithEmailLink(email) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(function(result) {
        window.localStorage.removeItem(EMAILFORSIGNIN);
        resolve(result);
      })
      .catch(function(error) {
        window.localStorage.removeItem(EMAILFORSIGNIN);
        reject(error);
      });
  });
}

function signOut() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        resolve();
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function signInWithPopup(provider) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        resolve(result);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function signInWithGoogleProvider() {
  const provider = new firebase.auth.GoogleAuthProvider();
  // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  return signInWithPopup(provider);
}

export {
  db,
  getCurrentUser,
  isAuthenticated,
  updateProfile,
  updateEmail,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithGoogleProvider,
  signOut
};
