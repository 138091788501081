var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("scroll", { staticClass: "file-browser", attrs: { dir: "y" } }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "top sticky" }, [_vm._t("top")], 2),
        _vm._t("content")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }