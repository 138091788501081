<template lang="pug">
  select.f-p.size-input-s.color-button-normal.radius-m.ani-fast.pointer(@change="onChange" :value="value")
    //- 不需要此提示
    //- option(disabled value="") Please choose an option
    option(v-for="option in options", :value="option.toLowerCase()") {{ option }}
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

</style>

<script>
export default {
  name: "drop_selector",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    value: String
  },
  methods: {
    onChange(e) {
      return this.$emit("change", e.target.value);
    }
  }
};
</script>
