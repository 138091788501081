<template lang="pug">
  //- instance
  .label-layer.radius-s.pointer.ani-fast.color-text-2(:class="{ 'is-focused': isFocused }")
    icon.icon(:name="icon")
    .text.flex.ellipsis {{name}}
    .hasChildren.oval(v-show="hasChildren")
  //- .instance.clip.radius-s.ani-fast
    .label.flex.color-text-2 {{name}}
    .indicator
      .hasChildren.oval(v-show="hasChildren")
      //- .updateAvailable.pill.f-small.pointer.ani-fast.color-content-3() Update
      //- .updateAvailable 有更新版本时显示

</template>
<style lang="stylus" scoped>
@require '../../../../styles/layout/main';
@require '../../../../styles/design/main';

.label-layer
  // layout
  display inline-grid
  grid-template-columns auto 1fr auto
  grid-gap .5rem
  padding 0.5rem
  align-items center
.label-layer.is-focused
  color: var(--clr-content-reverse);
  background: var(--clr-primary);

.label-layer:not(.is-focused):hover
  background var(--clr-bg-2)

.icon
  width: 1rem
  height: 1rem

.text
  max-width 12rem
  min-width 100%

.indicator
  stack(x, ali: center, gap: 0.25rem)
  padding-right: 0.5rem

.hasChildren
  background: var(--clr-shadow-2)
  width: 0.25rem
  height: 0.25rem

.updateAvailable
  padding .125rem .25rem
  background var(--clr-shadow-2)

</style>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "layer.name"
    },
    icon: {
      type: String,
      default: "component/component"
    },
    hasChildren: {
      default: false
    },
    isFocused: {
      type: Boolean,
      default: false
    }
  }
};
</script>
