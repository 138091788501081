<template lang="pug">
  .layout-props
    //- 选择布局类型
    //- .title
      .flex.f-title.color-text Layout
      b-switcher(:value="layoutTypeSwitch" @change="(value) => this.layoutTypeSwitch = value" size="l")
    //- bRadioBtn
    //- template(v-if="layoutTypeSwitch")
    bRadioBtn(
      :options="[{label: 'Off', value: 'off'}, {label: 'Stack', value: 'stack'}, {label: 'Grid', value: 'grid'},  {label: 'Flow', value: 'gridflow'}]"
      :value="value.layoutType"
      @change="onLayoutTypeChange"
      )
    //- 特定布局类型的 editor
    Component(:is="layoutType" v-bind:value="layout" @change="onInnerChange" v-if="isOtherLayout")
    //- padding editor
    KeyValue(label="Padding" v-show="isOtherLayout")
      PaddingEditor(@change="onPaddingChange" :value="value.padding")
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.layout-props
  stack(y, gap: .5rem, arr: stretch)
.title
  stack(x, gap:.5rem)
</style>

<script>
import Stack from "./stack.vue";
import Gridflow from "./gridflow.vue";
import Grid from "./grid.vue";
import KeyValue from "@/components/prop_panel/component/label.vue";
import dotProp from "dot-prop";
import PaddingEditor from "@/components/form_element/padding.vue";

export default {
  name: "LayoutEditor",
  components: {
    PaddingEditor,
    KeyValue,
    Stack,
    Gridflow,
    Grid
  },
  props: ["value"],
  computed: {
    // 当前类型是否是除了 off 以外的其他布局
    isOtherLayout() {
      if (this.layoutType != "off") {
        return true;
      } else {
        return false;
      }
    },
    layoutType() {
      return this.value.layoutType;
    },
    layoutTypeSwitch: {
      get() {
        return this.value.layoutType != "off";
      },
      set(val) {
        if (val == false) {
          this.value.layoutType = "off";
          this.onChange();
          return;
        }
        this.value.layoutType = "stack";
        this.onChange();
      }
    },
    layout() {
      return this.value[this.layoutType];
    }
  },
  methods: {
    onLayoutTypeChange(value) {
      this.value.layoutType = value;
      this.onChange();
    },
    onPaddingChange(v) {
      this.value.padding = v;
      return this.onChange();
    },
    onInnerChange(value, key) {
      dotProp.set(this.value[this.layoutType], key, value);
      this.onChange();
    },
    onChange() {
      return this.$emit("change", this.value, "layout");
    }
  }
};
</script>
