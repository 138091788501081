var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("color-picker-chrome", {
    attrs: { value: _vm.color },
    on: { input: _vm.updateValue }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }