var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-wrap" }, [
    _c("div", { staticClass: "text", style: _vm.style }, [
      _vm._v(_vm._s(_vm.content))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }