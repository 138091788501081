import { set_height, set_width } from "../size/size";

function item(layer, container) {
  const sizeX = layer.position.size.x;
  const sizeY = layer.position.size.y;

  // 复写设置方法，绑定宽高大小
  const width_set = () => set_width(sizeX.default, sizeX.max, sizeX.min);
  const height_set = () => set_height(sizeY.default, sizeY.max, sizeY.min);

  if (container.dir == "x") {
    return {
      ...width_set(),
      height: "100%",
      maxHeight: "100%",
      minHeight: "100%"
    };
  } else if (container.dir == "y") {
    return {
      ...height_set(),
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%"
    };
  } else {
    return {
      ...height_set(),
      ...width_set()
    };
  }
}

export default item;
