<template lang="pug">
//- .proj-nav
  .left
    router-link.nav-btn.ani-fast.pointer.radius-m.btn-color(:to="{ name: 'ProjectBrowser', params: { id: team.selectedTeamId }}") Home
    .split /
    .nav-btn.ani-fast.pointer.radius-m.btn-color.input {{selectedProject.name}}
    .split /
    .proj-tabs.radius-m.clip
      .nav-btn.ani-fast(@click="changeTab('workspace')", :class="[ currentTab === 'workspace' ? 'current' : '' ]") Workspace
      .nav-btn.ani-fast(@click="changeTab('components')", :class="[ currentTab === 'components' ? 'current' : '' ]") Components
      .nav-btn.ani-fast(@click="changeTab('themes')", :class="[ currentTab === 'themes' ? 'current' : '' ]") Themes
    .split /
    .comp-nav.radius-m.clip
      //- .nav-btn.ani-fast.pointer.btn-color.prev-comp
        icon.icon(name="nav/arrow_filled")
      .nav-btn.ani-fast.comp-name {{this.selectedComponent.label}}
      //- .nav-btn.ani-fast.pointer.btn-color.next-comp
        icon.icon(name="nav/arrow_filled")
.nav-proj.b-z--unset
  //- left
  .left
    router-link.btn-back.radius-m.ani-fast.nav-btn.pointer(:to="{ name: 'ProjectBrowser', params: { id: team.selectedTeamId }}")
      img.logo-img(src='@/assets/logo/empty logo.png')
    icon.split(name="nav/arrow_line")
    input.proj-name.radius-m.ani-fast.size-input-m.f-title.nav-btn.ellipsis(:value="selectedProject.name" v-if="selectedProject" :class="{hover: focus}" @click="focus=true" @blur="focus=false" @input="handleInput")
    icon.split(name="nav/arrow_line")
    .tabs
      .tab.ani-fast.bold(@click="changeTab('workspace')", :class="[ currentTab === 'workspace' ? 'current' : '' ]") Workspace
      .tab.ani-fast.bold(@click="changeTab('components')", :class="[ currentTab === 'components' ? 'current' : '' ]") Components
    //- 临时隐藏 theme tab
    //- .tab.ani-fast.bold(@click="changeTab('themes')", :class="[ currentTab === 'themes' ? 'current' : '' ]") Themes
  //- right
  .right
    navExportBtn
  //- avatar(:email="email" @click.native="logout")
</template>

<style lang="stylus" scoped>

@require '~@/styles/layout/main'
@require '~@/styles/design/main'

// // preset
// .btn-color
//   // opacity .5
//   &:hover
//     opacity .75

// // elements
// .proj-nav
//   // layout
//   stack(x, arr: between)
//   // style
//   background var(--clr-bg-reverse)
//   .left
//     // layout
//     stack(x)
// .nav-btn
//   // background var(--clr-bg-reverse-2)
//   color var(--clr-content-reverse)
//   padding .75rem
//   &:hover
//     background var(--clr-content-3)
//     // background var(--clr-bg-2)
// .split
//   padding .75rem 0
//   color var(--clr-content-reverse)
//   opacity .5
// .comp-nav
// .proj-tabs
//   stack(x)
//   // box-shadow inset 0 0 0 1px var(--clr-bg-3)
//   color var(--clr-content-reverse)
// .proj-tabs
//   &>*:not(.current)
//     opacity .5
//     @extend .pointer
// .proj-tabs
//   &>*:not(.current)
//     @extend .btn-color
// .comp-nav
//   &>.btn-color
//     &:not(:hover)
//       opacity .5
//     .icon
//       width 12px
//       height 12px
// .prev-comp
// .next-comp
//   display inline-grid
//   place-content center
//   line-height 0
// .prev-comp
//   padding-right .25rem
// .next-comp
//   padding-left .25rem
//   .icon
//     transform rotateY(180deg)

// global
.nav-btn
  // padding .75rem 1rem
  &:hover
    background $gray-7

// left
.left
  stack(x, gap: .25rem, ali:center)
.right
  &>*
    position relative

.comp-name
  // 将来使用
  padding-left 0
  padding-right 0

.nav-proj
  background $gray-9
  color $gray-0
  stack(x, arr: between)
  padding .25rem
  .left
    stack(x, gap:.25rem)
.split
  width 6px
  height auto
  color $gray-4

.btn-back
  padding .25rem .5rem
  .logo-img
    height 1.5rem

.proj-name
  padding .5rem
  cursor pointer
  color $gray-0
  background transparent
  overflow hidden
  &:focus
    background black
    box-shadow inset 0 0 0 2px $gray-5

.tabs
  stack(x, ali:center)
.tab
  padding .5rem
  &.current
    // background var(--clr-bg-reverse)
    // color var(--clr-content-reverse)
    color $gray-0
    // box-shadow inset 0 -2px 0 0 $gray-1
    position relative
    &::before
      content ''
      // position
      position absolute
      bottom 2px
      width 1rem
      height 2px
      left calc(50% - .5rem)
      // style 
      background $gray-3
      border-radius 99rem
  &:not(.current)
    color $gray-3
    &:hover
      cursor pointer
.hover
  background var(--clr-bg-3)
  cursor pointer
</style>

<script>
import navExportBtn from "@/components/project_editor/component/nav.export.vue";
import { mapGetters, mapState } from "vuex";
import debounce from "lodash.debounce";
import { getCurrentUser, signOut } from "@/services/init.js";

export default {
  created() {
    this.id = this.$route.params.id;
  },
  data() {
    return {
      focus: false,
      email: getCurrentUser() ? getCurrentUser().email : "somebody@somewhere.com"
    };
  },
  components: {
    navExportBtn
  },
  computed: {
    ...mapGetters(["selectedComponent"]),
    ...mapState(["team"]),
    ...mapGetters(["selectedProject"]),
    currentTab: {
      set(val) {
        this.$store.state.ui.currentTab = val;
      },
      get() {
        return this.$store.state.ui.currentTab;
      }
    }
  },
  methods: {
    async logout() {
      await signOut();
      this.$router.push("/login");
    },
    handleInput: debounce(function(e) {
      const value = e.target.value;
      this.$store.dispatch("changeSelectedProject", {
        name: value
      });
    }, 600),
    changeTab: function(tab) {
      this.currentTab = tab;
      if (tab === "components") {
        this.$router.push({
          name: "ProjEditorIndex"
        });
      }

      if (tab == "workspace") {
        this.$router.push({
          name: "ProjWorkspace"
        });
      }
    }
  }
};
</script>
