<template lang="pug">
  .typography-props
    //- KeyValue(label="Typeface")
    TypefaceEditor(v-model="value.typeface")
    //- KeyValue(label="Fontscale")
    //- FontScaleEditor(v-model="value.fontScale")
    .inline
      KeyValue(label="Font Size")
        bInputSelector(
          :defaultOptions="['inherit']"
          v-model="value.fontSize"
        )
      KeyValue(label="Line Height")
        bInputSelector(
          :defaultOptions="addPencent"
          v-model="value.lineHeight"
        )
    KeyValue(label="Align")
      TextAlignEditor(v-model="value.textAlign")

</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.typography-props
  stack(y, gap: .5rem)

.inline
  display inline-grid
  grid-template-columns 1fr 1fr
  grid-column-gap .25rem
</style>

<script>
import KeyValue from "@/components/prop_panel/component/label.vue";
// import KeyValue from "@/components/prop_panel/component/label_width_prop_connector.vue";
import TypefaceEditor from "./typeface.vue";
import FontScaleEditor from "./fontscale.vue";
import TextAlignEditor from "./text_align.vue";
import SpaceEditor from "../space.vue";
import LineBreakEditor from "./line_break.vue";
import WordBreakEditor from "./word_break.vue";

export default {
  name: "TypographyEditor",
  components: {
    TypefaceEditor,
    FontScaleEditor,
    TextAlignEditor,
    SpaceEditor,
    LineBreakEditor,
    WordBreakEditor,
    KeyValue
  },
  model: {
    prop: "value",
    event: "change"
  },
  watch: {
    "value.typeface": function() {
      this.upload();
    },
    "value.fontScale": function() {
      this.upload();
    },
    "value.textAlign": function() {
      this.upload();
    },
    "value.textColor": function() {
      this.upload();
    },
    "value.letterSpacing": function() {
      this.upload();
    },
    "value.wordSpacing": function() {
      this.upload();
    },
    "value.lineBreak": function() {
      this.upload();
    },
    "value.wordBreak": function() {
      this.upload();
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      addPencent: [
        v => {
          if (v == "" || v == "inherit" || (v.indexOf && v.indexOf("%") != -1)) {
            return null;
          }
          if (/^[0-9]+|[0-9]+\.[0-9]*$/.test(v)) {
            return `${v}%`;
          }
          return null;
        },
        "inherit"
      ]
    };
  },
  methods: {
    upload() {
      this.$emit("change", this.value);
    }
  }
};
</script>
