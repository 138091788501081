import merge from "lodash.merge";
import { unset_height, unset_width, set_width, set_height } from "../size/size";

// 计算绝对定位的 align 类型
function abs_align_type(item) {
  // 给个简称
  const top = item.position.align.absolute.top;
  const bottom = item.position.align.absolute.bottom;
  const left = item.position.align.absolute.left;
  const right = item.position.align.absolute.right;

  // 定义 align 值
  function align(start, end) {
    if (start == "off" && end == "off") {
      return "center";
    } else if (start != "off" && end == "off") {
      return "start";
    } else if (start == "off" && end != "off") {
      return "end";
    } else if (start != "off" && end != "off") {
      return "stretch";
    } else {
      return "undefined";
    }
  }
  // 为每个方向分别确定 align 类型
  function x_align() {
    return align(left, right);
  }
  function y_align() {
    return align(top, bottom);
  }
  // 输出每个方向的 align 类型
  return {
    x: x_align,
    y: y_align
  };
}

function handleX(style, postion, absolute, handler) {
  switch (postion) {
    case "start":
      style = merge(
        {},
        style,
        {
          left: absolute.left + "px",
          right: "unset"
        },
        handler.set()
      );
      break;
    case "end":
      style = merge(
        {},
        style,
        {
          left: "unset",
          right: absolute.right + "px"
        },
        handler.set()
      );
      break;
    case "stretch":
      style = merge(
        {},
        style,
        {
          left: absolute.left + "px",
          right: absolute.right + "px"
        },
        handler.unset()
      );
      break;
    case "center":
      style = merge(
        {},
        style,
        {
          left: "unset",
          right: "unset"
        },
        handler.set()
      );
      break;
  }
  return style;
}

function handleY(style, postion, absolute, handler) {
  switch (postion) {
    case "start":
      style = merge(
        {},
        style,
        {
          top: absolute.top + "px",
          bottom: "unset"
        },
        handler.set()
      );
      break;
    case "end":
      style = merge(
        {},
        style,
        {
          top: "unset",
          bottom: absolute.bottom + "px"
        },
        handler.set()
      );
      break;
    case "stretch":
      style = merge(
        {},
        style,
        {
          top: absolute.top + "px",
          bottom: absolute.bottom + "px"
        },
        handler.unset()
      );
      break;
    case "center":
      style = merge(
        {},
        style,
        {
          top: "unset",
          bottom: "unset"
        },
        handler.set()
      );
      break;
  }
  return style;
}

function handleCenter(style, x, y) {
  if (x == "center" && y != "center") {
    style = merge({}, style, {
      left: "50%",
      transform: "translateX(-50%)"
    });
  } else if (x != "center" && y == "center") {
    style = merge({}, style, {
      top: "50%",
      transform: "translateY(-50%)"
    });
  } else if (x == "center" && y == "center") {
    style = merge({}, style, {
      top: "50%",
      left: "50%",
      transform: "translateY(-50%) translateX(-50%)"
    });
  }
  return style;
}

const item = item => {
  const absolute = item.position.align.absolute;
  const sizeX = item.position.size.x;
  const sizeY = item.position.size.y;

  const { x, y } = abs_align_type(item);

  let style = {
    position: "absolute"
  };

  // 复写设置方法，绑定宽高大小
  const width_set = () => set_width(sizeX.default, sizeX.max, sizeX.min);
  const width_unset = () => unset_width();
  const height_set = () => set_height(sizeY.default, sizeY.max, sizeY.min);
  const height_unset = () => unset_height();

  style = handleX(style, x(), absolute, { set: width_set, unset: width_unset });
  style = handleY(style, y(), absolute, { set: height_set, unset: height_unset });

  style = handleCenter(style, x(), y());

  return style;
};

export default item;
