<template lang="pug">
  .imgalign-editor
    b-radio-btn(
      :options="valueOptions"
      :value="value.objectfit"
      @change="onAlignChange"
    )
    .none-editor(v-show="showObjPos")
      b-radio-btn(
        :options="xOptions"
        :value="value.alignX"
        @change="onAlignXChange"
      )
      b-radio-btn(
        :options="yOptions"
        :value="value.alignY"
        @change="onAlignYChange"
        rotate
      )
</template>

<style lang="stylus" scoped>
.imgalign-editor
  display inline-grid
  grid-gap .5rem
.none-editor
  display inline-grid
  grid-template-columns 1fr 1fr
  grid-gap .25rem
</style>


<script>
export default {
  name: "ImageAlign",
  model: {
    prop: "value",
    event: "change"
  },
  computed: {
    showObjPos() {
      if (this.value.objectfit == "none" || this.value.objectfit == "cover") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      valueOptions: [
        { icon: "imgalign/cover", value: "cover" },
        { icon: "imgalign/fill", value: "fill" },
        { icon: "imgalign/contain", value: "contain" },
        { icon: "imgalign/none", value: "none" }
      ],
      xOptions: [
        { icon: "alignself/start", value: "left" },
        { icon: "alignself/center", value: "center" },
        { icon: "alignself/end", value: "right" }
      ],
      yOptions: [
        { icon: "alignself/start", value: "top" },
        { icon: "alignself/center", value: "center" },
        { icon: "alignself/end", value: "bottom" }
      ]
    };
  },
  props: {
    rotate: false,
    value: {
      type: Object
    }
  },
  methods: {
    onAlignChange(v) {
      this.value.objectfit = v;
      this.$emit("change", this.value);
    },
    onAlignXChange(v) {
      this.value.alignX = v;
      this.$emit("change", this.value);
    },
    onAlignYChange(v) {
      this.value.alignY = v;
      this.$emit("change", this.value);
    }
  }
};
</script>
