var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "typography-props" },
    [
      _c("TypefaceEditor", {
        model: {
          value: _vm.value.typeface,
          callback: function($$v) {
            _vm.$set(_vm.value, "typeface", $$v)
          },
          expression: "value.typeface"
        }
      }),
      _c(
        "div",
        { staticClass: "inline" },
        [
          _c(
            "KeyValue",
            { attrs: { label: "Font Size" } },
            [
              _c("bInputSelector", {
                attrs: { defaultOptions: ["inherit"] },
                model: {
                  value: _vm.value.fontSize,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "fontSize", $$v)
                  },
                  expression: "value.fontSize"
                }
              })
            ],
            1
          ),
          _c(
            "KeyValue",
            { attrs: { label: "Line Height" } },
            [
              _c("bInputSelector", {
                attrs: { defaultOptions: _vm.addPencent },
                model: {
                  value: _vm.value.lineHeight,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "lineHeight", $$v)
                  },
                  expression: "value.lineHeight"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Align" } },
        [
          _c("TextAlignEditor", {
            model: {
              value: _vm.value.textAlign,
              callback: function($$v) {
                _vm.$set(_vm.value, "textAlign", $$v)
              },
              expression: "value.textAlign"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }