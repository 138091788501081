<template lang="pug">
//- TODO：space 值为负数时，对应 ruler 添加 .negative 
//- 显示属性值，而不是 CSS值
transition(name="fade")
  .measure.ani-fast(v-if="show")
    //- left
    .ruler-container.left(v-show="left != 'off'" :class="{revert: toInt(left).negative}")
      ruler.x(:style="{width: toInt(left).value + 'px'}")
        value(:valueText="toInt(left).raw" v-show="left != 'off'")
    //- right
    .ruler-container.right(v-show="right != 'off' && notWorkspace" :class="{revert: toInt(right).negative}")
      ruler.x(:style="{width: toInt(right).value + 'px'}")
        value(:valueText="toInt(right).raw" v-show="right != 'off'")
    //- top
    .ruler-container.top(v-show="top != 'off'" :class="{revert: toInt(top).negative}")
      ruler.y(:style="{height: toInt(top).value + 'px'}")
        value(:valueText="toInt(top).raw" v-show="top != 'off'")
    //- bottom
    .ruler-container.bottom(v-show="bottom != 'off' && notWorkspace" :class="{revert: toInt(bottom).negative}")
      ruler.y(:style="{height: toInt(bottom).value + 'px'}")
        value(:valueText="toInt(bottom).raw" v-show="bottom != 'off'")
    //- width & height
    //- .ruler-container.width
    //-   ruler.x
    //- .ruler-container.height
    //-   ruler.y
    //- .ruler-container.size
    //-   value(valueText="w: 123; h: 123")
    value.pin(:valueText="info")
</template>

<style lang="stylus" scoped>
.measure
  pointer-events none
  display inline-block
  &>*
    position absolute

.ruler-container
  display inline-grid
  place-content center
  //   background blue

.ruler-container.left
  height 100%
  right 100%
  & > *
    height 1rem
.ruler-container.right
  height 100%
  left 100%
  & > *
    height 1rem
.ruler-container.top
  bottom 100%
  width 100%
  & > *
    width 1rem
.ruler-container.bottom
  top 100%
  width 100%
  & > *
    width 1rem
.ruler-container.width
.ruler-container.height
  position absolute
  top 0
  bottom 0
  right 0
  left 0
.ruler-container.width
  place-content center stretch
  & > *
    height 1rem
.ruler-container.height
  place-content stretch center 
  & > *
    width 1rem
.revert
  &.top
    transform translateY(100%)
  &.bottom
    transform translateY(-100%)
  &.left
    transform translateX(100%)
  &.right
    transform translateX(-100%)
.pin
  // right .5rem
  // top .5rem
  left calc(100% + .5rem)
  bottom calc(100% + .5rem)

// animation
.fade-enter, .fade-leave-to
  opacity: 0;

</style>


<script>
import ruler from "./component/ruler";
import value from "./component/value";
// import dotProp from "dot-prop";

export default {
  components: {
    ruler,
    value
  },
  props: {
    positionName: {
      type: String,
      default: "absolute"
    }
  },
  computed: {
    layer() {
      return this.$store.getters.foucsLayer;
    },
    notWorkspace() {
      return this.layer && this.layer.position.activeLayout != "workspace";
    },
    show() {
      /* eslint-disable-next-line */
      // 是根元素就不显示标尺
      if (this.layer && this.layer.position && this.layer.position.activeLayout === "componentpreview") {
        return false;
      }
      return (
        Boolean(this.layer && this.layer.position && this.layer.position.align && this.layer.position.align.absolute) &&
        this.$store.state.ui.showMeasure
      );
    },
    left() {
      /* eslint-disable-next-line */
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName] &&
        this.layer.position.align[this.positionName].left
      );
    },
    top() {
      /* eslint-disable-next-line */
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName] &&
        this.layer.position.align[this.positionName].top
      );
    },
    bottom() {
      /* eslint-disable-next-line */
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName] &&
        this.layer.position.align[this.positionName].bottom
      );
    },
    right() {
      /* eslint-disable-next-line */
      return (
        this.layer &&
        this.layer.position &&
        this.layer.position.align &&
        this.layer.position.align[this.positionName] &&
        this.layer.position.align[this.positionName].right
      );
    },
    width() {
      return this.layer && this.layer.position && this.layer.position.size.x.default;
    },
    height() {
      return this.layer && this.layer.position && this.layer.position.size.y.default;
    },
    info() {
      return `w: ${this.width}; h: ${this.height}`;
    }
  },
  methods: {
    toInt(val) {
      try {
        val = parseInt(val);
        return {
          negative: val < 0 ? true : false,
          value: Math.abs(val).toString(),
          raw: val.toString()
        };
      } catch (e) {
        /* eslint-disable-next-line */
        console.error(e);
      }
      return {
        negative: false,
        value: 0
      };
    }
  }
};
</script>
