// 和 Input 重复了
<template lang="pug">
  input.input.f-p.size-input-s.radius-m.ani-fast(
    :placeholder="placeholder"
    :value="value"
    v-on:change="upload"
  )
    
</template>

<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.input
  background var(--clr-bg-2)
  &:hover
    background var(--clr-bg-3)
  &:focus
    background var(--clr-bg)
    box-shadow inset 0 0 0 2px var(--clr-bg-3)
  &::placeholder
    color var(--clr-content-3)

</style>


<script>
export default {
  name: "StringEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    param: String
  },
  methods: {
    upload(e) {
      this.$emit("change", e.target.value, this.param);
      this.$el.blur();
    }
  }
};
</script>
