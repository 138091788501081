<template lang="pug">
  .stack-props
    KeyValue(label="Direction")
      bRadioBtn(
        :options="[{label: 'x', value: 'x'}, {label: 'y', value: 'y'}]"
        :value="value.direction"
        @change="(v) => buildChange(v, 'direction')"
        )
    KeyValue(label="Arrange")
      ArrangeEditor(@change="(v) => buildChange(v, 'arrange')" :value="value.arrange" :rotate="rotate_icon")
    KeyValue(label="Align")
      AlignEditor(@change="(v) => buildChange(v, 'align')" :value="value.align" :rotate="!rotate_icon")
    KeyValue(label="Gap")
      SpaceEditor(@change="(v) => buildChange(v, 'gap')" :value="gap")
</template>
<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.stack-props
  stack(y, gap: .5rem)

</style>
<script>
import KeyValue from "@/components/prop_panel/component/label.vue";
import SpaceEditor from "@/components/form_element/space.vue";
import AlignEditor from "@/components/form_element/alignitems.vue";
import ArrangeEditor from "@/components/form_element/arrange.vue";

export default {
  name: "LayoutStack",
  components: {
    KeyValue,
    SpaceEditor,
    AlignEditor,
    ArrangeEditor
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          padding: "0",
          direction: "x",
          arrange: "start",
          align: "stretch",
          gap: "0"
        };
      }
    }
  },
  computed: {
    rotate_icon() {
      if (this.value.direction === "y") {
        return true;
      }
    },
    gap() {
      return this.isPixel(this.value && this.value.gap) ? Number.parseFloat(this.value.gap) : this.value.gap;
    }
  },
  methods: {
    isPixel(value) {
      const pixelRegx = /^\d+(\.\d+)?px$/;
      return pixelRegx.test(value);
    },
    buildChange(v, key) {
      return this.onChange(v, key);
    },
    onChange(value, key) {
      return this.$emit("change", value, key);
    }
  }
};
</script>
