export default function render(param) {
  if (param == "break") {
    return {
      whiteSpace: "normal"
    };
  }

  if (param == "visible") {
    return {
      whiteSpace: "nowrap"
    };
  }

  if (param == "ellipsis") {
    return {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    };
  }

  if (param == "hidden") {
    return {
      whiteSpace: "nowrap",
      overflow: "hidden"
    };
  }
}
