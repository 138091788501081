<template lang="pug">
  scroll(
    dir='y'
    padding='.5rem'
    hideBar
    v-shortkey.once="['shift', 'd']"
    @shortkey.native="deleteCurrentNode"
  ).pointer-event-off
    .tree.pointer-event-on.radius-m.shd-m(layout="stack" dir='y' padding='.5rem' v-if="rootLayer")
      //- 根据实例树自动生成
      //- node(:insType='rootLayer.icon || rootLayer.type' :name='rootLayer.name' :children='rootLayer.children' :currentSlots="rootLayer.slots" :root="true" :isExpand="rootLayer.isExpand" :id="rootLayer.id")
      nodePure(v-if="isPure" :tree="rootLayer")
      nodeSlotly(v-else :tree="rootLayer")
      //- nodePure
      //-   nodePure
      //-     nodePure
      //-   nodePure
      //-     nodeSlotly

</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.tree
  background var(--clr-bg)
  stack(y)
  padding .5rem
</style>

<script>
import { mapGetters } from "vuex";
import nodePure from "./component/node/pure.vue";
import nodeSlotly from "./component/node/slotly.vue";

export default {
  name: "InstanceViewer",
  components: {
    nodePure,
    nodeSlotly
  },
  data() {
    return {};
  },
  methods: {
    deleteCurrentNode() {
      this.$store.dispatch("deleteLayer", this.$store.state.layer.layerId);
    }
  },
  computed: {
    ...mapGetters(["rootLayer"]),
    isPure() {
      return Object.keys(this.rootLayer.children).length <= 1;
    }
  }
};
</script>
