import Firestore from "./init.js";

const firestorePublishedInst = new Firestore({ collectionName: "published" });

export async function publish(data) {
  return await firestorePublishedInst.create({
    layers: data.layers,
    components: data.components,
    component: data.component,
    version: data.version
  });
}
