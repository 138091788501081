var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c("input", {
      ref: "input",
      staticClass: "f-p size-input-s color-input-sub radius-m ani-fast",
      class: { warn: _vm.hasError },
      attrs: { placeholder: _vm.placeholder },
      domProps: { value: _vm.currValue },
      on: { input: _vm.onInput, change: _vm.onChange }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }