import { eqProp } from "../helpers";
import { project as projectService, component as componentService } from "../services";
import map from "lodash.map";
import clone from "clone";

//
// ─── STATE ──────────────────────────────────────────────────────────────────────
//

const state = {
  projects: [],
  selectedProjectId: 0 // 路由的 project id
};

//
// ─── GETTERS ────────────────────────────────────────────────────────────────────
//

const getters = {
  selectedProject({ projects, selectedProjectId }) {
    return projects.find(eqProp("id", selectedProjectId));
  },
  existProject: ({ projects }) => id => {
    return projects.find(eqProp("id", id));
  },
  project: ({ projects }) => id => {
    return projects.find(eqProp("id", id));
  }
};

//
// ─── MUTATIONS ──────────────────────────────────────────────────────────────────
//

const mutations = {
  setSelectedProjectId(state, { id }) {
    state.selectedProjectId = id;
  },
  changeSelectedProject(state, params) {
    const project = state.projects.find(eqProp("id", state.selectedProjectId));
    Object.assign(project, params);
  },
  setSelectedProjectIdByName(state, { name }) {
    const project = state.projects.find(eqProp("name", name));
    if (!project) {
      return;
    }
    state.selectedProjectId = project.id;
  },
  addNewComponentToSeletedProject(state, { id }) {
    const project = state.projects.find(eqProp("id", state.selectedProjectId));
    if (!project) {
      return;
    }
    if (!project.componentIds.includes(id)) {
      project.componentIds.push(id);
    }
  },
  removeComponentFromSeletedProject(state, { id }) {
    const project = state.projects.find(eqProp("id", state.selectedProjectId));
    if (!project) {
      return;
    }
    project.componentIds = project.componentIds.filter(_id => _id != id);
  },
  pushProject(state, { project }) {
    // 已存在
    if (state.projects.some(p => p.id == project.id)) {
      return;
    }
    state.projects.push(project);
  },
  replaceProject(state, { projects }) {
    state.projects = projects;
  },
  deleteProject(state, { id }) {
    state.projects = state.projects.filter(p => p.id != id);
  }
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//

const actions = {
  async fetchProject({ commit }, { id }) {
    try {
      let project = await projectService.findById(id);
      const components = await Promise.all(map(project.componentIds, componentService.findById));
      commit("pushComponents", { components });

      // 替换源数据
      commit("pushProject", {
        project
      });
    } catch (error) {
      alert(error);
    }
  },
  async setSelectedProjectId({ commit, getters, dispatch }, { id }) {
    commit("setSelectedProjectId", { id });
    let project = getters.project(id);
    if (!project) {
      project = await projectService.findById(id);
      commit("pushProject", { project });
    }
    await Promise.all(
      map(project.componentIds, componentId => {
        if (getters.existComponent(componentId)) {
          return false;
        }
        return dispatch("fetchComponentById", { id: componentId });
      })
    );
  },
  async fetchProjects({ commit }) {
    try {
      let projects = await projectService.list();
      await Promise.all(
        map(projects, async project => {
          const components = await Promise.all(map(project.componentIds, componentService.findById));
          commit("pushComponents", { components });
        })
      );
      // 替换源数据
      commit("replaceProject", {
        projects
      });
      // 选中第一个
      // commit("setSelectedProjectId", {
      //   id: projects[0].id
      // });
    } catch (error) {
      alert(error);
    }
  },
  async createProject({ commit, dispatch }, { name }) {
    // 生成 workspace layer
    const layerId = await dispatch("createWorkspaceLayer");
    const project = await projectService.create(name, layerId);
    commit("setSelectedProjectId", { id: project.id });
    commit("pushProject", { project });
    await dispatch("addNewProjectToSeletedTeam", { id: project.id });
    return project.id;
  },
  async addNewComponentToSeletedProject({ state, commit }, { id }) {
    let componentId = id;
    let project = state.projects.find(eqProp("id", state.selectedProjectId));
    project = clone(project); // 避免持有引用
    project.componentIds.push(componentId);
    await projectService.update(project);
    commit("addNewComponentToSeletedProject", { id });
  },
  async removeComponentFromSeletedProject({ commit, getters }, { id }) {
    let project = getters.selectedProject;
    project = clone(project); // 避免持有引用
    project.componentIds = project.componentIds.filter(i => i != id);
    await projectService.update(project);
    commit("removeComponentFromSeletedProject", { id });
  },
  async deleteProject({ commit, dispatch }, { id }) {
    commit("deleteProject", { id });
    await projectService.remove(id);
    dispatch("removeProjectFromSeletedTeam", { id });
  },
  async changeSelectedProject({ commit, getters }, params) {
    commit("changeSelectedProject", params);
    await projectService.update(getters.selectedProject);
  }
};

export default { getters, actions, mutations, state };
