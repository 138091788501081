import clone from "clone";
import basicBuiltInComponents from "@/components/preview_area/built-in component/builtIn.js";
import { publish } from "@/services/publish.js";
// import { eqProp, notEq } from "../helpers";

const basicComponentNames = basicBuiltInComponents.map(c => c.id);

// 扫描组件树，生成快照
function generateSnapshot(rootLayer, getters) {
  const layers = [];
  const components = [];

  function getLayers(layer) {
    layers.push(clone(layer));

    let componentId = layer.componentId;

    if (!basicComponentNames.includes(componentId) && componentId !== "workspace") {
      if (componentId.startsWith("code")) {
        componentId = componentId.replace("code", "");
      }
      const component = getters.component(componentId);
      components.push(clone(component));

      if (component && Reflect.has(component, "root")) {
        getLayers(getters.layer(component.root));
      }
    }

    if (layer.children && Object.keys(layer.children).length > 0) {
      for (let slot of Object.keys(layer.children)) {
        for (let childId of layer.children[slot]) {
          getLayers(getters.layer(childId));
        }
      }
    }
  }
  getLayers(rootLayer);

  return {
    layers,
    components
  };
}
//
// ─── STATE ──────────────────────────────────────────────────────────────────────
//

const state = {};

//
// ─── GETTERS ────────────────────────────────────────────────────────────────────
//

const getters = {};

//
// ─── MUTATIONS ──────────────────────────────────────────────────────────────────
//

const mutations = {};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//

const actions = {
  // eslint-disable-next-line
  async publishComponent({ getters }, payload) {
    try {
      const snapshot = generateSnapshot(getters.rootLayer, getters);
      const params = {
        layers: snapshot.layers,
        components: snapshot.components,
        component: clone(getters.selectedComponent),
        version: payload.version
      };
      const data = await publish(params);
      // TODO: 发布成功，合成发布之后的预览链接
      // eslint-disable-next-line
      console.log(data) // 临时处理
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }
};

export default { getters, actions, mutations, state };
