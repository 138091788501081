var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg radius-l shd-xl" }, [
    _vm._m(0),
    _c("div", { staticClass: "input-name" }, [
      _c("div", { staticClass: "color-text-2 f-title" }, [
        _vm._v("What is your new team called?")
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.teamName,
            expression: "teamName"
          }
        ],
        staticClass: "size-input-m radius-m ani-fast f-title color-input-gray",
        attrs: { placeholder: _vm.defaultName },
        domProps: { value: _vm.teamName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.teamName = $event.target.value
          }
        }
      })
    ]),
    _c(
      "div",
      { staticClass: "buttonbar" },
      [
        _c(
          "b-btn",
          {
            attrs: { color: "gray" },
            nativeOn: {
              click: function($event) {
                return _vm.closeTeamCreator($event)
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "b-btn",
          {
            attrs: { color: "dark" },
            nativeOn: {
              click: function($event) {
                return _vm.createTeam($event)
              }
            }
          },
          [_vm._v("Create")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "f-h3 color-text" }, [
        _vm._v("Create a new team")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }