<template lang="pug">
  .master
    KeyValue(label="AlignSelf")
      AlignEditor(
        @change="onAlignSelfChange"
        :value="align.alignSelf"
      )
      //- AlignEditor(
        @change="onAlignSelfChange"
        :value="align.alignSelf"
        :options="['off', 'start', 'center', 'end', 'stretch']"
        )
    //- KeyValue(label="Flex" x extend)
      Switcher(@change="onFlexChange" :value="align.flex")
</template>

<style lang="stylus" scoped>
@require '../../../../styles/layout/main'
@require '../../../../styles/design/main'

.master
  stack(y, gap: .5rem)
</style>

<script>
import Switcher from "@/components/form_element/boolean/switcher.vue";
import KeyValue from "@/components/prop_panel/component/label.vue";

import AlignEditor from "@/components/form_element/alignself.vue";
export default {
  name: "stackEditor",
  components: {
    KeyValue,
    AlignEditor,
    Switcher
  },
  props: {
    align: {
      type: Object,
      default() {
        return {
          alignSelf: "off",
          flex: false
        };
      }
    }
  },
  methods: {
    onAlignSelfChange(v) {
      return this.onChange(v, "align.stack.alignSelf");
    },
    onFlexChange(v) {
      return this.onChange(v, "align.stack.flex");
    },
    onChange(value, param) {
      return this.$emit("change", value, param);
    }
  }
};
</script>
