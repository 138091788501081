var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c("input", {
      ref: "input",
      staticClass: "f-p size-input-s color-input-sub radius-m ani-fast",
      attrs: {
        type: "number",
        max: _vm.max,
        min: _vm.min,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder
      },
      domProps: { value: _vm.value },
      on: {
        input: _vm.onInput,
        paste: function($event) {
          $event.preventDefault()
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }