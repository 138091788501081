import { FIREBASE, UNSPLASH, GOOGLEAPIKEY } from "@/common/constants.js";

const config = new Map()
  .set("production", {
    [FIREBASE]: {
      apiKey: "AIzaSyCCI-aWYKaBcEvS-n_mi8fXFrI9FMBjMbs",
      authDomain: "make-bento.firebaseapp.com",
      storageBucket: "gs://make-bento.appspot.com",
      projectId: "make-bento"
    },
    [UNSPLASH]: {
      applicationId: "a1b70569b5de6f97ac0b258d28c2ad69db0235d0ba1fa79116fedad6a856df42",
      secret: "fa467fd9ad288abb1896f034e310d6e636bfb81de753259d331cef928a65ce71",
      callbackUrl: "urn:ietf:wg:oauth:2.0:oob"
    },
    [GOOGLEAPIKEY]: "AIzaSyBdqi21m40MlUBUCelMmzjLFf3PGGNMRPg"
  })
  .set("development", {
    [FIREBASE]: {
      apiKey: "AIzaSyAyJNz2nWdDN_OilwUgmWjNhe4TBKVuRCs",
      authDomain: "bento-192813.firebaseapp.com",
      databaseURL: "https://bento-192813.firebaseio.com",
      projectId: "bento-192813",
      storageBucket: "bento-192813.appspot.com",
      messagingSenderId: "30743580075"
    },
    [UNSPLASH]: {
      applicationId: "a1b70569b5de6f97ac0b258d28c2ad69db0235d0ba1fa79116fedad6a856df42",
      secret: "fa467fd9ad288abb1896f034e310d6e636bfb81de753259d331cef928a65ce71",
      callbackUrl: "urn:ietf:wg:oauth:2.0:oob"
    },
    [GOOGLEAPIKEY]: "AIzaSyBdqi21m40MlUBUCelMmzjLFf3PGGNMRPg"
  });

const getConfig = env => type => (config.get(env) || {})[type];

export default getConfig(process.env.VUE_APP_API_ENV);
