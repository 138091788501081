import fontScale from "./fontscale";
import typeface from "./typeface";
import letterSpacing from "./letterspacing";
import wordSpacing from "./wordspacing";
import textColor from "../textcolor";
import textAlign from "./textalign";
import lineBreak from "./linebreak";
import wordBreak from "./wordbreak";
import lineHeight from "./lineheight";
import fontSize from "./fontsize";

const renderFNs = {
  lineHeight,
  fontSize,
  fontScale,
  typeface,
  letterSpacing,
  wordSpacing,
  textColor,
  textAlign,
  lineBreak,
  wordBreak
};

export default function render(typograph) {
  return Object.keys(typograph).reduce((style, current) => {
    const param = typograph[current];
    const renderFN = renderFNs[current] || (() => {});

    style = {
      ...renderFN(param, typograph),
      ...style
    };
    return style;
  }, {});
}
