import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

let inHistory = false;

//
// ─── LOCAL DEP ──────────────────────────────────────────────────────────────────────
//

//
// ─── MUDULE ─────────────────────────────────────────────────────────────────────
//

import publish from "./publish";
import fonts from "./fonts";
import unsplash from "./unsplash";
import project from "./project";
import component from "./component";
import team from "./team";
import layer from "./layer";
import history from "./history";

export default new Vuex.Store({
  modules: {
    publish,
    fonts,
    unsplash,
    team,
    project,
    component,
    layer
  },
  state: {
    ui: {
      optionSelector: {
        visible: false,
        options: [],
        value: "",
        active: "",
        keyword: "",
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        eventName: ""
      },
      colorPicker: {
        visible: false,
        value: "#000000",
        top: 0,
        left: 0
      },
      imageSelector: false,
      showUploadComponentDialog: false,
      teamCreator: false,
      currentTab: "workspace",
      menus: [],
      menusConfig: {
        left: 0,
        top: 0
      },
      isDrag: false,
      hoverLayerId: 0,
      dragThumbnail: {
        isDragging: false,
        icon: "",
        label: "",
        style: {
          top: 0,
          left: 0
        }
      },
      showMeasure: false,
      needSetImageLayerSrc: "",
      floatingStartDrop: false,
      selectmode: "",
      showFloatingEditor: true,
      selectedLevel: 0
    }
  },
  getters: {
    // 组件的 root 是 layer 的 id
    layerIsRoot: (_, getters) => ({ id }) => {
      return getters.selectedComponent.root == id;
    },
    hasRootLayer(_, getters) {
      return getters.selectedComponent && getters.selectedComponent.root;
    },
    rootLayer(state, getters) {
      if (!getters.hasRootLayer) {
        return null;
      }
      return state.layer.layers.find(i => i.id == getters.selectedComponent.root);
    },
    selectedProjectComponent(state, getters) {
      if (!getters.selectedProject) {
        return null;
      }
      const cids = getters.selectedProject.componentIds;
      return state.component.components.filter(c => c && cids.includes(c.id));
    },
    /**
     * 当前 Team 的自己的 project
     */
    selectedTeamProject(state, getters) {
      if (!getters.selectedTeam) {
        return [];
      }
      const pids = getters.selectedTeam.projectIds;
      return state.project.projects.filter(p => pids.includes(p.id));
    },
    selectedTeamProjectComponent(state, getters) {
      const selectedTeamProject = getters.selectedTeamProject;
      return selectedTeamProject.reduce((arr, project) => {
        let cids = project.componentIds;
        const components = state.component.components.filter(c => cids.includes(c.id));
        arr = arr.concat(components);
        return arr;
      }, []);
    },
    /**
     * 当前 Team 的外部 project
     */
    selectedTeamExtenalProject(state, getters) {
      if (!getters.selectedTeam) {
        return [];
      }
      const epids = getters.selectedTeam.extenalProjectIds;
      return state.project.projects.filter(p => epids.includes(p.id));
    },
    // 当选 Team 所有外部项目的 componetId 数组
    selectedTeamExtenalProjectComponentIds(state, getters) {
      const allExtenalProject = getters.selectedTeamExtenalProject;
      const allExtenalProjectComponentIds = allExtenalProject.reduce((prev, project) => {
        prev = prev.concat(project.componentIds);
        return prev;
      }, []);
      return allExtenalProjectComponentIds;
    },
    /**
     * 当前 Team 可选择的所有 project
     */
    selectedTeamAllProjects(state, getters) {
      return [...getters.selectedTeamExtenalProject, ...getters.selectedTeamProject];
    },
    projectNames(state, getters) {
      return getters.selectedTeamAllProjects.map(p => p.name);
    },
    projectName(state, getters) {
      return getters.selectedProject ? getters.selectedProject.name : "";
    },
    projectEditable(state, getters) {
      return getters.selectedProject ? getters.selectedProject.editable : false;
    },
    currentComponentAllLayersHasLevel(state, getters) {
      const layers = [];

      const findChildren = (layer, level = 0) => {
        if (typeof layers[level] == "undefined") {
          layers[level] = [];
        }
        if (typeof layer == "undefined" || !layer.children) {
          return;
        }
        for (let slotname of Object.keys(layer.children)) {
          for (let id of layer.children[slotname]) {
            layers[level].push(getters.layer(id));
            findChildren(getters.layer(id), level + 1);
          }
        }
      };
      if (
        getters.selectedComponent &&
        getters.selectedComponent.root &&
        getters.layer(getters.selectedComponent.root)
      ) {
        findChildren(getters.layer(getters.selectedComponent.root));
      }

      return layers;
    },
    currentComponentAllLayers(state, getters) {
      return getters.currentComponentAllLayersHasLevel.reduce((acc, cur) => acc.concat(cur), []);
    }
  },
  mutations: {
    needSetImageLayerSrc(state, payload) {
      state.ui.needSetImageLayerSrc = payload;
    },
    setUploadVueDialogStatus(state, params) {
      if (params) {
        return (state.ui.showUploadComponentDialog = params);
      }
      // toggle
      state.ui.showUploadComponentDialog = !state.ui.showUploadComponentDialog;
    },
    setUI(state, { key, value }) {
      state.ui[key] = value;
    },
    dragThumbnailLabel: (state, { icon, label }) => {
      icon && (state.ui.dragThumbnail.icon = icon);
      label && (state.ui.dragThumbnail.label = label);
    },
    mousePozSet: (state, { x, y }) => {
      // 暂不确定为何有 0 出现
      if (x == 0 && y == 0) {
        return;
      }
      state.ui.dragThumbnail.style.top = y + "px";
      state.ui.dragThumbnail.style.left = x + "px";
    },
    mousePozToggle: (state, bool) => {
      state.ui.dragThumbnail.isDragging = bool;
      // reset
      if (state.ui.dragThumbnail.isDragging == false) {
        state.ui.dragThumbnail.label = "";
        state.ui.dragThumbnail.icon = "";
        state.ui.dragThumbnail.style = {
          top: 0,
          left: 0
        };
      }
    }
  },
  actions: {
    async redo({ state }) {
      if (inHistory) {
        return;
      }
      inHistory = true;
      await history.redo(state);
      inHistory = false;
    },
    async undo({ state }) {
      if (inHistory) {
        return;
      }
      inHistory = true;
      await history.undo(state);
      inHistory = false;
    }
  }
});
