// 一个像 finder 一样的浏览器，显示当前项目下的所有组件

<template lang="pug">
  //- v2
  scroll(dir="y" padding="2rem")
    //- empty
    .empty.abs.ani(v-if="selectedProjectComponent <= 0")
      .f-h3.color-text-1 No Component in this team.
      b-btn(color="dark" size="l" @click.native="addNewComponent") + New Component
    //- not empty
    .comp-finder(v-else)
      .comp-finder--top
        b-btn(size="m" color="dark" @click.native="addNewComponent" @click.alt.native="openFile") + New Component
      .comp-finder--body.flex
        ListItem(
          v-for='(item, index) in selectedProjectComponent'
          :progress="item.progress"
          :label="item.label"
          :design="isDesign(item)"
          :icon="icon(item.type)"
          :id="item.id"
          :key="item.id"
          @click.native.left="select(item.id)"
          )  
          //- :isFocus="projectEditable && isSeleted(item.id)"
          //- :editable="projectEditable"
  //- FileBrowser
    template(slot='top')
      .top-bg.shd-m
        //- select.select.color-button-normal.color-text-2.size-button-m.f-p.ani-fast.radius-m(:value="projectId" @change="handleSelect")
          option.option(value="basic" name="basic" :selected="projectId == 'basic'") basic
          option.option(v-for="project in selectedTeamAllProjects" v-if="selectedTeamAllProjects" :key="project.id" :value="project.id" :name="project.name" :selected="projectId == project.id") {{project.name}}
        template(v-if="projectEditable")
          button.add.color-button-normal.color-text-2.size-button-m.ani-fast.f-p.radius-m(@click="addNewComponent" @click.alt="openFile") +
          //- input.add.color-button-normal.color-text-2.size-button-m.ani-fast.f-p.radius-m(ref="input" v-show="false" type="file" @change="uploadComponent" multiple="multiple")
    template(slot='content')
      .list(v-show="selectedProjectComponent && selectedProjectComponent.length > 0")
        template(v-for='(item, index) in selectedProjectComponent')
          ListItem(
            :progress="item.progress"
            :label="item.label"
            :editable="projectEditable"
            :design="isDesign(item)"
            :icon="icon(item.type)"
            :id="item.id"
            :isFocus="projectEditable && isSeleted(item.id)"
            :key="item.id"
            @click.native.left="select(item.id)"
          )  
        //- @click.native.left="projectEditable && seletedComponent(item.id)"
</template>

<style lang="stylus" scoped>

@require '../../styles/layout/main'
@require '../../styles/design/main'

.comp-finder
  stack(y, gap: 2rem)

.comp-finder--top
  stack(x, gap: 1rem, arr:start)

.comp-finder--body
  gridflow(gap:2rem, colmin:10rem)
  grid-row-gap 3rem
  justify-items center
  &>*
    width 10rem

.empty
  position absolute
  pos-abs(x:stretch, y: stretch)
  stack(y, gap: 2rem, ali: center, arr: center)


// .top-bg
//   // position
//   width 100%
//   // layout
//   gridflow(gap: .25rem, colmin: 8rem)
//   padding .5rem
//   // color 
//   background var(--clr-bg)
// .list
//   // layout
//   gridflow(gap: .5rem, colmin:12rem)
//   padding .5rem

// // select
// .select
// .add
//   width 100%
//   cursor pointer
// .option
//   padding 2rem
</style>

<script>
// components
import FileBrowser from "@/components/others/fileBrowser.vue";
import ListItem from "@/components/component_finder/list_item.vue";
import { component } from "@/services";
import { mapGetters } from "vuex";
import basicBuiltInComponents from "@/components/preview_area/built-in component/builtIn.js";

export default {
  name: "CompFinder",
  components: {
    FileBrowser,
    ListItem
  },
  data() {
    return {
      projectId: this.$route.params.id
    };
  },
  computed: {
    ...mapGetters(["selectedTeamAllProjects", "selectedComponent"]),
    selectedProject() {
      if (this.projectId == "basic") {
        return {
          editable: false,
          componentIds: []
        };
      }
      const getters = this.$store.getters;
      return getters.project(this.projectId);
    },
    projectEditable() {
      if (
        this.$store.getters.selectedProject &&
        this.$store.getters.selectedProject.id == this.projectId &&
        this.$store.getters.selectedProject.editable
      ) {
        return true;
      }
      return false;
    },
    selectedProjectComponent() {
      // 对写死的 basic 进行拦截
      if (this.projectId == "basic") {
        return basicBuiltInComponents;
      }
      const getters = this.$store.getters;
      return (
        this.selectedProject &&
        this.selectedProject.componentIds
          .map(id => getters.component(id))
          .filter(Boolean)
          .filter(comp => comp.status == 1)
      ); // 一定要过滤，有可能返回 undefined
    }
  },
  mounted() {
    this.$store.commit("setSelectedProjectId", { id: this.$route.params.id });
  },
  methods: {
    isDesign(component) {
      if ("root" in component) {
        return true;
      }
      return false;
    },
    isSeleted(id) {
      if (!this.selectedComponent) {
        return false;
      }
      return this.selectedComponent.id == id;
    },
    handleSelect(e) {
      const val = e.target.value;
      this.projectId = val;
    },
    icon(type) {
      return this.$utils.master.getComponentIcon(type);
    },
    addNewComponent() {
      this.$store.dispatch("createComponent", {
        design: true,
        root: null,
        label: "component"
      });
    },
    seletedComponent(id) {
      this.$store.dispatch("setSelectedComponentId", { id });
    },
    openFile() {
      this.$refs.input.click();
    },
    uploadComponent() {
      const files = Array.from(this.$refs.input.files);
      if (0 == files.length) {
        return;
      }
      Promise.all(files.map(component.compileVue)).then(() => {
        // 清空，要不然在次上传不会触发 change
        this.$refs.input.value = "";
      });
    },
    select(componentId) {
      if (!componentId) {
        return;
      }
      this.$router.push({
        params: {
          componentId: componentId,
          id: this.projectId
        },
        name: "ProjEditorDetail"
      });
      this.$store.dispatch("setSelectedComponentId", {
        id: componentId
      });
    }
  }
};
</script>
