<template lang="pug">
.wrap
  input.f-p.size-input-s.color-input-sub.radius-m.ani-fast(
    type="number"
    ref="input"
    :max="max"
    :min="min"
    :disabled="disabled"
    :placeholder="placeholder"
    :value="value"
    @input="onInput"
    @paste.prevent
  )
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.wrap
  display inline-grid

</style>


<script>
export default {
  name: "NumberEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    },
    param: String,
    integer: {
      type: Boolean,
      default: true
    },
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(e) {
      if (!e.target.value) return;
      let valueAsNumber = e.target.valueAsNumber;

      if (this.integer && !Number.isInteger(valueAsNumber)) {
        valueAsNumber = Number.parseInt(`${valueAsNumber}`, 10);
      }

      if (valueAsNumber < this.min) {
        valueAsNumber = this.min;
      } else if (valueAsNumber >= this.max) {
        valueAsNumber = this.max;
      }

      if (e.target.valueAsNumber !== valueAsNumber) {
        this.$refs.input.value = valueAsNumber;
      }

      this.$emit("change", valueAsNumber, this.param);
    }
  }
};
</script>
