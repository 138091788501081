

<template lang="pug">
//- 用来实现 Node 树和 Insert 布局关系

.node-root.radius-s(
    :class="{'is-being-dragging': isBeingDragging}" 
  )
  //- 本节点触发拖动，拖动时整个组件被拖动。
  .label-wrapper.radius-s(
    @click="seleceLayer()"
    @dragover.prevent="debouncedPreventEvent"
    @dragleave.native="resetHoverId"
    @dragenter.stop="dragenter"
    :class="{'is-drop-in': isDropIn}"
    @drop="insertLast"
    :draggable="!isSlotLayout && !root"
    @dragstart="dragstart" 
    @drag="_drag"
    @dragend="dragend"
    @click.right="openMenu"
    )
    //- 这里放 labelSlot 或 labelLayer，纯显示
    slot(name="label") display a label
  //- 用来接受拖入事件，触发 InsertAfter()。根节点不显示这个 Insert。
  insert.insert.after.abs(
    @dragover.native.prevent="debouncedPreventEvent"
    @drop.native.prevent="insertAfter" 
    v-show="!root && !isSlotLayout && ui.isDrag"
    )
  //- 缩进的部分，包括 Insert 和子节点
  .indent
    .children-wrapper
      .children(v-show="isExpand")
        //- 放子节点
        slot
      //- 用来接受拖入事件，触发 InsertFirst()。不能有儿子的 layer 不显示这个 Insert。
      insert.insert.first.abs(
        v-show="hasChildren && !noFirst && ui.isDrag"
        @dragover.native.prevent="debouncedPreventEvent"
        @drop.native.prevent="insertFirst"
      )
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main';
@require '../../../styles/design/main';

.node-root.is-being-dragging
  background: var(--clr-bg-3)
  *
    transition: none;
    visibility: hidden;
    pointer-events: none;
.label-wrapper.is-drop-in
    box-shadow inset 0 0 0 1px var(--clr-primary)
    background var(--clr-primary-shade-3)
    .is-focused
      box-shadow: inset 0 0 0 1px var(--clr-primary);
      background: var(--clr-primary-shade-3);
      color: var(--clr-content-2)

// 重置本组件内部 z-index
.node-root *
  z-index unset
// 重置本组件 z-index end

.children-wrapper,
.node-root
  position relative

.indent
  padding-left 1rem

nodeGap = .25rem
.indent
  position relative
  // stack(y, gap: nodeGap, ali: stretch)
  // padding-left 1rem
  // 显示竖线
  &::after
    content ""
    position absolute
    // right 0
    bottom .25rem
    top .5rem
    left .5rem
    border-left 1px solid var(--clr-shadow)
    pointer-events none

.label-wrapper
  display grid

// inset
insertH = 1.25rem;
LayerGap = 0.25rem;
childrenInset = 1rem;
insertInset = 0.5rem;

.insert
  // position
  position absolute
  height insertH
  z-index 1
  left 0
  right 0

.insert.first
  top -0.5 * insertH// + (LayerGap / 2) - (nodeGap / 2);

.insert.after {
  bottom: -0.5 * insertH// - (LayerGap / 2) + (nodeGap / 2)
}

</style>

<script>
import insert from "./InsertZone";
import { mapState } from "vuex";
import debounce from "lodash.debounce";
import throttle from "lodash.throttle";

export default {
  name: "TreeNode",
  components: {
    insert
  },
  props: {
    root: {
      default: false
    },
    targetSlot: {
      default: "default"
    },
    id: {
      type: String,
      required: true
    },
    icon: {
      type: String
    },
    hasChildren: {
      default: false
    },
    noFirst: {
      default: false // 多 solt 不显示 first
    },
    isSlotLayout: {
      default: false // 多 slot 情况, true 说明是处于 slot label
    }
  },
  data() {
    return {
      isBeingDragging: false,
      isExpand: true
      // isDropIn: false
    };
  },
  computed: {
    layerId() {
      return this.$store.state.layer.layerId;
    },
    ...mapState(["ui"]),
    isDropIn() {
      if (this.noFirst) {
        return this.ui.hoverLayerId == this.id;
      }
      return this.ui.hoverLayerId == this.id + this.targetSlot;
    },
    layer() {
      return this.$store.getters.layer(this.id);
    }
  },
  created: function() {
    this.debouncedPreventEvent = debounce(this.preventEvent, 400);
    this._drag = throttle(this.drag, 10);
  },
  methods: {
    seleceLayer() {
      this.resetHoverId();
      if (this.layerId == this.id) {
        // 暂时禁止用户可以关闭
        // this.$store.commit({
        //   type: "toggleLayerExpand",
        //   id: this.id
        // });
      } else {
        this.$store.dispatch({
          type: "selectLayer",
          id: this.id
        });
      }
    },
    insertAfter(e) {
      this.$store.dispatch({
        type: "insertLayerAfter",
        // 来自于哪儿，可以是已经创建的 layer (layer) 也可以未创建的 （master）
        from: e.dataTransfer.getData("from"),
        layerId: e.dataTransfer.getData("layerId"),
        label: e.dataTransfer.getData("label"),
        design: e.dataTransfer.getData("design"),
        componentId: e.dataTransfer.getData("componentId"),
        targetId: this.id,
        target: this.targetSlot
      });
    },
    insertFirst(e) {
      this.$store.dispatch({
        type: "insertLayerFirst",
        from: e.dataTransfer.getData("from"),
        layerId: e.dataTransfer.getData("layerId"),
        label: e.dataTransfer.getData("label"),
        design: e.dataTransfer.getData("design"),
        componentId: e.dataTransfer.getData("componentId"),
        targetId: this.id,
        target: this.targetSlot
      });
    },
    insertLast(e) {
      this.$store.dispatch({
        type: "insertLayerLast",
        from: e.dataTransfer.getData("from"),
        layerId: e.dataTransfer.getData("layerId"),
        label: e.dataTransfer.getData("label"),
        design: e.dataTransfer.getData("design"),
        componentId: e.dataTransfer.getData("componentId"),
        targetId: this.id,
        target: this.targetSlot
      });
      this.$store.dispatch({
        type: "selectLayer",
        id: this.id
      });
    },
    dragenter() {
      /**
       * enter 从子元素移动到父级别，是不会再次出发enter的，在 hover 里面进行调用
       */
      if (!this.hasChildren) {
        this.resetHoverId();
        return;
      }
      if (this.noFirst) {
        // 没有 first 的代表是多 slot 的顶层 label 节点
        this.setHoverId(this.id);
        return;
      }
      this.setHoverId(this.id + this.targetSlot);
    },
    setHoverId(id) {
      this.$store.commit("setUI", {
        key: "hoverLayerId",
        value: id
      });
    },
    resetHoverId: function() {
      // 保证在 setHoverId 延迟之后
      this.$nextTick(() => {
        this.$store.commit("setUI", {
          key: "hoverLayerId",
          value: 0
        });
      });
    },
    preventEvent(e) {
      this.dragenter();
      this.$utils.center();
      e.dataTransfer.dropEffect = "move";
      e.preventDefault();
      return false;
    },
    drag(e) {
      let x = e.clientX;
      let y = e.clientY;
      this.$store.commit("mousePozSet", { x, y });
    },
    dragstart(e) {
      this.$utils.resetDropImage(e);
      this.isBeingDragging = true;
      this.$store.commit("setUI", {
        key: "isDrag",
        value: true
      });
      this.$store.commit("dragThumbnailLabel", {
        icon: this.icon,
        label: this.layer.label
      });
      e.dataTransfer.setData("from", "layer");
      e.dataTransfer.setData("layerId", this.id);
      this.$store.commit("mousePozToggle", true);
    },
    dragend() {
      this.isBeingDragging = false;
      this.$store.commit("setUI", {
        key: "isDrag",
        value: false
      });
      this.resetHoverId();
      this.$store.commit("mousePozToggle", false);
    },
    openMenu(e) {
      const comp = this.$store.getters.selectedComponent;
      if (this.id == comp.root && comp.id == "workspace") {
        return;
      }
      if (this.isSlotLayout) {
        return;
      }
      // 是 slot label 就不显示
      this.$utils.setMenuPoz(e);
      // 内部组件没有解散操作
      if (
        this.$store.getters.selectedTeamExtenalProjectComponentIds.includes(this.layer.componentId) ||
        this.$utils.isInnerComponent(this.layer.componentId)
      ) {
        this.$store.commit("setUI", {
          key: "menus",
          value: [
            {
              text: "Delete",
              operation: () => {
                this.$store.dispatch("deleteLayer", { id: this.id });
              }
            },
            {
              text: "Duplicate",
              operation: () => {
                this.$store.dispatch("userCopyLayer", { id: this.id });
              }
            },
            {
              text: "Convert to Component",
              operation: () => {
                this.$store.dispatch("convertLayerToComponent", {
                  id: this.id
                });
                // .then(() => {
                //   this.$store.state.ui.currentTab = "components";
                //   this.$router.push({
                //     name: "ProjEditorDetail",
                //     params: {
                //       id: this.$store.state.project.selectedProjectId,
                //       componentId: this.$store.state.component.selectedComponentId
                //     }
                //   });
                // });
              }
            }
          ]
        });
        return;
      }

      this.$store.commit("setUI", {
        key: "menus",
        value: [
          {
            text: "Delete",
            operation: () => {
              this.$store.dispatch("deleteLayer", { id: this.id });
            }
          },
          {
            text: "Duplicate",
            operation: () => {
              this.$store.dispatch("userCopyLayer", { id: this.id });
            }
          },
          {
            text: "Convert to Component",
            operation: () => {
              this.$store.dispatch("convertLayerToComponent", { id: this.id });
              // .then(() => {
              //   this.$store.state.ui.currentTab = "components";
              //   this.$router.push({
              //     name: "ProjEditorDetail",
              //     params: {
              //       id: this.$store.state.project.selectedProjectId,
              //       componentId: this.$store.state.component.selectedComponentId
              //     }
              //   });
              // });
            }
          },
          {
            text: "Detach",
            operation: () => {
              this.$store.dispatch("componentToLayer", { id: this.id });
            }
          }
        ]
      });
    }
  }
};
</script>
