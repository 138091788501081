var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-drawer" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.ui.menus.length > 0,
            expression: "ui.menus.length > 0"
          }
        ],
        staticClass: "context-menu radius-l shd-l",
        style: _vm.ui.menusConfig
      },
      [
        _vm._l(_vm.ui.menus, function(menu) {
          return [
            menu.sep
              ? _c("menuLine")
              : _c("menuItem", {
                  attrs: { menuText: menu.text, operation: menu.operation }
                })
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }