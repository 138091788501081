<template lang="pug">
  div(:style="style")
    slot
</template>

<script>
import layoutRender from "@/render_func/layout_system/layoutRender/layout";
import padding from "@/render_func/padding.js";
import clip from "@/render_func/clip.js";
import commonStyle from "@/components/mixins/commonStyle";
import typeface from "@/components/mixins/typeface";
import typographyRender from "@/render_func/typography";
import textColorRender from "@/render_func/textcolor";

import merge from "lodash.merge";
const layout = () => ({
  layoutType: "off",
  off: {},
  stack: {
    direction: "x",
    arrange: "start",
    align: "stretch",
    gap: "0"
  },
  gridflow: {
    gap_x: "0",
    gap_y: "0",
    col_min_width: "100"
  },
  grid: {
    x: {
      count: 3,
      arrange: "stretch",
      size: "auto",
      gap: "0"
    },
    y: {
      count: 3,
      arrange: "stretch",
      size: "auto",
      gap: "0"
    }
  },
  padding: "0"
});
const typography = () => ({
  typeface: "inherit",
  fontSize: "inherit",
  lineHeight: "inherit",
  // fontScale: "inherit",
  textAlign: "inherit"
});

export default {
  name: "Box",
  props: {
    layout: {
      type: Object,
      proptype: "layout",
      isRequired: true,
      default: layout
    },
    textColor: {
      proptype: "color",
      isRequired: true,
      default: "inherit"
    },
    typography: {
      type: Object,
      proptype: "typography",
      isRequired: true,
      default: typography
    },
    clip: {
      type: Boolean,
      proptype: "booleanradio",
      isRequired: true,
      default: false
    },
    ...commonStyle.props
  },
  mixins: [commonStyle, typeface],
  slots: ["default"],
  provide() {
    return {
      layoutItem: layer => {
        return this.item(layer, this.containerProps);
      },
      layoutType: () => this.layout.layoutType
    };
  },
  computed: {
    childrenLayer() {
      const findChildrenLayer = (vm, children = []) => {
        // 假如是 bento-slot 进行穿透处理
        for (let child of vm.$children) {
          if (child.layer && child.layer.componentId == "bento-slot") {
            return vm.findChildrenLayer(child, children);
          }
          children.push(child.$children.map(v => v.layer).filter(Boolean));
        }
        return children;
      };
      return findChildrenLayer(this);
    },
    type() {
      return this.layout.layoutType;
    },
    containerProps() {
      return this.layout[this.type];
    },
    renderRule() {
      return layoutRender[this.type];
    },
    item() {
      return this.renderRule.item;
    },
    container() {
      return this.renderRule.container;
    },
    outletStyle() {
      return merge(this.commonStyle, padding(this.layout.padding), clip(this.clip), textColorRender(this.textColor));
    },
    typographyStyle() {
      return typographyRender(this.typography);
    },
    style() {
      if (typeof this.containerProps == "undefined") {
        const initValue = layout()[this.type];
        this.$store.dispatch("addLayoutType", { id: this.$parent.id, type: this.type, value: initValue });
        return merge(this.container(initValue, this.childrenLayer), this.outletStyle, this.typographyStyle);
      }
      return merge(this.container(this.containerProps, this.childrenLayer), this.outletStyle, this.typographyStyle);
    }
  }
};
</script>
