<template lang="pug">
  .label-slot.color-text-2 {{name}}
</template>

<style lang="stylus" scoped>
@require '../../../../styles/layout/main';
@require '../../../../styles/design/main';

.label-slot
  // background alpha(blue .1)
  // box-shadow inset 0 0 0 1px alpha(blue .5)
  padding 0.5rem
  overflow hidden
  pointer-events none
</style>

<script>
export default {
  props: ["name"]
};
</script>
