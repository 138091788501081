// container 样式渲染函数
import offContainer from "./container/off";
import previewAreaContainer from "./container/previewArea";
import gridContainer from "./container/grid";
import stackContainer from "./container/stack";
import componentContainer from "./container/component";
import scrollContainer from "./container/scroll";
import gridflowContainer from "./container/gridflow";

// 子 layer 样式渲染函数
import offItem from "./item/off";
import previewAreaItem from "./item/previewArea";
import gridItem from "./item/grid";
import stackItem from "./item/stack";
import componentItem from "./item/component";
import scrollItem from "./item/scroll";
import gridflowItem from "./item/gridflow";

export default {
  gridflow: {
    container: gridflowContainer,
    item: gridflowItem
  },
  off: {
    container: offContainer,
    item: offItem
  },
  scroll: {
    container: scrollContainer,
    item: scrollItem
  },
  previewArea: {
    container: previewAreaContainer,
    item: previewAreaItem
  },
  grid: {
    container: gridContainer,
    item: gridItem
  },
  stack: {
    container: stackContainer,
    item: stackItem
  },
  component: {
    container: componentContainer,
    item: componentItem
  }
};

export function defaultPosition(type = "off", x = 100, y = 100) {
  return {
    activeLayout: type,
    align: {
      absolute: {
        top: "0",
        left: "0",
        right: "off",
        bottom: "off"
      },
      previewArea: {
        top: "0",
        left: "0",
        right: "off",
        bottom: "off"
      },
      grid: {
        top: 1,
        left: 1,
        right: 1,
        bottom: 1
      },
      stack: {
        alignSelf: "off",
        flex: false
      }
    },
    size: {
      x: { default: x, min: "off", max: "off" },
      y: { default: y, min: "off", max: "off" }
    }
  };
}
