import merge from "lodash.merge";
export default function render(param) {
  const style = {
    fontSize: "inherit",
    lineHeight: "inherit"
  };

  if (param == "inherit") {
    return style;
  }

  const [fontSize, lineHeight] = param.split("@");

  if (lineHeight.includes("%")) {
    return merge(style, {
      fontSize,
      lineHeight
    });
  }

  return merge(style, {
    fontSize,
    lineHeight: lineHeight + "px"
  });
}
