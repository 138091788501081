// root of master viewer. 
// Master Viewer lists some specific type of Master, like Component, DesignToken

<template lang="pug">
  //- padding
  .padding
    //- content
    .bg.radius-m.shd-m.pointer-event-on
      //- toggleList
      .drawerlist
        //- 这是之前的，先注释了
        //- ToggleBotton(
          v-for='item in toggleButtons'
          :key="item.type"
          @click.native="switchDrawer(item.type)"

          :drawerType="item.type"
          :hint="item.hint"
          :isFocus="currentOpeningDrawer == item.type"
          :icon="item.icon"
          )
        ToggleBotton(
          v-for='item in toggleButtons'
          :key="item.type"
          @click.native="switchDrawer(item.type)"

          :drawerType="item.type"
          :hint="item.hint"
          :isFocus="currentOpeningDrawer == item.type"
          :icon="item.icon"
          )
      //- drawer
      DrawerContainer(
        v-show="currentOpeningDrawer != ''"
        :drawerType="currentOpeningDrawer"
        )
</template>

<style lang="stylus" scoped>
@require '../../../../styles/layout/main'
@require '../../../../styles/design/main'

.padding
  padding .5rem
  padding-right 0
.bg
  stack(x)
  background var(--clr-bg)
  max-height 100%
.drawerlist
  stack(y)
  padding .5rem 0
  z-index 2
</style>


<script>
import ToggleBotton from "./component/ToggleBotton.vue";
import DrawerContainer from "./component/DrawerContainer.vue";

export default {
  components: {
    ToggleBotton,
    DrawerContainer
  },
  data() {
    return {
      toggleButtons: [
        {
          type: "Internal",
          hint: "Insert",
          icon: "toolbar/add"
        }
        // {
        //   type: "External",
        //   hint: "External Components",
        //   icon: "component/component"
        // }
      ],
      currentOpeningDrawer: ""
    };
  },
  computed: {
    // items() {
    //   return this.$utils.master.basicComponentMenu();
    // },
    // 右边组件/图片抽屉是否打开
    isDrawerOpened() {
      if (this.drawerType == "component") {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    switchDrawer(a) {
      if (this.currentOpeningDrawer == a) {
        this.currentOpeningDrawer = "";
      } else {
        this.currentOpeningDrawer = a;
      }
    }
    // toggle(type) {
    //   this.masterType = type;
    // }
  }
};
</script>
