// todo 不能工作 ing
<template lang="pug">
  .tab-group
    .tab.active.ani-fast
      .tab-name.f-h3 Projects
      .border.pill
    .tab.pointer.ani-fast
      .tab-name.f-h3 Sites
      .border.pill
    .tab.pointer.ani-fast
      .tab-name.f-h3 Sites
      .border.pill
</template>
<script>
export default {};
</script>
<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'

.tab-group
  stack(x)
.tab
  padding 0 1rem
  color var(--clr-content-2)
  &:not(.active)
    &:hover
      color var(--clr-content-3)
      @extend .pointer
    &>.border
      opacity 0
  &.active
    color var(--clr-content)
.tab-name
  padding 1rem 0
.border
  height 3px
  width 100%
  background var(--clr-content-3)

</style>
