<template lang="pug">
  bRadioBtn(
    :options="options"
    :value="value"
    @change="$emit('change', $event)"
  )
</template>

<script>
export default {
  name: "TextAlignEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    options: {
      type: Array,
      default() {
        return [
          {
            label: "",
            value: "inherit",
            icon: "off"
          },
          {
            label: "",
            value: "start",
            icon: "textalign/start"
          },
          {
            label: "",
            value: "center",
            icon: "textalign/center"
          },
          {
            label: "",
            value: "end",
            icon: "textalign/end"
          },
          {
            label: "",
            value: "stretch",
            icon: "textalign/stretch"
          }
        ];
      }
    },
    value: {
      type: String,
      default: "stretch"
    }
  }
};
</script>
