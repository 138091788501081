/* eslint-disable */
export function mockBackend() {
  let users = [
    {
      id: 1,
      email: "vinciarts@gmail.com",
      name: "vinci zhou",
      accounts: [
        {
          id: 1,
          name: "Account-1",
          projects: [
            {
              name: "My First Bento Project",
              lastModify: "10-01-2018",
              screens: null,
              components: null,
              designTokens: null
            },
            {
              name: "My First Bento Project Version 2",
              lastModify: "10-02-2018",
              screens: null,
              components: null,
              designTokens: null
            }
          ]
        },
        {
          id: 2,
          name: "Account-2",
          projects: [
            {
              name: "My Second Bento Project",
              lastModify: "09-09-2018",
              screens: null,
              components: null,
              designTokens: null
            },
            {
              name: "My Second Bento Project Version 4",
              lastModify: "09-19-2018",
              screens: null,
              components: null,
              designTokens: null
            }
          ]
        }
      ]
    }
  ];
  // let realFetch = window.fetch;
  // window.fetch = function(url, opts) {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (url.endsWith("/user/auth") && opts.method === "POST") {
  //         let params = JSON.parse(opts.body);

  //         // Pair to fake credentials
  //         let filteredUsers = users.filter(user => {
  //           return user.email === params.email;
  //         });

  //         if (filteredUsers.length) {
  //           let user = filteredUsers[0];
  //           let responseJson = {
  //             id: user.id,
  //             email: user.email,
  //             name: user.name,
  //             accounts: user.accounts
  //           };
  //           resolve({
  //             ok: true,
  //             text: () => Promise.resolve(JSON.stringify(responseJson))
  //           });
  //         } else {
  //           reject("Email is incorrect");
  //         }

  //         return;
  //       }

  //       // get mulitple users
  //       // for future usage
  //       if (url.endsWith("/users") && opts.method === "GET") {
  //         // 200 if token is valid
  //         // Make Auth Headers by converting to Base64
  //         if (opts.headers && opts.headers.Authorization === `Basic ${window.btoa("vinciarts@gmail.com")}`) {
  //           resolve({
  //             ok: true,
  //             text: () => Promise.resolve(JSON.stringify(users))
  //           });
  //         } else {
  //           // 401 if token is null or invalid
  //           reject("Unauthorised");
  //         }

  //         return;
  //       }

  //       realFetch(url, opts).then(response => resolve(response));
  //     }, 500);
  //   });
  // };
}
