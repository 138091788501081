var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "prop-editor",
      class: [{ x: _vm.x }, _vm.level, { "extend-label": _vm.extend }]
    },
    [
      _c("div", { staticClass: "label flex" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }