<template lang="pug">
.prop-folder.radius-m.shd-m.ani
  .f-title.color-text(v-if="label") {{label}}
  slot
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.prop-folder
  background var(--clr-bg)
  padding .5rem
  // width 12rem
  // display grid
  stack(y, gap: .5rem)
</style>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    }
  }
};
</script>
