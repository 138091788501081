// 重复了
<template lang="pug">
  .bento-select
    div.f-p.size-input-s.color-input-sub.radius-m.ani-fast(
      v-if="!isSearch"
      @click="toggleSearch"
    ) {{ value || placeholder }}
    input.f-p.size-input-s.color-input-sub.radius-m.ani-fast(
      v-if="isSearch"
      :value="keyword"
      :placeholder="value"
      ref="input"
      @input="onInput"
      @focus.stop="onFocus"
      @blur.stop="onBlur"
      @keyup.esc="onKeyEsc"
      @keyup.up="onKeyUp"
      @keyup.down="onKeyDown"
      @keyup.enter="onChange"
    )
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.bento-select
  position relative
  display inline-grid
</style>

<script>
import { generate } from "shortid";
import EditorBus from "@/eventBus/EditorBus.js";

export default {
  name: "BentoSelect",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: "Please choose an option"
    },
    value: {
      type: [String, Number],
      default: ""
    },
    // 暂不使用该属性
    showSearch: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isSearch: false,
      active: "",
      keyword: "",
      eventName: generate()
    };
  },
  computed: {
    filterOptions() {
      if (!this.keyword) return this.options;
      return this.options.filter(i => i.toLowerCase().includes(this.keyword.toLowerCase()));
    }
  },
  methods: {
    toggleSearch() {
      this.isSearch = !this.isSearch;
      if (this.isSearch) {
        this.$nextTick(function() {
          this.$refs.input.focus();
        });
      }
    },
    updateOptionSelector(params = {}) {
      const domRect = this.$refs.input.getBoundingClientRect();
      const appRect = document.getElementById("app").getBoundingClientRect();

      let optionSelectorTop = domRect.bottom + 8;
      if (optionSelectorTop + 200 > appRect.height) {
        optionSelectorTop = domRect.top - 208;
      }

      this.$store.commit("setUI", {
        key: "optionSelector",
        value: {
          visible: true,
          options: this.filterOptions,
          value: this.value,
          active: this.active,
          keyword: this.keyword,
          top: optionSelectorTop,
          left: domRect.left,
          width: domRect.width,
          height: 200,
          eventName: this.eventName,
          ...params
        }
      });
    },
    onInput(e) {
      this.keyword = e.target.value;
      return this.updateOptionSelector({ visible: true });
    },
    onFocus() {
      return this.updateOptionSelector({ visible: true });
    },
    onBlur() {
      this.active = "";
      this.keyword = "";
      this.toggleSearch();
      return this.updateOptionSelector({ visible: false });
    },
    onKeyUp() {
      const optionsLen = this.filterOptions.length;
      const currIndex = this.filterOptions.indexOf(this.active);
      if (currIndex === -1) {
        this.active = this.filterOptions[0];
      } else if (currIndex === 0) {
        this.active = this.filterOptions[optionsLen - 1];
      } else {
        this.active = this.filterOptions[currIndex - 1];
      }
      return this.updateOptionSelector({ visible: true });
    },
    onKeyDown() {
      const optionsLen = this.filterOptions.length;
      const currIndex = this.filterOptions.indexOf(this.active);
      if (currIndex === -1) {
        this.active = this.filterOptions[0];
      } else if (currIndex === optionsLen - 1) {
        this.active = this.filterOptions[0];
      } else {
        this.active = this.filterOptions[currIndex + 1];
      }
      return this.updateOptionSelector({ visible: true });
    },
    onKeyEsc() {
      this.$refs.input.blur();
    },
    onChange() {
      if (this.active) {
        this.$emit("change", this.active);
      }
      this.$refs.input.blur();
    },
    updateValue(value) {
      this.$emit("change", value);
    }
  },
  created() {
    EditorBus.$on(this.eventName, this.updateValue);
  },
  beforeDestroy() {
    EditorBus.$off(this.eventName, this.updateValue);
  }
};
</script>
