export * from "./mock-backend";
export * from "./router";
export * from "./authHead";
export * from "./yugo";
export * from "./handleError";

export function insertCSS(rawCSS, id) {
  const scriptTag = document.createElement("style");
  scriptTag.setAttribute("type", "text/css");
  scriptTag.setAttribute("id", id);
  scriptTag.textContent = rawCSS;
  document.getElementsByTagName("head")[0].appendChild(scriptTag);
}
