var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "scroll",
    {
      staticClass: "flex",
      attrs: { dir: "y", padding: "0 0.5rem", hideBar: "" }
    },
    [
      _c("div", { staticClass: "lib-list" }, [
        _c("div", { staticClass: "library" }, [
          _c(
            "div",
            { staticClass: "library--comp-list" },
            _vm._l(_vm.BuiltInComponents, function(item, index) {
              return _c("ListItem", {
                key: item.id,
                attrs: {
                  progress: item.progress,
                  label: item.label,
                  icon: _vm.icon(item.icon),
                  id: item.id,
                  isFocus: false
                }
              })
            }),
            1
          )
        ]),
        _vm.selectedProjectComponent.length > 0
          ? _c("div", { staticClass: "library" }, [
              _c(
                "div",
                {
                  staticClass:
                    "library--title ellipsis sticky f-title color-text-2"
                },
                [_vm._v("Local Components")]
              ),
              _c(
                "div",
                { staticClass: "library--comp-list" },
                _vm._l(_vm.selectedProjectComponent, function(item, index) {
                  return _c("ListItem", {
                    key: item.id,
                    attrs: {
                      progress: item.progress,
                      label: item.label,
                      icon: _vm.componentSlotIcon(item),
                      id: item.id,
                      isFocus: _vm.isSeleted(item.id)
                    }
                  })
                }),
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }