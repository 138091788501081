var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "input f-p size-input-s radius-m ani-fast",
    attrs: { placeholder: _vm.placeholder },
    domProps: { value: _vm.value },
    on: { change: _vm.upload }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }