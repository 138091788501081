import merge from "lodash.merge";
export default function render(param) {
  const style = {
    fontFamily: "inherit",
    fontWeight: "inherit"
  };

  if (param == "inherit") {
    return style;
  }
  // [0] is fontType -> generic or google
  /* eslint-disable-next-line */
  const [_, fontFamily, fontWeight] = param.split("@");

  return merge(style, {
    fontFamily,
    fontWeight
  });
}
