export default function render({ objectfit, alignX, alignY }) {
  if (objectfit == "none" || objectfit == "cover") {
    return {
      objectFit: objectfit,
      objectPosition: `${alignX} ${alignY}`
    };
  } else {
    return {
      objectFit: objectfit
    };
  }
}
