export const firstNames = [
  "Eighty",
  "Crabby",
  "Sectile",
  "Quintic",
  "Rhinal",
  "Frontless",
  "Sarky",
  "Tailing",
  "Cissoid",
  "Afraid",
  "Unplucked",
  "Cirrate",
  "Unmourned",
  "Unspilled",
  "Prudish",
  "Lovesick",
  "Askance",
  "Gorgeous",
  "Foretold",
  "Blurry",
  "Humic",
  "Corky",
  "Stupid",
  "Disliked",
  "aback",
  "abaft",
  "abandoned",
  "abashed",
  "aberrant",
  "abhorrent",
  "abiding",
  "abject",
  "ablaze",
  "able",
  "abnormal",
  "aboard",
  "aboriginal",
  "abortive",
  "abounding",
  "abrasive",
  "abrupt",
  "absent",
  "absorbed",
  "absorbing",
  "abstracted",
  "absurd",
  "abundant",
  "abusive",
  "acceptable",
  "accessible",
  "accidental",
  "accurate",
  "acid",
  "acidic",
  "acoustic",
  "acrid",
  "action",
  "active",
  "actual",
  "actually",
  "adamant",
  "adaptable",
  "addicted",
  "addicting",
  "adept",
  "adhesive",
  "adjoining",
  "adorable",
  "adored",
  "adroit",
  "adventurous",
  "affectionate",
  "afraid",
  "aggressive",
  "agile",
  "agonizing",
  "agreeable",
  "ahead",
  "airy",
  "ajar",
  "alcoholic",
  "alert",
  "alike",
  "alive",
  "alleged",
  "alluring",
  "aloof",
  "alter",
  "amazing",
  "ambiguous",
  "ambitious",
  "amiable",
  "ample",
  "amuck",
  "amused",
  "amusing",
  "ancient",
  "angry",
  "animated",
  "annoyed",
  "annoying",
  "anxious",
  "apathetic",
  "apt",
  "aquatic",
  "ardent",
  "aromatic",
  "arrogant",
  "ashamed",
  "aspiring",
  "assorted",
  "astonishing",
  "astute",
  "attractive",
  "august",
  "auspicious",
  "automatic",
  "available",
  "average",
  "avid",
  "awake",
  "aware",
  "awesome",
  "awful",
  "axiomatic",
  "bad",
  "balmy",
  "barbarous",
  "bashful",
  "bawdy",
  "beautiful",
  "beefy",
  "befitting",
  "belligerent",
  "beneficial",
  "benevolent",
  "bent",
  "berserk",
  "best",
  "better",
  "bewildered",
  "big",
  "billowing",
  "billowy",
  "bitter",
  "bizarre",
  "black",
  "blessed",
  "bloody",
  "blue",
  "blushing",
  "boiling",
  "bold",
  "boorish",
  "bored",
  "boring",
  "boss",
  "bouncy",
  "boundless",
  "brainy",
  "brash",
  "brave",
  "brawny",
  "breakable",
  "breezy",
  "brief",
  "bright",
  "brisk",
  "broad",
  "broken",
  "brown",
  "bumpy",
  "burly",
  "bustling",
  "busy",
  "cagey",
  "calculating",
  "callous",
  "calm",
  "can",
  "canny",
  "capable",
  "capricious",
  "cared",
  "careful",
  "careless",
  "caring",
  "casual",
  "cautious",
  "ceaseless",
  "celestial",
  "certain",
  "changeable",
  "charming",
  "cheap",
  "cheerful",
  "chemical",
  "chic",
  "chief",
  "childlike",
  "chilly",
  "chivalrous",
  "choice",
  "chosen",
  "chubby",
  "chummy",
  "chunky",
  "civic",
  "civil",
  "clammy",
  "classy",
  "clean",
  "clear",
  "clever",
  "cloistered",
  "close",
  "closed",
  "cloudy",
  "clumsy",
  "cluttered",
  "cogent",
  "coherent",
  "cold",
  "colorful",
  "colossal",
  "combative",
  "comfortable",
  "common",
  "complete",
  "complex",
  "composed",
  "concerned",
  "condemned",
  "confused",
  "conscious",
  "cooing",
  "cool",
  "cooperative",
  "coordinated",
  "cosmic",
  "courageous",
  "cowardly",
  "cozy",
  "crabby",
  "craven",
  "crazy",
  "creepy",
  "crooked",
  "crowded",
  "cruel",
  "cuddly",
  "cultured",
  "cumbersome",
  "curious",
  "curly",
  "curved",
  "curvy",
  "cut",
  "cute",
  "cynical",
  "daffy",
  "daily",
  "dainty",
  "damaged",
  "damaging",
  "damp",
  "dandy",
  "dangerous",
  "dapper",
  "daring",
  "dark",
  "dashing",
  "dazzling",
  "dead",
  "deadpan",
  "deafening",
  "dear",
  "debonair",
  "decent",
  "decisive",
  "decorous",
  "deep",
  "deeply",
  "defeated",
  "defective",
  "defiant",
  "deft",
  "delicate",
  "delicious",
  "delightful",
  "delirious",
  "deluxe",
  "demonic",
  "dependent",
  "depressed",
  "deranged",
  "descriptive",
  "deserted",
  "detailed",
  "determined",
  "devilish",
  "devout",
  "didactic",
  "different",
  "difficult",
  "diligent",
  "direct",
  "direful",
  "dirty",
  "disagreeable",
  "disastrous",
  "discreet",
  "disgusted",
  "disgusting",
  "disillusioned",
  "dispensable",
  "distinct",
  "disturbed",
  "divergent",
  "divine",
  "dizzy",
  "domineering",
  "doted",
  "doting",
  "doubtful",
  "drab",
  "draconian",
  "dramatic",
  "dreamy",
  "dreary",
  "driven",
  "drunk",
  "dry",
  "dull",
  "dusty",
  "dynamic",
  "dysfunctional",
  "eager",
  "early",
  "earsplitting",
  "earthy",
  "easy",
  "eatable",
  "economic",
  "educated",
  "efficacious",
  "efficient",
  "eight",
  "elastic",
  "elated",
  "elderly",
  "electric",
  "elegant",
  "elfin",
  "elite",
  "embarrassed",
  "eminent",
  "empty",
  "enchanted",
  "enchanting",
  "encouraging",
  "end",
  "endurable",
  "energetic",
  "energized",
  "enigmatic",
  "enormous",
  "entertaining",
  "enthusiastic",
  "envious",
  "equable",
  "equal",
  "erect",
  "erratic",
  "ethereal",
  "evanescent",
  "evasive",
  "even",
  "evil",
  "exact",
  "excellent",
  "excited",
  "exciting",
  "exclusive",
  "exotic",
  "expensive",
  "expert",
  "exuberant",
  "exultant",
  "fabulous",
  "faded",
  "faint",
  "fair",
  "faithful",
  "fallacious",
  "false",
  "famed",
  "familiar",
  "famous",
  "fanatical",
  "fancy",
  "fantastic",
  "far",
  "fascinated",
  "fast",
  "fat",
  "faulty",
  "fearful",
  "fearless",
  "feeble",
  "feigned",
  "female",
  "fertile",
  "festive",
  "few",
  "fierce",
  "fiery",
  "filthy",
  "fine",
  "finicky",
  "first",
  "fit",
  "five",
  "fixed",
  "flagrant",
  "flaky",
  "flashy",
  "flat",
  "flawless",
  "fleet",
  "flimsy",
  "flippant",
  "flowery",
  "flowing",
  "fluent",
  "fluffy",
  "fluttering",
  "flying",
  "foamy",
  "fond",
  "foolish",
  "for",
  "foregoing",
  "forgetful",
  "forlorn",
  "fortunate",
  "four",
  "fragile",
  "frail",
  "frank",
  "frantic",
  "free",
  "freezing",
  "frequent",
  "fresh",
  "fretful",
  "friendly",
  "frightened",
  "frightening",
  "full",
  "fumbling",
  "fun",
  "functional",
  "funny",
  "furry",
  "furtive",
  "fuscia",
  "future",
  "futuristic",
  "fuzzy",
  "gabby",
  "gainful",
  "gamy",
  "gaping",
  "garrulous",
  "gas",
  "gaudy",
  "general",
  "genial",
  "gentle",
  "giant",
  "giddy",
  "gifted",
  "gigantic",
  "giving",
  "glad",
  "glamorous",
  "gleaming",
  "glib",
  "glistening",
  "glorious",
  "glossy",
  "gnarly",
  "godly",
  "gold",
  "golden",
  "good",
  "goodly",
  "goofy",
  "gorgeous",
  "graceful",
  "grand",
  "grandiose",
  "grateful",
  "gratis",
  "gray",
  "greasy",
  "great",
  "greedy",
  "green",
  "grey",
  "grieving",
  "groovy",
  "grotesque",
  "grouchy",
  "grubby",
  "gruesome",
  "grumpy",
  "guarded",
  "guided",
  "guiltless",
  "gullible",
  "gusty",
  "gutsy",
  "guttural",
  "habitual",
  "half",
  "hallowed",
  "haloed",
  "halting",
  "handsome",
  "handsomely",
  "handy",
  "hanging",
  "hapless",
  "happy",
  "hard",
  "hardy",
  "harmonious",
  "harsh",
  "hateful",
  "heady",
  "healthy",
  "heartbreaking",
  "hearty",
  "heavenly",
  "heavy",
  "hellish",
  "helpful",
  "helpless",
  "heroic",
  "hesitant",
  "hideous",
  "high",
  "highfalutin",
  "hilarious",
  "hip",
  "hissing",
  "historical",
  "holistic",
  "hollow",
  "holy",
  "homeless",
  "homely",
  "honest",
  "honorable",
  "horrible",
  "hospitable",
  "hot",
  "huge",
  "hulking",
  "human",
  "humane",
  "humble",
  "humdrum",
  "humorous",
  "hungry",
  "hunky",
  "hurried",
  "hurt",
  "hushed",
  "husky",
  "hypnotic",
  "hysterical",
  "icky",
  "icy",
  "ideal",
  "idiotic",
  "ignorant",
  "ill",
  "illegal",
  "illustrious",
  "imaginary",
  "immense",
  "imminent",
  "immune",
  "impartial",
  "imperfect",
  "impolite",
  "important",
  "imported",
  "impossible",
  "incandescent",
  "incompetent",
  "inconclusive",
  "incredible",
  "indigo",
  "industrious",
  "inexpensive",
  "infamous",
  "innate",
  "innocent",
  "inquisitive",
  "insidious",
  "instinctive",
  "intelligent",
  "interesting",
  "internal",
  "invincible",
  "irate",
  "irritating",
  "itchy",
  "jaded",
  "jagged",
  "jazzed",
  "jazzy",
  "jealous",
  "jittery",
  "jobless",
  "jolly",
  "jovial",
  "joyful",
  "joyous",
  "jubilant",
  "judicious",
  "juicy",
  "jumbled",
  "jumpy",
  "just",
  "juvenile",
  "kaput",
  "keen",
  "khaki",
  "kind",
  "kindhearted",
  "kindly",
  "kingly",
  "knotty",
  "knowing",
  "knowledgeable",
  "known",
  "labored",
  "lackadaisical",
  "lacking",
  "lame",
  "lamentable",
  "languid",
  "large",
  "last",
  "late",
  "laughable",
  "lavish",
  "lawful",
  "lazy",
  "lean",
  "learned",
  "left",
  "legal",
  "legit",
  "lethal",
  "level",
  "lewd",
  "light",
  "like",
  "likeable",
  "liked",
  "likely",
  "limber",
  "limitless",
  "limping",
  "literate",
  "little",
  "lively",
  "living",
  "lonely",
  "long",
  "longing",
  "loose",
  "lopsided",
  "loud",
  "lousy",
  "loutish",
  "loved",
  "lovely",
  "loving",
  "low",
  "lowly",
  "loyal",
  "lucid",
  "lucky",
  "ludicrous",
  "lumpy",
  "lush",
  "luxuriant",
  "lying",
  "lyrical",
  "macabre",
  "macho",
  "maddening",
  "madly",
  "magenta",
  "magical",
  "magnificent",
  "main",
  "majestic",
  "major",
  "makeshift",
  "male",
  "malicious",
  "mammoth",
  "maniacal",
  "many",
  "marked",
  "married",
  "marvelous",
  "massive",
  "master",
  "material",
  "materialistic",
  "mature",
  "max",
  "maxed",
  "mean",
  "measly",
  "meaty",
  "medical",
  "meek",
  "mellow",
  "melodic",
  "melted",
  "merciful",
  "mere",
  "merry",
  "messy",
  "mighty",
  "military",
  "milky",
  "mindless",
  "miniature",
  "minor",
  "mint",
  "mirthful",
  "miscreant",
  "misty",
  "mixed",
  "moaning",
  "modern",
  "modest",
  "moldy",
  "momentous",
  "money",
  "moonlit",
  "moral",
  "motionless",
  "mountainous",
  "moving",
  "mucho",
  "muddled",
  "mundane",
  "murky",
  "mushy",
  "mute",
  "mutual",
  "mysterious",
  "naive",
  "nappy",
  "narrow",
  "nasty",
  "native",
  "natural",
  "naughty",
  "nauseating",
  "near",
  "neat",
  "nebulous",
  "necessary",
  "needed",
  "needless",
  "needy",
  "neighborly",
  "nervous",
  "new",
  "next",
  "nice",
  "nifty",
  "nimble",
  "nine",
  "nippy",
  "noble",
  "noiseless",
  "noisy",
  "nonchalant",
  "nondescript",
  "nonstop",
  "normal",
  "nostalgic",
  "nosy",
  "noted",
  "novel",
  "noxious",
  "null",
  "numberless",
  "numero",
  "numerous",
  "nutritious",
  "nutty",
  "oafish",
  "obedient",
  "obeisant",
  "obese",
  "obnoxious",
  "obscene",
  "obsequious",
  "observant",
  "obsolete",
  "obtainable",
  "oceanic",
  "odd",
  "offbeat",
  "okay",
  "old",
  "omniscient",
  "one",
  "onerous",
  "open",
  "opposite",
  "optimal",
  "orange",
  "ordinary",
  "organic",
  "ossified",
  "outgoing",
  "outrageous",
  "outstanding",
  "oval",
  "overconfident",
  "overjoyed",
  "overrated",
  "overt",
  "overwrought",
  "pacific",
  "painful",
  "painstaking",
  "pale",
  "paltry",
  "panicky",
  "panoramic",
  "parallel",
  "parched",
  "parsimonious",
  "past",
  "pastoral",
  "pathetic",
  "peaceful",
  "peachy",
  "penitent",
  "peppy",
  "perfect",
  "periodic",
  "permissible",
  "perpetual",
  "petite",
  "phobic",
  "physical",
  "picayune",
  "pink",
  "piquant",
  "pithy",
  "placid",
  "plain",
  "plant",
  "plastic",
  "plausible",
  "pleasant",
  "plucky",
  "plum",
  "poetic",
  "pointless",
  "poised",
  "polite",
  "political",
  "poor",
  "posh",
  "possessive",
  "possible",
  "potent",
  "powerful",
  "precious",
  "premium",
  "present",
  "pretty",
  "previous",
  "pricey",
  "prickly",
  "prime",
  "primo",
  "private",
  "prized",
  "pro",
  "probable",
  "productive",
  "profuse",
  "prompt",
  "proper",
  "protective",
  "proud",
  "psychedelic",
  "psychotic",
  "public",
  "puffy",
  "pumped",
  "punchy",
  "puny",
  "pure",
  "purple",
  "purring",
  "pushy",
  "puzzled",
  "puzzling",
  "quack",
  "quaint",
  "quarrelsome",
  "questionable",
  "quick",
  "quickest",
  "quiet",
  "quirky",
  "quixotic",
  "quizzical",
  "rabid",
  "racial",
  "rad",
  "radioactive",
  "ragged",
  "rainy",
  "rambunctious",
  "rampant",
  "rapid",
  "rare",
  "raspy",
  "ratty",
  "reach",
  "ready",
  "real",
  "rebel",
  "receptive",
  "recondite",
  "red",
  "redundant",
  "reflective",
  "regal",
  "regular",
  "relieved",
  "remarkable",
  "reminiscent",
  "repulsive",
  "resilient",
  "resolute",
  "resonant",
  "responsible",
  "rhetorical",
  "rich",
  "right",
  "righteous",
  "rightful",
  "rigid",
  "ripe",
  "ritzy",
  "roasted",
  "robust",
  "romantic",
  "roomy",
  "rooted",
  "rosy",
  "rotten",
  "rough",
  "round",
  "royal",
  "ruddy",
  "rude",
  "rugged",
  "rural",
  "rustic",
  "ruthless",
  "sable",
  "sad",
  "safe",
  "salty",
  "same",
  "sassy",
  "satisfying",
  "saucy",
  "savory",
  "savvy",
  "scandalous",
  "scarce",
  "scared",
  "scary",
  "scattered",
  "scenic",
  "scientific",
  "scintillating",
  "scrawny",
  "screeching",
  "second",
  "secret",
  "secretive",
  "sedate",
  "seemly",
  "selective",
  "selfish",
  "sensitive",
  "separate",
  "serene",
  "serious",
  "shaggy",
  "shaky",
  "shallow",
  "sharp",
  "shiny",
  "shivering",
  "shocking",
  "short",
  "showy",
  "shrewd",
  "shrill",
  "shut",
  "shy",
  "sick",
  "silent",
  "silky",
  "silly",
  "simple",
  "simplistic",
  "sincere",
  "six",
  "skillful",
  "skinny",
  "sleek",
  "sleepy",
  "slick",
  "slim",
  "slimy",
  "slippery",
  "sloppy",
  "slow",
  "small",
  "smart",
  "smelly",
  "smiley",
  "smiling",
  "smoggy",
  "smooth",
  "snappy",
  "snazzy",
  "sneaky",
  "snobbish",
  "snotty",
  "snowy",
  "snugly",
  "social",
  "soft",
  "soggy",
  "sole",
  "solid",
  "solitary",
  "somber",
  "sophisticated",
  "sordid",
  "sore",
  "sound",
  "sour",
  "spacial",
  "sparkling",
  "special",
  "spectacular",
  "spicy",
  "spiffy",
  "spiky",
  "spiritual",
  "spiteful",
  "splendid",
  "spooky",
  "spotless",
  "spotted",
  "spotty",
  "spry",
  "spurious",
  "squalid",
  "square",
  "squealing",
  "squeamish",
  "stable",
  "staking",
  "stale",
  "standing",
  "star",
  "stark",
  "statuesque",
  "steadfast",
  "steady",
  "steep",
  "stereotyped",
  "sticky",
  "stiff",
  "stimulating",
  "stingy",
  "stoic",
  "stormy",
  "straight",
  "strange",
  "striped",
  "strong",
  "stunning",
  "stupendous",
  "stupid",
  "sturdy",
  "suave",
  "subdued",
  "subsequent",
  "substantial",
  "subtle",
  "successful",
  "succinct",
  "sudden",
  "sulky",
  "sunny",
  "sunset",
  "super",
  "superb",
  "superficial",
  "supreme",
  "sure",
  "swank",
  "swanky",
  "sweet",
  "swell",
  "sweltering",
  "swift",
  "symptomatic",
  "synonymous",
  "taboo",
  "tacit",
  "tacky",
  "talented",
  "tall",
  "tame",
  "tan",
  "tangible",
  "tangy",
  "tart",
  "tasteful",
  "tasteless",
  "tasty",
  "tawdry",
  "teal",
  "tearful",
  "tedious",
  "teeny",
  "telling",
  "temporary",
  "ten",
  "tender",
  "tense",
  "tenuous",
  "terrible",
  "terrific",
  "tested",
  "testy",
  "thankful",
  "the",
  "therapeutic",
  "thick",
  "thin",
  "thinkable",
  "third",
  "thirsty",
  "thoughtful",
  "thoughtless",
  "threatening",
  "three",
  "thriving",
  "thundering",
  "tidy",
  "tight",
  "tightfisted",
  "timely",
  "tiny",
  "tired",
  "tiresome",
  "toothsome",
  "top",
  "tops",
  "torpid",
  "tough",
  "touted",
  "towering",
  "tranquil",
  "trashy",
  "tremendous",
  "tricky",
  "trim",
  "trite",
  "tropical",
  "troubled",
  "truculent",
  "true",
  "trusty",
  "truthful",
  "try",
  "two",
  "typical",
  "ubiquitous",
  "ugliest",
  "ugly",
  "ultra",
  "unable",
  "unaccountable",
  "unadvised",
  "unarmed",
  "unbecoming",
  "unbiased",
  "uncovered",
  "understood",
  "undesirable",
  "undisturbed",
  "unequal",
  "unequaled",
  "uneven",
  "unhealthy",
  "uninterested",
  "unique",
  "united",
  "unkempt",
  "unknown",
  "unnatural",
  "unruly",
  "unsightly",
  "unsuitable",
  "untidy",
  "unused",
  "unusual",
  "unwavering",
  "unwieldy",
  "unwritten",
  "upbeat",
  "uplifting",
  "uppity",
  "upset",
  "uptight",
  "urbane",
  "usable",
  "used",
  "useful",
  "useless",
  "utmost",
  "utopian",
  "utter",
  "uttermost",
  "vacuous",
  "vagabond",
  "vague",
  "valid",
  "valuable",
  "various",
  "vast",
  "vengeful",
  "venomous",
  "verdant",
  "versed",
  "vestal",
  "viable",
  "victorious",
  "vigorous",
  "violent",
  "violet",
  "vital",
  "vivacious",
  "vivid",
  "vocal",
  "vogue",
  "voiceless",
  "volatile",
  "voracious",
  "vulgar",
  "wacky",
  "waggish",
  "waiting",
  "wakeful",
  "wandering",
  "wanted",
  "wanting",
  "warlike",
  "warm",
  "wary",
  "wasteful",
  "watery",
  "weak",
  "wealthy",
  "weary",
  "wet",
  "whimsical",
  "whispering",
  "white",
  "whole",
  "wholesale",
  "wicked",
  "wide",
  "wiggly",
  "wild",
  "willing",
  "windy",
  "winged",
  "wired",
  "wiry",
  "wise",
  "wistful",
  "witty",
  "woebegone",
  "womanly",
  "wonderful",
  "wooden",
  "woozy",
  "workable",
  "worried",
  "worthless",
  "worthy",
  "wrathful",
  "wretched",
  "wrong",
  "wry",
  "xenophobic",
  "yellow",
  "yielding",
  "young",
  "youthful",
  "yummy",
  "zany",
  "zealous",
  "zesty",
  "zippy",
  "zonked"
];

export const lastNames = [
  "Eighty",
  "Marimba",
  "Uncle",
  "Piano",
  "Speedboat",
  "Gore-tex",
  "Laugh",
  "Lunch",
  "Battle",
  "Mouth",
  "Carbon",
  "Architecture",
  "Children",
  "Insulation",
  "Camera",
  "Cheese",
  "Hell",
  "Brace",
  "Limit",
  "Roof",
  "August",
  "Home",
  "Liquid",
  "Tire"
];
