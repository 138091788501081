var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "master" },
    [
      _c("LengthEditor", {
        staticClass: "fix-width top",
        attrs: { placeholder: "Top", value: _vm.computedTop },
        on: { change: _vm.onTopChange }
      }),
      _c(
        "div",
        { staticClass: "middle" },
        [
          _c("LengthEditor", {
            staticClass: "fix-width",
            attrs: { placeholder: "Left", value: _vm.computedLeft },
            on: { change: _vm.onLeftChange }
          }),
          _c(
            "div",
            {
              staticClass: "center flex border radius-s pointer ani-fast",
              class: { uniform: _vm.isUniform },
              on: {
                click: function($event) {
                  _vm.switchUniform()
                }
              }
            },
            [_vm._m(0)]
          ),
          _c("LengthEditor", {
            staticClass: "fix-width",
            attrs: { placeholder: "Right", value: _vm.computedRight },
            on: { change: _vm.onRightChange }
          })
        ],
        1
      ),
      _c("LengthEditor", {
        staticClass: "fix-width bottom",
        attrs: { placeholder: "Bottom", value: _vm.computedBottom },
        on: { change: _vm.onBottomChange }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cross" }, [
      _c("div", { staticClass: "x pill ani-fast" }),
      _c("div", { staticClass: "y pill ani-fast" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }