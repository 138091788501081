var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bento-button ani-fast pointer radius--m b-layer",
      class: [_vm.color_class, _vm.size_class, { hover: _vm.hover }],
      on: {
        touchstart: _vm.hoverStart,
        mouseenter: _vm.hoverStart,
        mouseleave: _vm.hoverEnd,
        touchend: _vm.hoverEnd
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }