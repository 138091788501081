var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "master" },
    [
      _c(
        "KeyValue",
        { attrs: { label: "X" } },
        [
          _c("SpaceEditor", {
            attrs: { value: _vm.left },
            on: { change: _vm.onLeftChange }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: "Y" } },
        [
          _c("SpaceEditor", {
            attrs: { value: _vm.top },
            on: { change: _vm.onTopChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }