var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "card" } }, [
    _c(
      "div",
      {
        staticClass: "item ani radius-m color-text-2 clip",
        class: { isBeingDragging: _vm.isBeingDragging, focus: _vm.isFocus },
        attrs: { draggable: "" },
        on: {
          dragstart: _vm.dragstart,
          drag: _vm.drag,
          dragover: _vm.preventEvent,
          dragend: _vm.dragend,
          contextmenu: function($event) {
            return _vm.showMenus($event)
          }
        }
      },
      [
        _c("div", { staticClass: "basic ani" }, [
          _c(
            "div",
            { staticClass: "icon-container" },
            [_c("icon", { staticClass: "icon", attrs: { name: _vm.icon } })],
            1
          ),
          _c("div", { staticClass: "name f-p ellipsis flex" }, [
            _vm._v(_vm._s(_vm.label))
          ]),
          _vm.showProgress
            ? _c("div", { staticClass: "label f-p color-text-3" }, [
                _vm._v(_vm._s(_vm.progress) + "%")
              ])
            : _vm.shortcut
              ? _c("div", { staticClass: "label f-p color-text-3" }, [
                  _vm._v(_vm._s(_vm.shortcut))
                ])
              : _vm._e()
        ]),
        _c("div", { staticClass: "actions abs ani-fast" }, [
          _vm.selectedComponent
            ? _c(
                "div",
                {
                  staticClass:
                    "action-button insert shd-m size-button-s radius-m ani-fast pointer",
                  on: { click: _vm.insert }
                },
                [_vm._v("insert")]
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }