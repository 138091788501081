<template lang="pug">
  .bg.pointer-event-on
    //- left side
    .left
      //- next & previous
      //- 点击->清空「当前编辑的组件」
      //- .btn.size-button-s.color-button-normal.radius-m.ani-fast.pointer.f-p(@click="close")
        icon.icon.lh-0(name="nav/close")
      //- .group.radius-m.clip
        button.size-button-m.color-button-normal.ani-fast.pointer.f-p <
        button.size-button-m.color-button-normal.ani-fast.pointer.f-p >
      //- component path
      .path
        //- button.size-button-m.color-button-normal.radius-m.ani-fast.pointer.f-p path
        //- button.size-button-m.color-button-normal.radius-m.ani-fast.pointer.f-p of
        .path-node.color-input-sub.radius-m.ani-fast
          icon.icon(name="component/component")
          input.input.comp-name.size-input-s.color-text-2.f-p(size=12 v-model="selectedComponentName")
    //- right side
    //- .right
      button.lh-0.size-button-s.color-button-normal.radius-m.ani-fast.pointer.f-p(@click="publishSite")
        icon.icon(name='export')
      //- button.lh-0.size-button-s.color-button-normal.radius-m.ani-fast.pointer.f-p
      //-   icon.icon(name='play')
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.left
.right
  stack(x, gap:.25rem)
.path
  stack(x, gap:.125rem)
  .path-node
    stack(x, gap:.125rem, ali:center)
    padding-left .25rem
    .icon
      width 1rem
      height 1rem
    .input
      background transparent
.btn
  display inline-grid
  place-content center
.comp-name
  padding-left .5rem
.group
  stack(x)
.lh-0
  line-height 0
.bg
  stack(x, arr: between)
  padding .25rem
  background var(--clr-bg)
.back-prv
  stack(x)
</style>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";
export default {
  components: {},
  props: {
    privacy: {
      type: String,
      default: "" // Private, Team, Public
    }
  },
  data() {
    return {};
  },
  methods: {
    publishSite() {
      this.$store.dispatch("publishComponent", {
        version: "0.0.1"
      });
    },
    update: debounce(function(val) {
      this.$store.dispatch("updateComponentInfomation", {
        label: val
      });
    }, 200)
    // close() {
    //   if (this.currentTab == "components") {
    //     this.$router.push({
    //       name: "ProjEditorIndex",
    //       params: this.$route.params
    //     });
    //   }
    // }
  },
  computed: {
    ...mapGetters(["selectedComponent", "selectedProject", "projectEditable"]),
    currentTab() {
      return this.$store.state.ui.currentTab;
    },
    selectedComponentName: {
      get() {
        /* eslint-disable-next-line */
        return this.selectedComponent ? this.selectedComponent.label : null;
      },
      set(val) {
        if (!this.selectedComponent) {
          return;
        }
        this.update(val);
      }
    }
  }
};
</script>
