//todo 不知道这是啥，为什么在这里？
<template lang="pug">
  .flex.stack
    CompFinder.flex
</template>

<script>
import CompFinder from "@/components/component_finder/finder.vue";
export default {
  name: "ProjectComponentBrowser",
  components: {
    CompFinder
  },
  watch: {
    $route: function() {
      this.$store.commit("setSelectedProjectId", { id: this.$route.params.id });
      this.$store.dispatch("fetchProject", { id: this.$route.params.id });
    }
  }
};
</script>
