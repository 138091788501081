<template lang="pug">
div.scroll-root(:class='[classDir]' :style='style')
  .padding(:style='paddingStyle')
    slot
</template>

<style lang="stylus" scoped>
.scroll-root
  touch-action manipulation pan-x pan-y
  &>.padding
    display inline-grid
  // dir
  &.x
    overflow-x auto
    overflow-y hidden
    &>.padding
      height  100%
      & > *
        height  100%
  &.y
    overflow-x hidden
    overflow-y auto
    &>.padding
      width  100%
      & > *
        width  100%
  &.both
    overflow auto
  // hidebar
  // &.hidebar
  //   &::-webkit-scrollbar
  //     display: none

</style>

<script>
import commonStyle from "@/components/mixins/commonStyle";
import layoutRender from "@/render_func/layout_system/layoutRender/layout";

export default {
  name: "BentoScroll",
  slots: ["default"],
  mixins: [commonStyle],
  props: {
    dir: {
      type: String,
      isRequired: true,
      proptype: "dir",
      default: "both" // x, y, both
    },
    padding: {
      type: String,
      isRequired: true,
      proptype: "padding",
      default: "0"
    },
    // hideBar: {
    //   type: Boolean,
    //   proptype: "boolean",
    //   default: false
    // },
    ...commonStyle.props
  },
  provide() {
    return {
      layoutItem: layer => {
        return layoutRender.scroll.item(layer, this.$props);
      },
      layoutType: () => "scroll"
    };
  },
  computed: {
    classDir() {
      if (this.dir == "x") {
        return "x";
      } else if (this.dir == "y") {
        return "y";
      } else {
        return "both";
      }
    },
    // classHideBar() {
    //   if (this.hideBar) {
    //     return "hidebar";
    //   }
    // },
    paddingStyle() {
      return {
        padding: this.padding
      };
    },
    style() {
      return {
        ...this.commonStyle
      };
    }
  }
};
</script>
