<template lang="pug">

  .radio-button.radius-m.clip
    .option.ani-fast.size-button-s.lh-0(
      v-for="(item, index) in options"
      :key="index"
      @click="handleClick(item)"
      :class="{ active: item.value === current }"
    )
      icon.icon(v-if="item.icon" :name="item.icon" :class="{rotate: rotate}")
      span.f-p(v-if="item.label") {{item.label}}

  //- select.f-p.size-input-s.color-button-normal.radius-m.ani-fast.pointer(@change="onChange" :value="value")
  //-   option(v-for="option in options", :value="option.toLowerCase()") {{ option }}
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'

.radio-button
  // layout
  display inline-grid
  grid-auto-flow column
  grid-auto-columns 1fr
  background var(--clr-bg-2)
  // style
  color var(--clr-content-2)
  & > *:first-child
    border-radius 4px 0 0 4px
  & > *:last-child
    border-radius 0 4px 4px 0
.option:not(.active)
  &:hover
    background var(--clr-bg-3)
  cursor pointer
  box-shadow inset 0 0 0 0 var(--clr-primary-shade-1)
.option.active
  background var(--clr-primary-shade-3)
  // color var(--clr-content-reverse)
  box-shadow inset 0 0 0 1px var(--clr-primary-shade-1)
  // background var(--clr-primary)
  // color var(--clr-content-reverse)
.icon
  width 1rem
  height 1rem
  &.rotate
    transform rotate(90deg)
.lh-0
  line-height 0
</style>

<script>
export default {
  name: "bRadioButton",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    rotate: {
      type: Boolean,
      default: false
    },
    value: [String, Number],
    options: {
      type: [Array],
      default: function() {
        return [
          // 参考值
          // {
          //   label: "Inherit",
          //   value: "value1",
          //   icon: "icon1"
          // }
        ];
      }
    }
  },
  data() {
    return {
      current: ""
    };
  },
  mounted() {
    this.current = this.value;
  },
  watch: {
    value(val) {
      this.current = val;
    }
  },
  methods: {
    handleClick(_item) {
      if (this.current === _item.value) return;
      this.current = _item.value;
      this.$emit("change", _item.value);
    }
  }
};
</script>
