var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "typeface-editor" },
    [
      _c(
        "KeyValue",
        { attrs: { label: "Typeface" } },
        [
          _c("bInputSelector", {
            attrs: {
              value: _vm.fontsFamily,
              defaultOptions: _vm.typefaceArr,
              placeholder: "Please choose an option"
            },
            on: { change: _vm.onFontsFamilyChange }
          })
        ],
        1
      ),
      _c(
        "KeyValue",
        { attrs: { label: _vm.label } },
        [
          _c("bInputSelector", {
            attrs: {
              disableTipInput: true,
              defaultOptions: _vm.weightArr,
              placeholder: "Please choose an option"
            },
            model: {
              value: _vm.weight,
              callback: function($$v) {
                _vm.weight = $$v
              },
              expression: "weight"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }