<template lang="pug">
.background-editor
  .title
    .flex.f-title.color-text Background
    b-switcher(:value="backgroundSwitch" @change="val => this.backgroundSwitch = val"  size="l")
  .form(v-if="backgroundSwitch")
    //- bDropSelector(:value="type" @change="onTypeChange" :options="['Custom', 'Transparent']")
    input.fix-width.color-input-sub.radius-m.ani-fast.size-input-s(@change="onValueChange" placeholder="Custom " :style="style" :value="value")
</template>

<style lang="stylus" scoped>
@require '../../styles/layout/main'
@require '../../styles/design/main'
.background-editor
  stack(y, gap: .5rem)
  .preview
    width 100%
    height 1.5rem
    display inline-grid
    place-items center
    box-shadow inset 0 0 0 1px var(--clr-shadow-2)
.fix-width
  width 100%
.title
  stack(x, gap:.5rem)
.form
  stack(y, gap: .25rem)
</style>

<script>
export default {
  name: "ColorEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    param: String
  },
  computed: {
    style() {
      return {
        background: this.value
      };
    },
    type() {
      // if (this.value !== "transparent") {
      //   return "custom";
      // }
      return this.value;
    },
    // showInput() {
    //   if (this.value !== "transparent" && this.value !== "inherit") {
    //     return true;
    //   }
    //   return false;
    // },
    backgroundSwitch: {
      get() {
        return this.value != "unset";
      },
      set(val) {
        if (val == false) {
          this.$emit("change", "unset", this.param);
          return;
        }
        this.$emit("change", "#fff", this.param);
      }
    }
  },
  methods: {
    // onTypeChange(v) {
    //   this.$emit("change", v, this.param);
    //   if (v == "custom") {
    //     this.$emit("change", "#fff", this.param);
    //     return;
    //   }
    // },
    onValueChange(e) {
      this.$emit("change", e.target.value, this.param);
    }
  }
};
</script>
