<template lang="pug">
div.flow-root
  .wrap(:style='wrapStyle')
    slot

</template>

<style lang="stylus" scoped>
.flow-root
  display inline-flex
  align-items stretch
.wrap
  display inline-flex
  position relative
  align-content flex-start
  flex-wrap wrap
  margin-left calc(var(--gap-x) * -1)
  margin-top calc(var(--gap-y) * -1)
  &>*
    position relative
    flex 0 0 auto
    margin-left var(--gap-x)
    margin-top var(--gap-y)

</style>

<script>
export default {
  name: "Flow",
  props: {
    dir: {
      type: String,
      default: "x" // x, y
    },
    gapX: {
      type: String,
      default: "0"
    },
    gapY: {
      type: String,
      default: "0"
    },
    arrange: {
      type: String,
      default: "start"
    },
    alignItems: {
      type: String,
      default: "stretch"
    }
  },
  computed: {
    value_dir() {
      if (this.dir == "x") {
        return "row";
      } else if (this.dir == "y") {
        return "column";
      }
    },
    value_alignItems() {
      if (this.alignItems == "start" || this.alignItems == "end") {
        return "flex-" + this.alignItems;
      } else {
        return this.alignItems;
      }
    },
    value_arrange() {
      if (this.arrange == "start" || this.arrange == "end") {
        return "flex-" + this.arrange;
      } else {
        return this.arrange;
      }
    },
    // add style to dom
    wrapStyle() {
      return {
        "flex-direction": this.value_dir,
        "align-items": this.value_alignItems,
        "justify-content": this.value_arrange,
        "--gap-x": this.gapX,
        "--gap-y": this.gapY
      };
    }
  }
};
</script>
