var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shadows" },
    [
      _vm._l(_vm.value, function(item, i) {
        return [
          _c("ShadowEditor", {
            key: "shadow-" + i,
            attrs: { value: item },
            on: {
              change: function(v) {
                return _vm.change(v, i)
              }
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }