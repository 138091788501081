<template lang="pug">
.typeface-editor
  KeyValue(label="Typeface")
    bInputSelector(
      :value="fontsFamily"
      :defaultOptions="typefaceArr"
      @change="onFontsFamilyChange"
      placeholder="Please choose an option"
    )
    //- (v-if="isGeneric || isGoogleFonts")
  KeyValue(:label="label")
    bInputSelector(
      :disableTipInput="true"
      v-model="weight"
      :defaultOptions="weightArr"
      placeholder="Please choose an option"
    )
</template>

<style lang="stylus" scoped>
@require '../../../styles/layout/main'
@require '../../../styles/design/main'
.typeface-editor
  display inline-grid
  grid-template-columns 1fr 1fr
  grid-column-gap .25rem
</style>

<script>
import { mapState } from "vuex";
import BentoSelect from "@/components/form_element/bento_select.vue";
import KeyValue from "@/components/prop_panel/component/label.vue";

export default {
  name: "TypefaceEditor",
  model: {
    prop: "value",
    event: "change"
  },
  components: {
    BentoSelect,
    KeyValue
  },
  props: {
    value: {
      type: String,
      default: "inherit"
    },
    param: String
  },
  watch: {
    value(newValue) {
      // 多人协作，同步更新 & 切换数据源更新 UI
      this.processValue(newValue);
    },
    result(newResult) {
      this.$emit("change", newResult, this.param);
    }
  },
  data() {
    return {
      fontsFamily: "inherit",
      weight: "inherit"
    };
  },
  computed: {
    ...mapState({
      googleFonts: state => state.fonts.fonts,
      generic: state => state.fonts.generic
    }),
    googleFontsFamilyArr() {
      return this.googleFonts.map(i => i.family);
    },
    typefaceArr() {
      return this.generic.concat(this.googleFontsFamilyArr);
    },
    type() {
      if (this.fontsFamily.toLowerCase() === "inherit") return "inherit";
      if (this.generic.includes(this.fontsFamily.toLowerCase())) return "generic";
      return "google";
    },
    isGeneric() {
      return this.type === "generic";
    },
    isGoogleFonts() {
      return this.type === "google";
    },
    weightArr() {
      if (this.type === "google") {
        const defaultArr = ["inherit"];
        return defaultArr.concat((this.googleFonts.find(i => i.family === this.fontsFamily) || {})["variants"]);
      } else {
        return ["inherit", "100", "200", "300", "400", "500", "600", "700", "800", "900"];
      }
    },
    label() {
      if (this.isGoogleFonts) return "Variants";
      return "Weight";
    },
    result() {
      return [this.type, this.fontsFamily, this.weight].join("@");
    }
  },
  methods: {
    processValue(value) {
      if (!value || !value.includes("@")) {
        this.fontsFamily = "inherit";
        this.weight = "inherit";
      } else {
        const valueArr = value.split("@");
        this.fontsFamily = valueArr[1];
        this.weight = valueArr[2];
      }
    },
    onFontsFamilyChange(value) {
      this.fontsFamily = value;
      // if (this.isGeneric) {
      //   this.weight = "400";
      // } else if (this.isGoogleFonts) {
      //   this.weight = "regular";
      // }
    }
  },
  created() {
    this.processValue(this.value);
  }
};
</script>
