var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scroll", attrs: { id: "scroll" } }, [
    _c(
      "div",
      {
        staticClass: "padding",
        on: {
          drop: _vm.uploadReceoverDrop,
          dragleave: _vm.noop,
          dragenter: _vm.noop,
          dragover: _vm.noop,
          dragend: _vm.noop,
          click: function($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.selectWorkspace($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "content-wrapper pointer-event-off",
            style: _vm.container,
            attrs: { id: "rootRender" }
          },
          [
            _vm.currentTab == "workspace"
              ? _c(
                  "div",
                  { staticClass: "zero pointer-event-on" },
                  _vm._l(_vm.childrenLayers, function(item) {
                    return _c("layer", { key: item.id, attrs: { id: item.id } })
                  }),
                  1
                )
              : [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _c("rootContainer-cutline", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showEditorUI,
                            expression: "showEditorUI"
                          }
                        ],
                        staticClass: "rootContainerCutline ani"
                      })
                    ],
                    1
                  ),
                  _vm.rootLayer
                    ? _c("layer", { attrs: { id: _vm.rootLayer.id } })
                    : _vm._e()
                ],
            _c("floatingEditor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEditorUI,
                  expression: "showEditorUI"
                }
              ],
              staticClass: "abs pointer-event-on"
            })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }