export default function render(value) {
  let str = value.reduce((acc, { x, y, blur, spread, inset, color }) => {
    return acc + `${x}px ${y}px ${blur}px ${spread}px ${color} ${inset ? "inset" : ""},`;
  }, "");
  if (str[str.length - 1] == ",") {
    str = str.slice(0, str.length - 1);
  }
  return {
    boxShadow: str
  };
}
