import getConfig from "../utils/config";
import { GOOGLEAPIKEY } from "@/common/constants.js";

const APIKEY = getConfig(GOOGLEAPIKEY);

const webFontsUrl = `https://www.googleapis.com/webfonts/v1/webfonts?key=${APIKEY}&sort=trending`;

export default async function getWebFonts() {
  return fetch(webFontsUrl, {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  }).then(d => d.json());
}
