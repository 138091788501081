var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "imgalign-editor" },
    [
      _c("b-radio-btn", {
        attrs: { options: _vm.valueOptions, value: _vm.value.objectfit },
        on: { change: _vm.onAlignChange }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showObjPos,
              expression: "showObjPos"
            }
          ],
          staticClass: "none-editor"
        },
        [
          _c("b-radio-btn", {
            attrs: { options: _vm.xOptions, value: _vm.value.alignX },
            on: { change: _vm.onAlignXChange }
          }),
          _c("b-radio-btn", {
            attrs: {
              options: _vm.yOptions,
              value: _vm.value.alignY,
              rotate: ""
            },
            on: { change: _vm.onAlignYChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }