<template lang="pug">
div.scroll-root(:class='[classDir, classHideBar]')
  .padding(
    :style='paddingStyle'
  )
    slot
</template>

<style lang="stylus" scoped>
.scroll-root
  touch-action manipulation pan-x pan-y
  // dir
  &.x
    overflow-x scroll
    overflow-y hidden
    &>.padding
      height 100%
      width fit-content
      &>*
        height 100%
  &.y
    overflow-x hidden
    overflow-y scroll
    &>.padding
      height fit-content
      width 100%
      &>*
        width 100%
  &.xy
    overflow scroll
  // hidebar
  &.hidebar
    &::-webkit-scrollbar
      display: none
</style>

<script>
export default {
  name: "Scroll",
  slots: ["default"],
  props: {
    dir: {
      type: String,
      default: "xy" // x, y, xy
    },
    padding: {
      type: String,
      default: ""
    },
    hideBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classDir() {
      if (this.dir == "x") {
        return "x";
      } else if (this.dir == "y") {
        return "y";
      } else {
        return "xy";
      }
    },
    classHideBar() {
      if (this.hideBar) {
        return "hidebar";
      }
    },
    paddingStyle() {
      return {
        padding: this.padding
      };
    }
  }
};
</script>
