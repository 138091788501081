<template lang="pug">
  .export-options
    .btn.ani-fast.radius-m.pointer.bold(@click="toggleDrawer") Export
    transition(name="fade-shd")
      .shadow.ani(@click="toggleDrawer" v-show="isOpen")
    transition(name="fade-drawer")
      .drawer.shd-l.radius-m.ani(v-show="isOpen")
        .f-title.color-text-1 Export
        .content
          .option-list
            .option.radius-m(
              v-for="option in exportOptions")
              .f-p.flex.bold {{option.label}}
              bBtn(size="s" color="gray") {{option.action}}
          //- .option.radius-m
            .f-p.bold I need more
</template>

<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.btn
  padding .5rem
  &:hover
    background $gray-7
.shadow
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  background alpha(black, .25)
  z-index 2

.drawer
  // position
  position absolute
  top calc(100% + .125rem)
  right 0
  z-index 3
  min-width 20rem
  // layout
  stack(y, gap: 1rem)
  padding 1rem
  // style
  color var(--clr-content-2)
  background white

.content
  // layout
  stack(y, gap: .5rem)

.option-list
  stack(y, gap: .25rem)
.option
  // layout
  stack(x, gap: .5rem, ali: center)
  padding .5rem .75rem
  // style
  background var(--clr-bg-2)
.note
  padding 0 .5rem

a
  color var(--clr-content-2)
  &:hover
    text-decoration-line underline
// animation
.fade-shd-enter, .fade-shd-leave-to
  opacity: 0;

.fade-drawer-enter, .fade-drawer-leave-to
  opacity: 0;
  transform translateY(.5rem)

</style>

<script>
export default {
  data() {
    return {
      isOpen: false,
      exportOptions: [
        { label: "Image", action: "Coming Soon" },
        { label: "Web Page", action: "Coming Soon" },
        { label: "React Component", action: "Coming Soon" },
        { label: "Vue Component", action: "Coming Soon" },
        { label: "Angular Component", action: "Coming Soon" }
      ]
    };
  },
  methods: {
    toggleDrawer() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
