var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-selector" }, [
    _c(
      "div",
      { staticClass: "position-ctrl" },
      [
        _c("input", {
          ref: "input",
          staticClass: "input f-p size-input-s clr-input-p1 radius-m ani-fast",
          class: { warn: _vm.hasError },
          attrs: { placeholder: _vm.placeholder },
          domProps: { value: _vm.selected },
          on: {
            input: _vm.onInput,
            focus: function($event) {
              $event.stopPropagation()
              return _vm.onFocus($event)
            },
            blur: function($event) {
              $event.stopPropagation()
              return _vm.onBlur($event)
            },
            keyup: [
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.onKeyEsc($event)
              },
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                return _vm.onKeyUp($event)
              },
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                return _vm.onKeyDown($event)
              },
              function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onChange($event)
              }
            ],
            change: _vm.onChange
          }
        }),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFocus && _vm.options.length > 0,
                  expression: "isFocus && options.length > 0"
                }
              ],
              staticClass: "option-list abs shd-xl radius-m ani-fast options",
              class: { top: _vm.top }
            },
            _vm._l(_vm.options, function(item) {
              return _c(
                "div",
                {
                  staticClass: "option f-small pointer ani-fast ellipsis",
                  class: { selected: _vm.active === item },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.handleClick(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }