var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "master" },
    [
      _c("div", { staticClass: "label f-p color-text-2" }, [_vm._v("Align")]),
      _c("NumberEditor", {
        staticClass: "fix-width top",
        attrs: { min: 1, value: _vm.align.top, placeholder: "Top" },
        on: { change: _vm.onTopChange }
      }),
      _c(
        "div",
        { staticClass: "middle" },
        [
          _c("NumberEditor", {
            staticClass: "fix-width",
            attrs: { min: 1, value: _vm.align.left, placeholder: "Left" },
            on: { change: _vm.onLeftChange }
          }),
          _c("div", { staticClass: "center flex border radius-s" }),
          _c("NumberEditor", {
            staticClass: "fix-width",
            attrs: { min: 1, value: _vm.align.right, placeholder: "Right" },
            on: { change: _vm.onRightChange }
          })
        ],
        1
      ),
      _c("NumberEditor", {
        staticClass: "fix-width bottom",
        attrs: { min: 1, value: _vm.align.bottom, placeholder: "Bottom" },
        on: { change: _vm.onBottomChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }