<template lang="pug">
  textarea.textarea.f-p.size-input-s.clr-input-p1.radius-m.ani-fast(
    :placeholder="placeholder"
    :value="value"
    @keydown.enter.prevent="upload"
    v-on:change="upload"
  )
</template>

<style lang="stylus" scoped>
@require '~@/styles/layout/main'
@require '~@/styles/design/main'

.textarea 
  height 4rem
  resize none

</style>


<script>
export default {
  name: "TextEditor",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: {
      type: String,
      default: "No Content"
    },
    value: {
      type: String,
      default: ""
    },
    param: String
  },
  methods: {
    upload(e) {
      this.$emit("change", e.target.value, this.param);
      this.$el.blur();
    }
  }
};
</script>
