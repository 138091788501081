var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "node-root radius-s",
      class: { "is-being-dragging": _vm.isBeingDragging }
    },
    [
      _c(
        "div",
        {
          staticClass: "label-wrapper radius-s",
          class: { "is-drop-in": _vm.isDropIn },
          attrs: { draggable: !_vm.isSlotLayout && !_vm.root },
          on: {
            click: function($event) {
              _vm.seleceLayer()
            },
            dragover: function($event) {
              $event.preventDefault()
              return _vm.debouncedPreventEvent($event)
            },
            dragenter: function($event) {
              $event.stopPropagation()
              return _vm.dragenter($event)
            },
            drop: _vm.insertLast,
            dragstart: _vm.dragstart,
            drag: _vm._drag,
            dragend: _vm.dragend,
            contextmenu: function($event) {
              return _vm.openMenu($event)
            }
          },
          nativeOn: {
            dragleave: function($event) {
              return _vm.resetHoverId($event)
            }
          }
        },
        [_vm._t("label", [_vm._v("display a label")])],
        2
      ),
      _c("insert", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.root && !_vm.isSlotLayout && _vm.ui.isDrag,
            expression: "!root && !isSlotLayout && ui.isDrag"
          }
        ],
        staticClass: "insert after abs",
        nativeOn: {
          dragover: function($event) {
            $event.preventDefault()
            return _vm.debouncedPreventEvent($event)
          },
          drop: function($event) {
            $event.preventDefault()
            return _vm.insertAfter($event)
          }
        }
      }),
      _c("div", { staticClass: "indent" }, [
        _c(
          "div",
          { staticClass: "children-wrapper" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isExpand,
                    expression: "isExpand"
                  }
                ],
                staticClass: "children"
              },
              [_vm._t("default")],
              2
            ),
            _c("insert", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasChildren && !_vm.noFirst && _vm.ui.isDrag,
                  expression: "hasChildren && !noFirst && ui.isDrag"
                }
              ],
              staticClass: "insert first abs",
              nativeOn: {
                dragover: function($event) {
                  $event.preventDefault()
                  return _vm.debouncedPreventEvent($event)
                },
                drop: function($event) {
                  $event.preventDefault()
                  return _vm.insertFirst($event)
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }