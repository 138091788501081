// import api from "./graphqlClient";
import { ready } from "@/helpers";
// import { insertCSS } from "@/helpers";
// import { parseComponent, compileToFunctions } from "vue-template-compiler";
import Vue from "vue";
import store from "@/store/store";
import { generate } from "shortid";

export async function processWebFile(file) {
  const { wait, done, end } = ready();
  const reader = new FileReader();
  reader.onload = e => {
    if (!e.target.result) {
      this.wait = false;
      end("无法正常解析");
    }
    done(e.target.result);
  };
  reader.readAsText(file);
  return wait;
}

export async function compileVue(file) {
  const id = generate();
  try {
    // const label = file.name.split(".vue").shift();
    const source = await processWebFile(file);
    await handleSource(id, source);
  } catch (error) {
    alert(error);
    store.dispatch("removeComponentFromSeletedProject", { id });
    store.commit("deleteComponent", { id });
  }
}

export async function handleSource(id, source) {
  const raw_source = await vueCompile(source);
  const { default: component } = eval(raw_source);
  await store.dispatch("createComponent", { id, label: component.name, code: source, design: false });
  Vue.component("code" + id, component);
}

import Firestore from "./init.js";

const firestoreComponentsInst = new Firestore({ collectionName: "components" });

export async function findById(id) {
  return firestoreComponentsInst.findById(id);
}

export async function create(component) {
  return firestoreComponentsInst.create({
    id: component.id,
    label: component.label,
    props: component.props,
    root: component.root
  });
}

export async function remove(component) {
  return firestoreComponentsInst.remove(component.id);
}

export async function update(component) {
  return firestoreComponentsInst.update({
    id: component.id,
    label: component.label,
    props: component.props,
    root: component.root
  });
}

// TODO: 本地编译 or 线上编译
export async function vueCompile(content) {
  return fetch(process.env.VUE_APP_API_URL + "/compile", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ content })
  }).then(d => d.text());
}
